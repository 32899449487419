import { cn } from '@/client/utils/cn'
import { BoardBackground } from '@/common/constants/boards'
import { useBoard } from '@components/boards/hooks/use-board'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { t } from '@lingui/macro'
import { IconGrid4x4, IconSquare, IconSquareDot } from '@tabler/icons-react'
import React from 'react'

export function BackgroundSwitcher() {
  const board = useBoard()

  const state = useBoardState()
  const operations = useBoardOperations()

  const backgrounds = [
    {
      value: BoardBackground.Grid,
      name: t`Grid`,
      icon: IconGrid4x4,
      description: t`Light or dark background with crossing lines`,
    },
    {
      value: BoardBackground.Dotted,
      name: t`Dotted`,
      icon: IconSquareDot,
      description: t`Light or dark background with dots`,
    },
    {
      value: BoardBackground.Plain,
      name: t`Plain`,
      icon: IconSquare,
      description: t`Light or dark background with no lines or dots`,
    },
  ]

  function saveBackground(background: BoardBackground) {
    operations.updateBoard({ background })
  }

  if (!board) return null
  if (state.isReadOnly) return null

  return (
    <div className='absolute left-4 top-32 z-[20000] flex flex-col overflow-hidden rounded-lg border border-slate-600/10 dark:border-slate-200/10'>
      {backgrounds.map(background => (
        <button
          key={background.value}
          className={cn(
            'flex cursor-pointer items-center gap-1.5 p-2 text-black/60 dark:text-white/60',
            {
              'bg-slate-600/5 dark:bg-slate-200/5':
                background.value ===
                (board?.background ?? BoardBackground.Grid),
            },
          )}
          onClick={() => saveBackground(background.value)}
          title={background.name}
        >
          <background.icon className='h-4 w-4' />
        </button>
      ))}
    </div>
  )
}
