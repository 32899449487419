import { cn } from '@/client/utils/cn'
import { LanguageIcon } from '@/client/utils/flags'
import { LanguageCode } from '@/common/constants'
import { MenuButton } from '@components/navigation/menu-button'
import { Modal } from '@components/ui/modal'
import { useLocaleMemo } from '@hooks/use-locale-memo'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useState } from 'react'

export function LanguagePicker() {
  const { client, authenticated } = useMetaboardAuth()
  const { i18n } = useLingui()
  const [open, setOpen] = useState(false)

  const languageLabelByCode = useLocaleMemo(() => ({
    [LanguageCode.EN]: t`English`,
    [LanguageCode.PT]: t`Portuguese`,
  }))

  const languages = useLocaleMemo(() => [
    {
      label: t`English`,
      value: LanguageCode.EN,
    },
    {
      label: t`Portuguese`,
      value: LanguageCode.PT,
    },
  ])

  return (
    <>
      <MenuButton onClick={() => setOpen(true)} keep>
        <img
          src={LanguageIcon[i18n.locale]}
          className='h-4 w-4'
          alt={languageLabelByCode[i18n.locale]}
        />
        <Trans>Language</Trans> ({languageLabelByCode[i18n.locale]})
      </MenuButton>

      <Modal
        onClose={() => {
          setOpen(false)
        }}
        isOpen={open}
        title={<Trans>Choose Language</Trans>}
        className='p-8'
      >
        <div className='flex items-center justify-center gap-2'>
          {languages.map(({ label, value }) => (
            <button
              key={value}
              className={cn(
                'flex cursor-pointer items-center gap-1.5 rounded-lg border border-gray-300 bg-transparent px-4 py-2 text-sm hover:bg-gray-200 hover:bg-slate-300/10 dark:border-gray-600',
                {
                  'border-indigo-500 dark:border-indigo-400':
                    i18n.locale === value,
                },
              )}
              onClick={async () => {
                setOpen(false)

                i18n.activate(value)

                if (authenticated) {
                  await client.call('user.update', { language: value })
                }
              }}
            >
              <img src={LanguageIcon[value]} className='h-4 w-4' alt={label} />
              {label}
            </button>
          ))}
        </div>
      </Modal>
    </>
  )
}
