import { cn } from '@/client/utils/cn'

export function ProgressBar({ value, className }) {
  return (
    <div
      className={cn(
        'h-2 w-full rounded-full bg-gray-200/50 dark:bg-gray-700/50',
        className,
      )}
    >
      <div
        className='striped-progress h-2 rounded-full !bg-lime-600 transition-all'
        style={{
          width: `${value * 100}%`,
        }}
      ></div>
    </div>
  )
}
