import {
  ClientBoardEdgeCollection,
  ClientBoardNodeCollection,
} from '@/client/data'
import { roundToGrid } from '@/common/boards/constraints'
import { BoardEvent, BoardNode } from '@/common/constants/boards'
import { Edge } from '@components/boards/edge'
import { useBoardId } from '@components/boards/hooks/use-board-id'
import { useDebouncedEffect } from '@components/boards/hooks/use-debounced-effect'
import { useEdgePositions } from '@components/boards/hooks/use-edge-positions'
import { useClient, useFindOne } from '@helenejs/react'
import { useHeleneEvent } from '@hooks/use-helene-event'
import React, { useCallback, useState } from 'react'

export function EdgeWrapper({
  edge,
  selectedNodeIds,
  virtualBounds,
  viewportPosition,
}) {
  const { source, target } = edge

  const client = useClient()

  const boardId = useBoardId()

  const sourceNode = useFindOne(ClientBoardNodeCollection, {
    _id: source,
  })

  const targetNode = useFindOne(ClientBoardNodeCollection, {
    _id: target,
  })

  const [sourceNodeProxy, setSourceNodeProxy] = useState<BoardNode<string>>()

  const [targetNodeProxy, setTargetNodeProxy] = useState<BoardNode<string>>()

  useDebouncedEffect(
    () => {
      setSourceNodeProxy(sourceNode)
      setTargetNodeProxy(targetNode)
    },
    20,
    [sourceNode, targetNode],
  )

  useHeleneEvent(
    BoardEvent.GroupMove,
    ({ id, x, y }) => {
      if (!sourceNode || !targetNode) return

      if (id === source || selectedNodeIds.includes(source)) {
        setSourceNodeProxy(sourceNodeProxy => ({
          ...sourceNodeProxy,
          x: roundToGrid(sourceNode.x + x),
          y: roundToGrid(sourceNode.y + y),
        }))
      }

      if (id === target || selectedNodeIds.includes(target)) {
        setTargetNodeProxy(targetNodeProxy => ({
          ...targetNodeProxy,
          x: roundToGrid(targetNode.x + x),
          y: roundToGrid(targetNode.y + y),
        }))
      }
    },
    [source, target, sourceNode, targetNode, selectedNodeIds],
  )

  useHeleneEvent(
    BoardEvent.NodeResize,
    ({ id, width, height }) => {
      if (id === source) {
        setSourceNodeProxy(sourceNodeProxy => ({
          ...sourceNodeProxy,
          width,
          height,
        }))
      }

      if (id === target) {
        setTargetNodeProxy(targetNodeProxy => ({
          ...targetNodeProxy,
          width,
          height,
        }))
      }
    },
    [source, target, sourceNode, targetNode, selectedNodeIds],
  )

  const { sourceSide, targetSide, sx, sy, tx, ty } = useEdgePositions(
    sourceNodeProxy,
    targetNodeProxy,
  )

  const deleteEdge = useCallback(async () => {
    ClientBoardEdgeCollection.remove({
      _id: edge._id,
    }).catch(console.error)

    client
      .call('boards.deleteEdge', {
        boardId,
        edgeId: edge._id,
      })
      .catch(console.error)
  }, [edge])

  return (
    <Edge
      id={edge._id}
      sx={sx}
      sy={sy}
      tx={tx}
      ty={ty}
      viewportPosition={viewportPosition}
      virtualBounds={virtualBounds}
      onDelete={deleteEdge}
      direction={edge.direction}
      sourceSide={sourceSide}
      targetSide={targetSide}
    />
  )
}
