import { FunkyButton } from '@components/ui/funky-button'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useClient, useFindOne } from '@helenejs/react'
import DeleteConfirmation from '@components/delete-confirmation'
import { t } from '@lingui/macro'
import { ClientBoardCollection } from '@/client/data'

type DeleteButtonProps = {
  boardId: string
}

export function DeleteButton({ boardId }: DeleteButtonProps) {
  const history = useHistory()
  const client = useClient()

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)

  const board = useFindOne(ClientBoardCollection, { _id: boardId })

  return (
    <>
      <FunkyButton
        variant='danger'
        onClick={e => {
          e.preventDefault()
          setIsDeleteConfirmationOpen(true)
        }}
      >
        Delete
      </FunkyButton>

      <DeleteConfirmation
        title={t`Delete Board`}
        message={t`Are you sure you want to delete the board "${board?.name}"? This action cannot be undone.`}
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onCancel={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={async () => {
          await client.call('boards.deleteBoard', { boardId })
          history.push('/boards')
        }}
      />
    </>
  )
}
