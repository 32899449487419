import { Button } from '@/client/components/ui/button'
import { extractTextFromHTML } from '@/client/utils/dom-utils'
import { DefaultVoiceIdByLC } from '@/common/constants'
import { useClient } from '@helenejs/react'
import { usePracticeState } from '@hooks/use-practice-state'
import { LucideLoader, LucideStopCircle, LucideVolume2 } from 'lucide-react'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { AudioUtils } from '../../utils/audio-utils'

const autoPlayedItems = []

export function PlayAudio({ text, languageCode, voiceId, className }) {
  const hasInitializedRef = useRef(false)

  const [isAudioLoading, setAudioLoading] = useState(false)
  const [isPlaying, setPlaying] = useState(false)

  const client = useClient()
  const { isAutoPlayEnabled } = usePracticeState()

  const init = useCallback(async () => {
    if (hasInitializedRef.current) return

    AudioUtils.unlock()
    hasInitializedRef.current = true
  }, [])

  const playAudio = useCallback(async () => {
    if (document.visibilityState !== 'visible') return

    if (isPlaying) {
      await AudioUtils.stop()
      setPlaying(false)
      return
    }

    AudioUtils.unlock()
    setAudioLoading(true)

    const result = await client.call('ai.synthesize', {
      text: extractTextFromHTML(text),
      languageCode,
      voiceId: voiceId ?? DefaultVoiceIdByLC[languageCode],
    })

    setAudioLoading(false)

    setPlaying(true)

    await AudioUtils.play(result.buffer, () => {
      setPlaying(false)
    })
  }, [text, languageCode, voiceId, isPlaying])

  useLayoutEffect(() => {
    if (isAutoPlayEnabled && !autoPlayedItems.includes(text)) {
      playAudio().catch(console.error)

      autoPlayedItems.push(text)

      if (autoPlayedItems.length > 10) {
        autoPlayedItems.shift()
      }
    }
  }, [text])

  useEffect(() => {
    window.addEventListener('mousemove', init, false)
    window.addEventListener('touchend', init, false)

    return () => {
      window.removeEventListener('mousemove', init, false)
      window.removeEventListener('touchend', init, false)

      AudioUtils.stop()
    }
  }, [])

  return (
    <Button className={className} onClick={playAudio} variant='ghost'>
      {isAudioLoading ? (
        <LucideLoader className='h-4 w-4 animate-spin' />
      ) : isPlaying ? (
        <LucideStopCircle className='h-4 w-4' />
      ) : (
        <LucideVolume2 className='h-4 w-4' />
      )}
    </Button>
  )
}
