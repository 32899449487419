import React, { useEffect, useMemo, useState } from 'react'
import { cn } from '@/client/utils/cn'

function getColor(fraction) {
  if (fraction < 0 || fraction > 1) {
    return 'Invalid fraction'
  }

  const red = Math.round(255 * (1 - fraction))
  const blue = Math.round(255 * fraction)
  const green = 128

  return `rgb(${red},${green},${blue})`
}

export function Timer({ timeLimit = 20000, onTimeout = () => {} }) {
  const startedTime = useMemo(() => Date.now(), [])
  const [time, setTime] = useState(0)

  const progress = 1 - time / timeLimit

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = Date.now() - startedTime

      if (diff >= timeLimit) {
        setTime(timeLimit)
        clearInterval(interval)
        onTimeout()
      } else {
        setTime(diff)
      }
    }, 50)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className='mb-4 w-full bg-gray-200'>
      <div
        className={cn(
          'p-[1px] text-center text-xs font-medium leading-none transition-all',
        )}
        style={{
          width: `${progress * 100}%`,
          backgroundColor: getColor(progress),
        }}
      ></div>
    </div>
  )
}
