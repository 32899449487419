import { ObjectIdOrString } from '@/common/constants/database'

export enum RoleType {
  Owner = 'owner',
  Admin = 'admin',
  Member = 'member',
}

export type Role<T = ObjectIdOrString> = {
  org: T
  user: T
  type: RoleType
  accepted?: boolean
}
