import { useRef } from 'react'
import { useLongPress } from 'use-long-press'

export function useNodeLongPress({ mayMoveNode, setDragging }) {
  const start = useRef({ x: 0, y: 0 })
  const end = useRef({ x: 0, y: 0 })

  return useLongPress(
    () => {
      const distance = Math.sqrt(
        Math.pow(end.current.x - start.current.x, 2) +
          Math.pow(end.current.y - start.current.y, 2),
      )

      if (distance < 10) {
        mayMoveNode.current = true
        setDragging(true)
      }
    },
    {
      onStart(e: any) {
        start.current = { x: e.clientX, y: e.clientY }
        end.current = { x: e.clientX, y: e.clientY }
      },
      onMove(e: any) {
        end.current = { x: e.clientX, y: e.clientY }
      },
      onFinish() {
        setDragging(false)
        mayMoveNode.current = false
        start.current = { x: 0, y: 0 }
        end.current = { x: 0, y: 0 }
      },
    },
  )
}
