import { Client } from '@helenejs/client'
import { TOKEN_HEADER_KEY } from '@helenejs/utils'

export function fetchFileWithAuth(client: Client, path: string) {
  return new Promise((resolve, reject) => {
    return fetch(client.href(path), {
      headers: {
        [TOKEN_HEADER_KEY]: client.context.token,
      },
    })
      .then(response => response.blob())
      .then(blob => {
        resolve(URL.createObjectURL(blob))
      })
      .catch(reject)
  })
}
