import { BoardNode } from '@/common/constants/boards'
import { useFieldData } from '@components/boards/nodes/view/node-contact/contact-fields'
import { isEmail, isUrl } from '@components/boards/utils/is-url'
import { RichText } from '@components/rich-text/rich-text-editor'
import React from 'react'

type ContactReadOnlyProps = {
  node: BoardNode<string>
}

export function ContactReadOnly({ node }: ContactReadOnlyProps) {
  const i18n = useFieldData()

  return (
    <div>
      <div className='p-4 text-sm'>
        {node.contactInfo.map(info => (
          <div key={info.field} className='flex items-center gap-4'>
            <span>{i18n[info.field].label}:</span>
            <span>
              {isUrl(info.value) ? (
                <a
                  href={info.value}
                  target='_blank'
                  rel='nofollow noreferrer'
                  className='hover:underline'
                >
                  {info.value}
                </a>
              ) : isEmail(info.value) ? (
                <a href={`mailto:${info.value}`} className='hover:underline'>
                  {info.value}
                </a>
              ) : (
                info.value
              )}
            </span>
          </div>
        ))}
      </div>

      <RichText content={node.content} editable={false} />
    </div>
  )
}
