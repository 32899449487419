import { InputField } from '@components/fields/input-field'
import { FormGroup } from '@components/ui/forms/form-group'
import { t, Trans } from '@lingui/macro'
import React from 'react'

export function CompleteDetailsForm() {
  return (
    <>
      <h1 className='text-center text-xl'>
        <Trans>
          We are almost there! Please enter your name to complete the sign up
        </Trans>
      </h1>
      <FormGroup label={t`Full Name`}>
        <InputField name='name' />
      </FormGroup>
    </>
  )
}
