export const MetaboardEvent = {
  ALERT: 'alert',
  APP_INSTALL: 'app:install',
  USER_REFRESH: 'user:refresh',
  USER_REFRESHED: 'user:refreshed',
  USER_CHANGED: 'user:changed',
  XP_EARNED: 'xp:earned',
  LIVE_NOTIFICATION: 'live:notification',
  REFRESH_GOOGLE_TOKEN: 'refresh:google:token',
  UPDATE_DETECTED: 'update:detected',
  BROWSER_NOTIFICATION: 'browser:notification',
  DECK_GENERATION_PROGRESS: 'deck:generation:progress',
  DECK_ARTICLE_TOKEN: 'deck:article:token',
  DECK_COVER_IMAGE: 'deck:cover:image',
  LIMIT_CHANGED: 'limit:changed',
  UNENROLL_DECK: 'unenroll:deck',
  TOGGLE_SCROLL: 'toggle:scroll',
  CHAT_TOGGLE: 'chat:toggle',
  RECORDING_MODE_TOGGLE: 'recording:mode:toggle',
  COMBO: 'combo',
  SIGN_IN: 'sign:in',
  IMPORT_JOB_CHANGED: 'import:job:changed',

  TOGGLE_MENU: 'toggle:menu',

  STOP_DRAGGING: 'stop:dragging',

  CLOSE_BOARD_BROWSER: 'close:board:browser',
}

export const AdminEvent = {
  USER_REGISTERED: 'user:registered',
  VIDEO_UPDATED: 'video:updated',
}
