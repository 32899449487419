import Circuit from '@/client/assets/circuit.svg?react'
import anime from 'animejs'
import classnames from 'classnames'
import React, { useEffect } from 'react'

type AnimatedCircuitProps = {
  className?: string
}

export function AnimatedCircuit({ className }: AnimatedCircuitProps) {
  useEffect(() => {
    const pathElements = document.querySelectorAll(
      '#animated-circuit path',
    ) as any

    for (const pathElement of pathElements) {
      const offset = anime.setDashoffset(pathElement)
      pathElement.setAttribute('stroke-dashoffset', offset)
      anime({
        targets: pathElement,
        strokeDashoffset: [offset, 0],
        duration: anime.random(1000, 3000),
        delay: anime.random(0, 2000),
        loop: true,
        direction: 'alternate',
        easing: 'easeInOutSine',
        autoplay: true,
      })
    }
  }, [])

  return (
    <Circuit
      id='animated-circuit'
      className={classnames('relative h-full w-full', className)}
    />
  )
}
