import { BoardWorkspace } from '@/common/constants/boards'
import { useMemo } from 'react'
import { ClientOrganizationCollection } from '../data'
import { useData } from './use-data'
import { useMetaboardAuth } from './use-metaboard-auth'

export function useOrgs() {
  const { user } = useMetaboardAuth()

  const list = useData({
    method: 'org.list',
    collection: ClientOrganizationCollection,
  })

  const orgs = useMemo(() => {
    if (!user) return []

    return [
      {
        label: user?.name,
        value: BoardWorkspace.Personal,
      },
      ...list.data.map((org: any) => ({ label: org.name, value: org._id })),
    ]
  }, [user, list.data])

  if (orgs.length < 2) return null

  return orgs
}
