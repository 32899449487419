import { BoardBrowser } from '@components/boards/board-browser'
import { NodeSearch } from '@components/boards/node-search'
import { useHashState } from '@hooks/use-hash-state'
import { Trans } from '@lingui/macro'
import { Tabs } from '@mantine/core'
import { LucideCircuitBoard, LucideSearch } from 'lucide-react'
import React from 'react'

type CommandCenterProps = React.ComponentProps<typeof BoardBrowser>

export function CommandCenter(props: CommandCenterProps) {
  const [tab, setTab] = useHashState('tab', 'boards', 'home')
  return (
    <Tabs className='font-medium' value={tab} onChange={setTab}>
      <Tabs.List mb={16}>
        <Tabs.Tab
          fz='xs'
          value='boards'
          leftSection={<LucideCircuitBoard size={16} />}
        >
          <Trans>Boards</Trans>
        </Tabs.Tab>
        <Tabs.Tab
          fz='xs'
          value='search'
          leftSection={<LucideSearch size={16} />}
        >
          <Trans>Search</Trans>
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value='boards'>
        <BoardBrowser {...props} />
      </Tabs.Panel>

      <Tabs.Panel value='search'>
        <NodeSearch />
      </Tabs.Panel>
    </Tabs>
  )
}
