import React, { useEffect, useMemo, useState } from 'react'
import { MultipleChoiceOption } from './multiple-choice-option'
import shuffle from 'lodash/shuffle'
import { AnimatePresence } from 'framer-motion'

export function MultipleChoiceQuestion({ exercise, samples, onAnswer, game }) {
  const [isCorrect, setCorrect] = useState(false)
  const [selected, setSelected] = useState(null)

  const shuffledOptions = useMemo(() => {
    if (!exercise) return []
    return shuffle([...samples, { answer: exercise.answer }])
  }, [exercise])

  useEffect(() => {
    setCorrect(null)
  }, [exercise])

  return (
    <div>
      <AnimatePresence initial>
        {shuffledOptions.map((sample, index) => {
          return (
            <MultipleChoiceOption
              key={index}
              {...{
                index,
                selected,
                isCorrect,
                setSelected,
                setCorrect,
                sample,
                onAnswer,
                game,
              }}
            />
          )
        })}
      </AnimatePresence>
    </div>
  )
}
