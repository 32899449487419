import { MetaboardEvent } from '@/common/events'
import { LanguagePicker } from '@components/navigation/language-picker'
import { MenuButton } from '@components/navigation/menu-button'
import { ThemeButton } from '@components/navigation/theme-button'
import { LogoutButton } from '@components/ui/navigation/logout-button'
import { useHeleneEvent } from '@hooks/use-helene-event'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import { Drawer } from '@mantine/core'
import { IconBooks } from '@tabler/icons-react'
import {
  LucideCamera,
  LucideCreditCard,
  LucideDatabase,
  LucideHeart,
  LucideInfo,
  LucideMenu,
  LucideQrCode,
  LucideRefreshCcw,
  LucideShield,
  LucideText,
  LucideToggleLeft,
  LucideToggleRight,
  LucideUserCog,
  LucideUsers,
  LucideVideo,
} from 'lucide-react'
import React from 'react'

function Separator() {
  return <div className='border-t border-black/5 dark:border-white/5' />
}

export function MobileMenu() {
  const { client, isAdmin, recordingMode, userId, isPremiumActive } =
    useMetaboardAuth()
  const [open, setOpen] = React.useState(false)

  useHeleneEvent(
    MetaboardEvent.TOGGLE_MENU,
    () => {
      setOpen(!open)
    },
    [open],
  )

  return (
    <Drawer
      opened={open}
      onClose={() => setOpen(!open)}
      size='xs'
      title={
        <span className='flex items-center gap-1.5 leading-tight'>
          <LucideMenu className='h-5 w-5' />
          <Trans>Menu</Trans>
        </span>
      }
      zIndex={30000}
      position='right'
    >
      <div className='flex flex-col gap-2'>
        <MenuButton icon={LucideInfo} to='/settings/account'>
          <Trans>Account Settings</Trans>
        </MenuButton>
        <MenuButton icon={LucideHeart} to='/plan'>
          <Trans>Plan & Billing</Trans>
        </MenuButton>

        <ThemeButton />

        <LanguagePicker />

        <MenuButton
          icon={LucideCamera}
          onClick={() => client.emit(MetaboardEvent.RECORDING_MODE_TOGGLE)}
        >
          <Trans>Recording Mode</Trans>
          {recordingMode ? (
            <LucideToggleRight className='h-4 w-4' />
          ) : (
            <LucideToggleLeft className='h-4 w-4' />
          )}
        </MenuButton>

        {isAdmin ? (
          <MenuButton
            icon={LucideCreditCard}
            onClick={async () => {
              await client.call(
                isPremiumActive
                  ? 'admin.revoke.unlimited'
                  : 'admin.grant.unlimited',
                {
                  userId,
                },
              )
            }}
          >
            {isPremiumActive ? <Trans>Premium</Trans> : <Trans>Basic</Trans>}
            {isPremiumActive ? (
              <LucideToggleRight className='h-4 w-4' />
            ) : (
              <LucideToggleLeft className='h-4 w-4' />
            )}
          </MenuButton>
        ) : null}

        <MenuButton
          icon={LucideRefreshCcw}
          onClick={() => location.reload()}
          className='lg:hidden'
        >
          <Trans>Reload App</Trans>
        </MenuButton>

        {isAdmin ? (
          <>
            <Separator />
            <h3 className='ml-3 flex items-center gap-1.5 text-xs font-medium uppercase opacity-60'>
              <LucideUserCog className='h-4 w-4' />
              <Trans>Management</Trans>
            </h3>
            <MenuButton icon={LucideDatabase} to='/manage/analytics'>
              <Trans>Analytics</Trans>
            </MenuButton>
            <MenuButton icon={LucideText} to='/manage/articles'>
              <Trans>Articles</Trans>
            </MenuButton>
            <MenuButton icon={LucideShield} to='/manage/development-tokens'>
              <Trans>Development Tokens</Trans>
            </MenuButton>
            <MenuButton icon={IconBooks} to='/manage/legal-artifacts'>
              <Trans>Legal Artifacts</Trans>
            </MenuButton>
            <MenuButton icon={LucideQrCode} to='/manage/qrcodes'>
              <Trans>QR Codes</Trans>
            </MenuButton>
            <MenuButton icon={LucideUsers} to='/manage/users'>
              <Trans>Users</Trans>
            </MenuButton>
            <MenuButton icon={LucideVideo} to='/manage/videos'>
              <Trans>Videos</Trans>
            </MenuButton>
          </>
        ) : null}

        <Separator />

        <LogoutButton />
      </div>
    </Drawer>
  )
}
