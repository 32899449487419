import React from 'react'
import { ExerciseType } from '@/common/constants'
import { MultipleChoiceExercise } from '@/client/components/practice/exercise/types/multiple-choice-exercise'
import { TrueOrFalseExercise } from '@/client/components/practice/exercise/types/true-or-false-exercise'
import { ClozeExercise } from '@/client/components/practice/exercise/types/cloze'
import { Trans } from '@lingui/macro'
import { Alert } from '@components/ui/alert'

export function ExerciseRenderer({ type, params }) {
  switch (type) {
    case ExerciseType.TRANSLATION:
    case ExerciseType.MULTIPLE_CHOICE:
      return <MultipleChoiceExercise {...params} />
    case ExerciseType.TRUE_OR_FALSE:
      return <TrueOrFalseExercise {...params} />
    case ExerciseType.CLOZE:
      return <ClozeExercise {...params} />
    default:
      return (
        <Alert variant='danger'>
          <Trans>Unknown Exercise Type</Trans>
        </Alert>
      )
  }
}
