import { MetaboardEvent } from '@/common/events'
import { FunkyButton } from '@components/ui/funky-button'
import { Noop } from '@components/ui/noop'
import { useClient } from '@helenejs/react'
import { Trans } from '@lingui/macro'
import { DialogClose } from '@radix-ui/react-dialog'
import { useDeepCompareEffect } from 'ahooks'
import classnames from 'classnames'
import isDeepEqual from 'fast-deep-equal'
import { Form, Formik, useFormikContext } from 'formik'
import { Save } from 'lucide-react'
import React, { useState } from 'react'
import { AnyObjectSchema } from 'yup'

function AutoSubmit() {
  const formik = useFormikContext()

  useDeepCompareEffect(() => {
    if (!isDeepEqual(formik.initialValues, formik.values))
      formik.submitForm().catch(console.error)
  }, [formik.values, formik.submitForm])

  return null
}

type StandardFormFooterProps = {
  stickyFooter?: boolean
  hideFooter?: boolean
  submitText?: React.ReactNode
  isLoading?: boolean
  footer?: React.ReactNode
  autoSave?: boolean
  closeDialogOnSave?: boolean
}

export function StandardFormFooter({
  stickyFooter,
  hideFooter,
  submitText,
  isLoading,
  footer,
  autoSave,
  closeDialogOnSave,
}: StandardFormFooterProps) {
  const formik = useFormikContext()

  if (hideFooter) return null
  if (autoSave) return <AutoSubmit />

  const Wrapper = closeDialogOnSave ? DialogClose : Noop

  if (stickyFooter) {
    return (
      <div className='fixed bottom-0 left-0 flex w-full justify-end bg-white/80 p-4 dark:bg-slate-800/80'>
        {footer}
        <Wrapper asChild>
          <FunkyButton
            variant='primary'
            icon={Save}
            loading={isLoading}
            disabled={!formik.dirty || !formik.isValid}
            onClick={() => formik.submitForm()}
          >
            {submitText}
          </FunkyButton>
        </Wrapper>
      </div>
    )
  }

  return (
    <div className='mt-4 flex justify-end gap-2'>
      {footer}
      <Wrapper asChild>
        <FunkyButton
          variant='primary'
          loading={isLoading}
          disabled={!formik.dirty || !formik.isValid}
          onClick={e => {
            e.preventDefault()
            return formik.submitForm()
          }}
        >
          {submitText}
        </FunkyButton>
      </Wrapper>
    </div>
  )
}

export function FormWrapper({
  children,
  className,
  stickyFooter,
  footer,
  hideFooter,
  isLoading,
  submitText,
  autoSave,
  closeDialogOnSave,
}: {
  children: React.ReactNode
  className?: string
} & StandardFormFooterProps) {
  return (
    <Form
      autoComplete='off'
      className={classnames(className, {
        'pb-[56px]': stickyFooter,
      })}
    >
      {children}
      <StandardFormFooter
        footer={footer}
        hideFooter={hideFooter}
        stickyFooter={stickyFooter}
        isLoading={isLoading}
        submitText={submitText}
        autoSave={autoSave}
        closeDialogOnSave={closeDialogOnSave}
      />
    </Form>
  )
}

type StandardFormProps = {
  schema?: AnyObjectSchema
  children: React.ReactNode
  initialValues?: any
  onSave: (values: any) => Promise<void> | void
  footer?: React.ReactNode
  hideFooter?: boolean
  stickyFooter?: boolean
  className?: string
  submitText?: React.ReactNode
  autoSave?: boolean
  closeDialogOnSave?: boolean
}

export function StandardForm({
  schema,
  children,
  initialValues,
  onSave,
  footer,
  hideFooter = false,
  stickyFooter = false,
  className,
  submitText = initialValues ? <Trans>Save</Trans> : <Trans>Create</Trans>,
  autoSave,
  closeDialogOnSave = false,
}: StandardFormProps) {
  const [isLoading, setLoading] = useState(false)

  const client = useClient()

  return (
    <Formik
      initialValues={initialValues ?? {}}
      onSubmit={async (values, { setSubmitting }) => {
        setLoading(true)
        try {
          await onSave(values)
        } catch (e) {
          client.emit(MetaboardEvent.LIVE_NOTIFICATION, {
            message: e.message,
            icon: 'error',
            variation: 'error',
          })
        }
        setSubmitting(false)
        setLoading(false)
      }}
      validationSchema={schema}
      enableReinitialize
    >
      <FormWrapper
        className={className}
        stickyFooter={stickyFooter}
        footer={footer}
        hideFooter={hideFooter}
        isLoading={isLoading}
        submitText={submitText}
        autoSave={autoSave}
        closeDialogOnSave={closeDialogOnSave}
      >
        {children}
      </FormWrapper>
    </Formik>
  )
}
