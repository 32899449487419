import { getAudioData, linearPath } from 'waveform-path'

const context = new AudioContext()

function unlockAudioContext() {
  if (context.state !== 'suspended') return
  const b = document.body
  const events = ['touchstart', 'touchend', 'mousedown', 'keydown']
  events.forEach(e => b.addEventListener(e, unlock, false))

  function unlock() {
    context.resume().then(clean)
  }

  function clean() {
    events.forEach(e => b.removeEventListener(e, unlock))
  }
}

/**
 * It needs to unlock it first, so it can play on Safari.
 *
 * https://codepen.io/kslstn/pen/pagLqL
 */
function unlock() {
  context.resume().catch(console.error)
}

let source = null

async function play(stream, callback) {
  const audioBuffer = await context.decodeAudioData(stream.buffer)

  source = context.createBufferSource()

  source.buffer = audioBuffer
  source.playbackRate.value = 1
  source.connect(context.destination)

  source.start(0)

  source.onended = () => {
    source?.disconnect()
    source = null
    callback?.()
  }
}

async function stop() {
  source?.stop()
  source = null
}

export async function getAudioPath(url: string) {
  const audioData = await getAudioData(url)

  if (!audioData) return ''

  return linearPath(audioData, {
    samples: 20,
    type: 'mirror',
    top: 0,
    left: 3,
    paths: [{ d: 'V', sy: 0, x: 8, ey: 44 }],
    height: 44,
    width: 156,
  })
}

export const AudioUtils = {
  unlock,
  unlockAudioContext,
  play,
  stop,
  context,
}
