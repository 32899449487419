export function getNodeIdFromEvent(e: MouseEvent & TouchEvent) {
  let count = 0

  let element = document.elementFromPoint(
    e.changedTouches?.[0]?.pageX ?? e.touches?.[0]?.pageX ?? e.pageX,
    e.changedTouches?.[0]?.pageY ?? e.touches?.[0]?.pageY ?? e.pageY,
  ) as HTMLElement

  if (!element) return null

  while (element?.dataset.nodeId === undefined) {
    count++
    element = element?.parentElement

    if (!element) {
      return null
    }

    if (element?.dataset.type === 'container' || count > 10) {
      return null
    }
  }

  return element?.dataset.nodeId
}
