import { useEffect, useRef } from 'react'

export function useCounterShifting({
  bounds,
  updatePosition,
  viewportPositionRef,
}) {
  const previousMinY = useRef(null)
  const previousMinX = useRef(null)

  useEffect(() => {
    const update = () => {
      previousMinY.current = bounds.minY
    }

    if (!previousMinY.current) return update

    const diffY = previousMinY.current - bounds.minY

    if (diffY < 0 || diffY > 0) {
      updatePosition(
        viewportPositionRef.current.x,
        viewportPositionRef.current.y - diffY,
        false,
        false,
      )
    }

    return update
  }, [bounds.minY])

  useEffect(() => {
    const update = () => {
      previousMinX.current = bounds.minX
    }

    if (!previousMinX.current) return update

    const diffX = previousMinX.current - bounds.minX

    if (diffX < 0 || diffX > 0) {
      updatePosition(
        viewportPositionRef.current.x - diffX,
        viewportPositionRef.current.y,
        false,
        false,
      )
    }

    return update
  }, [bounds.minX])
}
