import { Dimensions } from '@components/boards/utils/types'
import { Client } from '@helenejs/client'
import { t } from '@lingui/macro'
import { notifications } from '@mantine/notifications'
import axios from 'axios'

export async function getImageDimensions(file: File) {
  return new Promise<Dimensions & { temporaryUrl: string }>(
    (resolve, reject) => {
      const img = new Image()

      const src = URL.createObjectURL(file)

      img.onload = () => {
        resolve({
          width: img.width / (window.devicePixelRatio * 1.5),
          height: img.height / (window.devicePixelRatio * 1.5),
          temporaryUrl: src,
        })
      }
      img.onerror = reject
      img.src = src
    },
  )
}

export async function uploadBoardFile(
  client: Client,
  boardId: string,
  file: File,
  onError?: () => void,
) {
  const formData = new FormData()
  formData.append('file', file)

  try {
    const {
      data: { success, error, _id },
    } = await axios.post(client.href(`board/${boardId}/file`), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    if (error) {
      throw new Error(error)
    }

    if (success) {
      return _id
    }
  } catch (error) {
    notifications.show({
      title: t`Error`,
      message: t`Failed to upload file, try again later. If the problem persists, contact support.`,
      color: 'red',
    })
    onError?.()
  }
}
