import { useClient } from '@helenejs/react'
import useCreation from 'ahooks/lib/useCreation'
import isString from 'lodash/isString'
import { useCallback, useEffect } from 'react'

export type UseEventParams =
  | {
      event: string
      channel?: string
    }
  | string

export function useHeleneEvent(
  params: UseEventParams,
  fn: (...args: any[]) => void,
  deps: any[] = [],
) {
  const client = useClient()

  const event = isString(params) ? params : params.event
  const channel = isString(params) ? undefined : params.channel

  const callback = useCallback(fn, deps)

  const clientChannel = useCreation(() => {
    return channel && isString(channel) ? client.channel(channel) : client
  }, [channel])

  useEffect(() => {
    if (!clientChannel) return

    clientChannel.on(event, callback)

    return () => {
      clientChannel.off(event, callback)
    }
  }, [event, channel, callback])
}
