import { NavButton } from '@components/nav-button'
import { Alert } from '@components/ui/alert'
import { TextArea } from '@components/ui/forms/textArea'
import { FunkyButton } from '@components/ui/funky-button'
import { Modal } from '@components/ui/modal'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import { MessageCircle } from 'lucide-react'
import React, { useState } from 'react'

export function Feedback() {
  const [isOpen, setOpen] = useState(false)
  const [content, setContent] = useState('')
  const [sending, setSending] = useState(false)
  const client = useClient()
  const { user } = useMetaboardAuth()

  if (!user) return null

  return (
    <>
      <NavButton icon={MessageCircle} onClick={() => setOpen(true)} collapsible>
        <Trans>Feedback</Trans>
      </NavButton>
      <Modal
        onClose={() => {
          setOpen(false)
        }}
        isOpen={isOpen}
        title={<Trans>Feedback</Trans>}
        className='flex h-full w-full flex-col gap-4'
      >
        <TextArea
          onChange={e => setContent(e.target.value)}
          placeholder={t`Ideas, suggestions, bugs, or anything else you want to tell us...`}
          className='h-[192px] resize-none'
        />

        <Alert className='prose mb-0 flex items-center gap-1.5' variant='info'>
          <Trans>
            We will reach you through your email address in case we need more
            information: {user?.email}.
          </Trans>
        </Alert>

        <div className='flex w-full justify-end gap-1.5'>
          <FunkyButton variant='secondary' onClick={() => setOpen(false)}>
            <Trans>Cancel</Trans>
          </FunkyButton>
          <FunkyButton
            variant='fb'
            onClick={() => {
              if (sending) return
              setSending(true)
              client
                .call('feedback.create', { content })
                .then(() => {
                  setOpen(false)
                  setContent('')
                  setSending(false)
                })
                .catch(console.error)
            }}
            loading={sending}
          >
            <Trans>Send</Trans>
          </FunkyButton>
        </div>
      </Modal>
    </>
  )
}
