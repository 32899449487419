import { cn } from '@/client/utils/cn'
import { CodeBlockComponent } from '@components/rich-text/code-block-component'
import { getTaskListExtension, Link, RichTextEditor } from '@mantine/tiptap'
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight'

import Highlight from '@tiptap/extension-highlight'
import { Placeholder } from '@tiptap/extension-placeholder'
import SubScript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TaskItem from '@tiptap/extension-task-item'
import TipTapTaskList from '@tiptap/extension-task-list'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import { ReactNodeViewRenderer, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useDebounceFn } from 'ahooks'
import bash from 'highlight.js/lib/languages/bash'
import js from 'highlight.js/lib/languages/javascript'
import json from 'highlight.js/lib/languages/json'
import python from 'highlight.js/lib/languages/python'
import shell from 'highlight.js/lib/languages/shell'
import ts from 'highlight.js/lib/languages/typescript'
import yaml from 'highlight.js/lib/languages/yaml'
import { createLowlight } from 'lowlight'
import React, { useEffect } from 'react'

type RichTextProps = {
  className?: string
  content: string
  onChange?: (content: string) => void
  placeholder?: string
  toolbar?: React.ComponentProps<typeof RichTextEditor.Toolbar>
  editable?: boolean
}

const lowlight = createLowlight()

lowlight.register({
  ts,
  js,
  json,
  yaml,
  python,
  shell,
  bash,
})

export function RichText({
  className,
  content,
  onChange,
  placeholder,
  toolbar,
  editable = true,
}: RichTextProps) {
  const debouncedOnChange = useDebounceFn(
    ({ editor }) => {
      onChange?.(editor.getHTML())
    },
    { wait: 250 },
  )

  const editor = useEditor({
    extensions: [
      StarterKit.configure({ codeBlock: false }),
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      getTaskListExtension(TipTapTaskList),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: 'test-item',
        },
      }),
      Placeholder.configure({ placeholder }),
      CodeBlockLowlight.extend({
        addNodeView() {
          return ReactNodeViewRenderer(CodeBlockComponent)
        },
      }).configure({ lowlight }),
    ],
    content,
    onUpdate: debouncedOnChange.run,
    editable,
  })

  useEffect(() => {
    if (!editor) return
    if (editable) return
    editor.chain().focus().setContent(content).run()
  }, [content])

  if (!editor) return null

  if (!editable) {
    return (
      <RichTextEditor editor={editor} className={cn('!border-none', className)}>
        <RichTextEditor.Content content={content} fz='sm' />
      </RichTextEditor>
    )
  }

  return (
    <RichTextEditor editor={editor} className={className}>
      <RichTextEditor.Toolbar {...toolbar}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
          <RichTextEditor.CodeBlock />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.TaskList />
          <RichTextEditor.TaskListLift />
          <RichTextEditor.TaskListSink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Undo />
          <RichTextEditor.Redo />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content fz='sm' />
    </RichTextEditor>
  )
}
