import { useEffect } from 'react'

export function useWheel(
  onZoomIn: () => void,
  onZoomOut: () => void,
  deps: any[] = [],
) {
  useEffect(() => {
    const onWheel = (e: WheelEvent) => {
      if (!e.ctrlKey) return

      e.preventDefault()
      e.stopPropagation()

      if (e.deltaY > 0) {
        onZoomOut()
      } else {
        onZoomIn()
      }
    }

    document.addEventListener('wheel', onWheel, { passive: false })

    return () => {
      document.removeEventListener('wheel', onWheel)
    }
  }, deps)
}
