import { useBoardState } from '@components/boards/hooks/use-board-state'
import { useCreation } from 'ahooks'
import { throttle } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Rect } from '../utils/rect'

type Point = {
  x: number
  y: number
}

type Options = {
  onSelect(rect: Rect): void
}

function getRect(startPosition: Point, currentPosition: Point): Rect {
  return Rect.fromLWTH(
    Math.min(startPosition.x, currentPosition.x),
    Math.abs(startPosition.x - currentPosition.x),
    Math.min(startPosition.y, currentPosition.y),
    Math.abs(startPosition.y - currentPosition.y),
  )
}

export function useMultiSelect({ onSelect = () => {} }: Options) {
  const [selecting, setSelecting] = useState(false)
  const selectingRef = useRef(false)
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 })
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 })

  const { isReadOnly, containerElem } = useBoardState()

  useEffect(() => {
    if (isReadOnly) return
    if (!containerElem) return

    let startPos = { x: 0, y: 0 }
    let currentPos = {
      x: 0,
      y: 0,
    }

    let timeout = null

    function onMouseDown(e: MouseEvent) {
      if (!(e.target as HTMLElement)?.dataset?.multiSelect) return
      if (!e.shiftKey) return

      e.stopPropagation()

      if (e.button === 0) {
        startPos = currentPos = {
          x: e.clientX,
          y: e.clientY,
        }

        timeout = setTimeout(() => {
          setSelecting(true)
          selectingRef.current = true

          setStartPosition(startPos)
          setCurrentPosition(startPos)

          timeout = null
        }, 120)
      }
    }

    const throttledOnSelect = throttle(onSelect, 100)

    const onMouseMove = throttle((e: MouseEvent) => {
      if (!selectingRef.current) return

      currentPos = {
        x: e.clientX,
        y: e.clientY,
      }

      setCurrentPosition(currentPos)

      throttledOnSelect(getRect(startPos, currentPos))
    }, 10)

    const onMouseUp = (e: MouseEvent) => {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
        // This clears the selection on normal clicks
        onSelect(null)
        return
      }

      if (e.button !== 0) return
      if (!selectingRef.current) return

      setCurrentPosition({ x: 0, y: 0 })
      setStartPosition({ x: 0, y: 0 })
      setSelecting(false)
      selectingRef.current = false

      onSelect(getRect(startPos, currentPos))
    }

    containerElem.addEventListener('mousedown', onMouseDown)
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      containerElem.removeEventListener('mousedown', onMouseDown)
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [containerElem, isReadOnly])

  return useCreation(
    () => ({
      selecting,
      rect: getRect(startPosition, currentPosition),
    }),
    [selecting, startPosition, currentPosition],
  )
}
