import usIcon from 'flag-icons/flags/4x3/us.svg'
import itIcon from 'flag-icons/flags/4x3/it.svg'
import esIcon from 'flag-icons/flags/4x3/es.svg'
import brIcon from 'flag-icons/flags/4x3/br.svg'
import frIcon from 'flag-icons/flags/4x3/fr.svg'
import deIcon from 'flag-icons/flags/4x3/de.svg'

import { LanguageCode } from '@/common/constants'

export const LanguageIcon = {
  [LanguageCode.EN]: usIcon,
  [LanguageCode.IT]: itIcon,
  [LanguageCode.ES]: esIcon,
  [LanguageCode.PT]: brIcon,
  [LanguageCode.FR]: frIcon,
  [LanguageCode.DE]: deIcon,
}
