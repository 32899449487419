import React from 'react'
import { useField } from 'formik'
import { FieldOutline } from './field-outline'
import { OTPInput } from '@components/ui/forms/otp-input'

export function OTPField({
  outlineProps,
  name,
  onComplete,
  disabled,
  errorClassName,
}) {
  const [field, meta, helper] = useField(name)

  return (
    <FieldOutline meta={meta} errorClassName={errorClassName} {...outlineProps}>
      <OTPInput
        value={field.value}
        onChange={helper.setValue}
        onComplete={onComplete}
        disabled={disabled}
      />
    </FieldOutline>
  )
}
