import React, { useState } from 'react'
import { MultipleChoiceOption } from './multiple-choice-option'
import { t } from '@lingui/macro'

export function TrueOrFalseQuestion({ onAnswer }) {
  const [isCorrect, setCorrect] = useState(false)
  const [selected, setSelected] = useState(null)

  return (
    <div>
      {['True', 'False'].map((sample, index) => {
        return (
          <MultipleChoiceOption
            key={index}
            {...{
              index,
              selected,
              isCorrect,
              setSelected,
              setCorrect,
              sample:
                sample === 'True'
                  ? { label: t`True`, answer: sample }
                  : {
                      label: t`False`,
                      answer: sample,
                    },
              onAnswer,
            }}
          />
        )
      })}
    </div>
  )
}
