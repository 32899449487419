import React, { useMemo, useState } from 'react'
import { ExerciseForm } from '../forms/exercise-form'
import { useClient, useLocalEvent } from '@helenejs/react'
import { ExerciseStatus, ExerciseType, LanguageCode } from '@/common/constants'
import { LS } from '../../utils/ls'
import { Drawer } from '@components/ui/drawer'

export function ExerciseDrawer({ onSave, onClose, sections }) {
  const [isOpen, setIsOpen] = useState(false)
  const [exercise, setExercise] = useState(null)
  const [section, setSection] = useState(null)

  const client = useClient()

  useLocalEvent({ event: 'exercise.edit' }, exercise => {
    setIsOpen(true)
    setExercise(exercise)
  })

  useLocalEvent({ event: 'exercise.create' }, ({ section }) => {
    setIsOpen(true)
    setSection(section)
  })

  useLocalEvent({ event: 'exercise.close' }, () => {
    setExercise(null)
    setIsOpen(false)
    setSection(null)
  })

  const initialValues = useMemo(
    () =>
      exercise ?? {
        section: section?._id,
        type: ExerciseType.MULTIPLE_CHOICE,
        questionLanguage: LanguageCode.EN,
        answerLanguage: LanguageCode.EN,
        status: ExerciseStatus.PUBLISHED,
        ...LS.get('exerciseForm'),
      },
    [exercise, section],
  )

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        client.emit('exercise.close')

        onClose?.()
      }}
      title='Exercise'
    >
      <ExerciseForm
        sections={sections}
        initialValues={initialValues}
        onSave={async data => {
          await onSave?.(data)
        }}
      />
    </Drawer>
  )
}
