import { useCreation, useThrottleEffect, useUpdate } from 'ahooks'
import {
  getJSONFromLocalStorage,
  setJSONToLocalStorage,
} from '@/common/local-storage'
import { useRef } from 'react'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'

const proxyMap = new WeakMap()
const rawMap = new WeakMap()

function observer(initialVal: any, cb: () => void) {
  const existingProxy = proxyMap.get(initialVal)

  if (existingProxy) {
    return existingProxy
  }

  if (rawMap.has(initialVal)) {
    return initialVal
  }

  const proxy = new Proxy(initialVal, {
    get(target, key, receiver) {
      const res = Reflect.get(target, key, receiver)

      // Fixes Date objects not being able to be serialized.
      if (isFunction(res)) {
        return res.bind(target)
      }

      return isObject(res) ? observer(res, cb) : res
    },
    set(target, key, val) {
      const ret = Reflect.set(target, key, val)
      cb()
      return ret
    },
    deleteProperty(target, key) {
      const ret = Reflect.deleteProperty(target, key)
      cb()
      return ret
    },
  })

  proxyMap.set(initialVal, proxy)
  rawMap.set(proxy, initialVal)

  return proxy
}

/**
 * This is a better version of `useReactive` from ahooks, which works
 * well with dates.
 */
export function useReactive(initialState: Record<string, any>) {
  const update = useUpdate()
  const stateRef = useRef(initialState)

  return useCreation(() => observer(stateRef.current, update), [])
}

export function useReactiveLocalStorage(
  key: string,
  initialValues: Record<string, any>,
) {
  const state = useReactive(getJSONFromLocalStorage(key) || initialValues)

  useThrottleEffect(
    () => {
      setJSONToLocalStorage(key, state)
    },
    [JSON.stringify(state)],
    { wait: 1000, leading: true, trailing: true },
  )

  return state
}
