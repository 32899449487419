import { ClientBoardNodeCollection } from '@/client/data'
import { cn } from '@/client/utils/cn'
import { isTouchDevice } from '@/client/utils/environment'
import { roundToGrid } from '@/common/boards/constraints'
import { BoardNode } from '@/common/constants/boards'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useImageUrl } from '@components/boards/hooks/use-image-url'
import {
  getImageDimensions,
  uploadBoardFile,
} from '@components/boards/utils/image'
import { useClient } from '@helenejs/react'
import { Trans } from '@lingui/macro'
import defer from 'lodash/defer'
import { LucideImage } from 'lucide-react'
import React, { useState } from 'react'
import { Blurhash } from 'react-blurhash'

export function ImageContent({ node }: { node: BoardNode<string> }) {
  const client = useClient()
  const [fileInput, setFileInput] = useState(null)
  const imageUrl = useImageUrl(node)
  const operations = useBoardOperations()

  const [isImageVisible, setImageVisible] = useState(false)

  if (!node.image && !node._temporaryUrl)
    return (
      <div className='absolute inset-0 flex items-center justify-center rounded'>
        <input
          ref={setFileInput}
          type='file'
          accept='image/jpeg,image/png'
          className='hidden'
          onChange={async e => {
            const file = e.target.files[0]

            if (!file) return

            const { width, height, temporaryUrl } =
              await getImageDimensions(file)

            operations.updateNode(node._id, {
              width: roundToGrid(width),
              height: roundToGrid(height),
            })

            defer(() => {
              ClientBoardNodeCollection.update(
                { _id: node._id },
                {
                  $set: {
                    _temporaryUrl: temporaryUrl,
                  },
                },
              ).catch(console.error)
            })

            const image = await uploadBoardFile(
              client,
              node.board,
              file,
              () => {
                operations.deleteNode(node._id)
              },
            )

            operations.updateNode(node._id, {
              image,
            })
          }}
          hidden
        />

        <button
          className='flex items-center gap-2 rounded-full bg-gray-500/80 px-4 py-2 text-white hover:bg-gray-600'
          onMouseDown={e => e.stopPropagation()}
          onTouchStart={e => e.stopPropagation()}
          onClick={() => {
            if (fileInput) fileInput.click()
          }}
        >
          <LucideImage size={16} />
          <div className=''>
            <Trans>Select Image</Trans>
          </div>
        </button>
      </div>
    )

  if (!imageUrl)
    return (
      <Blurhash
        hash={node.imageBlurHash ?? 'LEHV6nWB2yk8pyo0adR*.7kCMdnj'}
        width={node.width}
        height={node.height}
        resolutionX={32}
        resolutionY={32}
        punch={1}
        className='-m-2'
      />
    )

  return (
    <img
      src={imageUrl}
      alt={node.name}
      className={cn(
        'absolute left-0 top-0 h-full min-w-full rounded object-cover',
        'opacity-0 transition-opacity duration-500',
        {
          'pointer-events-none': isTouchDevice(),
          'opacity-100': isImageVisible,
        },
      )}
      onDragStart={e => e.preventDefault()}
      onLoad={() => {
        setTimeout(() => {
          setImageVisible(true)
        }, 100)
      }}
    />
  )
}
