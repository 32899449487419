import { sleep } from '@/common/time'
import { Client } from '@helenejs/client'

export async function callWithRetry(
  client: Client,
  method: string,
  params: Record<string, any>,
) {
  const maxAttempts = 20
  const delayBetweenRetriesMs = 3000

  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      await client.call(method, params)
      return true
    } catch (error) {
      console.log(`Attempt ${attempt + 1} failed with error: ${error.message}`)

      if (attempt + 1 === maxAttempts) {
        throw error
      }

      await sleep(delayBetweenRetriesMs)
    }
  }
}
