import { throttle } from 'lodash'
import { useEffect, useState } from 'react'

export type WindowSize = {
  width: number | undefined
  height: number | undefined
  xs: boolean
  sm: boolean
  md: boolean
  lg: boolean
  xl: boolean
  '2xl': boolean
  '3xl': boolean
}

const getBreakpoints = () => ({
  xs: window.innerWidth >= 480,
  sm: window.innerWidth >= 640,
  md: window.innerWidth >= 768,
  lg: window.innerWidth >= 1024,
  xl: window.innerWidth >= 1280,
  '2xl': window.innerWidth >= 1536,
  '3xl': window.innerWidth >= 1920,
})

export const useWindowResize = (
  callback?: () => void,
  deps: any[] = [],
): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
    ...getBreakpoints(),
  })

  useEffect(() => {
    const handleResize = throttle(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        ...getBreakpoints(),
      })

      if (callback) callback()
    }, 100)

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, deps)

  return windowSize
}
