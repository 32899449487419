import { ClientBoardNodeCollection } from '@/client/data'
import { NodeType } from '@/common/constants/boards'
import { BaseColor } from '@/common/constants/color'
import { isYoutubeUrl } from '@/common/string-utils'
import { BoardOperations } from '@components/boards/hooks/use-board-operations'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { isUrl } from '@components/boards/utils/is-url'
import { getNodeDimensions } from '@components/boards/utils/nodes'
import {
  fromVirtualX,
  fromVirtualY,
} from '@components/boards/utils/virtualization'
import { Client } from '@helenejs/client'
import React from 'react'

export async function getLinkMetadata(client: Client, link: string) {
  try {
    if (!isUrl(link)) throw new Error('Invalid URL')

    const { title } = await client.call('utils.getPageMetadata', { url: link })

    return title
  } catch (e) {
    console.error(e)
    return link
  }
}

export async function processLink({
  client,
  xRef,
  yRef,
  link,
  bounds,
  operations,
  nodeId,
}: {
  client: Client
  boardId: string
  xRef?: React.MutableRefObject<number>
  yRef?: React.MutableRefObject<number>
  link: string
  bounds?: VirtualBounds
  operations: BoardOperations
  nodeId?: string
}) {
  const dimensions1 = getNodeDimensions({}, link)

  const isYouTubeVideo = isYoutubeUrl(link)

  const data = {
    type: NodeType.URL,
    name: link,
    url: link,
    width: 16 * 16,
    height: 48,
    color: isYouTubeVideo ? BaseColor.Red : BaseColor.Blue,
    isYouTubeVideo,
    ...dimensions1,
  }

  if (nodeId) {
    const node = await ClientBoardNodeCollection.findOne({ _id: nodeId })

    data.name = await getLinkMetadata(client, link)

    const dimensions2 = getNodeDimensions(node, data.name)

    data.width = dimensions2.width
    data.height = dimensions2.height

    await operations.updateNode(nodeId, data)
    return
  }

  if (!xRef || !yRef || !bounds) throw new Error('No position')

  nodeId = await operations.addNode(
    fromVirtualX(xRef.current, bounds),
    fromVirtualY(yRef.current, bounds),
    data,
  )

  const title = await getLinkMetadata(client, link)

  const node = await ClientBoardNodeCollection.findOne({ _id: nodeId })

  const dimensions2 = getNodeDimensions(node, title)

  operations.updateNode(nodeId, {
    name: title,
    ...dimensions2,
  })
}
