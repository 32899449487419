import { ClientBoardCollection } from '@/client/data'
import { normalize } from '@/client/utils/text'
import { BoardWorkspace } from '@/common/constants/boards'
import { useData } from '@hooks/use-data'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import useCreation from 'ahooks/lib/useCreation'
import { ObjectId } from 'bson'
import { useMemo, useState } from 'react'

function getFilter(currentWorkspace: string, userId: string) {
  if (ObjectId.isValid(currentWorkspace)) {
    return { org: currentWorkspace }
  }

  if (currentWorkspace === BoardWorkspace.Personal) {
    return {
      $or: [
        { org: { $exists: false } },
        {
          // If a board belongs to an organization it can still be shared to normal users
          // This is in case the user is seeing his personal scope, otherwise intra-org/team permissions will apply
          shareWith: userId,
        },
      ],
    }
  }

  return {}
}

export function useBoardSearch() {
  const { userId, currentWorkspace } = useMetaboardAuth()

  const filter = getFilter(currentWorkspace, userId)

  const boards = useData({
    method: 'boards.list',
    collection: ClientBoardCollection,
    filter,
    sort: { name: 1, createdAt: 1 },
    authenticated: true,
    selectiveSync: true,
  })

  const [search, setSearch] = useState('')

  const normalized = useMemo(
    () =>
      boards.data?.map(board => ({
        ...board,
        _name: normalize(board.name).toLowerCase(),
      })) ?? [],
    [boards.data],
  )

  const filteredBoards = useMemo(
    () =>
      normalized.filter(board => board._name.includes(search.toLowerCase())),
    [normalized, search],
  )

  return useCreation(
    () => ({
      loading: boards.loading,
      search,
      boards: filteredBoards,
      setSearch,
    }),
    [filteredBoards, search, setSearch, boards.loading],
  )
}
