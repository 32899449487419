import { FunkyButton } from '@/client/components/ui/funky-button'
import { cn } from '@/client/utils/cn'
import { HorizontalLoader } from '@components/horizontal-loader'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Trans } from '@lingui/macro'
import React, { Fragment } from 'react'

export enum DrawerSize {
  Small = 'sm',
  Big = 'lg',
}

type DrawerProps = {
  children: React.ReactNode
  open: boolean
  onClose: () => void
  onConfirm?: () => void
  title?: React.ReactNode
  footer?: React.ReactNode
  loading?: boolean
  onFinishedClosing?: () => void
  className?: string
  dialogClassName?: string
  position?: 'left' | 'right'
  drawerClassName?: string
  size?: DrawerSize
}

export function Drawer({
  children,
  open,
  onClose,
  onConfirm,
  title,
  footer,
  loading,
  onFinishedClosing,
  className,
  dialogClassName,
  position = 'right',
  drawerClassName,
  size = DrawerSize.Big,
}: DrawerProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className={cn('relative z-[100000]', dialogClassName)}
        onClose={onClose}
      >
        <div className='fixed inset-0' />

        <div className='fixed inset-0'>
          <div className='absolute inset-0' data-drawer>
            <div
              className={cn(
                'pointer-events-none fixed inset-y-0 flex w-full ',
                drawerClassName,
                position === 'right' ? 'right-0' : 'left-0',
                size === DrawerSize.Small ? 'max-w-sm' : 'max-w-[960px]',
              )}
            >
              <Transition.Child
                as='div'
                enter='transform transition ease-in-out duration-300'
                enterFrom={
                  position === 'right'
                    ? 'translate-x-full w-full'
                    : '-translate-x-full w-full'
                }
                enterTo='translate-x-0 w-full'
                leave='transform transition ease-in-out duration-300'
                leaveFrom='translate-x-0 w-full'
                leaveTo={
                  position === 'right'
                    ? 'translate-x-full w-full'
                    : '-translate-x-full w-full'
                }
                onTransitionEnd={() => {
                  if (!open) {
                    onFinishedClosing?.()
                  }
                }}
              >
                <Dialog.Panel className='pointer-events-auto w-full'>
                  <div
                    className={cn(
                      'flex h-screen flex-col divide-y divide-gray-200 border-gray-100 bg-white shadow-xl dark:divide-slate-500/50 dark:border-slate-600 dark:bg-slate-700',
                      position === 'right' ? 'border-l' : 'border-r',
                    )}
                  >
                    <div className='flex max-h-full min-h-0 flex-1 flex-col'>
                      <div className='border-b border-gray-100 bg-gray-50 p-4 dark:border-slate-600 dark:bg-slate-700'>
                        <div className='flex items-center justify-between'>
                          <Dialog.Title className='mb-0 text-base font-semibold leading-6 text-gray-900 dark:text-gray-300'>
                            {title}
                          </Dialog.Title>
                          <div className='ml-3 flex h-7 items-center'>
                            <button
                              type='button'
                              className='rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none'
                              onClick={onClose}
                            >
                              <span className='sr-only'>
                                <Trans>Close Panel</Trans>
                              </span>
                              <XMarkIcon
                                className='h-6 w-6'
                                aria-hidden='true'
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className={cn(
                          'relative flex-1 overflow-auto p-4',
                          className,
                        )}
                      >
                        {loading ? <HorizontalLoader /> : children}
                      </div>
                    </div>
                    {footer || onConfirm ? (
                      <div className='flex flex-shrink-0 justify-end gap-2 px-4 py-4'>
                        {footer}
                        {onConfirm ? (
                          <FunkyButton
                            variant='fb'
                            onClick={() => {
                              onClose?.()
                            }}
                          >
                            <Trans>Cancel</Trans>
                          </FunkyButton>
                        ) : null}
                        {onConfirm ? (
                          <FunkyButton
                            variant='primary'
                            onClick={e => {
                              e.preventDefault()
                              onConfirm?.()
                            }}
                          >
                            <Trans>Save</Trans>
                          </FunkyButton>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
