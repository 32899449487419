import { EstimationSession } from '@/common/constants/task-hold-em'
import { Control } from '@components/task-hold-em/control'
import { Voting } from '@components/task-hold-em/voting'
import { useClient } from '@helenejs/react'
import isEmpty from 'lodash/isEmpty'
import { Check, EyeOff } from 'lucide-react'
import React, { useState } from 'react'

type SessionProps = {
  isInSession: boolean
  session: EstimationSession | null
}

export function Session({ isInSession, session }: SessionProps) {
  const client = useClient()

  const [consensusElem, setConsensusElem] = useState<HTMLElement | null>(null)

  const isAdmin = session?.admin === client.uuid

  const users = session
    ? Object.entries(session.users).map(([uuid, username]) => ({
        uuid,
        username,
        points: session.points[uuid],
      }))
    : null

  const observers = session
    ? Object.entries(session.observers).map(([uuid, username]) => ({
        uuid,
        username,
      }))
    : null

  const uniqueVotes = new Set(Object.values(session?.points || {}))
  const allVotesSame = uniqueVotes.size === 1 && uniqueVotes.has(null) === false

  const isPlayer = session?.users[client.uuid]

  if (!session || !isInSession) {
    return null
  }

  return (
    <div>
      <Control session={session} isAdmin={isAdmin} />

      {!isEmpty(users) ? (
        <div className='mb-4 flex flex-col gap-2'>
          <div className='flex justify-between font-bold'>
            <span>Player</span>
            <span>Points</span>
          </div>
          {users.map(({ uuid, username, points }) => (
            <div key={uuid} className='flex justify-between'>
              <span className='flex items-center gap-1.5'>
                {points ? <Check className='h-4 w-4' /> : null}
                {username}
              </span>
              <span>
                {session.hidden ? <EyeOff className='h-4 w-4' /> : points}
              </span>
            </div>
          ))}
        </div>
      ) : null}

      {!isEmpty(observers) ? (
        <div className='mb-4 flex flex-col gap-2'>
          <div className='font-bold'>Observer</div>
          {observers.map(({ uuid, username }) => (
            <div key={uuid}>{username}</div>
          ))}
        </div>
      ) : null}

      {allVotesSame && !session.hidden ? (
        <div className='mb-4'>
          <p
            className='animate-pulse text-center text-lg font-semibold text-green-500'
            ref={setConsensusElem}
            data-consensus
          >
            Consensus!
          </p>
        </div>
      ) : null}

      {isPlayer ? <Voting /> : null}

      {isAdmin ? (
        <div className='mt-8 flex justify-center'>
          <div
            className='cursor-pointer text-xs text-gray-500'
            onClick={() => {
              navigator.clipboard.writeText(location.href)
            }}
          >
            {location.href}
          </div>
        </div>
      ) : null}
    </div>
  )
}
