import { ClientOrganizationCollection } from '@/client/data'
import { useMetaboardAuth } from '@/client/hooks/use-metaboard-auth'
import { cn } from '@/client/utils/cn'
import { BoardPrivacy } from '@/common/constants/boards'
import { MetaboardEvent } from '@/common/events'
import { OuterBoardContextMenu } from '@components/boards/outer-board-context-menu'
import { useClient, useFindOne } from '@helenejs/react'
import { Trans } from '@lingui/macro'
import {
  LucideBox,
  LucideBuilding,
  LucideUnlock,
  LucideUsers,
} from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

function Pill({ children, className = '' }) {
  return (
    <span
      className={cn(
        'absolute bottom-0 left-0 z-30',
        'rounded-md rounded-br-none rounded-tl-none',
        'bg-gray-300/50 p-1 text-xs text-white/80 dark:bg-slate-600/50',
        'flex items-center gap-1.5 font-medium',
        className,
      )}
    >
      {children}
    </span>
  )
}

function PublicPill({ isPublic }) {
  return isPublic ? (
    <Pill className='bottom-[unset] left-[unset] right-0 top-0 rounded-bl-md rounded-tr-md bg-green-500 dark:bg-green-800'>
      <LucideUnlock className='h-3 w-3' />
      <Trans>Public</Trans>
    </Pill>
  ) : null
}

function ExternalPill({ isExternal }) {
  return isExternal ? (
    <Pill className='bg-indigo-500 dark:bg-indigo-800'>
      <LucideUsers className='h-3 w-3' />
      <Trans>External</Trans>
    </Pill>
  ) : null
}

function SharedPill({ isShared, count }) {
  return isShared ? (
    <Pill className='bg-blue-500 dark:bg-blue-800'>
      <LucideUsers className='h-3 w-3' />
      <Trans>Shared ({count})</Trans>
    </Pill>
  ) : null
}

function OrgPill({ org }) {
  const { user } = useMetaboardAuth()

  return (
    <Pill className='bottom-[unset] left-0 top-0 rounded-bl-none rounded-br-md rounded-tr-none'>
      <LucideBuilding className='h-3 w-3' />
      {org ? org.name : user?.name}
    </Pill>
  )
}

export function BoardCard({ board, teamById, userId }) {
  const client = useClient()

  const contextMenuTarget = React.useRef(null)

  const teamNames =
    board.teams
      ?.map(teamId => teamById[teamId as string]?.name)
      .filter(Boolean) ?? []

  const isExternal =
    board.author !== userId && board.shareWith?.includes(userId)
  const isPublic = board.privacy === BoardPrivacy.Public
  const isShared = board.author === userId && board.shareWith?.length > 0
  const isFromTeam = teamNames?.length > 0

  const org = useFindOne(ClientOrganizationCollection, { _id: board.org })

  return (
    <>
      <div ref={contextMenuTarget} className='shine relative'>
        <Link
          to={`/b/${board._id.toString()}${location.hash}`}
          className={cn(
            'relative flex h-[96px] cursor-pointer items-center justify-center rounded-md bg-slate-700 p-4 text-sm font-semibold shadow transition hover:shadow-md dark:bg-slate-800 dark:text-white',
            'overflow-hidden hover:shadow-xl',
            {
              'bg-teal-500 dark:bg-teal-800': isPublic,
              'bg-indigo-500 dark:bg-indigo-800': isExternal,
              'bg-blue-500 dark:bg-blue-800': isShared,
            },
          )}
          onClick={() => {
            return client.emit(MetaboardEvent.CLOSE_BOARD_BROWSER)
          }}
        >
          <PublicPill isPublic={isPublic} />
          <ExternalPill isExternal={isExternal} />
          <SharedPill isShared={isShared} count={board.shareWith?.length} />
          <OrgPill org={org} />

          {board.thumbnail ? (
            <div
              dangerouslySetInnerHTML={{ __html: board.thumbnail }}
              className='[&_svg]:absolute [&_svg]:inset-0 [&_svg]:h-full [&_svg]:w-full [&_svg]:object-contain'
            />
          ) : null}

          <div className='absolute inset-0 z-20 bg-slate-700/80 dark:bg-slate-800/80' />

          <span className='z-30 text-center text-white'>{board.name}</span>

          <Pill className='left-[unset] right-0 rounded-bl-none rounded-tl-md rounded-tr-none'>
            <LucideBox className='h-3 w-3' /> {board.nodeCount ?? 0}
          </Pill>

          {isFromTeam ? (
            <Pill className='bg-fuchsia-500/50 text-xs font-medium text-fuchsia-300 dark:bg-fuchsia-800/50 dark:text-fuchsia-500'>
              <LucideUsers size={12} /> {teamNames.join(', ')}
            </Pill>
          ) : null}
        </Link>
      </div>
      <OuterBoardContextMenu target={contextMenuTarget} boardId={board._id} />
    </>
  )
}
