import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ExerciseRow } from '@/client/components/deck-editor/exercise-row'
import React from 'react'

export const SortableExerciseRow = ({ exercise, index }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: exercise._id, data: { type: 'exercise', index } })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <ExerciseRow
      exercise={exercise}
      index={index}
      setNodeRef={setNodeRef}
      listeners={listeners}
      attributes={attributes}
      style={style}
      isDragging={isDragging}
    />
  )
}
