import { RichText } from '@components/rich-text/rich-text-editor'
import { FunkyButton } from '@components/ui/funky-button'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'
import EventEmitter2 from 'eventemitter2'
import React, { useEffect, useState } from 'react'

const emitter = new EventEmitter2()

export function EditableCell({
  label,
  data,
  collection,
  field,
  highlight = false,
  placeholder = null,
}) {
  const [isEditing, setEditing] = useState(false)
  const [changedValue, setChangedValue] = useState(data?.[field])

  async function open() {
    emitter.emit('open')
    setChangedValue(data?.[field])
    setEditing(true)
  }

  async function save() {
    await collection.update(
      { _id: data._id },
      {
        $set: {
          [field]: changedValue,
        },
      },
    )

    setEditing(false)
  }

  async function reset() {
    setChangedValue(data?.[field])
    setEditing(false)
  }

  useEffect(() => {
    if (!isEditing) return

    emitter.on('open', reset)

    return () => {
      emitter.off('open', reset)
    }
  }, [isEditing])

  return (
    <div>
      {isEditing ? (
        <div className='max-w-full rounded-lg'>
          <RichText
            content={changedValue}
            onChange={value => {
              setChangedValue(value)
            }}
            className='mb-3'
          />

          <div className='flex justify-end space-x-2'>
            <FunkyButton onClick={reset}>
              <Trans>Cancel</Trans>
            </FunkyButton>
            <FunkyButton variant='primary' onClick={save}>
              <Trans>Save</Trans>
            </FunkyButton>
          </div>
        </div>
      ) : (
        <div className='inline-block'>
          {label ? <span className='block font-medium'>{label}: </span> : null}
          <div
            className={classnames(
              'prose inline-block cursor-pointer rounded text-[14px]',
              {
                'border border-indigo-100 bg-indigo-100/50 px-2 py-1 dark:border-cyan-100/50 dark:bg-cyan-100/20':
                  highlight,
                italic: !data?.[field],
              },
            )}
            onClick={open}
            dangerouslySetInnerHTML={{
              __html: data?.[field] || placeholder,
            }}
          ></div>
        </div>
      )}
    </div>
  )
}
