import { InputField } from '@components/fields/input-field'
import { FormGroup } from '@components/ui/forms/form-group'
import { t } from '@lingui/macro'
import React from 'react'

export function UserForm() {
  return (
    <div>
      <FormGroup label={t`Name`}>
        <InputField type='text' name='name' placeholder='Name' />
      </FormGroup>

      <FormGroup label={t`Email`}>
        <InputField type='email' name='email' placeholder={t`Email`} disabled />
      </FormGroup>
    </div>
  )
}
