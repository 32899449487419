import DeckList from '@/client/components/decks/deck-list'
import { FunkyButton } from '@/client/components/ui/funky-button'
import { LinkTabs } from '@/client/components/ui/link-tabs'
import { TimedLoader } from '@components/timed-loader'
import { useLocalEvent, useMethod } from '@helenejs/react'
import {
  MagnifyingGlassIcon,
  RocketLaunchIcon,
} from '@heroicons/react/20/solid'
import { useHashState } from '@hooks/use-hash-state'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import debounce from 'lodash/debounce'
import React from 'react'
import { useLocation } from 'react-router-dom'

export default function Decks() {
  const [search, setSearch] = useHashState('search', '')

  const { pathname } = useLocation()

  const authorOnly = pathname === '/decks/me'

  const { user } = useMetaboardAuth()

  const {
    loading: loadingCounts,
    result: counts,
    refresh: refreshCounts,
  } = useMethod({
    method: 'deck.counts',
  })

  // `useData` does not support pagination yet
  const {
    loading: loadingDecks,
    result,
    refresh: refreshList,
  } = useMethod({
    method: 'deck.explore.list',
    params: { search, authorOnly },
    deps: [search, authorOnly],
  })

  useLocalEvent(
    {
      event: '@e:refresh:list',
    },
    () => {
      refreshCounts()
      refreshList()
    },
    [],
  )

  const loading = loadingCounts || loadingDecks

  return (
    <div className='mx-auto grid max-w-[1440px] gap-4 @container'>
      {user ? (
        <div>
          <FunkyButton
            variant='primary'
            icon={RocketLaunchIcon}
            to='/decks/creator'
          >
            <Trans>Create Deck With AI</Trans>
          </FunkyButton>
        </div>
      ) : null}

      {user ? (
        <LinkTabs
          tabs={[
            {
              name: t`All`,
              href: '/decks',
              count: counts?.allDecks ?? '...',
            },
            {
              name: t`By Me`,
              href: '/decks/me',
              count: counts?.myDecks ?? '...',
            },
          ]}
        />
      ) : null}

      <div className='relative mt-2 rounded-md shadow-sm'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <MagnifyingGlassIcon
            className='h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <input
          type='search'
          className='block w-full rounded-md border-0 py-1.5 pl-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 dark:bg-slate-600 dark:text-slate-300 dark:ring-slate-500'
          defaultValue={search}
          placeholder={t`Search...`}
          onChange={debounce(e => {
            setSearch(e.target.value)
          }, 500)}
        />
      </div>

      {loading ? <TimedLoader /> : <DeckList decks={result || []} />}
    </div>
  )
}
