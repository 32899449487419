import { useCallback, useMemo, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import debounce from 'lodash/debounce'

interface Size {
  width: number
  height: number
}

export function useResizeObserver<T extends HTMLElement = HTMLElement>(): [
  Size,
  (node: T | null) => void,
] {
  const [size, setSize] = useState<Size>({
    width: undefined,
    height: undefined,
  })

  const callback = useMemo(
    () =>
      debounce(entries => {
        if (!Array.isArray(entries) || !entries.length) return

        const entry = entries[0]

        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        })
      }, 100),
    [setSize],
  )

  const observer = new ResizeObserver(callback)

  const ref = useCallback((node: T | null) => {
    if (node !== null) {
      observer.observe(node)
    }
  }, [])

  return [size, ref]
}
