import { BoardNode } from '@/common/constants/boards'
import { Side } from '@components/boards/utils/types'

export function getNodeEdgeSide(
  node1: BoardNode<string>,
  node2: BoardNode<string>,
) {
  const sides = findClosestSides(node1, node2)

  const [source, points1] = sides.source
  const [target, points2] = sides.target

  return {
    sx: points1.x,
    sy: points1.y,
    tx: points2.x,
    ty: points2.y,
    sourceSide: source,
    targetSide: target,
  }
}

export function getSourceNodeSide(
  node: BoardNode<string>,
  point: { x: number; y: number },
) {
  const [source, points1] = findClosestNodeSideToPoint(node, point)

  return {
    sx: points1.x,
    sy: points1.y,
    sourceSide: source,
    targetSide: getInverseSide(source as Side),
  }
}

export function calculateRotationDegrees(
  sx: number,
  sy: number,
  tx: number,
  ty: number,
): number {
  const dx = tx - sx
  const dy = ty - sy

  const angleRadians = Math.atan2(dy, dx)

  return angleRadians * (180 / Math.PI)
}

export function getSideFromAngle(angle: number) {
  if (angle >= -45 && angle <= 45) {
    return [Side.Right, Side.Left]
  } else if (angle > 45 && angle < 135) {
    return [Side.Bottom, Side.Top]
  } else if (angle >= 135 || angle <= -135) {
    return [Side.Left, Side.Right]
  } else {
    return [Side.Top, Side.Bottom]
  }
}

export function getNodeSidePoints(node: BoardNode<string>) {
  if (!node) {
    return {
      [Side.Top]: { x: 0, y: 0 },
      [Side.Right]: { x: 0, y: 0 },
      [Side.Bottom]: { x: 0, y: 0 },
      [Side.Left]: { x: 0, y: 0 },
    }
  }

  const { x, y, width, height } = node

  return {
    [Side.Top]: { x: x + width / 2, y },
    [Side.Right]: { x: x + width, y: y + height / 2 },
    [Side.Bottom]: { x: x + width / 2, y: y + height },
    [Side.Left]: { x, y: y + height / 2 },
  }
}

export function getDistance(sx: number, sy: number, tx: number, ty: number) {
  return Math.sqrt((tx - sx) ** 2 + (ty - sy) ** 2)
}

export function getCenterPoint(params: {
  x: number
  y: number
  width: number
  height: number
}) {
  const { x, y, width, height } = params

  return {
    x: x + width / 2,
    y: y + height / 2,
  }
}

export function findClosestSides(
  node1: BoardNode<string>,
  node2: BoardNode<string>,
) {
  const points1 = getNodeSidePoints(node1)
  const points2 = getNodeSidePoints(node2)

  let shortest = {
    distance: Infinity,
    sides: [],
  }

  for (const side1 in points1) {
    for (const side2 in points2) {
      const distance = getDistance(
        points1[side1].x,
        points1[side1].y,
        points2[side2].x,
        points2[side2].y,
      )

      if (distance <= shortest.distance) {
        if (
          distance === shortest.distance &&
          ![Side.Top, Side.Bottom].includes(side1 as Side)
        ) {
          continue
        }

        shortest = {
          distance,
          sides: [side1, side2],
        }
      }
    }
  }

  const source = shortest.sides[0]
  const target = shortest.sides[1]

  return {
    source: [source, points1[source], shortest.distance],
    target: [target, points2[target], shortest.distance],
  }
}

export function findClosestNodeSideToPoint(
  node: BoardNode<string>,
  point: { x: number; y: number },
) {
  const points = getNodeSidePoints(node)

  let shortest = {
    distance: Infinity,
    side: null,
  }

  for (const side in points) {
    const distance = getDistance(
      points[side].x,
      points[side].y,
      point.x,
      point.y,
    )

    if (distance <= shortest.distance) {
      if (
        distance === shortest.distance &&
        ![Side.Top, Side.Bottom].includes(side as Side)
      ) {
        continue
      }

      shortest = {
        distance,
        side,
      }
    }
  }

  return [shortest.side, points[shortest.side], shortest.distance]
}

export function getInverseSide(side: Side) {
  switch (side) {
    case Side.Top:
      return Side.Bottom
    case Side.Right:
      return Side.Left
    case Side.Bottom:
      return Side.Top
    case Side.Left:
      return Side.Right
  }
}
