export const MimeType = {
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_PNG: 'image/png',
  IMAGE_GIF: 'image/gif',
  IMAGE_WEBP: 'image/webp',

  APPLICATION_PDF: 'application/pdf',
  TEXT_PLAIN: 'text/plain',
  APPLICATION_MSWORD: 'application/msword',
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  AUDIO_WAV: 'audio/wav',
}

export const AllowedImageMimeTypes = [
  MimeType.IMAGE_JPEG,
  MimeType.IMAGE_PNG,
  MimeType.IMAGE_GIF,
  MimeType.IMAGE_WEBP,
]

export const AllowedDocumentMimeTypes = [
  MimeType.APPLICATION_PDF,
  MimeType.TEXT_PLAIN,
  MimeType.APPLICATION_MSWORD,
  MimeType.APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT,
  MimeType.AUDIO_WAV,
]

export const MaxImageSize = 20 * 1024 * 1024
export const MaxDocumentSize = 20 * 1024 * 1024

export const UploadError = {
  NO_FILE: 'NO_FILE',
  INVALID_FILE_TYPE: 'INVALID_FILE_TYPE',
  INVALID_FILE_SIZE: 'INVALID_FILE_SIZE',
  FILE_EXISTS: 'FILE_EXISTS',
}
