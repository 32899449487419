import { FolderPlusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { FunkyButton } from '@components/ui/funky-button'

export function EmptyState({
  title,
  description,
  buttonText,
  onClick,
  to,
  icon: Icon = FolderPlusIcon,
}) {
  return (
    <div className='text-center'>
      <Icon className='mx-auto h-12 w-12 text-gray-400' aria-hidden='true' />
      <h3 className='mt-2 text-sm font-semibold text-gray-900 dark:text-slate-300'>
        {title}
      </h3>
      <p className='mt-1 text-sm text-gray-500 dark:text-slate-400'>
        {description}
      </p>
      <div className='mt-6'>
        <FunkyButton variant='fb' to={to} onClick={onClick}>
          <PlusIcon className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
          {buttonText}
        </FunkyButton>
      </div>
    </div>
  )
}
