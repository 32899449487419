import { useBoardState } from '@components/boards/hooks/use-board-state'
import { SimplePoint } from '@components/boards/utils/virtualization'
import { useEffect } from 'react'

export function useFileDrop(
  onDrop: (files: File[], point: SimplePoint) => void,
  deps = [],
) {
  const { containerElem } = useBoardState()

  useEffect(() => {
    if (!containerElem) return

    function handleDrop(e) {
      e.preventDefault()
      e.stopPropagation()
      onDrop(Array.from(e.dataTransfer.files), { x: e.clientX, y: e.clientY })
    }

    function handleDragOver(e) {
      e.preventDefault()
      e.stopPropagation()
    }

    containerElem.addEventListener('drop', handleDrop)
    containerElem.addEventListener('dragover', handleDragOver)

    return () => {
      containerElem.removeEventListener('drop', handleDrop)
      containerElem.removeEventListener('dragover', handleDragOver)
    }
  }, [containerElem, onDrop, ...deps])
}
