import { Board } from '@/common/constants/boards'
import { BoardCard } from '@components/boards/grid/board-card'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useOrgTeams } from '@hooks/use-org-teams'
import React from 'react'

type BoardGridProps = {
  boards: Board[]
}

export function BoardGrid({ boards }: BoardGridProps) {
  const { userId } = useMetaboardAuth()

  const { teamById } = useOrgTeams()

  return (
    <div className='mt-4 grid gap-4 @xl:grid-cols-3 @3xl:grid-cols-4'>
      {boards?.map((board: Board) => (
        <BoardCard
          key={board._id.toString()}
          board={board}
          teamById={teamById}
          userId={userId}
        />
      ))}
    </div>
  )
}
