import React from 'react'
import { LanguageCode } from '@/common/constants'
import { t } from '@lingui/macro'
import { Tag } from '@components/ui/tag'

export function LanguageTag({ languageCode }) {
  const LanguageName = {
    [LanguageCode.EN]: t`English`,
    [LanguageCode.ES]: t`Spanish`,
    [LanguageCode.FR]: t`French`,
    [LanguageCode.DE]: t`German`,
    [LanguageCode.IT]: t`Italian`,
    [LanguageCode.PT]: t`Portuguese`,
  }

  return <Tag color='indigo'>{LanguageName[languageCode]}</Tag>
}
