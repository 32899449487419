import { BoardEvent, BoardNode } from '@/common/constants/boards'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { DeckCreator } from '@components/deck-creator/deck-creator'
import { useClient } from '@helenejs/react'
import React from 'react'

type NodeFlashcardsCreatorProps = {
  node: BoardNode<string>
}

export function NodeFlashcardsCreator({ node }: NodeFlashcardsCreatorProps) {
  const client = useClient()
  const operations = useBoardOperations()

  return (
    <div className='p-4'>
      <DeckCreator
        onSave={async ({ _id: deckId, title }) => {
          await operations.updateNode(node._id, { deck: deckId, name: title })
          client.emit(BoardEvent.CloseNode)
        }}
      />
    </div>
  )
}
