import React from 'react'
import { Button } from '@/client/components/ui/button'
import { Trans } from '@lingui/macro'
import { EmailIcon } from '@/client/components/icons/email-icon'

export function EmailAuthButton({ onClick }) {
  return (
    <Button
      onClick={onClick}
      className='flex h-[36px] flex-row items-center px-4'
    >
      <EmailIcon />
      <Trans>Sign in with Email</Trans>
    </Button>
  )
}
