import { Point } from '@/client/utils/point'
import { Rect } from '@/client/utils/rect'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import {
  fromVirtualX,
  fromVirtualY,
} from '@components/boards/utils/virtualization'
import { WindowSize } from '@hooks/use-window-resize'
import { useMemo } from 'react'

export function useVisibleRectAbs(
  viewportPosition: Point,
  virtualBounds: VirtualBounds,
  win: WindowSize,
  zoom: number,
  padding = 0,
) {
  return useMemo(
    () =>
      viewportPosition
        ? new Rect(
            fromVirtualX(viewportPosition.x * -1, virtualBounds) - padding,
            fromVirtualY(viewportPosition.y * -1, virtualBounds) - padding,
            fromVirtualX(
              viewportPosition.x * -1 + win.width / zoom,
              virtualBounds,
            ) + padding,
            fromVirtualY(
              viewportPosition.y * -1 + win.height / zoom,
              virtualBounds,
            ) + padding,
          )
        : null,
    [viewportPosition, virtualBounds, win, zoom],
  )
}
