import { CommandCenter } from '@components/boards/command-center'
import { Trans } from '@lingui/macro'
import { Affix, Button, rem, Transition } from '@mantine/core'
import { useWindowScroll } from '@mantine/hooks'
import { IconArrowUp } from '@tabler/icons-react'
import React from 'react'

export function Boards() {
  const [scroll, scrollTo] = useWindowScroll()

  return (
    <>
      <CommandCenter />
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition='slide-up' mounted={scroll.y > 0}>
          {transitionStyles => (
            <Button
              leftSection={
                <IconArrowUp style={{ width: rem(16), height: rem(16) }} />
              }
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
              variant='subtle'
            >
              <Trans>Back to top</Trans>
            </Button>
          )}
        </Transition>
      </Affix>
    </>
  )
}
