import { UrlHelper } from '@/common/url-helper'
import { Image } from 'lucide-react'
import React from 'react'

export function EnrolledDeckImage({ image, title }) {
  return (
    <figure className='avatar ring-offset-base-100 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-gray-200 ring ring-slate-500 ring-offset-2 dark:bg-slate-600 md:h-16 md:w-16'>
      {image ? (
        <img
          src={UrlHelper.getImageUrl(image, {
            width: 192,
            height: 192,
            quality: 70,
          })}
          className='h-8 rounded-full object-cover md:h-16'
          alt={title}
        />
      ) : (
        <Image className='h-6 w-6 text-gray-400' />
      )}
    </figure>
  )
}
