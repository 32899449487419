import { cn } from '@/client/utils/cn'
import { parseIcon } from '@/client/utils/parse-icon'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cva, VariantProps } from 'class-variance-authority'
import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom'

const buttonVariants = cva(
  'inline-flex gap-1.5 justify-center items-center overflow-hidden text-sm font-medium transition hover:no-underline disabled:opacity-50 disabled:cursor-not-allowed [&>p]:m-0',
  {
    variants: {
      variant: {
        primary:
          'bg-emerald-400/10 dark:text-emerald-400 text-emerald-700 hover:text-emerald-600 ring-1 ring-inset ring-emerald-600/20 dark:ring-emerald-400/20 dark:hover:text-emerald-300 hover:ring-emerald-600 dark:hover:ring-emerald-300',
        secondary:
          'bg-slate-400/10 dark:text-slate-400 text-slate-700 hover:text-slate-600 ring-1 ring-inset ring-slate-600/20 dark:ring-slate-400/20 dark:hover:text-slate-300 hover:ring-slate-600 dark:hover:ring-slate-300',
        danger:
          'bg-rose-400/10 text-rose-700 hover:bg-rose-400/5 dark:bg-rose-400/20 dark:text-rose-400 dark:hover:bg-rose-400/10 dark:hover:text-rose-300 ring-1 ring-inset ring-rose-400/20 dark:ring-rose-400/20 dark:hover:ring-rose-300',
        warning:
          'bg-yellow-400/10 text-yellow-700 hover:bg-yellow-400/5 dark:bg-yellow-400/20 dark:text-yellow-400 dark:hover:bg-yellow-400/10 dark:hover:text-yellow-300 ring-1 ring-inset ring-yellow-400/20 dark:ring-yellow-400/20 dark:hover:ring-yellow-300',
        filled:
          'bg-emerald-600 text-white hover:bg-emerald-700 dark:text-white',
        outline:
          'text-zinc-700 ring-1 ring-inset ring-zinc-900/10 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:text-slate-300 dark:hover:ring-slate-300',
        text: 'text-emerald-500 hover:text-emerald-600 dark:text-emerald-400 dark:hover:text-emerald-500',
        ghost:
          'text-zinc-900 hover:text-zinc-700 dark:text-zinc-400 dark:hover:text-zinc-300',
      },
      size: {
        sm: 'px-3 py-1.5 font-medium text-sm',
        md: 'px-4 py-2 font-medium text-sm',
        lg: 'px-4 py-2 fond-medium text-lg',
      },
      fill: {
        true: 'w-full',
      },
      rounded: {
        true: 'rounded-full',
        false: 'rounded-md',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'sm',
      rounded: true,
      fill: false,
    },
  },
)

function ArrowIcon(props) {
  return (
    <svg viewBox='0 0 20 20' fill='none' aria-hidden='true' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9'
      />
    </svg>
  )
}

export interface ButtonProps extends VariantProps<typeof buttonVariants> {
  children?: React.ReactNode
  arrow?: 'left' | 'right'
  loading?: boolean
  icon?: any
  rightIcon?: any
  className?: string
  to?: string
  onClick?: () => void
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
}

export const Button = forwardRef<any, ButtonProps>(
  (
    {
      className,
      children,
      arrow,
      loading,
      icon,
      rightIcon,
      fill,
      size,
      variant,
      rounded,
      disabled,
      onClick,
      type,
      ...props
    },
    ref,
  ) => {
    const Component = props.to ? Link : ('button' as any)

    className = cn(
      buttonVariants({
        fill,
        size,
        variant,
        rounded,
      }),
      className,
    )

    const arrowIcon = (
      <ArrowIcon
        className={cn(
          'h-5 w-5',
          arrow === 'left' && '-ml-1 rotate-180',
          arrow === 'right' && '-mr-1',
        )}
      />
    )

    return (
      <Component
        ref={ref}
        className={className}
        disabled={loading || disabled}
        onClick={!disabled ? onClick : undefined}
        type={type ?? props.to ? undefined : 'button'}
        {...props}
      >
        {arrow === 'left' && arrowIcon}
        {icon ? parseIcon(icon, 'h-4 w-4') : null}
        {children}
        {rightIcon ? parseIcon(rightIcon, 'h-4 w-4') : null}
        {arrow === 'right' && arrowIcon}
        {loading ? (
          <FontAwesomeIcon icon={faCircleNotch} className='ml-1 animate-spin' />
        ) : null}
      </Component>
    )
  },
)
