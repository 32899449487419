import React from 'react'
import { useField } from 'formik'
import { FieldOutline } from './field-outline'
import { RadioList, RadioListItem } from '@components/ui/radio-list'

type RadioListFieldProps = {
  outlineProps?: React.ComponentProps<typeof FieldOutline>
  items: RadioListItem[]
  name: string
}

export function RadioListField({
  outlineProps,
  items,
  name,
}: RadioListFieldProps) {
  const [field, meta, helper] = useField(name)

  return (
    <FieldOutline meta={meta} {...outlineProps}>
      <RadioList items={items} value={field.value} onChange={helper.setValue} />
    </FieldOutline>
  )
}
