import {
  ClientBoardCollection,
  ClientBoardEdgeCollection,
  ClientBoardNodeCollection,
} from '@/client/data'
import { addTab } from '@/client/models/board-tabs'
import { cn } from '@/client/utils/cn'
import { Rect } from '@/client/utils/rect'
import {
  BoardBackground,
  BoardEdge,
  BoardEvent,
  BoardNode,
} from '@/common/constants/boards'
import { BackgroundSwitcher } from '@components/boards/background-switcher'
import { BoardMinimap } from '@components/boards/board-minimap'
import { DeveloperInfo } from '@components/boards/developer-info'
import { DraggableNode } from '@components/boards/draggable-node'
import { Edge } from '@components/boards/edge'
import { EdgeWrapper } from '@components/boards/edge-wrapper'
import { useAutoCenterNode } from '@components/boards/hooks/use-auto-center-node'
import { useBoardChangeEvents } from '@components/boards/hooks/use-board-change-events'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useBoardPermission } from '@components/boards/hooks/use-board-permission'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { useEdges } from '@components/boards/hooks/use-edges'
import { usePaste } from '@components/boards/hooks/use-paste'
import { useViewport } from '@components/boards/hooks/use-viewport'
import { useVirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { useVisibleEdges } from '@components/boards/hooks/use-visible-edges'
import { useVisibleNodes } from '@components/boards/hooks/use-visible-nodes'
import { InnerBoardContextMenu } from '@components/boards/inner-board-context-menu'
import { NodeDrawer } from '@components/boards/node-drawer'
import { Selector } from '@components/boards/selector'
import { ShareModal } from '@components/boards/share-modal'
import { getSourceNodeSide } from '@components/boards/utils/positioning'
import {
  fromVirtualX,
  fromVirtualY,
} from '@components/boards/utils/virtualization'
import { useClient } from '@helenejs/react'
import { useData } from '@hooks/use-data'
import { useHeleneEvent } from '@hooks/use-helene-event'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useMultiSelect } from '@hooks/use-multi-select'
import { useEventListener } from 'ahooks'
import { LucideLoader2 } from 'lucide-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHotkeys } from 'react-hotkeys-hook'
import { BoardContext } from './utils/board-context'

type BoardViewportProps = {
  boardId: string
  embedded?: boolean
}

export function BoardViewport({ boardId, embedded }: BoardViewportProps) {
  const { user } = useMetaboardAuth()

  useBoardChangeEvents(boardId)

  const client = useClient()

  useEffect(() => {
    const original = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = original
    }
  }, [])

  const {
    selectedIds,
    setState,
    isReadOnly,
    containerElem,
    setContainerElem,
    draggableElem,
    setDraggableElem,
    zoomMultiplier,
    developerMode,
    setDeveloperMode,
    isDragging,
  } = useBoardState()

  useEffect(() => {
    addTab(boardId)
    setState(draft => {
      draft.boardId = boardId
    })
  }, [boardId, setState])

  const virtualBounds = useVirtualBounds(boardId)

  const {
    viewportPosition,
    viewportPositionRef,
    updatePosition,
    visibleRect,
    isViewportInitialized,
  } = useViewport(
    boardId,
    virtualBounds,
    zoomMultiplier,
    containerElem,
    draggableElem,
  )

  useEffect(() => {
    if (!containerElem) return
    draggableElem.style.cursor = isDragging ? 'grabbing' : undefined
    containerElem.style.transform = `scale(${zoomMultiplier}) translate3d(${viewportPosition.x}px, ${viewportPosition.y}px, 0)`
  }, [
    isDragging,
    draggableElem,
    containerElem,
    !!viewportPosition.x,
    !!viewportPosition.y,
  ])

  const board = useData({
    method: 'boards.get',
    params: { boardId },
    filter: { _id: boardId },
    collection: ClientBoardCollection,
    single: true,
  })

  useBoardPermission(board.data, user)

  const nodes = useData({
    method: 'boards.listNodes',
    params: { boardId },
    filter: { board: boardId },
    collection: ClientBoardNodeCollection,
    selectiveSync: true,
  })

  const edges = useData({
    method: 'boards.listEdges',
    params: { boardId },
    filter: { board: boardId },
    collection: ClientBoardEdgeCollection,
    selectiveSync: true,
  })

  useHeleneEvent(BoardEvent.Refresh, () => {
    board.refresh()
    nodes.refresh()
    edges.refresh()
  })

  useHeleneEvent(BoardEvent.ClearSelection, () => {
    setState(draft => {
      draft.selectedIds = []
    })
  })

  const multiSelect = useMultiSelect({
    onSelect(rect) {
      if (!rect) {
        setState(draft => {
          draft.selectedIds = []
        })
        return
      }

      const ids = []

      document.querySelectorAll('[data-node]').forEach(node => {
        const nodeRect = Rect.fromDOM(node as HTMLElement)

        if (!nodeRect.intersectsWith(rect)) return

        ids.push(node.getAttribute('data-node-id'))
      })

      setState(draft => {
        draft.selectedIds = ids
      })
    },
  })

  const isNodeDrawerOpenRef = useRef(false)
  const [isNodeDrawerOpen, setNodeDrawerOpen] = useState(false)
  const [activeNodeId, setActiveNodeId] = useState(null)

  const operations = useBoardOperations()

  useHotkeys(['del', 'backspace'], () => {
    for (const id of selectedIds) {
      operations.deleteNode(id)
    }
  })

  useEventListener(
    'dblclick',
    e => {
      if ((e.target as HTMLElement).closest('[data-drawer]')) return
      if (isReadOnly) return

      operations.addNode(
        fromVirtualX(
          e.clientX / zoomMultiplier - viewportPosition.x,
          virtualBounds,
        ),
        fromVirtualY(
          e.clientY / zoomMultiplier - viewportPosition.y,
          virtualBounds,
        ),
      )
    },
    {
      target: draggableElem,
    },
  )

  useHeleneEvent(BoardEvent.OpenNode, async (id: string) => {
    setActiveNodeId(id)
    setNodeDrawerOpen(true)
    isNodeDrawerOpenRef.current = true
  })

  useHeleneEvent(BoardEvent.CloseNode, async () => {
    setActiveNodeId(null)
    setNodeDrawerOpen(false)
    isNodeDrawerOpenRef.current = false
  })

  const { isDraggingEdge, edgeTargetPosition, sourceNode } = useEdges(
    viewportPosition,
    virtualBounds,
  )

  usePaste(virtualBounds, viewportPosition, boardId)

  const loading = board.loading || nodes.loading || edges.loading

  const sides = useMemo(
    () =>
      getSourceNodeSide(sourceNode, {
        x: fromVirtualX(edgeTargetPosition.x, virtualBounds),
        y: fromVirtualY(edgeTargetPosition.y, virtualBounds),
      }),
    [sourceNode, edgeTargetPosition],
  )

  useAutoCenterNode({
    isViewportInitialized,
    virtualBounds,
    updatePosition,
    loading,
  })

  const visibleNodes = useVisibleNodes(nodes.data, visibleRect)
  const visibleEdges = useVisibleEdges(visibleNodes, edges.data)

  return (
    <BoardContext.Provider value={{ boardId }}>
      {!embedded && board.data?.name ? (
        <Helmet>
          <title>{board.data?.name} | Metaboard</title>
        </Helmet>
      ) : null}
      <div className='relative h-screen w-screen touch-none select-none'>
        {!isViewportInitialized || loading ? (
          <LucideLoader2 className='absolute left-16 top-32 z-[20000] animate-spin' />
        ) : null}

        <ShareModal />

        <BackgroundSwitcher />

        <DeveloperInfo
          developerMode={developerMode}
          setDeveloperMode={setDeveloperMode}
          viewportPosition={viewportPosition}
          virtualBounds={virtualBounds}
          zoomMultiplier={zoomMultiplier}
          user={user}
        />

        {isViewportInitialized ? (
          <BoardMinimap bounds={virtualBounds} viewport={viewportPosition} />
        ) : null}

        <InnerBoardContextMenu
          target={draggableElem}
          viewportPosition={viewportPosition}
          virtualBounds={virtualBounds}
          zoomMultiplier={zoomMultiplier}
        />
        <div
          className={cn(
            'relative overflow-hidden bg-gray-300 dark:bg-slate-700',
          )}
          style={{
            // This severely degrades font rendering quality on Windows
            // willChange: 'transform',
            transformStyle: 'flat',
            transformOrigin: '0 0',
            width: virtualBounds.width,
            height: virtualBounds.height,
          }}
          ref={setContainerElem}
          data-type='container'
        >
          <svg
            id='svg-root'
            className={cn('absolute inset-0 touch-none select-none', {
              'grid-background':
                !board.data?.background ||
                board.data?.background === BoardBackground.Grid,
              'dotted-background':
                board.data?.background === BoardBackground.Dotted,
            })}
            style={{
              width: virtualBounds.width,
              height: virtualBounds.height,
            }}
            ref={setDraggableElem}
            data-multi-select
            data-viewport-handle
          >
            <defs>
              <marker
                id='arrow'
                viewBox='0 0 10 10'
                refX='10'
                refY='5'
                markerWidth='6'
                markerHeight='6'
                orient='auto-start-reverse'
                className='fill-gray-500'
              >
                <path d='M 0 0 L 10 5 L 0 10 z' />
              </marker>
            </defs>
          </svg>

          {isViewportInitialized
            ? visibleEdges?.map((edge: BoardEdge<string>) => (
                <EdgeWrapper
                  key={edge._id}
                  edge={edge}
                  selectedNodeIds={selectedIds}
                  virtualBounds={virtualBounds}
                  viewportPosition={viewportPosition}
                />
              ))
            : null}

          {isDraggingEdge ? (
            <Edge
              sx={sides.sx}
              sy={sides.sy}
              tx={edgeTargetPosition.x}
              ty={edgeTargetPosition.y}
              virtualBounds={virtualBounds}
              viewportPosition={viewportPosition}
              sourceSide={sides.sourceSide}
              targetSide={sides.targetSide}
              isSimulation
            />
          ) : null}

          {isViewportInitialized
            ? visibleNodes.map((node: BoardNode<string>) => (
                <DraggableNode
                  key={node._id}
                  id={node._id}
                  node={node}
                  isSelected={selectedIds.includes(node._id)}
                  virtualBounds={virtualBounds}
                  viewportPositionRef={viewportPositionRef}
                />
              ))
            : null}
        </div>

        <NodeDrawer
          id={activeNodeId}
          isOpen={isNodeDrawerOpen}
          onClose={() => {
            setNodeDrawerOpen(false)
            isNodeDrawerOpenRef.current = false
          }}
          onFinishedClosing={() => {
            setActiveNodeId(null)
          }}
        />

        <Selector {...multiSelect} />
      </div>
    </BoardContext.Provider>
  )
}
