import isFunction from 'lodash/isFunction'
import React, { MutableRefObject, PropsWithChildren } from 'react'

export const isJSXElement = (element: any) =>
  React.isValidElement(element) && element.type !== React.Fragment

export const isReactComponent = (element: any) =>
  isFunction(element) && element.prototype.isReactComponent

export const RenderWithLayout = ({
  parent: Parent,
  children,
  layoutProps,
}: PropsWithChildren<{
  parent?: React.FunctionComponent<any>
  layoutProps?: any
  children: React.FunctionComponent<any> | JSX.Element
}>) => {
  if (isReactComponent(children)) {
    const Component = children as React.FunctionComponent<any>

    return Parent ? (
      <Parent {...layoutProps}>
        <Component />
      </Parent>
    ) : (
      <Component />
    )
  }

  if (isJSXElement(children)) {
    return Parent ? (
      <Parent {...layoutProps}>{children}</Parent>
    ) : (
      (children as JSX.Element)
    )
  }

  throw new Error('Invalid Children')
}

export const isMutableRefObject = (ref: any): ref is MutableRefObject<any> =>
  ref && 'current' in ref
