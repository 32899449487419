export const LS = {
  get: key => {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      return null
    }
  },
  set: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      return null
    }
  },
}
