import { cn } from '@/client/utils/cn'
import { Button } from '@components/ui/button'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useReactive } from 'ahooks'
import { Edit, RefreshCw, Undo } from 'lucide-react'
import React from 'react'
import { ExerciseDialog } from '../decks/exercise-dialog'

export function AdminBar({ exercise, game, deck }) {
  const { _id: deckId } = deck

  const client = useClient()
  const { user } = useMetaboardAuth()

  const state = useReactive({
    isResetting: false,
  })

  if (!user) return null
  if (user._id !== deck.author && !user.isAdmin) return null

  return (
    <div className='fixed bottom-[88px] right-0 flex flex-col items-center justify-center gap-4 rounded-bl-lg rounded-tl-lg bg-slate-50 bg-white bg-opacity-20 px-3 py-4 shadow-md backdrop-blur-lg'>
      <Button
        onClick={async () => {
          if (!state.isResetting) {
            state.isResetting = true

            setTimeout(() => {
              state.isResetting = false
            }, 3000)

            return
          }

          state.isResetting = false

          await client.call('deck.reset', {
            deckId,
          })

          game.reload()
        }}
        variant='ghost'
        className={cn('rounded-none p-0', {
          'animate-pulse text-red-500 hover:text-red-400 dark:text-red-400 dark:hover:text-red-300':
            state.isResetting,
        })}
      >
        <RefreshCw className='h-5 w-5' />
      </Button>
      {exercise && (
        <Button
          onClick={() => client.emit('exercise.open', exercise._id)}
          variant='ghost'
          className='rounded-none p-0'
        >
          <Edit className='h-5 w-5' />
        </Button>
      )}

      {exercise && (
        <Button
          onClick={async () => {
            await client.call('exercise.review', {
              deckId,
              cardId: exercise._id,
            })
            game.next()
          }}
          variant='ghost'
          className='rounded-none p-0'
        >
          <Undo className='h-5 w-5' />
        </Button>
      )}

      {exercise && (
        <ExerciseDialog
          onSave={() => {
            game.reload()
          }}
        />
      )}
    </div>
  )
}
