import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'

export function arrayFieldMap(array, fields: Record<string, string>) {
  if (isEmpty(array)) return []

  const pairs = Object.entries(fields)

  return array.map(item => {
    const obj = {}

    for (const [target, source] of pairs) {
      obj[target] = item[source]
    }

    return obj
  })
}

export function incOrSet(object, key, value) {
  if (isNumber(object[key])) {
    object[key] += value
  } else {
    object[key] = value
  }
}
