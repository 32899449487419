import { roundToGrid } from '@/common/boards/constraints'
import { BoardEvent } from '@/common/constants/boards'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { useHeleneEvent } from '@hooks/use-helene-event'

export function useGroupEvents({
  id,
  position,
  size,
  operations,
  setPosition,
  startPos,
}) {
  const { selectedIds } = useBoardState()

  useHeleneEvent(
    BoardEvent.GroupStart,
    ({ id: eid }) => {
      if (eid === id || selectedIds.includes(id)) {
        startPos.current = {
          x: position.x,
          y: position.y,
        }
      }
    },
    [id, position, selectedIds],
  )

  useHeleneEvent(
    BoardEvent.GroupMove,
    ({ id: eid, x, y }) => {
      if (eid === id || selectedIds.includes(id)) {
        setPosition({
          x: roundToGrid(startPos.current.x + x),
          y: roundToGrid(startPos.current.y + y),
        })
      }
    },
    [id, setPosition, selectedIds],
  )

  useHeleneEvent(
    BoardEvent.GroupEnd,
    ({ id: eid }) => {
      if (eid === id || selectedIds.includes(id)) {
        operations.updateNode(id, {
          x: position.x,
          y: position.y,
          width: size.width,
          height: size.height,
        })
      }
    },
    [id, position, size, operations, selectedIds],
  )
}
