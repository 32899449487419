import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { Markdown } from '../../markdown'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import { motion } from 'framer-motion'
import { Spinner } from '@components/spinner'
import { useHotkeys } from 'react-hotkeys-hook'
import { KBD } from '@components/ui/kbd'
import { clsx } from 'clsx'
import { FontStyle } from '@/common/constants'

function getBackground({ isSelected, isCorrect, isLoading }) {
  if (isLoading || (isSelected && isCorrect === null))
    return 'bg-slate-500/20 text-slate-500 dark:text-slate-300'
  if (isSelected && isCorrect)
    return 'bg-green-500/20 text-green-600 dark:text-green-300'
  if (isSelected && !isCorrect)
    return 'bg-red-500/20 text-red-500 dark:text-red-300'
  return 'dark:text-slate-300'
}

export function MultipleChoiceOption({
  index,
  selected,
  isCorrect,
  setSelected,
  sample,
  onAnswer,
  setCorrect,
  game,
}) {
  const _sample = isString(sample) ? sample : sample.answer

  const [isLoading, setLoading] = useState(false)
  const isSelected = selected === _sample
  const isNotSelected = selected && !isSelected

  const parsedSample = useMemo(() => {
    /**
     * This is needed for i18n
     */
    if (isObject(sample)) {
      return (
        <Markdown
          className={clsx('grow', {
            '!font-serif': game.settings.fontStyle === FontStyle.SERIF,
            '!font-mono': game.settings.fontStyle === FontStyle.MONOSPACE,
          })}
        >
          {sample.label ?? sample.answer}
        </Markdown>
      )
    }

    return (
      <Markdown
        className={clsx('grow', {
          '!font-serif': game.settings.fontStyle === FontStyle.SERIF,
          '!font-mono': game.settings.fontStyle === FontStyle.MONOSPACE,
        })}
      >
        {sample}
      </Markdown>
    )
  }, [sample])

  const answer = useCallback(
    async e => {
      if (selected) return

      const _answer = isString(sample) ? sample : sample.answer

      setSelected(_answer)
      setLoading(true)

      const positions = {
        pageX: e.touches?.[0]?.pageX ?? e.pageX,
        pageY: e.touches?.[0]?.pageY ?? e.pageY,
      }

      await onAnswer({
        answer: _answer,
        setLoading,
        setCorrect,
        setSelected,
        ...positions,
      })
    },
    [selected, sample, setSelected, onAnswer, setCorrect],
  )

  useHotkeys(String(index + 1), answer, [answer])

  return (
    <motion.div
      layout
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, delay: index * 0.05 }}
      onClick={answer}
      style={{
        originX: 0,
      }}
    >
      <div
        className={classNames(
          'flex select-none items-center gap-2 rounded p-3 transition-all',
          {
            'cursor-pointer': !selected,
            'hover:bg-slate-700/10 active:bg-slate-700/10 dark:hover:bg-white/10 dark:active:bg-white/10':
              !selected,
            'opacity-20': isNotSelected,
          },
          getBackground({
            isSelected,
            isCorrect,
            isLoading,
          }),
        )}
      >
        <span className='flex h-[24px] w-[24px] shrink-0 items-center justify-center self-start opacity-30'>
          {index + 1}.
        </span>
        {parsedSample}
        <KBD>{index + 1}</KBD>
        {isSelected && isLoading ? (
          <Spinner className='h-[24px] w-[24px] shrink-0' />
        ) : (
          <div className='h-[24px] w-[24px] shrink-0' />
        )}
      </div>
    </motion.div>
  )
}
