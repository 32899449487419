import classnames from 'classnames'

export function TypingAnimation({ isSomeoneTyping, className }) {
  if (!isSomeoneTyping) return null

  return (
    <div className={classnames('flex justify-center', className)}>
      <div className='flex animate-bounce items-center space-x-2'>
        <div className='h-2 w-2 rounded-full bg-gray-500'></div>
        <div className='h-2 w-2 rounded-full bg-gray-500'></div>
        <div className='h-2 w-2 rounded-full bg-gray-500'></div>
      </div>
    </div>
  )
}
