import { cn } from '@/client/utils/cn'
import { useObject } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'

export function useRecModeProps() {
  const { recordingMode } = useMetaboardAuth()

  return useObject({
    className: cn({ 'blur-sm': recordingMode }),
  })
}
