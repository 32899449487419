import { BoardNode } from '@/common/constants/boards'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useClickOutside } from '@components/boards/hooks/use-click-outside'
import { getNodeDimensions } from '@components/boards/utils/nodes'
import React, { useEffect, useState } from 'react'

type NodeNameEditorProps = {
  id: string
  defaultValue: string
  onDone: () => void
  onSimulation: (value: string) => void
  node: BoardNode<string>
}

export function NodeNameEditor({
  id,
  defaultValue,
  onDone,
  onSimulation,
  node,
}: NodeNameEditorProps) {
  const [editor, setEditor] = useState(null)
  const operations = useBoardOperations()

  useClickOutside(
    editor,
    () => {
      operations.updateNode(id, {
        _renaming: false,
        name: editor.textContent,
        ...getNodeDimensions(node, editor.textContent),
      })
      onDone()
    },
    [id, onDone, operations],
  )

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      operations.updateNode(id, {
        name: editor.textContent,
        ...getNodeDimensions(node, editor.textContent),
      })
      onDone()
      return
    }

    if (e.key === 'Escape') {
      e.preventDefault()
      onDone()
      onSimulation(defaultValue)
      return
    }

    onSimulation(editor.textContent)
  }

  useEffect(() => {
    if (editor) {
      focus(editor)
    }
  }, [editor])

  return (
    <div
      ref={setEditor}
      contentEditable
      className='z-[10000] cursor-text bg-transparent italic'
      onKeyDown={onKeyDown}
      suppressContentEditableWarning
    >
      {defaultValue}
    </div>
  )
}

function focus(element: HTMLDivElement) {
  setTimeout(function () {
    let sel: Selection, range: Range
    if (window.getSelection && document.createRange) {
      range = document.createRange()
      range.selectNodeContents(element)
      sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    } else {
      // @ts-ignore
      if (document.body.createTextRange) {
        // @ts-ignore
        range = document.body.createTextRange()
        // @ts-ignore
        range.moveToElementText(element)
        // @ts-ignore
        range.select()
      }
    }
  }, 0)
}
