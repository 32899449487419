import { Button, CopyButton } from '@mantine/core'
import { IconClipboardCheck, IconCopy } from '@tabler/icons-react'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import React from 'react'

export function CodeBlockComponent({ node, updateAttributes, extension }) {
  // const defaultLanguage = node.attrs.language || 'null'

  return (
    <NodeViewWrapper className='code-block relative'>
      {/*<select*/}
      {/*  contentEditable={false}*/}
      {/*  defaultValue={defaultLanguage}*/}
      {/*  onChange={event => updateAttributes({ language: event.target.value })}*/}
      {/*>*/}
      {/*  <option value='null'>auto</option>*/}
      {/*  <option disabled>—</option>*/}
      {/*  {extension.options.lowlight.listLanguages().map((lang, index) => (*/}
      {/*    <option key={index} value={lang}>*/}
      {/*      {lang}*/}
      {/*    </option>*/}
      {/*  ))}*/}
      {/*</select>*/}

      <CopyButton value={node.textContent}>
        {({ copied, copy }) => (
          <Button
            color='gray'
            onClick={copy}
            className='absolute right-1 top-1'
            variant='transparent'
          >
            {copied ? <IconClipboardCheck size={16} /> : <IconCopy size={16} />}
          </Button>
        )}
      </CopyButton>
      <pre>
        <NodeViewContent as='code' />
      </pre>
    </NodeViewWrapper>
  )
}
