import { ClientBoardCollection } from '@/client/data'
import { yup } from '@/server/utils/schema-utils'
import { useBoardId } from '@components/boards/hooks/use-board-id'
import { ShareList } from '@components/boards/share-list'
import { HorizontalLoader } from '@components/horizontal-loader'
import { useClient, useFindOne, useMethod } from '@helenejs/react'
import { t, Trans } from '@lingui/macro'
import { Button, Input } from '@mantine/core'
import defer from 'lodash/defer'
import { LucideAtSign } from 'lucide-react'
import React, { useState } from 'react'

export function ShareWith() {
  const boardId = useBoardId()

  const board = useFindOne(ClientBoardCollection, { _id: boardId })

  const shareWithUsers = useMethod({
    method: 'boards.listShareWith',
    params: {
      boardId,
    },
    authenticated: true,
    deps: [boardId, board],
  })

  if (!shareWithUsers.result) {
    return <HorizontalLoader />
  }

  return (
    <ShareList data={shareWithUsers.result} refresh={shareWithUsers.refresh} />
  )
}

export function ShareWithInput({ permission }) {
  const client = useClient()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const boardId = useBoardId()

  const isValidEmail = yup.string().email().required().isValidSync(email)

  const share = async () => {
    if (!isValidEmail) return
    if (loading) return

    setLoading(true)
    setEmail('')
    await client.call('boards.shareByEmail', {
      boardId,
      email,
      permission,
    })

    defer(() => {
      setLoading(false)
    })
  }

  return (
    <div className='flex items-center gap-2'>
      <Input
        value={email ?? ''}
        onChange={e => {
          setEmail(e.target.value.trim())
        }}
        placeholder={t`Email`}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.stopPropagation()
            e.preventDefault()
            share().catch(console.error)
          }
        }}
        className='flex-grow'
        leftSection={<LucideAtSign size={16} />}
        error={email && !isValidEmail}
      />

      <Button
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          share().catch(console.error)
        }}
        color='blue'
        disabled={!isValidEmail}
        loading={loading}
      >
        <Trans>Share</Trans>
      </Button>
    </div>
  )
}
