import { Boards } from '@/client/pages/boards'
import Landing from '@/client/pages/public/landing'
import { MetaboardEvent } from '@/common/events'
import { CompleteDetailsForm } from '@components/forms/complete-details-form'
import { StandardForm } from '@components/forms/standard-form'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useReactive } from '@hooks/use-reactive-local-storage'
import { SchemaHook } from '@hooks/use-schema'
import React from 'react'
import { object } from 'yup'

export function Home() {
  const state = useReactive({
    loading: false,
  })

  const client = useClient()

  const { user, incomplete } = useMetaboardAuth()

  const nameSchema = SchemaHook.useName()

  if (incomplete) {
    return (
      <StandardForm
        className='mx-auto max-w-3xl'
        initialValues={{ name: '' }}
        onSave={async ({ name }) => {
          state.loading = true
          client.call('user.update', { name }).catch(console.error)
          await client.waitFor(MetaboardEvent.USER_REFRESHED, 30000)
          state.loading = false
        }}
        schema={object({
          name: nameSchema,
        })}
      >
        <CompleteDetailsForm />
      </StandardForm>
    )
  }

  if (user) {
    return <Boards />
  }

  return <Landing />
}
