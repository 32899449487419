import { ColorfulSpinner } from '@components/colorful-spinner'
import React, { useEffect, useMemo, useState } from 'react'

type TimedLoaderProps = {
  label?: string
}

export function TimedLoader({ label }: TimedLoaderProps) {
  const startedTime = useMemo(() => Date.now(), [])

  const [time, setTime] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now() - startedTime)
    }, 100)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className='flex flex-col gap-2 p-4 text-center'>
      <div className='flex items-center justify-center'>
        <ColorfulSpinner className='mx-auto' />
      </div>
      {label ? (
        <div className='!mt-2 animate-pulse text-sm uppercase'>{label}</div>
      ) : null}
      <div className='animate-pulse text-sm'>{(time / 1000).toFixed(1)}s</div>
    </div>
  )
}
