import { cn } from '@/client/utils/cn'
import { ColorMap } from '@/common/boards/color-map'
import { BaseColor } from '@/common/constants/color'
import React from 'react'

type ColorButtonProps = {
  color: BaseColor
  currentColor?: BaseColor
  onClick?: () => void
}

export function ColorButton({
  color,
  currentColor,
  onClick,
}: ColorButtonProps) {
  return (
    <button
      className={cn(
        'h-4 w-4 rounded-full border border-neutral-100',
        {
          'border-2 border-indigo-400': color === currentColor,
        },
        ColorMap[color],
      )}
      onClick={onClick}
    />
  )
}
