import { TextInputField } from '@components/mantine-ui/fields/text-input-field'
import { useClient } from '@helenejs/react'
import { t, Trans } from '@lingui/macro'
import { Button } from '@mantine/core'
import { IconAt, IconSend } from '@tabler/icons-react'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { object, string } from 'yup'

export function InviteForm({
  orgId,
  refresh,
}: {
  orgId: string
  refresh: () => void
}) {
  const client = useClient()
  const [submitting, setSubmitting] = useState(false)

  return (
    <div>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={async (values, { resetForm }) => {
          try {
            setSubmitting(true)
            await client.call('org.invite', {
              orgId,
              email: values.email,
            })
            resetForm()
            refresh()
          } finally {
            setSubmitting(false)
          }
        }}
        validationSchema={object({
          email: string().email().required(),
        })}
      >
        <Form autoComplete='off'>
          <TextInputField
            name='email'
            leftSectionPointerEvents='none'
            leftSection={<IconAt size={16} />}
            rightSection={
              <Button
                type='submit'
                variant='subtle'
                leftSection={<IconSend size={16} />}
              >
                <Trans>Invite</Trans>
              </Button>
            }
            rightSectionWidth='auto'
            label={t`Invite User`}
            placeholder={t`Email`}
            disabled={submitting}
          />
        </Form>
      </Formik>
    </div>
  )
}
