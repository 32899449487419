import { cn } from '@/client/utils/cn'
import { MetaboardEvent } from '@/common/events'
import { XpUtils } from '@/common/xp-utils'
import { useRemoteEvent } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'

import { motion, useAnimationControls } from 'framer-motion'
import isNumber from 'lodash/isNumber'
import React, { useEffect, useMemo, useRef, useState } from 'react'

export function ProgressXp({ className }) {
  const { user, userId } = useMetaboardAuth()

  const previousRef = useRef(null)

  const [xpEarned, setXpEarned] = useState(user?.xpEarned ?? 0)

  const levelData = useMemo(
    () => (isNumber(xpEarned) ? XpUtils.calcLevelData(xpEarned) : {}),
    [xpEarned],
  )

  const [percentage, setPercentage] = useState(0)
  const [duration, setDuration] = useState(0.2)

  useRemoteEvent(
    {
      event: MetaboardEvent.XP_EARNED,
      channel: userId,
      active: !!userId,
    },
    xp => {
      setXpEarned(xpEarned + xp)
    },
    [userId, xpEarned],
  )

  const controls = useAnimationControls()
  const controlsXp = useAnimationControls()

  useEffect(() => {
    controlsXp.start({
      scale: [1.25, 1],
      transition: { type: 'spring', duration: 0.2 },
    })

    const isLevelUp =
      previousRef.current && levelData.level > previousRef.current.level

    if (isLevelUp) {
      controls.start({
        scale: [1.25, 1],
        transition: { type: 'spring', duration: 0.2 },
      })

      setDuration(0)
      setPercentage(1)

      setTimeout(() => {
        setPercentage(0)

        setTimeout(() => {
          setDuration(0.2)
          setPercentage(levelData.percentage)
        }, 200)
      }, 300)
    } else {
      setDuration(0.2)
      setPercentage(levelData.percentage)
    }

    previousRef.current = levelData
  }, [levelData])

  if (!user) return null

  return (
    <div
      className={cn(
        'fixed bottom-0 left-0 flex h-auto w-full flex-row items-center gap-4 p-8',
        className,
      )}
    >
      <motion.span
        className='flex-shrink-0 text-sm font-medium dark:text-slate-300'
        animate={controls}
      >
        <Trans>Level {levelData.level}</Trans>
      </motion.span>
      <div
        className={cn(
          'relative flex h-2 grow flex-row justify-start rounded-full bg-gray-300',
        )}
      >
        <motion.div
          className={cn('rounded-full', {
            'bg-green-500': percentage < 1,
            'bg-emerald-400': percentage === 1,
          })}
          animate={{ width: `${percentage * 100}%` }}
          transition={{ duration }}
        ></motion.div>
        <div className='absolute inset-0 grid grid-cols-10 rounded-full'>
          {Array.from({ length: 10 }).map((_, i) => (
            <div
              key={i}
              className={cn('w-1/10 h-2', {
                'border-r border-gray-800/20': i < 9,
                'border-emerald-800/20': Math.floor(percentage * 10) > i,
              })}
            />
          ))}
        </div>
      </div>
      <motion.span
        className='flex-shrink-0 text-sm font-medium dark:text-slate-300'
        animate={controlsXp}
      >
        {xpEarned?.toLocaleString() || 0} XP
      </motion.span>
    </div>
  )
}
