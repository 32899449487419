import React from 'react'
import classnames from 'classnames'

export function SmallAvatar({ online, googlePicture }) {
  return (
    <span
      className={classnames(
        'inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100',
        {
          'ring-2 ring-green-500': !!online,
        },
      )}
    >
      <img
        src={
          // Get a high resolution image from Google, perhaps we should make this more generic.
          googlePicture ?? '/profile-picture.png'
        }
        referrerPolicy='no-referrer'
        className='aspect-square h-full w-full object-cover'
        alt={name}
      />
    </span>
  )
}
