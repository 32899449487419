import { cn } from '@/client/utils/cn'
import { EdgeDirection } from '@/common/constants/boards'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { useClickOutside } from '@components/boards/hooks/use-click-outside'
import { useCursorPosition } from '@components/boards/hooks/use-cursor-position'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { SvgPortal } from '@components/boards/svg-portal'
import { Side } from '@components/boards/utils/types'
import { fromAbsX, fromAbsY } from '@components/boards/utils/virtualization'
import { ChevronsLeftRight, XIcon } from 'lucide-react'
import React, { useRef, useState } from 'react'
import { useLongPress } from 'use-long-press'
import { getBezierPath } from './utils/bezier-edge'

type Props = {
  id?: string
  sx: number
  sy: number
  tx: number
  ty: number
  onDelete?: () => void
  isSimulation?: boolean
  direction?: EdgeDirection
  viewportPosition: { x: number; y: number }
  virtualBounds: VirtualBounds
  sourceSide: Side
  targetSide: Side
}

export function Edge({
  id,
  sx,
  sy,
  tx,
  ty,
  onDelete,
  viewportPosition,
  virtualBounds,
  isSimulation = false,
  sourceSide,
  targetSide,
  direction,
}: Props) {
  const pathRef = useRef()
  const contextMenuRef = useRef()

  const [path] = getBezierPath({
    sourceX: fromAbsX(sx, virtualBounds),
    sourceY: fromAbsY(sy, virtualBounds),
    targetX: isSimulation ? tx : fromAbsX(tx, virtualBounds),
    targetY: isSimulation ? ty : fromAbsY(ty, virtualBounds),
    sourceSide,
    targetSide,
  })

  const [isButtonVisible, setButtonVisible] = useState(false)

  const { isReadOnly, zoomMultiplier } = useBoardState()

  useClickOutside(contextMenuRef, () => {
    setButtonVisible(false)
  })

  const operations = useBoardOperations()

  const mousePositionRef = useCursorPosition()
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const bind = useLongPress(() => {
    setButtonVisible(true)
    setPosition(mousePositionRef.current)
  })

  const isDirected = direction && direction !== EdgeDirection.None

  return (
    <>
      <SvgPortal>
        <g>
          <path
            ref={pathRef}
            d={path}
            strokeWidth='2'
            fill='none'
            className={cn('stroke-gray-500/50 transition-colors duration-200')}
            markerEnd={
              direction === EdgeDirection.Target ? 'url(#arrow)' : undefined
            }
            markerStart={
              direction === EdgeDirection.Source ? 'url(#arrow)' : undefined
            }
            strokeDasharray={isDirected ? '5,5' : null}
          >
            {isDirected ? (
              <animate
                attributeName='stroke-dashoffset'
                from='0'
                to={direction === EdgeDirection.Target ? '-100' : '100'}
                dur='3s'
                repeatCount='indefinite'
              />
            ) : null}
          </path>
          <path
            d={path}
            stroke='transparent'
            fill='none'
            strokeWidth='32'
            pointerEvents='visibleStroke'
            className='cursor-default'
            onContextMenu={e => {
              e.stopPropagation()
              e.preventDefault()
              setButtonVisible(true)
              setPosition(mousePositionRef.current)
            }}
            {...bind()}
          />
        </g>
      </SvgPortal>

      {isSimulation || isReadOnly ? null : (
        <div
          ref={contextMenuRef}
          className={cn(
            'fixed h-6 -translate-x-1/2 -translate-y-1/2 transform',
            'cursor-pointer rounded bg-gray-500/20 transition-opacity duration-200',
            'z-[10100] items-center justify-center',
          )}
          style={{
            display: isButtonVisible ? 'flex' : 'none',
            left: position.x / zoomMultiplier - viewportPosition.x,
            top: position.y / zoomMultiplier - viewportPosition.y,
          }}
        >
          <span
            className='flex h-6 w-6 items-center justify-center rounded-l hover:bg-slate-500/50'
            onPointerDown={e => {
              e.stopPropagation()
              onDelete?.()
              setButtonVisible(false)
            }}
            onDoubleClick={e => e.stopPropagation()}
          >
            <XIcon className='h-4 w-4 text-red-500 dark:text-red-400' />
          </span>

          <span
            className='flex h-6 w-6 items-center justify-center rounded-r hover:bg-slate-500/50'
            onPointerDown={e => {
              e.stopPropagation()
              operations.updateEdgeDirection(
                id,
                !direction || direction === EdgeDirection.None
                  ? EdgeDirection.Target
                  : direction === EdgeDirection.Target
                  ? EdgeDirection.Source
                  : EdgeDirection.None,
              )
            }}
            onDoubleClick={e => e.stopPropagation()}
          >
            <ChevronsLeftRight className='h-4 w-4 text-gray-500 dark:text-gray-400' />
          </span>
        </div>
      )}
    </>
  )
}
