import React from 'react'
import { FieldMetaProps } from 'formik'
import { cn } from '@/client/utils/cn'

type Props = {
  meta: FieldMetaProps<any>
  children: React.ReactNode
  className?: string
  childrenClassName?: string
  errorClassName?: string
}

export function FieldOutline({
  meta,
  children,
  className,
  childrenClassName,
  errorClassName,
}: Props) {
  return (
    <div className={cn('mb-4', className)}>
      <div className={childrenClassName}>{children}</div>
      {meta.error ? (
        <span
          className={cn(
            'mt-2 inline-flex items-center rounded-lg bg-red-600/10 px-2 py-0.5 text-sm font-medium text-red-500',
            errorClassName,
          )}
        >
          {meta.error}
        </span>
      ) : null}
    </div>
  )
}
