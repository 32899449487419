import { Rect } from '@/client/utils/rect'
import { BoardNode } from '@/common/constants/boards'
import { useMemo } from 'react'

export function useVisibleNodes(
  nodes: BoardNode<string>[],
  visibleRect: Rect | null,
) {
  const nodesWithRect = useMemo(
    () =>
      nodes.map(node => ({
        ...node,
        _rect: new Rect(node.x, node.y, node.xw, node.yh),
      })),
    [nodes],
  )

  return useMemo(
    () => nodesWithRect.filter(node => visibleRect?.intersectsWith(node._rect)),
    [nodesWithRect, visibleRect],
  )
}
