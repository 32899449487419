import React, { useEffect, useState } from 'react'
import { tsParticles } from 'tsparticles-engine'

export const Particles = props => {
  const [init, setInit] = useState(false)
  const [library, setLibrary] = useState(null)

  const initializeParticles = async () => {
    if (props.init) {
      await props.init(tsParticles)
    }

    setInit(true)

    await loadParticles()
  }

  useEffect(() => {
    initializeParticles()

    return () => {
      destroy()
    }
  }, [])

  useEffect(() => {
    if (init) {
      refresh()
    }
  }, [
    props.url,
    props.id,
    props.className,
    props.height,
    props.width,
    props.style,
    props.init,
    props.loaded,
    props.options,
    props.params,
    init,
  ])

  const destroy = () => {
    if (library) {
      library.destroy()
      setLibrary(null)
    }
  }

  const refresh = async () => {
    destroy()
    await loadParticles()
  }

  const loadParticles = async () => {
    if (!init) {
      return
    }

    const id = props.id ?? Particles.defaultProps.id,
      container = await tsParticles.load({
        url: props.url,
        id,
        options: props.options ?? props.params,
      })

    if (props.container) {
      props.container.current = container
    }

    setLibrary(container)

    if (props.loaded) {
      await props.loaded(container)
    }
  }

  const { width, height, className, canvasClassName, id } = props

  return (
    <div className={className} id={id}>
      <canvas
        className={canvasClassName}
        style={{
          ...props.style,
          width,
          height,
        }}
      />
    </div>
  )
}

Particles.defaultProps = {
  id: 'particles',
}
