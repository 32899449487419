import { MetaboardEvent } from '@/common/events'
import { TimedLoader } from '@components/timed-loader'
import { FunkyButton } from '@components/ui/funky-button'
import { useClient, useMethod } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import { Alert, Image, Text } from '@mantine/core'
import { LucideCheck, LucideTimerOff } from 'lucide-react'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

export function ShareInvitation() {
  const { uuid } = useParams<{ uuid: string }>()

  const method = useMethod({
    method: 'boards.getShareLink',
    params: { uuid },
    deps: [uuid],
  })

  const { user } = useMetaboardAuth()

  if (method.loading) {
    return (
      <div className='mx-auto mt-32 flex max-w-[360px] flex-col items-center gap-4 rounded p-4 '>
        <TimedLoader />
      </div>
    )
  }

  if (!method.loading && !method.result) {
    return (
      <div className='mx-auto mt-32 flex max-w-[360px] flex-col items-center gap-4 rounded p-4 '>
        <Alert
          color='orange'
          fz='xs'
          className='text-center font-medium'
          icon={<LucideTimerOff size={16} />}
        >
          <Trans>Sorry, this link has expired</Trans>
        </Alert>
      </div>
    )
  }

  const { shareLink, alreadyShared } = method.result ?? {}

  const { name, googlePicture } = shareLink.author ?? {}

  return (
    <div className='mx-auto mt-32 flex max-w-[360px] flex-col items-center gap-4 rounded p-4 '>
      <Image
        src={
          // Get a high resolution image from Google, perhaps we should make this more generic.
          googlePicture?.replace('s96-c', 's256-c') ?? '/profile-picture.png'
        }
        referrerPolicy='no-referrer'
        className='mb-4 aspect-square w-[96px] max-w-full rounded-lg object-cover'
        alt={name}
      />
      <Text fz='xs' className='text-center font-medium'>
        <Trans>
          {name} has invited you to collaborate on the board "
          {shareLink.board.name}"
        </Trans>
      </Text>

      <Acceptance
        uuid={uuid}
        user={user}
        author={shareLink.author}
        alreadyShared={alreadyShared}
      />
    </div>
  )
}

function Acceptance({ uuid, user, author, alreadyShared }) {
  const history = useHistory()
  const client = useClient()

  const { _id } = author

  const isDisabled = user?._id === _id || alreadyShared

  async function join() {
    const boardId = await client.call('boards.acceptShareLink', { uuid })

    if (!boardId) {
      console.error('Failed to accept share link')
    }

    history.push(`/b/${boardId}`)
  }

  function accept() {
    if (!user) {
      client.once(MetaboardEvent.USER_REFRESHED, async user => {
        if (user) {
          join().catch(console.error)
        }
      })

      client.emit(MetaboardEvent.SIGN_IN)
      return
    }

    join().catch(console.error)
  }

  return (
    <div className='space-x-2'>
      <FunkyButton
        variant='primary'
        disabled={isDisabled}
        onClick={accept}
        icon={alreadyShared ? LucideCheck : null}
      >
        <Trans>Accept</Trans>
      </FunkyButton>
      <FunkyButton
        variant='secondary'
        disabled={isDisabled}
        onClick={() => history.push('/')}
      >
        <Trans>Reject</Trans>
      </FunkyButton>
    </div>
  )
}

ShareInvitation.layoutProps = {
  particles: true,
  noSpacing: true,
}
