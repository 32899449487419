import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

type ModalProps = {
  title?: React.ReactNode
  children?: React.ReactNode
  isOpen: boolean
  onClose: () => void
  className?: string
}

export function Modal({
  title,
  children,
  isOpen = false,
  onClose,
  className,
}: ModalProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-[100000]' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500/75 transition-opacity dark:bg-gray-800/75' />
        </Transition.Child>

        <div className='fixed inset-0 z-[20000] overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative w-full transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all dark:bg-gray-700 sm:my-8 sm:max-w-[720px]'>
                <div>
                  {title ? (
                    <Dialog.Title
                      as='h3'
                      className='mb-4 text-center text-base font-semibold leading-6 text-black/90 dark:text-white/90'
                    >
                      {title}
                    </Dialog.Title>
                  ) : null}
                  <div className={className}>{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
