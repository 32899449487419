import { ucfirst } from '../string-utils'

export const Dunno = '¯\\_(ツ)_/¯'

export const SettingsGroup = {
  ONBOARDING: 'onboarding',
}

export const PathName = {
  LANDING_PAGE: '/get-started',
}

export const DeckType = {
  GENERAL: 'GENERAL',
  LANGUAGE: 'LANGUAGE',
}

export const DeckStatus = {
  DRAFT: 'DRAFT',
  WIP: 'WIP',
  PUBLISHED: 'PUBLISHED',
}

export const DeckAvailability = {
  FREE: 'FREE',
  UNLIMITED: 'UNLIMITED',
}

export const DeckStatusLabel = {
  [DeckStatus.DRAFT]: 'Draft',
  [DeckStatus.WIP]: 'Work In Progress',
  [DeckStatus.PUBLISHED]: 'Published',
}

export const DeckStatuses = [
  {
    value: DeckStatus.DRAFT,
    label: DeckStatusLabel[DeckStatus.DRAFT],
  },
  {
    value: DeckStatus.WIP,
    label: DeckStatusLabel[DeckStatus.WIP],
  },
  {
    value: DeckStatus.PUBLISHED,
    label: DeckStatusLabel[DeckStatus.PUBLISHED],
  },
]

export const ExerciseType = {
  CLOZE: 'CLOZE',
  TRANSLATION: 'TRANSLATION',
  TRUE_OR_FALSE: 'TRUE_OR_FALSE',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  PHRASE_BUILDER: 'PHRASE_BUILDER',
}

export const ExerciseTypes = [
  {
    value: ExerciseType.CLOZE,
    label: 'Cloze',
  },
  {
    value: ExerciseType.MULTIPLE_CHOICE,
    label: 'Multiple Choice',
  },
  {
    value: ExerciseType.PHRASE_BUILDER,
    label: 'Phrase Builder',
  },
  {
    value: ExerciseType.TRANSLATION,
    label: 'Translation',
  },
  {
    value: ExerciseType.TRUE_OR_FALSE,
    label: 'True / False',
  },
]

export const ExerciseStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
}

export const MaxScore = 8

export const ScoreProgression = {
  0: 0,
  1: 6,
  2: 12,
  3: 24,
  4: 6 * 24,
  5: 12 * 24,
  6: 48 * 24,
  7: 96 * 24,
  8: 180 * 24,
}

export enum LanguageCode {
  EN = 'en-US',
  ES = 'es-ES',
  FR = 'fr-FR',
  DE = 'de-DE',
  IT = 'it-IT',
  PT = 'pt-BR',
}

export const LanguageName = {
  EN: 'English',
  ES: 'Spanish',
  FR: 'French',
  DE: 'German',
  IT: 'Italian',
  PT: 'Portuguese',
}

export const LocalLanguageName = {
  EN: 'English',
  ES: 'Español',
  FR: 'Français',
  DE: 'Deutsch',
  IT: 'Italiano',
  PT: 'Português',
}

export const LocalizedLanguageName = {
  [LanguageName.EN]: 'English',
  [LanguageName.ES]: 'Español',
  [LanguageName.FR]: 'Français',
  [LanguageName.DE]: 'Deutsch',
  [LanguageName.IT]: 'Italiano',
  [LanguageName.PT]: 'Português',
}

export const LanguageNameByCode = {
  [LanguageCode.EN]: LanguageName.EN,
  [LanguageCode.ES]: LanguageName.ES,
  [LanguageCode.FR]: LanguageName.FR,
  [LanguageCode.DE]: LanguageName.DE,
  [LanguageCode.IT]: LanguageName.IT,
  [LanguageCode.PT]: LanguageName.PT,
}

export const Languages = [
  { value: LanguageCode.EN, label: LocalLanguageName.EN },
  { value: LanguageCode.DE, label: LocalLanguageName.DE },
  { value: LanguageCode.FR, label: LocalLanguageName.FR },
  { value: LanguageCode.ES, label: LocalLanguageName.ES },
  { value: LanguageCode.IT, label: LocalLanguageName.IT },
  { value: LanguageCode.PT, label: LocalLanguageName.PT },
]

export const UserSupportedLanguages = [
  {
    value: LanguageCode.EN,
    label: LocalLanguageName.EN,
  },
  {
    value: LanguageCode.PT,
    label: LocalLanguageName.PT,
  },
]

export const VoiceType = {
  MALE: 'MALE',
  FAMALE: 'FEMALE',
}

export const VoiceIdsByLC = {
  [LanguageCode.EN]: [
    {
      name: 'Salli',
      type: VoiceType.FAMALE,
    },
    {
      name: 'Kimberly',
      type: VoiceType.FAMALE,
    },
    {
      name: 'Kendra',
      type: VoiceType.FAMALE,
    },
    {
      name: 'Joanna',
      type: VoiceType.FAMALE,
    },
    {
      name: 'Ivy',
      type: VoiceType.FAMALE,
    },
    {
      name: 'Kevin',
      type: VoiceType.MALE,
    },
    {
      name: 'Matthew',
      type: VoiceType.MALE,
    },
    {
      name: 'Justin',
      type: VoiceType.MALE,
    },
    {
      name: 'Joey',
      type: VoiceType.MALE,
    },
  ],
  [LanguageCode.IT]: [
    {
      name: 'Bianca',
      type: VoiceType.FAMALE,
    },
  ],
  [LanguageCode.ES]: [
    {
      name: 'Lucia',
      type: VoiceType.FAMALE,
    },
  ],
  [LanguageCode.PT]: [
    {
      name: 'Vitoria',
      type: VoiceType.FAMALE,
    },
    {
      name: 'Camila',
      type: VoiceType.FAMALE,
    },
  ],
  [LanguageCode.FR]: [
    {
      name: 'Lea',
      type: VoiceType.FAMALE,
    },
  ],
  [LanguageCode.DE]: [
    {
      name: 'Vicki',
      type: VoiceType.FAMALE,
    },
    {
      name: 'Daniel',
      type: VoiceType.MALE,
    },
  ],
}

export const DefaultVoiceIdByLC = {
  [LanguageCode.IT]: 'Bianca',
  [LanguageCode.EN]: 'Joanna',
  [LanguageCode.ES]: 'Lucia',
  [LanguageCode.PT]: 'Vitoria',
  [LanguageCode.FR]: 'Lea',
  [LanguageCode.DE]: 'Vicki',
}

export const GameMode = {
  SESSION: 'SESSION',
  UNLIMITED: 'UNLIMITED',
}

export const ExerciseOrder = {
  RANDOM: 'RANDOM',
  SEQUENTIAL: 'SEQUENTIAL',
  COMPLEXITY: 'COMPLEXITY',
}

export const NewCardBehavior = {
  ANSWER_FIRST: 'ANSWER_FIRST',
  QUESTION_FIRST: 'QUESTION_FIRST',
}

export const FontStyle = {
  SANS_SERIF: 'SANS_SERIF',
  SERIF: 'SERIF',
  MONOSPACE: 'MONOSPACE',
}

export const SortByExerciseOrder = {
  [ExerciseOrder.RANDOM]: { questionComplexity: 1 }, // Randomisation is actually handled by using `$sample` in the aggregation pipeline
  [ExerciseOrder.SEQUENTIAL]: { sectionOrder: 1, order: 1 },
  [ExerciseOrder.COMPLEXITY]: { questionComplexity: 1 },
}

export const DefaultGameSettings = {
  gameMode: GameMode.SESSION,
  sessionLength: 5,
  exerciseOrder: ExerciseOrder.SEQUENTIAL,
  newCardBehavior: NewCardBehavior.ANSWER_FIRST,
  fontStyle: FontStyle.SANS_SERIF,
}

export const ArticleStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
}

export const ArticleStatuses = [
  {
    value: ArticleStatus.DRAFT,
    label: ucfirst(ArticleStatus.DRAFT),
  },
  {
    value: ArticleStatus.PUBLISHED,
    label: ucfirst(ArticleStatus.PUBLISHED),
  },
]

export const ExpiredExerciseQuery = () => ({
  status: ExerciseStatus.PUBLISHED,
  isIgnored: { $ne: true },
  $or: [{ expiredAt: { $lt: new Date() } }, { isWrong: true }],
})

export const VideoStatus = {
  PROCESSING: 'PROCESSING',
  UPLOADING: 'UPLOADING',
  READY: 'READY',
}
