import { BoardNode } from '@/common/constants/boards'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { ContactFields } from '@components/boards/nodes/view/node-contact/contact-fields'
import { ContactReadOnly } from '@components/boards/nodes/view/node-contact/contact-read-only'
import { InputField } from '@components/fields/input-field'
import { RichTextField } from '@components/fields/rich-text-field'
import { StandardForm } from '@components/forms/standard-form'
import { FormGroup } from '@components/ui/forms/form-group'
import { t } from '@lingui/macro'
import useDebounceFn from 'ahooks/lib/useDebounceFn'
import React, { useMemo } from 'react'
import { array, object, string } from 'yup'

type NodeContactProps = {
  node: BoardNode<string>
}

export function NodeContact({ node }: NodeContactProps) {
  const { isWritable } = useBoardState()

  const initialValues = useMemo(() => node, [node?._id])

  const operations = useBoardOperations()

  const onSave = useDebounceFn(
    async values => {
      operations.updateNode(node._id, values).catch(console.error)
    },
    {
      wait: 100,
    },
  )

  return isWritable ? (
    <StandardForm
      initialValues={initialValues}
      onSave={onSave.run}
      schema={object({
        name: string(),
        contactInfo: array().of(object({ field: string(), value: string() })),
        content: string(),
      })}
      autoSave
    >
      <div className='p-4'>
        <FormGroup label={t`Name`}>
          <InputField name='name' placeholder={t`Write something...`} />
        </FormGroup>

        <ContactFields />
      </div>

      <div className='h-[1px] w-full bg-slate-500/20' />

      <div className='p-4'>
        <RichTextField name='content' placeholder={t`Write something...`} raw />
      </div>
    </StandardForm>
  ) : (
    <ContactReadOnly node={node} />
  )
}
