import { cn } from '@/client/utils/cn'
import { Menu } from '@mantine/core'
import { IconChevronDown, IconLoader } from '@tabler/icons-react'
import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom'

type ItemMenu = {
  label: string
  onClick: () => void
  leftSection: React.ReactNode
}

type NavButtonProps = React.HTMLAttributes<HTMLElement> & {
  icon?: React.ElementType
  collapsible?: boolean
  iconRight?: boolean
  to?: string
  loading?: boolean
  menu?: ItemMenu[]
}

const DEFAULT_CLASSES = [
  'flex items-center gap-1.5 rounded-md px-3 py-2 hover:bg-gray-300/50 dark:hover:bg-slate-500/50',
  'text-xs font-medium text-gray-700 dark:text-slate-300 hover:text-gray-900 dark:hover:text-slate-100',
  'active:bg-gray-400/50 dark:active:bg-slate-400/50 transition-colors duration-200 ease-in-out',
]

export const NavButton = forwardRef<any, NavButtonProps>(
  (
    {
      children,
      className,
      icon: Icon,
      collapsible = false,
      iconRight = false,
      to,
      loading,
      menu,
      ...props
    },
    ref,
  ) => {
    const genericProps = {
      className: cn(
        ...DEFAULT_CLASSES,
        {
          'rounded-tr-none rounded-br-none': menu,
        },
        className,
      ),
      ...props,
    }

    const icon =
      Icon && !iconRight ? (
        <Icon className='h-4 w-4' aria-hidden='true' />
      ) : null

    const contents = (
      <>
        {loading ? (
          <IconLoader className='h-4 w-4 animate-spin' aria-hidden='true' />
        ) : (
          icon
        )}

        {collapsible ? (
          <span className='hidden md:inline'>{children}</span>
        ) : (
          children
        )}

        {Icon && iconRight ? (
          <Icon className='h-4 w-4' aria-hidden='true' />
        ) : null}
      </>
    )

    if (to) {
      return (
        <Link to={to} ref={ref} {...genericProps}>
          {contents}
        </Link>
      )
    }

    if (menu) {
      return (
        <span className='inline-flex items-center'>
          <button type='button' ref={ref} {...genericProps}>
            {contents}
          </button>

          <span className='h-8 w-[1px] bg-slate-500/20' />
          <Menu shadow='md' width={200} position='bottom-end'>
            <Menu.Target>
              <button
                className={cn(...DEFAULT_CLASSES, 'px-1', {
                  'rounded-bl-none rounded-tl-none': menu,
                })}
                onClick={e => e.stopPropagation()}
              >
                <IconChevronDown size={16} />
              </button>
            </Menu.Target>

            <Menu.Dropdown>
              {menu.map(({ label, onClick, ...rest }, index) => (
                <Menu.Item
                  key={index}
                  onClick={e => {
                    e.stopPropagation()
                    onClick()
                  }}
                  fz='xs'
                  {...rest}
                >
                  {label}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </span>
      )
    }

    return (
      <button type='button' ref={ref} {...genericProps}>
        {contents}
      </button>
    )
  },
)

NavButton.displayName = 'NavButton'
