import { cn } from '@/client/utils/cn'
import DeleteConfirmation from '@components/delete-confirmation'
import { InputField } from '@components/fields/input-field'
import { StandardForm } from '@components/forms/standard-form'
import { UserMultiSelectField } from '@components/org/user-multi-select'
import { FormGroup } from '@components/ui/forms/form-group'
import { FunkyButton } from '@components/ui/funky-button'
import { useClient, useMethod } from '@helenejs/react'
import { useHashState } from '@hooks/use-hash-state'
import { t, Trans } from '@lingui/macro'
import { Badge, Button, ButtonGroup, Modal } from '@mantine/core'
import { IconBuilding, IconUsers } from '@tabler/icons-react'
import { LucideCornerDownRight } from 'lucide-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export function OrgList() {
  const client = useClient()

  const method = useMethod({
    method: 'org.list',
    defaultValue: [],
  })

  const [deletingTeam, setDeletingTeam] = useState(null)
  const [isDeletingTeam, setIsDeletingTeam] = useState(false)

  return (
    <div>
      <OrgCreationForm orgs={method} />

      <div className='flex flex-col rounded border border-slate-500 text-sm'>
        {method.result.map((org, index) => (
          <div
            key={org._id}
            className={cn('border-slate-500 p-4 hover:bg-slate-500/20', {
              'border-t': index > 0,
            })}
          >
            <div className='flex items-center gap-4' data-header>
              <Link
                to={`/org/${org._id}`}
                className='flex items-center gap-1.5 font-medium'
              >
                <IconBuilding size={16} /> {org.name} ({org.members})
              </Link>

              <TeamCreationButton orgId={org._id} orgs={method} />
            </div>
            <div className='mt-4 flex flex-col gap-4' data-teams>
              {org.teams.map(team => (
                <div key={team._id}>
                  <div className='mb-4 flex items-center gap-3'>
                    <LucideCornerDownRight
                      size={16}
                      className='text-slate-500'
                    />

                    <span className='flex items-center gap-1.5 font-medium'>
                      <IconUsers size={16} /> {team.name}{' '}
                      {team.systemType && (
                        <Badge size='xs' color='gray' variant='outline'>
                          <Trans>System</Trans>
                        </Badge>
                      )}
                    </span>
                    {!team.systemType ? (
                      <>
                        <Button
                          onClick={() => {
                            setDeletingTeam(team)
                            setIsDeletingTeam(true)
                          }}
                          variant='subtle'
                          size='xs'
                        >
                          <Trans>Delete Team</Trans>
                        </Button>
                      </>
                    ) : null}
                  </div>
                  <StandardForm
                    initialValues={{
                      members: team.members,
                    }}
                    onSave={async values => {
                      console.log(values.members)
                      await client
                        .call('team.update', {
                          teamId: team._id,
                          orgId: org._id,
                          data: values,
                        })
                        .catch(console.error)
                      method.refresh()
                    }}
                    className='pl-7'
                    autoSave
                  >
                    <FormGroup label={t`Members`}>
                      <UserMultiSelectField
                        name='members'
                        orgId={org._id}
                        disabled={team.systemType}
                      />
                    </FormGroup>
                  </StandardForm>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <DeleteConfirmation
        title={t`Delete Team`}
        message={t`Are you sure you want to delete the team "${deletingTeam?.name}"?`}
        isOpen={isDeletingTeam}
        onConfirm={async () => {
          await client.call('team.delete', {
            teamId: deletingTeam?._id,
            orgId: deletingTeam?.org,
          })
          setIsDeletingTeam(false)
          method.refresh()
        }}
        onCancel={() => setIsDeletingTeam(false)}
        onClose={() => setIsDeletingTeam(false)}
      />
    </div>
  )
}

function OrgCreationForm({ orgs }) {
  const [isCreatingOrg, setCreatingOrg] = useHashState(
    'new-org',
    false,
    '',
    true,
    JSON.parse,
  )
  const client = useClient()

  if (!isCreatingOrg) {
    return (
      <ButtonGroup className='mb-4'>
        <Button variant='subtle' onClick={() => setCreatingOrg(true)}>
          <Trans>Create Organization</Trans>
        </Button>
      </ButtonGroup>
    )
  }

  return (
    <StandardForm
      className='mb-4'
      onSave={async values => {
        await client.call('org.create', values)
        orgs.refresh()
      }}
      footer={
        <FunkyButton
          onClick={e => {
            e.preventDefault()
            setCreatingOrg(false)
          }}
        >
          <Trans>Cancel</Trans>
        </FunkyButton>
      }
    >
      <FormGroup label={t`Organization Name`}>
        <InputField name='name' placeholder={t`Organization Name`} />
      </FormGroup>
    </StandardForm>
  )
}

function TeamCreationButton({ orgId, orgs }) {
  const [isCreatingTeam, setCreatingTeam] = useState(false)
  const client = useClient()

  return (
    <>
      <Button
        variant='subtle'
        onClick={() => setCreatingTeam(true)}
        size='xs'
        color='blue'
      >
        <Trans>Create Team</Trans>
      </Button>
      <Modal
        opened={isCreatingTeam}
        onClose={() => setCreatingTeam(false)}
        title={t`Create Team`}
        zIndex={30000}
      >
        <StandardForm
          className='mb-4'
          onSave={async values => {
            await client.call('team.create', { ...values, orgId })
            orgs.refresh()
            setCreatingTeam(false)
          }}
          footer={
            <FunkyButton
              onClick={e => {
                e.preventDefault()
                setCreatingTeam(false)
              }}
            >
              <Trans>Cancel</Trans>
            </FunkyButton>
          }
        >
          <FormGroup label={t`Team Name`}>
            <InputField name='name' placeholder={t`Team Name`} />
          </FormGroup>
        </StandardForm>
      </Modal>
    </>
  )
}
