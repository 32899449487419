import { AnyFunction } from '@helenejs/utils'
import defer from 'lodash/defer'
import isNil from 'lodash/isNil'

import qs from 'qs'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * Query names must be unique across the application
 *
 * @param query
 * @param defaultValue
 * @param resetOnUnmount
 * @param namespace
 * @param parser
 */
export function useHashState(
  query: string,
  defaultValue: any,
  namespace = '',
  resetOnUnmount = true,
  parser: AnyFunction = String,
) {
  const key = namespace ? `${namespace}_${query}` : query

  const history = useHistory()

  const currentValue = useMemo(() => {
    const value = qs.parse(location.hash.slice(1), { ignoreQueryPrefix: true })[
      key
    ]

    if (isNil(value)) {
      return defaultValue
    }

    return parser(value)
  }, [location.hash])

  const setQuery = useCallback(
    (value: any) => {
      defer(() => {
        const existingQueries = qs.parse(location.hash.slice(1), {
          ignoreQueryPrefix: true,
        })

        const queryString = qs.stringify(
          { ...existingQueries, [key]: value },
          { skipNulls: true },
        )

        history.push(`${location.pathname}#?${queryString}`)
      })
    },
    [history, location.search, query],
  )

  useEffect(() => {
    if (isNil(currentValue)) {
      setQuery(defaultValue)
    }

    return () => {
      defer(() => {
        if (resetOnUnmount) {
          const existingQueries = qs.parse(location.hash.slice(1), {
            ignoreQueryPrefix: true,
          })

          delete existingQueries[key]

          const queryString = qs.stringify(existingQueries, { skipNulls: true })

          if (queryString) {
            history.replace(
              location.pathname + location.search + '#?' + queryString,
            )
          } else {
            history.replace(location.pathname + location.search)
          }
        }
      })
    }
  }, [])

  return [currentValue, setQuery]
}
