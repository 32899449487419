import { AudioUtils } from '@/client/utils/audio-utils'
import { cn } from '@/client/utils/cn'
import { BoardNavbar } from '@components/boards/board-navbar'
import { useGlobalChangeEvents } from '@components/boards/hooks/use-global-change-events'
import { Footer } from '@components/footer'
import { GlobalAlert } from '@components/global-alert'
import { LiveNotifications } from '@components/live-notifications'
import { OnboardingSlides } from '@components/navigation/onboarding-slides'
import { ParticleBackground } from '@components/particles/particle-background'
import { SignInDialog } from '@components/sign-in-dialog'
import { useClient } from '@helenejs/react'
import { useInteractionTime } from '@hooks/use-interaction-time'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useTheme } from '@hooks/use-theme'
import { createTheme, MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

type LayoutProps = {
  children: React.ReactNode
  particles?: boolean
  noSpacing?: boolean
}

const theme = createTheme({
  components: {
    Modal: {
      defaultProps: {
        zIndex: 999999,
      },
    },
    Popover: {
      defaultProps: {
        zIndex: 999999,
      },
    },
  },
})

export function Layout({ children, particles, noSpacing }: LayoutProps) {
  const { isDark } = useTheme()

  useEffect(() => {
    AudioUtils.unlockAudioContext()
  }, [])

  const { user } = useMetaboardAuth()

  return (
    <MantineProvider theme={theme} forceColorScheme={isDark ? 'dark' : 'light'}>
      <Notifications style={{ zIndex: 999999 }} />
      <div
        className={cn(
          'grid-background flex min-h-screen flex-col overflow-hidden lg:overflow-visible',
          {
            'px-4': !noSpacing,
          },
        )}
      >
        {particles
          ? createPortal(
              <ParticleBackground />,
              document.getElementById('portal'),
              'particles',
            )
          : null}

        <BoardNavbar />

        <section
          className={cn({
            'mx-auto mb-12 mt-20 w-full max-w-7xl rounded-lg bg-white/75 p-4 dark:bg-slate-600/75':
              !noSpacing,
          })}
        >
          {children}
        </section>

        {!noSpacing ? <Footer /> : null}

        <SignInDialog />

        <GlobalAlert />

        <LiveNotifications />

        <div className='fixed bottom-4 left-4 flex items-center gap-2'>
          <OnboardingSlides />
        </div>
      </div>
    </MantineProvider>
  )
}

export function MainLayout({ children, noSpacing, particles = false }) {
  const client = useClient()

  useInteractionTime(time => {
    client.void('analytics.interaction', { time })
  })

  useGlobalChangeEvents()

  return (
    <Layout particles={particles} noSpacing={noSpacing}>
      {children}
    </Layout>
  )
}
