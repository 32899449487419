import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'

type SpinnerProps = {
  className?: string
}

export function Spinner({ className }: SpinnerProps) {
  return (
    <div
      className={classnames(
        'flex h-[24px] items-center justify-center',
        className,
      )}
    >
      <FontAwesomeIcon icon={faCircleNotch} className='animate-spin' />
    </div>
  )
}
