import {
  limit2grid,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
} from '@/common/boards/constraints'
import { BoardEvent, NodeType } from '@/common/constants/boards'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useClient } from '@helenejs/react'
import { useEventListener } from 'ahooks'
import defer from 'lodash/defer'
import { useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'

export function useNodeResize({
  id,
  node,
  position,
  size,
  setSize,
  zoomMultiplier,
  setDragging,
  setResizing,
  initialSize,
  startMousePositionRef,
  dragging,
  resizing,
  startPos,
}) {
  const client = useClient()
  const operations = useBoardOperations()
  const history = useHistory()

  const timeoutRef = useRef(null)

  const onMouseMove = useCallback(
    e => {
      const x = e.touches ? e.touches[0].clientX : e.clientX
      const y = e.touches ? e.touches[0].clientY : e.clientY

      if (resizing) {
        const newWidth =
          (x - startMousePositionRef.current.x) / zoomMultiplier + node.width
        const newHeight =
          (y - startMousePositionRef.current.y) / zoomMultiplier + node.height

        const newSize = {
          width: limit2grid(newWidth, minWidth, maxWidth),
          height: limit2grid(newHeight, minHeight, maxHeight),
        }

        setSize(newSize)

        client.emit(BoardEvent.NodeResize, { id, ...newSize })
      }
    },
    [position, size, dragging, resizing],
  )

  const onMouseUp = () => {
    // If we have a timeout, we are not dragging but clicking
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null

      if (node.type === NodeType.URL && node.url) {
        window.open(node.url as string, '_blank')
        return
      }

      if (node.type === NodeType.Flashcards && node.deck) {
        history.push(`/practice/${node.deck}`)
        return
      }

      client.emit(BoardEvent.OpenNode, id)
      return
    }

    if (!dragging && !resizing) return

    // Only update if the position has changed
    if (
      position.x !== startPos.current.x ||
      position.y !== startPos.current.y ||
      size.width !== initialSize.width ||
      size.height !== initialSize.height
    ) {
      operations.updateNode(id, {
        x: position.x,
        y: position.y,
        width: size.width,
        height: size.height,
      })
    }

    defer(() => {
      setDragging(false)
      setResizing(false)
    })
  }

  function onResizeStart(e) {
    e.stopPropagation()

    const x = e.touches ? e.touches[0].clientX : e.clientX
    const y = e.touches ? e.touches[0].clientY : e.clientY

    setResizing(true)
    startPos.current = {
      x: position.x,
      y: position.y,
    }

    startMousePositionRef.current = {
      x,
      y,
    }
  }

  useEventListener('mousemove', onMouseMove, {
    target: window,
  })

  useEventListener('mouseup', onMouseUp, {
    target: window,
  })

  useEventListener('touchmove', onMouseMove, {
    target: window,
  })

  useEventListener('touchend', onMouseUp, {
    target: window,
  })

  return onResizeStart
}
