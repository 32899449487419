import React from 'react'
import tada from '../../assets/tada.png'
import { Trans } from '@lingui/macro'

export function DeckComplete() {
  return (
    <div className='text-center dark:text-slate-300'>
      <img
        src={tada}
        alt='Course Completed'
        width={120}
        height={120}
        className='mx-auto mb-4'
      />
      <div>
        <Trans>
          You have completed all the exercises for this deck. Well done!
        </Trans>
      </div>
      <div>
        <Trans>More exercises will be available for review soon.</Trans>
      </div>
    </div>
  )
}
