import { NavButton } from '@components/nav-button'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import { Carousel } from '@mantine/carousel'
import { Image, Modal } from '@mantine/core'
import { useLocalStorageState } from 'ahooks'
import { LucideLeaf } from 'lucide-react'
import React, { useEffect } from 'react'

export function OnboardingSlides() {
  const { authenticated } = useMetaboardAuth()
  const [isOnboardingVisible, setOnboardingVisible] = useLocalStorageState(
    'onboarding:visible',
    {
      defaultValue: true,
    },
  )

  useEffect(() => {
    if (!authenticated) {
      setOnboardingVisible(true)
    }
  }, [authenticated])

  /**
   * @todo Blink the button for 10s when the user has first closed the onboarding
   * @todo Add translations
   */

  if (!authenticated) return null

  const IMAGE_CLS = 'mx-auto mb-2 aspect-auto max-w-[20rem] md:max-w-[30rem]'

  return (
    <>
      <NavButton icon={LucideLeaf} onClick={() => setOnboardingVisible(true)}>
        Tutorial
      </NavButton>
      <Modal
        opened={isOnboardingVisible}
        onClose={() => setOnboardingVisible(false)}
        closeOnClickOutside={false}
        size='xl'
      >
        <Carousel
          withIndicators
          height={400}
          nextControlProps={{ style: { top: -116, position: 'relative' } }}
          previousControlProps={{ style: { top: -116, position: 'relative' } }}
        >
          <Carousel.Slide>
            <Image
              src='/tutorial/slide1.jpeg'
              alt='Metaboard'
              className={IMAGE_CLS}
            />

            <Trans>
              Metaboard is a powerful visual knowledge management system that
              allows you to store and easily retrieve large volumes of data. You
              can easily access information through visual/spatial awareness or
              through our powerful search engine.
            </Trans>
          </Carousel.Slide>
          <Carousel.Slide>
            <Image
              src='/tutorial/slide2.jpeg'
              alt='Metaboard'
              className={IMAGE_CLS}
            />
            <Trans>
              You can easily create a new board by clicking the "+ Create Board"
              button near the top right corner of the navigation bar. You can
              also open an existing board by clicking on its name in the grid
              view, or filter for the board name by typing in the search bar.
            </Trans>
          </Carousel.Slide>
          <Carousel.Slide>
            <Image
              src='/tutorial/slide3.jpeg'
              alt='Metaboard'
              className={IMAGE_CLS}
            />
            <Trans>
              Once you created a board, you can interact with it by dragging
              with your finger or mouse. You can add, edit or delete elements by
              holding your finger or right clicking at a point in the screen.
            </Trans>
          </Carousel.Slide>
          <Carousel.Slide>
            <Image
              src='/tutorial/slide4.jpeg'
              alt='Metaboard'
              className={IMAGE_CLS}
            />
            <Trans>
              You can add images and links by pasting with "Ctrl+V/Cmd+V" or
              through the context menu by right clicking. We index the text
              inside your images with Artificial Intelligence, providing a
              powerful search experience.
            </Trans>
          </Carousel.Slide>
          <Carousel.Slide>
            <Image
              src='/tutorial/slide5.jpeg'
              alt='Metaboard'
              className={IMAGE_CLS}
            />
            <Trans>
              The sky is the limit! You can create boards for anything you can
              imagine. We have seen boards for studying, project management,
              software development, personal knowledge management, and even for
              playing games.
            </Trans>
          </Carousel.Slide>
          <Carousel.Slide>
            <Image
              src='/tutorial/slide6.jpeg'
              alt='Metaboard'
              className={IMAGE_CLS}
            />
            <Trans>
              You can see Metaboard as a Second Brain, a place where you can
              store all your knowledge and ideas. You will experience a new way
              of thinking and organizing information, with reduced cognitive
              load and lower stress levels. Metaboard remembers so you don't
              have to. Work hard, play hard!
            </Trans>
          </Carousel.Slide>
          <Carousel.Slide>
            <Image
              src='/tutorial/slide7.jpeg'
              alt='Metaboard'
              className={IMAGE_CLS}
            />
            <Trans>
              You will quickly notice that the more you use Metaboard, the more
              powerful it becomes as it accumulates knowledge and context. You
              will be able to see the big picture and make better decisions.
            </Trans>
          </Carousel.Slide>
          <Carousel.Slide>
            <Image
              src='/tutorial/slide8.jpeg'
              alt='Metaboard'
              className={IMAGE_CLS}
            />
            <Trans>
              Your data is safe with us. We use the latest security standards
              and technologies to keep your data secure. We are constantly
              monitoring and improving our systems to ensure your data is safe.
              If you have any feedback or suggestions, please let us know. We
              are here to help you succeed.
            </Trans>
          </Carousel.Slide>
        </Carousel>
      </Modal>
    </>
  )
}
