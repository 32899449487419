import { useMethod } from '@helenejs/react'
import { Button } from '@components/ui/button'
import { Eye } from 'lucide-react'
import { Spinner } from '@components/spinner'

export function IgnoredCards({ deckId }) {
  const { result, loading, refresh, client } = useMethod({
    method: 'exercise.list_ignored',
    params: { deckId },
    required: ['deckId'],
    defaultValue: [],
    authenticated: true,
  })

  if (loading) return <Spinner />

  return (
    <div className='flex flex-wrap gap-2'>
      {result.map(({ _id, question }) => (
        <Button
          key={_id}
          variant='outline'
          onClick={async () => {
            await client.call('exercise.restore_ignored', { exerciseId: _id })
            refresh()
          }}
        >
          <Eye className='h-4 w-4' /> {question}
        </Button>
      ))}
    </div>
  )
}
