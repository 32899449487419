import { ClientBoardNodeCollection } from '@/client/data'
import { AudioUtils, getAudioPath } from '@/client/utils/audio-utils'
import { cn } from '@/client/utils/cn'
import { fetchAudioBufferWithAuth } from '@/client/utils/fetch-audio-buffer-with-auth'
import { BoardNode } from '@/common/constants/boards'
import { useAudioRecorder } from '@components/boards/hooks/use-audio-recorder'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useClient } from '@helenejs/react'
import { useTheme } from '@hooks/use-theme'
import { useAsyncEffect } from 'ahooks'
import axios from 'axios'
import { LucideMic, LucidePlayCircle, LucideStopCircle } from 'lucide-react'
import React, { useEffect, useMemo, useState } from 'react'

type AudioPlayerProps = {
  node: BoardNode<string>
  player?: boolean
}

async function uploadAudio(
  client: ReturnType<typeof useClient>,
  boardId: string,
  blob: Blob,
) {
  const formData = new FormData()
  formData.append('file', blob, 'audio.wav')

  const {
    data: { success, error, _id },
  } = await axios.post(client.href(`board/${boardId}/file`), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

  if (error) {
    console.error(error)
  }

  if (success) {
    return _id
  }
}

export function NodeAudioPlayer({ node, player = true }: AudioPlayerProps) {
  const [localUrl, setLocalUrl] = useState('')
  const [isPlaying, setIsPlaying] = useState(false)
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)
  const client = useClient()

  const src = useMemo(
    () => (node.audio ? `board/${node.board}/audio/${node.audio}` : null),
    [node.audio],
  )

  // If playing audio then prevent loading audio from the server so that the audio doesn't stop
  useEffect(() => {
    if (!src) return
    if (isPlaying) return
    fetchAudioBufferWithAuth(client, src)
      .then((audioBuffer: ArrayBuffer) => {
        const localUrl = URL.createObjectURL(new Blob([audioBuffer]))
        setLocalUrl(localUrl)
        setAudio(new Audio(localUrl))
      })
      .catch(console.error)
  }, [src, isPlaying])

  const audioRecorder = useAudioRecorder({
    onUrlReady: async url => {
      setLocalUrl(url)
      setAudio(new Audio(url))
      const d = await getAudioPath(url)
      setPath(d)
    },
  })

  const operations = useBoardOperations()

  useAsyncEffect(async () => {
    if (!audioRecorder.audioBlob) return

    const audioId = await uploadAudio(
      client,
      node.board as string,
      audioRecorder.audioBlob,
    )

    operations.updateNode(node._id, {
      audio: audioId,
    })
  }, [audioRecorder.audioBlob, node.board, node._id])

  const togglePlayStop = async e => {
    e.stopPropagation()

    AudioUtils.unlock()

    if (!audio) {
      if (audioRecorder.isRecording) {
        audioRecorder.stopRecording()

        await ClientBoardNodeCollection.update(
          { _id: node._id },
          {
            $unset: {
              _recording: '',
            },
          },
        )

        return
      }

      audioRecorder.startRecording()

      await ClientBoardNodeCollection.update(
        { _id: node._id },
        {
          $set: {
            _recording: true,
          },
        },
      )
      return
    }

    if (isPlaying) {
      audio?.pause()
    } else {
      audio?.play()
    }
    setIsPlaying(!isPlaying)
  }
  useEffect(() => {
    if (!audio) return

    audio.onended = () => {
      setIsPlaying(false)
    }

    return () => {
      audio.pause()
      audio.removeAttribute('src')
      audio.load()
    }
  }, [audio])

  const theme = useTheme()

  const [path, setPath] = useState('')

  useAsyncEffect(async () => {
    if (!localUrl) return
    setPath(await getAudioPath(localUrl))
  }, [localUrl])

  let button = isPlaying ? (
    <LucideStopCircle className='h-4 w-4' />
  ) : (
    <LucidePlayCircle className='h-4 w-4' />
  )

  if (!audio) {
    button = <LucideMic className='h-4 w-4' />
  }

  return (
    <div className='relative z-[10000] flex h-full w-full items-center justify-center'>
      {path ? (
        <svg height='44' width='156' className='z-[998] opacity-30'>
          <path
            style={{
              fill: 'none',
              strokeWidth: '4px',
              strokeLinecap: 'round',
              stroke: theme.isLight ? 'black' : 'white',
            }}
            d={path}
          />
        </svg>
      ) : null}
      {player ? (
        <button
          onClick={togglePlayStop}
          onMouseDown={e => e.stopPropagation()}
          onTouchStart={e => e.stopPropagation()}
          className={cn(
            'absolute left-[50%] top-[50%] z-[10010] inline-flex h-8 w-8',
            'translate-x-[-50%] translate-y-[-50%] transform items-center',
            'justify-center rounded-full bg-gray-500/80 hover:bg-gray-600',
            'text-white',
            {
              'bg-red-500 hover:bg-red-600': audioRecorder.isRecording,
            },
          )}
        >
          {button}
        </button>
      ) : null}
    </div>
  )
}
