import { BaseButton } from '@/client/components/practice/buttons/base-button'
import {
  DefaultGameSettings,
  ExerciseOrder,
  GameMode,
} from '@/common/constants'
import { MetaboardEvent } from '@/common/events'
import { gameSettingsSchema } from '@/common/schema'
import { GameSettingsForm } from '@components/forms/game-settings-form'
import { StandardForm } from '@components/forms/standard-form'
import { IgnoredCards } from '@components/practice/ignored-cards'
import { FunkyButton } from '@components/ui/funky-button'
import { Modal } from '@components/ui/modal'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@components/ui/tabs'
import { useClient } from '@helenejs/react'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import merge from 'lodash/merge'
import React, { useState } from 'react'

export function SettingsButton({ deckId }) {
  const client = useClient()
  const { user, isPremiumActive } = useMetaboardAuth()
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <Modal
        onClose={() => {
          setOpen(false)
        }}
        isOpen={isOpen}
        title={<Trans>Settings</Trans>}
      >
        <Tabs defaultValue='learning-settings'>
          <TabsList>
            <TabsTrigger value='learning-settings'>
              <Trans>Learning Settings</Trans>
            </TabsTrigger>
            <TabsTrigger value='ignored-cards'>
              <Trans>Ignored Cards</Trans>
            </TabsTrigger>
          </TabsList>
          <TabsContent value='learning-settings'>
            <StandardForm
              initialValues={
                isPremiumActive
                  ? merge(
                      {
                        exerciseOrder: ExerciseOrder.COMPLEXITY,
                        gameMode: GameMode.SESSION,
                        sessionLength: 5,
                      },
                      user.gameSettings,
                    )
                  : DefaultGameSettings
              }
              schema={gameSettingsSchema}
              onSave={async values => {
                await client.call('user.updateGameSettings', values)

                client.emit(MetaboardEvent.USER_REFRESH)

                setOpen(false)
              }}
              footer={
                <FunkyButton
                  onClick={e => {
                    e.preventDefault()
                    setOpen(false)
                  }}
                >
                  <Trans>Close</Trans>
                </FunkyButton>
              }
            >
              <GameSettingsForm />
            </StandardForm>
          </TabsContent>
          <TabsContent value='ignored-cards'>
            <IgnoredCards deckId={deckId} />
          </TabsContent>
        </Tabs>
      </Modal>
      <BaseButton
        onClick={() => setOpen(true)}
        rightIcon={Cog6ToothIcon}
        text={t`Settings`}
      />
    </>
  )
}
