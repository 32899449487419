import { DeckDuration } from '@/client/components/decks/deck-duration'
import { Dropdown } from '@/client/components/ui/dropdown'
import { Tag } from '@/client/components/ui/tag'
import { UrlHelper } from '@/common/url-helper'
import { MasonryGrid } from '@components/masonry/masonry-grid'
import { EmptyState } from '@components/ui/empty-state'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UsersIcon } from '@heroicons/react/24/solid'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import { CardStackPlusIcon } from '@radix-ui/react-icons'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

export function DeckMenu({ deck }) {
  const { user } = useMetaboardAuth()
  const history = useHistory()

  if (!user) return null

  return (
    <Dropdown
      items={[
        {
          key: 'edit',
          onClick() {
            history.push(`/decks/editor/${deck._id}`)
          },
          children: <Trans>Edit</Trans>,
          visible: Boolean(deck.isAuthor || user.isAdmin),
        },
      ]}
      rightIcon={null}
      className='absolute right-1 top-3 z-[100]'
    >
      <FontAwesomeIcon icon={faEllipsisVertical} />
    </Dropdown>
  )
}

export function DeckCard({ deck }) {
  return (
    <Link
      to={`/deck/${deck.slug}`}
      key={deck._id}
      className='pointer group absolute h-full w-full overflow-hidden rounded bg-slate-400 shadow dark:bg-slate-800'
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${UrlHelper.getUrlWithOptions(deck.imageUrl, {
          width: 480,
          height: 270,
          quality: 90,
        })})`,
      }}
    >
      <div className='h-full w-full object-cover before:absolute before:inset-0 before:bg-gradient-to-t before:from-black/50 before:content-[""] group-hover:before:from-black/80'></div>

      <DeckMenu deck={deck} />

      <div className='absolute bottom-0 p-3'>
        <div className='mb-3 pr-2'>
          <span className='text-sm font-semibold text-slate-100 group-hover:text-slate-300'>
            {deck.title}
          </span>
        </div>

        <div className='flex flex-none items-end gap-x-2'>
          <div className='mt-1 flex items-center gap-x-2 text-gray-400 dark:text-gray-400'>
            <span className='text-sm font-medium'>
              {Number(deck.exerciseCount).toLocaleString()} <Trans>items</Trans>
            </span>
            <span className='flex items-center gap-1.5 text-sm font-medium'>
              {deck.enrollmentCount} <UsersIcon className='h-4 w-4' />
            </span>
            <DeckDuration
              exerciseCount={deck.exerciseCount}
              className='text-sm font-medium'
            />
            {deck.private ? (
              <Tag color='rose'>
                <Trans>Private</Trans>
              </Tag>
            ) : null}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default function DeckList({ decks }) {
  if (!decks?.length) {
    return (
      <EmptyState
        title={t`No decks found`}
        description={t`Start learning something new by creating a new deck`}
        buttonText={t`Create Deck`}
        icon={CardStackPlusIcon}
        to='/decks/creator'
      />
    )
  }

  return (
    <MasonryGrid
      items={decks}
      render={deck => {
        return <DeckCard deck={deck} />
      }}
    />
  )
}
