import { ClientBoardNodeCollection } from '@/client/data'
import { getBoundsFromNodes } from '@/common/boards/bounds'
import { CollectionEvent } from '@helenejs/data'
import { useFind } from '@helenejs/react'
import { useWindowResize } from '@hooks/use-window-resize'
import { useCreation } from 'ahooks'
import { useEffect, useState } from 'react'

export type VirtualBounds = {
  width: number
  height: number
  minX: number
  minY: number
  maxX: number
  maxY: number
  offsetX: number
  offsetY: number
  diffX: number
  diffY: number
  initialized: boolean
}

export function useVirtualBounds(boardId: string): VirtualBounds {
  const [initialized, setInitialized] = useState(false)

  const nodes = useFind(ClientBoardNodeCollection, { board: boardId })

  useEffect(() => {
    if (ClientBoardNodeCollection.ready) {
      setInitialized(true)
      return
    }

    const onReady = () => setInitialized(true)

    ClientBoardNodeCollection.on(CollectionEvent.READY, onReady)

    return () => {
      ClientBoardNodeCollection.off(CollectionEvent.READY, onReady)
    }
  }, [])

  const win = useWindowResize()

  return useCreation(() => {
    if (!initialized) {
      return {
        initialized: false,
        width: win.width,
        height: win.height,
        minX: 0,
        minY: 0,
        maxX: win.width,
        maxY: win.height,
        offsetX: 0,
        offsetY: 0,
        diffX: 0,
        diffY: 0,
      }
    }

    return {
      ...getBoundsFromNodes(nodes, win),
      initialized: true,
    }
  }, [boardId, nodes, win.width, win.height, initialized])
}
