import React, { useCallback } from 'react'
import { Button } from '@/client/components/ui/button'
import { Trans } from '@lingui/macro'
import { useClient } from '@helenejs/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export function IgnoreButton({ game, exercise }) {
  const client = useClient()

  const onClick = useCallback(async () => {
    await client.call('exercise.ignore', { _id: exercise._id })

    game.reload()
  }, [exercise, game.reload])

  return (
    <Button onClick={onClick} variant='outline'>
      <ExclamationTriangleIcon className='h-4 w-4' /> <Trans>Ignore</Trans>
    </Button>
  )
}
