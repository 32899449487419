import { useCallback } from 'react'
import { loadSlim } from 'tsparticles-slim'
import { Particles } from '@components/particles/custom-particles'
import { useTheme } from '@hooks/use-theme'

export const ParticleBackground = () => {
  const theme = useTheme()

  const particlesInit = useCallback(async engine => {
    await loadSlim(engine)
  }, [])

  return (
    <Particles
      init={particlesInit}
      options={{
        fpsLimit: 60,
        interactivity: {
          events: {
            onClick: {
              enable: false,
            },
            onHover: {
              enable: true,
              mode: 'repulse',
            },
            resize: true,
          },
          modes: {
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: theme.isDark ? '#ffffff' : '#000000',
          },
          links: {
            color: theme.isDark ? '#ffffff' : '#000000',
            distance: 300,
            enable: true,
            opacity: 0.1,
            width: 0.5,
          },
          move: {
            direction: 'none',
            enable: true,
            outModes: {
              default: 'bounce',
            },
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 42,
          },
          opacity: {
            value: 0.1,
          },
          shape: {
            type: 'circle',
          },
          size: {
            value: { min: 1, max: 2 },
          },
        },
        detectRetina: true,
      }}
    />
  )
}
