import { useOrgs } from '@/client/hooks/use-orgs'
import { BoardWorkspace } from '@/common/constants/boards'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Select } from '@mantine/core'
import { IconBuilding } from '@tabler/icons-react'
import { useField } from 'formik'
import React from 'react'

type GlobalOrgSwitcherProps = {
  className?: string
}

export function GlobalOrgSwitcher({ className }: GlobalOrgSwitcherProps) {
  const { currentWorkspace, setCurrentWorkspace } = useMetaboardAuth()

  return (
    <OrgSwitcher
      value={currentWorkspace}
      onChange={setCurrentWorkspace}
      className={className}
    />
  )
}

type OrgSwitcherProps = {
  value: string
  onChange: (value: string) => void
  className?: string
  disabled?: boolean
}

export function OrgSwitcher({
  value,
  onChange,
  className,
  disabled,
}: OrgSwitcherProps) {
  const data = useOrgs()

  if (!data) return null

  return (
    <Select
      data={data}
      value={value || ''}
      leftSection={<IconBuilding size={16} />}
      onChange={value => {
        if (value === BoardWorkspace.Personal) {
          onChange(null)
        } else {
          onChange(value)
        }
      }}
      allowDeselect={false}
      className={className}
      variant='unstyled'
      size='xs'
      searchable
      disabled={disabled}
    />
  )
}

export function OrgSwitcherField(props: { name: string; disabled: boolean }) {
  const [field, , helper] = useField(props.name)
  return (
    <OrgSwitcher
      value={field.value ?? BoardWorkspace.Personal}
      onChange={helper.setValue}
      disabled={props.disabled}
    />
  )
}
