import { IgnoreButton } from '@/client/components/practice/buttons/ignore-button'
import { ModeRenderer } from '@/client/components/practice/mode-renderer'
import { useGame } from '@/client/components/practice/use-game'
import { HorizontalLoader } from '@components/horizontal-loader'
import { SessionProgress } from '@components/practice/session-progress'
import { Spinner } from '@components/spinner'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useReactive } from '@hooks/use-reactive-local-storage'
import { useCreation, useDeepCompareEffect } from 'ahooks'
import { AnimatePresence, motion } from 'framer-motion'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import React from 'react'
import { AdminBar } from '../admin/admin-bar'
import { DeckComplete } from './deck-complete'

export function PracticeExercise({ deck }) {
  const { _id: deckId } = deck

  const { authenticated } = useMetaboardAuth()

  const state = useReactive({
    hasLearnedNewTerm: false,
    articleId: null,
    currentIndex: 0,
    currentItem: null,
    data: null,
    isExLoading: true,
    virtualSessionNum: 0,
    consecutiveHits: 0,
    correctCount: 0,
    incorrectCount: 0,
    xpEarned: 0,
    bonusXpEarned: 0,
  })

  useDeepCompareEffect(() => {
    if (state.data) {
      state.currentIndex = 0
      state.currentItem = state.data.session[state.currentIndex]
    }
  }, [state.data])

  const { exercise, samples, isReview, isNew, history } =
    state.currentItem ?? {}

  const game = useGame({ state, deckId, exercise, history })

  const articles = useCreation(() => {
    if (!exercise) return []
    if (!exercise.section) return []

    const { section } = exercise

    return uniqBy([...(section?.articles ?? []), ...exercise.articles], '_id')
  }, [exercise?.section, exercise])

  const params = {
    exercise,
    articles,
    isNew,
    isReview,
    samples,
    history,
    state,
    game,
  }

  if (state.isExLoading) return <HorizontalLoader />

  if (isEmpty(state.data?.session)) {
    return (
      <div>
        <AdminBar game={game} deck={deck} />
        <DeckComplete />
      </div>
    )
  }

  if (!exercise) return <Spinner />

  return (
    <>
      <AdminBar game={game} deck={deck} exercise={exercise} />

      <SessionProgress game={game} />

      <AnimatePresence
        mode='wait'
        onExitComplete={() => {
          state.hasLearnedNewTerm = false
        }}
      >
        <motion.div
          key={exercise._id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.16,
          }}
          style={{
            position: 'relative',
          }}
        >
          <ModeRenderer
            game={game}
            exercise={exercise}
            type={exercise.type}
            params={params}
          />

          {authenticated && !game.finished ? (
            <div className='mt-12 flex w-full flex-row items-center justify-center gap-2 self-start sm:flex-col'>
              <IgnoreButton game={game} exercise={exercise} />
            </div>
          ) : null}
        </motion.div>
      </AnimatePresence>
    </>
  )
}
