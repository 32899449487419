import { useClient } from '@helenejs/react'
import { AnyFunction, NO_CHANNEL } from '@helenejs/utils'
import useCreation from 'ahooks/lib/useCreation'
import isString from 'lodash/isString'
import { useEffect, useState } from 'react'

type UseSubscribeParams = {
  event: string
  channel?: string[] | string
  active?: boolean
}

export function useHeleneSubscribe(
  { event, channel = NO_CHANNEL, active = true }: UseSubscribeParams,
  callback: AnyFunction = null,
  deps: any[] = [],
) {
  const channelArray = useCreation(() => {
    if (isString(channel)) {
      return [channel]
    }

    if (Array.isArray(channel)) {
      return channel
    }

    return []
  }, [channel])

  if (!isString(event)) {
    throw new Error('event name is required')
  }

  const client = useClient()
  const [ready, setReady] = useState(false)

  const channels = useCreation(
    () => channelArray.map(c => client.channel(c)),
    [channelArray],
  )

  useEffect(() => {
    if (!callback) return
    if (!active) return

    for (const _channel of channels) {
      _channel.on(event, callback)
    }

    return () => {
      for (const _channel of channels) {
        _channel.off(event, callback)
      }
    }
  }, [event, channels, callback, active].concat(deps))

  useEffect(() => {
    if (!active) return

    for (const _channel of channels) {
      _channel
        .subscribe(event)
        .then(result => setReady(result?.[event]))
        .catch(console.error)
    }

    return () => {
      // Prevent unsubscribing too early due to simple re-rendering
      setTimeout(() => {
        for (const _channel of channels) {
          // Only unsubscribe if there are no other listeners
          if (!_channel._events[event]?.length) {
            _channel.unsubscribe(event).catch(console.error)
          }
        }
      }, 1000)
    }
  }, [event, channels, active])

  return ready
}
