import { BaseOrderInterval } from '@/client/components/deck-editor/use-orderable'
import { DeckStatus, ExerciseStatus, ExerciseType } from '@/common/constants'
import { Board, BoardNode } from '@/common/constants/boards'
import { Organization } from '@/common/constants/organization'
import { Role } from '@/common/constants/role'
import { Team } from '@/common/constants/team'
import { ColName } from '@/server/data/col-name'
import { Collection } from '@helenejs/data'
import { IDBStorage } from '@helenejs/data/lib/browser'
import { t } from '@lingui/macro'
import first from 'lodash/first'
import last from 'lodash/last'

async function beforeUpdate(doc, oldDoc) {
  if (!doc._new) {
    Object.assign(doc, { _modified: true })
  }

  // If the section has changed, but the order is the same, we need to bump the order
  if (doc.section !== oldDoc.section && doc.order === oldDoc.order) {
    const latest = await this.findOne(
      { section: doc.section },
      { sort: { order: -1 } },
    )

    Object.assign(doc, {
      order: latest?.order + BaseOrderInterval || BaseOrderInterval,
    })
  }

  console.log(doc.order)

  Object.assign(doc, { updatedAt: new Date() })

  return doc
}

export const ClientDeckCollection = new Collection({
  beforeUpdate,
})

export const ClientSectionCollection = new Collection({
  beforeUpdate,
})

export const ClientExerciseCollection = new Collection({
  beforeUpdate,
})

export const ClientOrganizationCollection = new Collection<
  Organization<string>
>({
  name: ColName.Organizations,
  storage: new IDBStorage(),
  autoload: true,
})

export const ClientRoleCollection = new Collection<Role<string>>({
  name: ColName.Roles,
  storage: new IDBStorage(),
  autoload: true,
})

export const ClientTeamCollection = new Collection<Team<string>>({
  name: ColName.Teams,
  storage: new IDBStorage(),
  autoload: true,
})

export const ClientBoardCollection = new Collection<Board<string>>({
  name: ColName.Boards,
  storage: new IDBStorage(),
  autoload: true,
})

export const ClientBoardNodeCollection = new Collection<BoardNode<string>>({
  name: ColName.BoardNodes,
  storage: new IDBStorage(),
  autoload: true,
})

export const ClientBoardEdgeCollection = new Collection({
  name: ColName.BoardEdges,
  storage: new IDBStorage(),
  autoload: true,
})

ClientExerciseCollection.ensureIndex({
  fieldName: 'section',
})

ClientExerciseCollection.ensureIndex({
  fieldName: 'order',
})

export async function addDeck(deck) {
  if (await ClientDeckCollection.findOne({})) {
    throw new Error('only one deck is allowed.')
  }

  return ClientDeckCollection.insert({
    ...deck,
    status: DeckStatus.PUBLISHED,
    private: false,
    _new: true,
  })
}

export async function addSection({ title = null, top = false } = {}) {
  const deck = (await ClientDeckCollection.findOne({})) as any

  const sections = await ClientSectionCollection.find({
    deck: deck._id,
    removedAt: { $exists: false },
  }).sort({ order: 1 })

  return ClientSectionCollection.insert({
    title: title ?? t`Main`,
    deck: deck._id,
    order: top
      ? first(sections)?.order / 2 || 4096
      : (last(sections)?.order || 0) + 4096,
    _new: true,
  })
}

export async function addExercise({
  question = null,
  answer = null,
  section,
  top = false,
  ...rest
}) {
  const exercises = await ClientExerciseCollection.find({
    section: section._id,
    removedAt: { $exists: false },
  }).sort({ order: 1 })

  return ClientExerciseCollection.insert({
    type: ExerciseType.MULTIPLE_CHOICE,
    status: ExerciseStatus.PUBLISHED,
    deck: section.deck,
    section: section._id,
    question: question ?? 'Lorem',
    answer: answer ?? 'Ipsum',
    order: top
      ? first(exercises)?.order / 2 || 4096
      : (last(exercises)?.order || 0) + 4096,

    _new: true,
    ...rest,
  })
}
