import React, { Fragment, useMemo } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import classnames from 'classnames'
import { Scalar } from '@/client/components/ui/types'
import isEmpty from 'lodash/isEmpty'
import { arrayFieldMap } from '@/common/object-utils'
import { t } from '@lingui/macro'

export type SelectItem = {
  label: string
  value: Scalar
}

type Props = {
  items: SelectItem[]
  value: Scalar
  onChange: (item: Scalar) => void
  mapping?: { label: string; value: string }
  nullable?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export function Select({
  items,
  value,
  onChange,
  mapping,
  nullable,
  ...rest
}: Props) {
  const mappedItems = useMemo(() => {
    const _items = isEmpty(mapping) ? items : arrayFieldMap(items, mapping)

    if (nullable) {
      _items.unshift({
        label: t`None`,
        value: null,
      })
    }

    return _items
  }, [items, mapping])

  const selected = mappedItems.find(item => item.value === value)

  return (
    <Listbox
      value={selected}
      onChange={item => {
        // @ts-ignore
        onChange(item.value)
      }}
      as='div'
      {...rest}
    >
      {({ open }) => (
        <div className='relative'>
          <Listbox.Button className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 dark:border-slate-700 dark:bg-slate-500 sm:text-sm'>
            <span className='block truncate text-base text-gray-900 dark:text-gray-200'>
              {selected?.label ?? (
                <span className='text-gray-500'>Select...</span>
              )}
            </span>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronUpDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute !z-[10000] mb-0 ml-0 mr-0 mt-1 max-h-60 min-w-full list-none overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {mappedItems.map(item => (
                <Listbox.Option
                  key={item.value}
                  className={({ active }) =>
                    classnames(
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-8 pr-4',
                    )
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classnames(
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate',
                        )}
                      >
                        {item.label}
                      </span>

                      {selected ? (
                        <span
                          className={classnames(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          )}
                        >
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}
