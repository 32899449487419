export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function prettyElapsedTime(ms: number) {
  const seconds = Math.ceil(ms / 1000)

  if (seconds < 60) {
    return `${seconds}s`
  }

  const minutes = Math.ceil(seconds / 60)

  if (minutes < 60) {
    return `${minutes}m`
  }

  const hours = Math.ceil(minutes / 60)

  if (hours < 24) {
    return `${hours}h`
  }

  const days = Math.ceil(hours / 24)

  return `${days}d`
}

export function prettyElapsedTimeFromDates(
  date1: Date,
  date2: Date = new Date(),
) {
  return prettyElapsedTime(date2.getTime() - date1.getTime())
}
