export function isUrl(str: string) {
  try {
    new URL(str)
    return true
  } catch {
    return false
  }
}

export function isEmail(str: string) {
  return /\S+@\S+\.\S+/.test(str)
}
