import { cn } from '@/client/utils/cn'
import { LucideCheck } from 'lucide-react'
import React, { useMemo } from 'react'

export function NodeChecklistBadge({ content }) {
  const checklist = useMemo(() => {
    const checkedCount = content?.match(CHECKED_REGEX)?.length ?? 0
    const uncheckedCount = content?.match(UNCHECKED_REGEX)?.length ?? 0
    const total = checkedCount + uncheckedCount

    return {
      checked: checkedCount,
      unchecked: uncheckedCount,
      total,
    }
  }, [content])

  return content && checklist.total ? (
    <span
      className={cn(
        'absolute bottom-0 left-0 flex items-center gap-1 px-1.5 py-1 text-left text-[8px] font-medium',
        'rounded-bl rounded-tr bg-black/15 text-white transition-all',
        {
          'text-lime-500': checklist.checked === checklist.total,
        },
      )}
    >
      <LucideCheck size={12} />
      {checklist.checked}/{checklist.total}
    </span>
  ) : null
}

const CHECKED_REGEX = /data-checked=\\?"true\\?"/gi
const UNCHECKED_REGEX = /data-checked=\\?"false\\?"/gi
