import { BoardNode } from '@/common/constants/boards'
import { getNodeEdgeSide } from '@components/boards/utils/positioning'
import { Side } from '@components/boards/utils/types'
import { useMemo } from 'react'

export function useEdgePositions(
  node1: BoardNode<string>,
  node2: BoardNode<string>,
) {
  return useMemo(() => {
    if (!node1 || !node2)
      return {
        sx: 0,
        sy: 0,
        tx: 0,
        ty: 0,
        sourceSide: Side.Right,
        targetSide: Side.Left,
      }

    return getNodeEdgeSide(node1, node2)
  }, [
    node1?.x,
    node1?.y,
    node1?.width,
    node1?.height,
    node2?.x,
    node2?.y,
    node2?.width,
    node2?.height,
  ])
}
