import debounce from 'lodash/debounce'
import { useEffect, useRef } from 'react'

export const useInteractionTime = (
  onPersist: (time: number) => void,
  deps = [],
) => {
  const startTimeRef = useRef(null)

  useEffect(() => {
    const start = () => {
      if (!startTimeRef.current) {
        startTimeRef.current = Date.now()
      }

      end()
    }

    const end = debounce(() => {
      if (startTimeRef.current) {
        const endTime = Date.now()
        const duration = endTime - startTimeRef.current
        startTimeRef.current = null
        onPersist(duration)
      }
    }, 1000)

    window.addEventListener('mousemove', start)
    window.addEventListener('keydown', start)
    window.addEventListener('mousedown', start)
    window.addEventListener('touchstart', start)

    return () => {
      window.removeEventListener('mousemove', start)
      window.removeEventListener('keydown', start)
      window.removeEventListener('mousedown', start)
      window.removeEventListener('touchstart', start)
    }
  }, deps)
}
