import { cva, VariantProps } from 'class-variance-authority'
import React from 'react'
import { cn } from '@/client/utils/cn'

const alertVariants = cva(
  'p-4 mb-4 text-sm rounded-lg shadow rounded [&_p]:m-0',
  {
    variants: {
      variant: {
        info: 'text-blue-800 bg-blue-50 dark:bg-blue-800/20 dark:text-blue-400',
        danger: 'text-red-800 bg-red-50 dark:bg-red-800/20 dark:text-red-400',
        success:
          'text-green-800 bg-green-50 dark:bg-green-800/20 dark:text-green-400',
        warning:
          'text-yellow-800 bg-yellow-50 dark:bg-yellow-800/20 dark:text-yellow-300',
        plain:
          'text-gray-800 bg-gray-50 dark:bg-gray-800/20 dark:text-gray-300',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  },
)

export interface AlertProps extends VariantProps<typeof alertVariants> {
  children: React.ReactNode
  title?: string
  className?: string
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className, children, title, ...props }, ref) => (
    <div ref={ref} className={cn(alertVariants(props), className)} role='alert'>
      {title && <header className='mb-4 font-bold'>{title}</header>}

      {children}
    </div>
  ),
)
