import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import zip from 'lodash/zip'

export function getSmallestArray(a, b) {
  return a.length < b.length ? a : b
}

export function getLargestArray(a, b) {
  return a.length > b.length ? a : b
}

export function padSmallest(a, b) {
  const max = getLargestArray(a, b)
  const min = getSmallestArray(a, b)

  const padded = new Array(max.length - min.length).fill(undefined).concat(min)

  if (max === a) {
    return [a, padded]
  }

  return [padded, b]
}

export function intercalateSmallestEnd(a, b) {
  const [x, y] = padSmallest(a, b)

  return compact(flatten(zip(x, y)))
}

export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
