import useDebounceFn from 'ahooks/lib/useDebounceFn'
import { useEffect } from 'react'

export function useDebouncedEffect(
  callback: () => void,
  wait: number,
  deps = [],
) {
  const debouncedCallback = useDebounceFn(callback, {
    wait,
  })

  useEffect(() => {
    debouncedCallback.run()
  }, deps)
}
