import { Tag } from '@/client/components/ui/tag'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import React from 'react'

/**
 * It is only visible if the user is not premium.
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
export function PremiumTag() {
  const { isPremiumActive } = useMetaboardAuth()

  if (isPremiumActive) return null

  return (
    <Tag color='amber'>
      <Trans>Premium Only</Trans>
    </Tag>
  )
}
