import { HorizontalLoader } from '@components/horizontal-loader'
import { Markdown } from '@components/markdown'
import { useMethod } from '@helenejs/react'
import React from 'react'

export default function ArtifactRenderer({ artifactKey }) {
  const method = useMethod({
    method: 'legal.artifacts.get',
    params: { key: artifactKey },
    required: ['key'],
    deps: [artifactKey],
  })

  if (method.loading) {
    return <HorizontalLoader />
  }

  return (
    <div>
      <Markdown>{method.result?.content}</Markdown>
    </div>
  )
}
