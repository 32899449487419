import React, { useState } from 'react'
import { object, string } from 'yup'
import { StandardForm } from './standard-form'
import {
  ExerciseStatus,
  ExerciseType,
  ExerciseTypes,
  Languages,
} from '@/common/constants'
import { Field, useField } from 'formik'
import { RadioField } from '../fields/radio-field'
import { smartLowerCase } from '@/common/string-utils'
import { FormGroup } from '@/client/components/ui/forms/form-group'
import { FunkyButton } from '@/client/components/ui/funky-button'
import { t, Trans } from '@lingui/macro'
import { useClient } from '@helenejs/react'
import { SelectField } from '@components/fields/select-field'
import { RefreshCw } from 'lucide-react'
import { MarkdownEditorField } from '@components/fields/markdown-editor-field'

export function ExerciseTypeFields() {
  return (
    <>
      <FormGroup label={t`Type`} inline>
        <SelectField name='type' items={ExerciseTypes} />
      </FormGroup>
    </>
  )
}

export function ExerciseFormFields({ sections }) {
  const [isTranslating, setTranslating] = useState(false)

  const [{ value: type }] = useField('type')
  const [{ value: question }, , questionHelper] = useField('question')
  const [{ value: answer }, , answerHelper] = useField('answer')
  const [{ value: questionLanguage }, , qlHelper] = useField('questionLanguage')
  const [{ value: answerLanguage }, , alHelper] = useField('answerLanguage')

  const client = useClient()

  function getAnswerField() {
    if (type === ExerciseType.CLOZE) {
      return null
    }

    if (type === ExerciseType.TRUE_OR_FALSE) {
      return (
        <FormGroup label={t`Answer`}>
          <RadioField
            name='isTrue'
            normalize={JSON.parse}
            items={[
              { label: t`True`, value: true },
              { label: t`False`, value: false },
            ]}
          ></RadioField>
        </FormGroup>
      )
    }

    return (
      <FormGroup label={t`Answer`}>
        <MarkdownEditorField name='answer' />

        <div className='flex'>
          <FunkyButton
            variant='secondary'
            onClick={() => {
              answerHelper.setValue(question)
              questionHelper.setValue(answer)
              qlHelper.setValue(answerLanguage)
              alHelper.setValue(questionLanguage)
            }}
            className='ml-auto mt-2'
            icon={RefreshCw}
          >
            <Trans>Switch</Trans>
          </FunkyButton>
        </div>
      </FormGroup>
    )
  }

  return (
    <>
      <Field type='hidden' name='deck' />

      <div className='flex flex-wrap items-center gap-4'>
        <ExerciseTypeFields />

        <FormGroup label={t`Section`} inline>
          <SelectField
            name='section'
            items={sections?.map(({ _id, title }) => ({
              label: title,
              value: _id,
            }))}
          />
        </FormGroup>
      </div>

      <div className='flex flex-wrap items-center gap-4'>
        <FormGroup label={t`Question Language`} inline>
          <SelectField name='questionLanguage' items={Languages} />
        </FormGroup>

        <FormGroup label={t`Answer Language`} inline>
          <SelectField name='answerLanguage' items={Languages} />
        </FormGroup>
      </div>

      <div>
        <FormGroup label={t`Question`}>
          <MarkdownEditorField name='question' />

          <div className='mt-2 flex items-center gap-2'>
            {type === ExerciseType.TRANSLATION && (
              <FunkyButton
                icon='translate'
                variant='fb'
                onClick={async () => {
                  setTranslating(true)
                  const { TranslatedText: translation } = await client.call(
                    'ai.translate',
                    {
                      text: question,
                      sourceLanguageCode: questionLanguage,
                      targetLanguageCode: answerLanguage,
                    },
                  )

                  answerHelper.setValue(smartLowerCase(translation))
                  setTranslating(false)
                }}
                loading={isTranslating}
              >
                <Trans>Translate</Trans>
              </FunkyButton>
            )}
          </div>
        </FormGroup>

        {getAnswerField()}
      </div>

      <FormGroup label={t`Explanation`}>
        <MarkdownEditorField name='explanation' />
      </FormGroup>

      <FormGroup label={t`Status`}>
        <SelectField
          name='status'
          items={[
            { label: t`Draft`, value: ExerciseStatus.DRAFT },
            {
              label: t`Published`,
              value: ExerciseStatus.PUBLISHED,
            },
          ]}
        />
      </FormGroup>
    </>
  )
}

export function ExerciseForm({ onSave, initialValues, sections }) {
  return (
    <StandardForm
      initialValues={initialValues}
      onSave={onSave}
      schema={object().shape({
        question: string().required(t`The question can't be blank`),
        answer: string().when('type', {
          is: type =>
            type !== ExerciseType.TRUE_OR_FALSE && type !== ExerciseType.CLOZE,
          then: schema => schema.required(t`The answer can't be blank`),
          otherwise: schema => schema,
        }),
        questionLanguage: string().when('type', {
          is: type => type === ExerciseType.TRANSLATION,
          then: schema =>
            schema.required(t`The question language can't be blank`),
          otherwise: schema => schema,
        }),
        answerLanguage: string().when('type', {
          is: type => type === ExerciseType.TRANSLATION,
          then: schema =>
            schema.required(t`The answer language can't be blank`),
          otherwise: schema => schema,
        }),
        status: string().required(t`The status can't be blank`),
      })}
      autoSave
    >
      <ExerciseFormFields sections={sections} />
    </StandardForm>
  )
}
