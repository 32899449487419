import { ObjectIdOrString } from '@/common/constants/database'

export enum ChatType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  SUPPORT = 'SUPPORT',
}

export const TypingStatus = {
  STARTED: 'STARTED',
  STOPPED: 'STOPPED',
}

export const ChatEvent = {
  ChatMessage: 'chat:message',
  ChatTyping: 'chat:typing',
  MessageToken: 'message:token',
}

export type Chat<I = ObjectIdOrString> = {
  _id: I
  uuid: string
  type: ChatType
  bot: string
  participants: I[]
  createdAt: Date
  updatedAt: Date
}
