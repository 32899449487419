import { Button } from '@components/ui/button'
import { Undo2 } from 'lucide-react'
import React from 'react'
import { useClient } from '@helenejs/react'
import { EstimationSession } from '@/common/constants/task-hold-em'

type ControlProps = {
  session: EstimationSession
  isAdmin: boolean
}

export function Control({ session, isAdmin }: ControlProps) {
  const client = useClient()

  if (!isAdmin) {
    return null
  }

  const allPlayersVoted = Object.values(session.points).every(p => p !== null)

  return (
    <div className='mb-4 flex gap-2'>
      <Button
        variant='danger'
        onClick={() => {
          client
            .call('taskholdem.reset', { id: session.id })
            .catch(console.error)
        }}
        icon={Undo2}
      >
        Clear Votes
      </Button>

      <Button
        onClick={() => {
          client
            .call('taskholdem.toggle', { id: session.id })
            .catch(console.error)
        }}
        icon={Undo2}
        variant={allPlayersVoted ? 'primary' : 'warning'}
      >
        {session.hidden ? 'Show Votes' : 'Hide Votes'}
      </Button>
    </div>
  )
}
