import { ClientTeamCollection } from '@/client/data'
import { useData } from '@hooks/use-data'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { ObjectId } from 'bson'
import keyBy from 'lodash/keyBy'

export function useOrgTeams() {
  const { currentWorkspace } = useMetaboardAuth()

  const orgId = ObjectId.isValid(currentWorkspace)
    ? currentWorkspace
    : undefined

  const teams = useData({
    method: 'org.teams.list',
    params: {
      orgId,
    },
    filter: ObjectId.isValid(currentWorkspace) ? { org: currentWorkspace } : {},
    collection: ClientTeamCollection,
    authenticated: true,
    required: [orgId],
  })

  return {
    teams: teams.data,
    teamById: keyBy(teams.data, '_id'),
    loading: teams.loading,
    error: teams.error,
  }
}
