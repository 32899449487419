import { SapienzaBot } from '@/common/constants/bots'
import classnames from 'classnames'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { t, Trans } from '@lingui/macro'
import { useClickAway } from 'ahooks'
import { useRef } from 'react'
import algusSmall from '@/client/assets/algus-small.png'
import { cn } from '@/client/utils/cn'

export function ChatPicker({
  activeRecipient,
  setActiveRecipient,
  className,
  state,
}) {
  const ref = useRef(null)

  useClickAway(() => {
    if (state.isChatPickerOpen) {
      state.isChatPickerOpen = false
    }
  }, ref.current)

  return (
    <div
      ref={ref}
      className={classnames(
        'flex flex-col border-l border-slate-500/25 bg-gray-100 dark:bg-slate-600',
        className,
      )}
    >
      <div className='flex h-[45px] items-center justify-between border-b border-slate-500/25 lg:hidden'>
        <span className='pl-4 text-lg'>
          <Trans>Contacts</Trans>
        </span>

        <XMarkIcon
          className='h-[45px] w-[45px] cursor-pointer p-2 text-slate-400 hover:text-slate-500'
          onClick={() => (state.isChatPickerOpen = false)}
        />
      </div>
      <div
        className={cn(
          'flex cursor-pointer items-center gap-2 px-3 py-2 text-sm text-gray-800 dark:text-white',
          {
            'hover:bg-gray-400/50 dark:hover:bg-slate-500/50':
              activeRecipient !== SapienzaBot,
            'bg-gray-400/50 dark:bg-slate-500/50':
              activeRecipient === SapienzaBot,
          },
        )}
        onClick={() => {
          setActiveRecipient(SapienzaBot)
          state.isChatPickerOpen = false
        }}
      >
        <img
          className='inline-block h-8 w-8 rounded object-cover'
          src={algusSmall}
          alt={t`Algus Dumbletoken`}
        />
        <Trans>Algus Dumbletoken</Trans>
      </div>
    </div>
  )
}
