import { Board } from '@/common/constants/boards'
import { Permission } from '@/common/constants/permissions'
import { User } from '@/common/constants/users'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { useEffect } from 'react'

export function useBoardPermission(board: Board, user: User | null) {
  const { setState } = useBoardState()

  useEffect(() => {
    if (!board) return

    setState(draft => {
      if (!user) {
        draft.isReadOnly = true
        return
      }

      if (board.author === user._id) {
        draft.isReadOnly = false
        return
      }

      const access = board?.accessList?.find(a => a.user === user._id)

      if (access?.permission === Permission.ReadWrite) {
        draft.isReadOnly = false
        return
      }

      /**
       * User wouldn't even be able to read the board if he didn't have access to the organization/team
       *
       * @todo Find way to propagate this in real-time
       */
      if (board.org && board.teams?.length > 0) {
        draft.isReadOnly = false
        return
      }

      draft.isReadOnly = true
    })
  }, [board, user])
}
