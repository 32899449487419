import { Rect } from '@/client/utils/rect'
import React from 'react'

type SelectorProps = {
  rect: Rect
  selecting: boolean
}

export function Selector({ rect, selecting }: SelectorProps) {
  return (
    <div
      className='!fixed border border-blue-700 bg-blue-600/50'
      style={{
        display: selecting ? 'block' : 'none',
        left: `${rect.left || 0}px`,
        top: `${rect.top || 0}px`,
        width: `${rect.width || 0}px`,
        height: `${rect.height || 0}px`,
      }}
    />
  )
}
