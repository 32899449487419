import DeleteConfirmation from '@/client/components/delete-confirmation'
import { ChatInputControl } from '@components/chat/chat-input-control'
import { t, Trans } from '@lingui/macro'
import React, { useState } from 'react'

export const MAX_LENGTH = 140

export function ChatInput({ chat, onSend, onClearChat }) {
  const [message, setMessage] = useState('')
  const [isConfirmingClear, setConfirmingClear] = useState(false)

  return (
    <div className='flex shrink-0 items-start space-x-4 p-4 lg:p-2'>
      <div className='relative min-w-0 flex-1'>
        <div className='overflow-hidden rounded-lg border border-gray-300 bg-white shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500'>
          <label htmlFor='comment' className='sr-only'>
            <Trans>Write your message</Trans>
          </label>

          <textarea
            className='block w-full resize-none border-0 py-3 font-medium text-black focus:ring-0 sm:text-sm'
            value={message}
            onChange={e => setMessage(e.target.value)}
            onKeyDown={e => {
              if (e.key !== 'Enter') return
              if (!message) return
              e.preventDefault()
              onSend?.({ message })
              setMessage('')
            }}
            placeholder={t`Write your message...`}
            maxLength={MAX_LENGTH}
          />

          <div className='h-[64px]' aria-hidden='true'></div>
        </div>
        <ChatInputControl
          message={message}
          onInputSend={() => {
            if (!message) return
            onSend?.({ message })
            setMessage('')
          }}
          setConfirmingClear={setConfirmingClear}
          chat={chat}
        />
      </div>

      <DeleteConfirmation
        title={t`Clear Chat`}
        message={t`Are you sure you want to clear the chat?`}
        isOpen={isConfirmingClear}
        onConfirm={() => {
          onClearChat?.()
          setConfirmingClear(false)
        }}
        onCancel={() => setConfirmingClear(false)}
        onClose={() => setConfirmingClear(false)}
      />
    </div>
  )
}
