import { Avatar } from '@mantine/core'
import React from 'react'

export function Recommendation({ name, url, image, text }) {
  return (
    <div className='mx-auto max-w-xl space-y-2'>
      <div className='flex items-center gap-2'>
        <Avatar src={image} alt='Isaque Chaves' />
        <a
          className='text-sm hover:underline'
          href={url}
          target='_blank'
          rel='noreferrer'
        >
          {name}
        </a>
      </div>
      <div className='text-sm font-medium'>“{text}”</div>
    </div>
  )
}
