import { ColName } from '@/server/data/col-name'
import { Collection } from '@helenejs/data'
import { IDBStorage } from '@helenejs/data/lib/browser'

export const ClientBoardTabCollection = new Collection({
  name: ColName.BoardTabs,
  storage: new IDBStorage(),
  autoload: true,
  timestamps: true,
})

ClientBoardTabCollection.ensureIndex({
  fieldName: 'boardId',
  unique: true,
})

export async function addTab(boardId: string) {
  if (
    await ClientBoardTabCollection.findOne({
      boardId,
    })
  ) {
    return
  }

  if ((await ClientBoardTabCollection.count({})) >= 10) {
    const oldest = await ClientBoardTabCollection.findOne({}, null, {
      createdAt: 1,
    })
    await ClientBoardTabCollection.remove({ _id: oldest._id })
  }

  await ClientBoardTabCollection.insert({
    boardId,
  })
}
