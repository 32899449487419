import { cn } from '@/client/utils/cn'
import { cva, VariantProps } from 'class-variance-authority'
import React from 'react'

const tagVariants = cva('inline-block font-medium leading-none', {
  variants: {
    color: {
      emerald:
        'ring-emerald-500 dark:ring-emerald-400/30 bg-emerald-500/10 dark:bg-emerald-400/10 text-emerald-500 dark:text-emerald-400',
      green:
        'ring-green-500 dark:ring-green-400/30 bg-green-500/10 dark:bg-green-400/10 text-green-500 dark:text-green-400',
      sky: 'ring-sky-500 dark:ring-sky-400/30 bg-sky-500/10 dark:bg-sky-400/10 text-sky-500 dark:text-sky-400',
      orange:
        'ring-orange-500 dark:ring-orange-400/30 bg-orange-500/10 dark:bg-orange-400/10 text-orange-500 dark:text-orange-400',
      amber:
        'ring-amber-500 dark:ring-amber-400/30 bg-amber-500/10 dark:bg-amber-400/10 text-amber-500 dark:text-amber-400',
      rose: 'ring-rose-500 dark:ring-rose-400/30 bg-rose-500/10 dark:bg-rose-400/10 text-rose-500 dark:text-rose-400',
      red: 'ring-red-500 dark:ring-red-400/30 bg-red-500/10 dark:bg-red-400/10 text-red-500 dark:text-red-400',
      pink: 'ring-pink-500 dark:ring-pink-400/30 bg-pink-500/10 dark:bg-pink-400/10 text-pink-500 dark:text-pink-400',
      purple:
        'ring-purple-500 dark:ring-purple-400/30 bg-purple-500/10 dark:bg-purple-400/10 text-purple-500 dark:text-purple-400',
      violet:
        'ring-violet-500 dark:ring-violet-400/30 bg-violet-500/10 dark:bg-violet-400/10 text-violet-500 dark:text-violet-400',
      indigo:
        'ring-indigo-500 dark:ring-indigo-400/30 bg-indigo-500/10 dark:bg-indigo-400/10 text-indigo-500 dark:text-indigo-400',
      blue: 'ring-blue-500 dark:ring-blue-400/30 bg-blue-500/10 dark:bg-blue-400/10 text-blue-500 dark:text-blue-400',
      cyan: 'ring-cyan-500 dark:ring-cyan-400/30 bg-cyan-500/10 dark:bg-cyan-400/10 text-cyan-500 dark:text-cyan-400',
      teal: 'ring-teal-500 dark:ring-teal-400/30 bg-teal-500/10 dark:bg-teal-400/10 text-teal-500 dark:text-teal-400',
      gray: 'ring-gray-500 dark:ring-gray-400/30 bg-gray-500/10 dark:bg-gray-400/10 text-gray-500 dark:text-gray-400',
      zinc: 'ring-zinc-500 dark:ring-zinc-400/30 bg-zinc-500/10 dark:bg-zinc-400/10 text-zinc-500 dark:text-zinc-400',
    },
    size: {
      sm: 'text-[0.625rem] px-2 py-1',
      md: 'text-sm px-2.5 py-1.5',
      lg: 'text-sm px-3 py-2',
    },
    rounded: {
      true: 'rounded-full',
      false: 'rounded-md',
    },
    ghost: {
      true: 'bg-transparent ring-0',
      false: 'bg-opacity-10 ring-1 ring-inset',
    },
  },

  defaultVariants: {
    color: 'gray',
    size: 'sm',
    rounded: false,
    ghost: false,
  },
})

export type TagProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> &
  VariantProps<typeof tagVariants>

export function Tag({ className, onClick, ...props }: TagProps) {
  return (
    <span
      className={cn(
        tagVariants(props),
        {
          'cursor-pointer': !!onClick,
        },
        className,
      )}
      onClick={onClick}
    >
      {props.children}
    </span>
  )
}
