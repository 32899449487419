import { Markdown } from '@/client/components/markdown'
import { UrlHelper } from '@/common/url-helper'
import { AnimatedCircuit } from '@components/animated-circuit'
import { HorizontalLoader } from '@components/horizontal-loader'
import { useMethod } from '@helenejs/react'
import { Image } from '@mantine/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom'

export default function Article() {
  const { slug } = useParams<{ slug: string }>()

  const { result: article, loading } = useMethod({
    method: 'article.getBySlug',
    params: { slug },
    deps: [slug],
  })

  if (loading) return <HorizontalLoader />

  const { title, description, keywords, content, image, authorName, videoUrl } =
    article

  const helmedMetaTags = [
    { name: 'keywords', content: keywords },
    { name: 'description', content: description },
    { name: 'author', content: authorName },
    { name: 'google', content: 'nositelinkssearchbox' },
    { name: 'google', content: 'notranslate' },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:url', content: location.href },
    { property: 'og:site_name', content: 'Metaboard' },
    image
      ? {
          name: 'og:image',
          content: `${UrlHelper.getImageUrl(image)}?width=800&height=418`,
        }
      : null,
  ]

  return (
    <>
      <Helmet>
        <title>{title} | Metaboard</title>
        {helmedMetaTags.map((tag, index) => {
          if (!tag) return null
          return <meta key={index} {...tag} />
        })}
      </Helmet>
      <figure className='relative mb-4 aspect-[12/4] overflow-hidden rounded-lg bg-indigo-500 lg:aspect-[12/2.5]'>
        <div className='h-full w-full object-cover before:absolute before:inset-0 before:bg-gradient-to-t before:from-black/75 before:content-[""]'>
          {image ? (
            <Image
              className='aspect-[12/4] w-full object-cover lg:aspect-[12/2.5]'
              src={UrlHelper.getImageUrl(image, {
                width: 1920,
                height: 480,
                quality: 90,
              })}
              alt={title}
            />
          ) : (
            <AnimatedCircuit />
          )}
        </div>
      </figure>

      <div className='mx-auto mb-12 max-w-4xl'>
        <div className='mb-4'>
          <h1 className='mb-0 text-xl'>{title}</h1>
        </div>

        {videoUrl ? (
          <ReactPlayer
            url={videoUrl.concat('&rel=0')}
            style={{
              margin: '0 auto',
            }}
            controls
          />
        ) : null}

        <Markdown className='text-[15px] font-normal leading-relaxed tracking-wide dark:text-slate-200/80 [&_img]:mx-auto [&_img]:max-h-[320px]'>
          {content}
        </Markdown>
      </div>
    </>
  )
}
