import { useTheme } from '@/client/hooks/use-theme'
import { RenderWithLayout } from '@/client/utils/components'
import { MainLayout } from '@components/layouts/main-layout'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import classnames from 'classnames'
import identity from 'lodash/identity'
import React, { useMemo } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { HorizontalLoader } from '../horizontal-loader'

export const defaultFallback = () => <Redirect to='/' />

export function ProtectedRoute({
  component,
  fallback = defaultFallback,
  protectedLayout = MainLayout,
  publicLayout = MainLayout,
  shouldAllow = identity,
  ...rest
}: RouteProps & {
  protectedLayout?: React.FunctionComponent<any>
  publicLayout?: React.FunctionComponent<any>
  shouldAllow?: (user: any) => boolean
  fallback?: React.FunctionComponent<any> | JSX.Element
}) {
  const { authenticated, context, user, loading } = useMetaboardAuth()
  const theme = useTheme()

  const isAllowed = useMemo(
    () => authenticated && shouldAllow({ ...context, user }),
    [authenticated, context, user, shouldAllow],
  )

  return (
    <Route
      {...rest}
      render={props => {
        if (loading)
          return (
            <div
              className={classnames('flex min-h-screen flex-col', {
                'bg-slate-700': theme.isDark,
              })}
            >
              <HorizontalLoader />
            </div>
          )

        if (isAllowed) {
          const Component = component
          const layoutProps = (Component as any).layoutProps || {}

          return (
            <RenderWithLayout
              parent={protectedLayout}
              layoutProps={layoutProps}
            >
              <Component {...props} />
            </RenderWithLayout>
          )
        }

        const Component = fallback as React.FunctionComponent<any>

        return (
          <RenderWithLayout
            parent={publicLayout}
            layoutProps={(fallback as any)?.layoutProps || {}}
          >
            <Component />
          </RenderWithLayout>
        )
      }}
    />
  )
}
