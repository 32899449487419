import { ClientBoardCollection } from '@/client/data'
import { MetaboardEvent } from '@/common/events'
import { CommandCenter } from '@components/boards/command-center'
import { NavButton } from '@components/nav-button'
import { useFindOne } from '@helenejs/react'
import { useHeleneEvent } from '@hooks/use-helene-event'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import { Drawer } from '@mantine/core'
import { LucideChevronsUpDown } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export function BoardBrowserDrawer() {
  // It's fine to use this since it's outside the board viewport (at navbar)
  const { boardId } = useParams<{ boardId: string }>()

  const { authenticated } = useMetaboardAuth()

  const [isBoardDrawerOpen, setBoardDrawerOpen] = useState(false)

  const currentBoard = useFindOne(ClientBoardCollection, { _id: boardId })

  const inputRef = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isBoardDrawerOpen) {
      setTimeout(() => {
        inputRef.current.focus()
      }, 100)
    }
  }, [isBoardDrawerOpen])

  useHeleneEvent(MetaboardEvent.CLOSE_BOARD_BROWSER, () => {
    setBoardDrawerOpen(false)
  })

  if (!authenticated) return null
  if (!boardId) return null

  return (
    <>
      <NavButton
        onClick={() => setBoardDrawerOpen(true)}
        icon={LucideChevronsUpDown}
        className='absolute left-0 top-16 truncate md:static'
        iconRight
      >
        {currentBoard?.name ?? <Trans>Boards</Trans>}
      </NavButton>
      <Drawer
        opened={isBoardDrawerOpen}
        onClose={() => setBoardDrawerOpen(false)}
        title={t`Quick Access`}
        position='left'
        size='xl'
        zIndex={30000}
      >
        <CommandCenter inputRef={inputRef} />
      </Drawer>
    </>
  )
}
