import React from 'react'
import { ExerciseRenderer } from '@/client/components/practice/exercise/exercise-renderer'

export function UnlimitedMode({ type, params }) {
  return (
    <>
      <ExerciseRenderer type={type} params={params} />
    </>
  )
}
