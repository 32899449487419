export const ServiceWorkerState = {
  INSTALLED: 'installed',
  ACTIVATED: 'activated',
  UPDATED: 'updated',
}

export const ServiceWorkerEvent = {
  STATE_CHANGE: 'statechange',
  UPDATE_FOUND: 'updatefound',
  MESSAGE: 'message',
  INSTALL: 'install',
  ACTIVATE: 'activate',
}

export const ServiceWorkerMessage = {
  SKIP_WAITING: 'SKIP_WAITING',
  RELOAD_PAGE: 'RELOAD_PAGE',
}
