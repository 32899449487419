import { cn } from '@/client/utils/cn'
import { MetaboardEvent } from '@/common/events'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'

export function FloatingChatPill() {
  const client = useClient()
  const { isChatOpen, isPremiumActive } = useMetaboardAuth()

  if (!isPremiumActive) return null

  return (
    <h1
      onClick={() => client.emit(MetaboardEvent.CHAT_TOGGLE)}
      className={cn(
        'fixed m-0 hidden cursor-pointer items-center justify-between bg-gray-300 p-4 text-sm font-medium',
        'text-gray-700 hover:bg-gray-400 dark:bg-slate-600 dark:text-slate-300 dark:hover:bg-slate-500',
        {
          'bottom-8 right-8 rounded-full px-5 py-3 md:flex lg:right-8':
            !isChatOpen,
        },
      )}
    >
      <span className='flex items-center gap-2'>
        <FontAwesomeIcon icon={faMessage} className='h-3 w-3' />
        <Trans>Chat</Trans>
      </span>
    </h1>
  )
}
