import React from 'react'
import { Button } from '@/client/components/ui/button'

export function BaseButton({ text, rightIcon, onClick, loading }) {
  const RightIcon = rightIcon

  return (
    <Button
      className='grow-0 gap-1.5'
      onClick={onClick}
      variant='outline'
      loading={loading}
    >
      <span className='hidden sm:inline'>{text}</span>
      {rightIcon ? (
        <span>
          <RightIcon className='h-4 w-4' />
        </span>
      ) : null}
    </Button>
  )
}
