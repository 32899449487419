import { ExerciseHeader } from '@/client/components/practice/exercise/exercise-header'
import { LanguageTag } from '@/client/components/practice/exercise/language-tag'
import { Answer } from '@/client/components/practice/exercise/answer'
import { Explanation } from '@/client/components/practice/exercise/explanation'
import { ExerciseArticles } from '@/client/components/practice/exercise/exercise-articles'
import { NextButton } from '@/client/components/practice/exercise/next-button'
import { MultipleChoiceQuestion } from '@/client/components/practice/questions/multiple-choice-question'
import { Timer } from '@components/practice/timer'
import React from 'react'

export function ClozeExercise({
  exercise,
  isReview,
  samples,
  history,
  game,
  articles,
  state,
}) {
  const { answer, questionLanguage, answerLanguage, isTranslation, cloze } =
    exercise

  const { isWrong } = history ?? {}

  const shouldShowAnswer = isWrong && !state.hasLearnedNewTerm

  const isDifferentLanguage = questionLanguage !== answerLanguage

  return (
    <div>
      {!shouldShowAnswer ? (
        <Timer
          onTimeout={() => {
            game.answer({ answer: null })
          }}
        />
      ) : null}
      <ExerciseHeader
        question={cloze}
        questionLanguage={questionLanguage}
        isReview={isReview}
        isTranslation={isTranslation}
        history={history}
        state={state}
        isDifferentLanguage={isDifferentLanguage}
        game={game}
      />
      {shouldShowAnswer ? (
        <>
          {isDifferentLanguage ? (
            <LanguageTag languageCode={answerLanguage} />
          ) : null}

          <Answer answer={answer} game={game} />

          <Explanation exercise={exercise} />

          <ExerciseArticles
            articles={articles}
            onArticleClick={articleId => {
              state.articleId = articleId
            }}
          />

          <NextButton
            onClick={() => {
              state.hasLearnedNewTerm = true
            }}
          />
        </>
      ) : (
        <MultipleChoiceQuestion
          exercise={exercise}
          samples={samples}
          onAnswer={game.answer}
          game={game}
        />
      )}
    </div>
  )
}
