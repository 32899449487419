import { ClientBoardCollection } from '@/client/data'
import { Permission } from '@/common/constants/permissions'
import { useBoardId } from '@components/boards/hooks/use-board-id'
import { ShareLink } from '@components/boards/share-link'
import { ShareWith, ShareWithInput } from '@components/boards/share-with'
import { NavButton } from '@components/nav-button'
import { Modal } from '@components/ui/modal'
import { useFindOne } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import { SegmentedControl } from '@mantine/core'
import { LucideEye, LucidePencil, LucideShare } from 'lucide-react'
import React, { useState } from 'react'

export function ShareModal() {
  const [isOpen, setOpen] = useState(false)
  const boardId = useBoardId()
  const board = useFindOne(ClientBoardCollection, { _id: boardId })
  const { userId } = useMetaboardAuth()

  const [permission, setPermission] = useState<string>(Permission.Read)

  if (!board) return null
  if (board.author !== userId) return null

  return (
    <>
      <NavButton
        icon={LucideShare}
        className='absolute right-4 top-32 z-[20000]'
        onClick={() => setOpen(true)}
      >
        <Trans>Share</Trans>
      </NavButton>
      <Modal
        onClose={() => setOpen(false)}
        isOpen={isOpen}
        title={<Trans>Share "{board.name}"</Trans>}
        className='space-y-4'
      >
        <SegmentedControl
          color='indigo'
          value={permission}
          onChange={setPermission}
          data={[
            {
              label: (
                <span className='flex items-center gap-1.5'>
                  <LucideEye size={16} /> <Trans>See</Trans>
                </span>
              ),
              value: Permission.Read,
            },
            {
              label: (
                <span className='flex items-center gap-1.5'>
                  <LucidePencil size={16} /> <Trans>Edit</Trans>
                </span>
              ),
              value: Permission.ReadWrite,
            },
          ]}
        />
        <ShareWithInput permission={permission} />

        <ShareLink permission={permission} />

        <ShareWith />
      </Modal>
    </>
  )
}
