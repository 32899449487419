import first from 'lodash/first'
import { useCallback } from 'react'
import { useObject } from '@helenejs/react'

export const BaseOrderInterval = 4096

export function useOrderable(collection) {
  const getNextInOrder = useCallback(
    async function (order) {
      return first(
        await collection
          .find({ order: { $gt: order }, removedAt: { $exists: false } })
          .sort({ order: 1 }),
      )
    },
    [collection],
  )

  const getPreviousInOrder = useCallback(
    async function (order) {
      return first(
        await collection
          .find({ order: { $lt: order }, removedAt: { $exists: false } })
          .sort({ order: -1 }),
      )
    },
    [collection],
  )

  const moveBefore = useCallback(
    async function (activeId, overId) {
      const over = await collection.findOne({ _id: overId })
      const previous = await getPreviousInOrder(over.order)

      const interval = previous
        ? over.order - (over.order - previous.order) / 2
        : over.order / 2

      await collection.update({ _id: activeId }, { $set: { order: interval } })
    },
    [collection, getPreviousInOrder],
  )

  const moveAfter = useCallback(
    async function (activeId, overId) {
      const over = await collection.findOne({ _id: overId })
      const next = await getNextInOrder(over.order)

      const interval = next
        ? over.order + (next.order - over.order) / 2
        : over.order + BaseOrderInterval

      await collection.update({ _id: activeId }, { $set: { order: interval } })
    },
    [collection, getNextInOrder],
  )

  return useObject({
    getNextInOrder,
    getPreviousInOrder,
    moveBefore,
    moveAfter,
  })
}
