import { MainLayout } from '@components/layouts/main-layout'
import React from 'react'
import { Route } from 'react-router-dom'

type PublicRouteProps = {
  component: React.ComponentType & { layoutProps?: Record<string, any> }
  layout?: React.ComponentType<any>
} & React.ComponentProps<typeof Route>

export function PublicRoute({
  component: Component,
  layout: Layout = MainLayout,
  ...rest
}: PublicRouteProps) {
  return (
    <Route
      {...rest}
      render={props =>
        Layout ? (
          <Layout {...Component.layoutProps}>
            <Component {...props} />
          </Layout>
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}
