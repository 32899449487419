import { Board } from '@/common/constants/boards'
import { OrgSwitcherField } from '@components/org/org-switcher'
import { TeamSwitcherField } from '@components/org/team-switcher'
import { FormGroup } from '@components/ui/forms/form-group'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useRoles } from '@hooks/use-roles'
import { t, Trans } from '@lingui/macro'
import { Alert } from '@mantine/core'
import { ObjectId } from 'bson'
import { useField, useFormikContext } from 'formik'
import { LucideLoader, LucideLock } from 'lucide-react'
import React, { useEffect } from 'react'

export function BoardOrganizationSwitcher({ board }: { board: Board<string> }) {
  const formik = useFormikContext()
  const { userId } = useMetaboardAuth()
  const roles = useRoles()

  const [{ value: orgId }] = useField('org')
  const [{ value: teams }, , helper] = useField('teams')

  useEffect(() => {
    if (formik.dirty) {
      helper.setValue(null)
    }
  }, [orgId])

  const isOrg = ObjectId.isValid(orgId)

  if (roles.loading) return <LucideLoader className='animate-spin' size={16} />
  if (roles.result.length === 0) return null
  if (board.author !== userId) return null

  return (
    <>
      <FormGroup label={t`Organization`}>
        <OrgSwitcherField name='org' disabled={!!board.node} />
      </FormGroup>
      {isOrg ? (
        <FormGroup label={t`Teams`}>
          <TeamSwitcherField
            name='teams'
            orgId={orgId}
            disabled={!!board.node}
          />
        </FormGroup>
      ) : null}

      {isOrg && !teams ? (
        <Alert
          color='red'
          className='mb-4'
          title={<Trans>Private Board</Trans>}
          icon={<LucideLock size={16} />}
          variant='light'
        >
          <Trans>
            Boards are private by default when no teams are assigned to it, but
            still belongs to the organization
          </Trans>
        </Alert>
      ) : null}
    </>
  )
}
