import first from 'lodash/first'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'

export enum LimitType {
  Flashcards = 'Flashcards',
  ChatbotMessage = 'ChatbotMessage',
  CoverGeneration = 'CoverGeneration',
}

export const LimitPlan = {
  Free: 'Free',
  Premium: 'Premium',
}

export const LimitWindow = {
  Daily: 'Daily',
  Monthly: 'Monthly',
}

export const Limits = [
  {
    type: LimitType.Flashcards,
    plan: LimitPlan.Free,
    window: LimitWindow.Monthly,
    limit: 100,
  },
  {
    type: LimitType.Flashcards,
    plan: LimitPlan.Premium,
    window: LimitWindow.Monthly,
    limit: 1000,
  },
  {
    type: LimitType.ChatbotMessage,
    plan: LimitPlan.Premium,
    window: LimitWindow.Daily,
    limit: 100,
  },
  {
    type: LimitType.CoverGeneration,
    plan: LimitPlan.Free,
    window: LimitWindow.Daily,
    limit: 5,
  },
  {
    type: LimitType.CoverGeneration,
    plan: LimitPlan.Premium,
    window: LimitWindow.Daily,
    limit: 10,
  },
]

export const LimitsByPlanAndType = mapValues(groupBy(Limits, 'plan'), limits =>
  mapValues(groupBy(limits, 'type'), first),
)
