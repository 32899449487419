import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React from 'react'
import { SectionRow } from '@/client/components/deck-editor/section-row'

export const SortableSectionRow = ({
  index,
  section,
  sectionId,
  setSectionId,
  handleDeleteSection,
  disableDelete,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: section._id, data: { type: 'section', index } })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <SectionRow
      section={section}
      sectionId={sectionId}
      setSectionId={setSectionId}
      handleDeleteSection={handleDeleteSection}
      setNodeRef={setNodeRef}
      listeners={listeners}
      attributes={attributes}
      style={style}
      isDragging={isDragging}
      disableDelete={disableDelete}
    />
  )
}
