import { ClientBoardNodeCollection } from '@/client/data'
import { roundToGrid } from '@/common/boards/constraints'
import { NodeType } from '@/common/constants/boards'
import { BoardOperations } from '@components/boards/hooks/use-board-operations'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import {
  getImageDimensions,
  uploadBoardFile,
} from '@components/boards/utils/image'
import {
  fromVirtualX,
  fromVirtualY,
} from '@components/boards/utils/virtualization'
import { Client } from '@helenejs/client'
import defer from 'lodash/defer'
import React from 'react'

export async function pasteImage({
  client,
  boardId,
  xRef,
  yRef,
  file,
  bounds,
  operations,
}: {
  client: Client
  boardId: string
  xRef: React.MutableRefObject<number>
  yRef: React.MutableRefObject<number>
  file: File
  bounds: VirtualBounds
  operations: BoardOperations
}) {
  const text = file.name

  const { width, height, temporaryUrl } = await getImageDimensions(file)

  const nodeId = await operations.addNode(
    fromVirtualX(xRef.current, bounds),
    fromVirtualY(yRef.current, bounds),
    {
      type: NodeType.Image,
      name: text,
      width: roundToGrid(width),
      height: roundToGrid(height),
    },
  )

  defer(() => {
    ClientBoardNodeCollection.update(
      { _id: nodeId },
      {
        $set: {
          _temporaryUrl: temporaryUrl,
        },
      },
    ).catch(console.error)
  })

  const image = await uploadBoardFile(client, boardId, file, () => {
    operations.deleteNode(nodeId)
  })

  operations.updateNode(nodeId, {
    image,
  })
}
