import { BoardNode, NodeType } from '@/common/constants/boards'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { isUrl } from '@components/boards/utils/is-url'
import { processLink } from '@components/boards/utils/links'
import { RichTextField } from '@components/fields/rich-text-field'
import { StandardForm } from '@components/forms/standard-form'
import { URLInputField } from '@components/mantine-ui/fields/url-input-field'
import { RichText } from '@components/rich-text/rich-text-editor'
import { useClient } from '@helenejs/react'
import { t } from '@lingui/macro'
import useDebounceFn from 'ahooks/lib/useDebounceFn'
import React, { useMemo } from 'react'
import { object, string } from 'yup'

type NodeTextEditorProps = {
  node: BoardNode<string>
}

export function NodeTextEditor({ node }: NodeTextEditorProps) {
  const client = useClient()
  const { isWritable } = useBoardState()

  const initialValues = useMemo(() => node, [node?._id])

  const operations = useBoardOperations()

  const onSave = useDebounceFn(
    async ({ content, url }) => {
      if (!node._id) {
        throw new Error('No Node ID')
      }

      if (node.content !== content) {
        operations.updateNode(node._id, {
          content,
        })
      }

      if (node.type === NodeType.URL && isUrl(url) && url !== node.url) {
        await processLink({
          client,
          boardId: node.board,
          nodeId: node._id,
          link: url,
          operations,
        })
      }
    },
    {
      wait: 1000,
    },
  )

  return isWritable ? (
    <StandardForm
      initialValues={initialValues}
      onSave={onSave.run}
      schema={object({
        content: string(),
        url: string().url(),
      })}
      autoSave
    >
      <div className='space-y-4 p-4'>
        {node.type === NodeType.URL ? <URLInputField name='url' /> : null}

        <RichTextField
          name='content'
          placeholder={t`Write something...`}
          raw
          toolbar={{
            sticky: true,
          }}
        />
      </div>
    </StandardForm>
  ) : (
    <RichText content={node.content} editable={false} />
  )
}
