import { MetaboardEvent } from '@/common/events'
import { FunkyButton } from '@components/ui/funky-button'
import { Modal } from '@components/ui/modal'
import { Popover } from '@headlessui/react'
import { useClient, useLocalEvent } from '@helenejs/react'
import { DevicePhoneMobileIcon } from '@heroicons/react/24/outline'
import { Trans } from '@lingui/macro'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import React, { useState } from 'react'

const userAgent = window.navigator.userAgent

const isStandaloneAlready =
  window.matchMedia('(display-mode: standalone)').matches ||
  window.navigator.standalone === true

const isAndroid = userAgent.includes('Android')
const isIOS = userAgent.includes('iPhone') || userAgent.includes('iPad')

export function InstallModal() {
  const [isOpen, setOpen] = useState(false)

  useLocalEvent({ event: 'app:install' }, () => {
    setOpen(true)
  })

  return (
    <Modal onClose={setOpen} isOpen={isOpen}>
      <div className='px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
        <div className='sm:flex sm:items-start'>
          <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10'>
            <DevicePhoneMobileIcon
              className='h-6 w-6 text-blue-600'
              aria-hidden='true'
            />
          </div>
          <div className='mt-3 sm:ml-4 sm:mt-0 sm:text-left'>
            <h3 className='text-lg font-medium leading-6'>
              <Trans>Install App</Trans>
            </h3>
            <div className='mt-2 text-sm'>
              <p>
                <Trans>
                  You can have Metaboard on your home screen. This will make it
                  easier and more fun to access your favorite decks.
                </Trans>
              </p>

              {isAndroid ? (
                <p>
                  <Trans>
                    On Android/Chrome, click the menu button and select "Add to
                    Home Screen".
                  </Trans>
                </p>
              ) : null}

              {isIOS ? (
                <p>
                  <Trans>
                    If you are on iPhone/Safari, click the Share button and
                    select "Add to Home Screen". May only work on Safari.
                  </Trans>
                </p>
              ) : null}

              <div className='mt-4 text-center'>
                <FunkyButton variant='info' onClick={() => setOpen(false)}>
                  Close
                </FunkyButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export function AddToHome() {
  const client = useClient()

  if (isStandaloneAlready) return null

  return (
    <Popover.Button
      onClick={() => {
        if (!window.deferredPrompt) {
          client.emit(MetaboardEvent.APP_INSTALL)
          return
        }

        window.deferredPrompt.prompt()

        window.deferredPrompt.userChoice.then(function (choiceResult) {
          console.log({ choiceResult })
          window.deferredPrompt = null
        })
      }}
      className='flex items-center rounded-md p-3 hover:bg-gray-50'
    >
      <DevicePhoneMobileIcon
        className='h-5 w-5 flex-shrink-0 text-blue-600'
        aria-hidden='true'
      />
      <span className='ml-3 flex items-center gap-1.5 text-base font-medium text-gray-900'>
        <Trans>How to Install</Trans>
        <QuestionMarkCircledIcon className='h-4 w-4 text-gray-500' />
      </span>
    </Popover.Button>
  )
}
