import React from 'react'
import { useField } from 'formik'
import { Radio } from '@components/ui/forms/radio'
import { FieldOutline } from '@components/ui/forms/field-outline'

export function RadioField({ name, ...props }) {
  const [field, meta, helper] = useField(name)

  return (
    <FieldOutline meta={meta}>
      <Radio onChange={helper.setValue} value={field.value} {...props} />
    </FieldOutline>
  )
}
