import React from 'react'
import { ExerciseHeader } from '../exercise-header'
import { ExerciseArticles } from '../exercise-articles'
import { NextButton } from '../next-button'
import { ucfirst } from '@/common/string-utils'
import { TrueOrFalseQuestion } from '../../questions/true-or-false-question'
import { Explanation } from '../explanation'
import { Timer } from '@components/practice/timer'

export function TrueOrFalseExercise({
  exercise,
  articles,
  isNew,
  isReview,
  game,
  history,
  state,
}) {
  const { question, questionLanguage, isTrue, isTranslation } = exercise

  const { isWrong } = history ?? {}

  const shouldShowAnswer = isWrong || isNew

  return (
    <div>
      {!shouldShowAnswer ? (
        <Timer
          onTimeout={() => {
            game.answer({ answer: null })
          }}
        />
      ) : null}
      <ExerciseHeader
        question={question}
        questionLanguage={questionLanguage}
        isReview={isReview}
        isTranslation={isTranslation}
        history={history}
        state={state}
        isDifferentLanguage={false}
        game={game}
      />
      {shouldShowAnswer && !state.hasLearnedNewTerm ? (
        <div>
          <div className='mb-6 text-lg font-medium italic dark:text-slate-300'>
            {ucfirst(JSON.stringify(isTrue))}
          </div>

          <Explanation exercise={exercise} />

          <ExerciseArticles
            articles={articles}
            onArticleClick={articleId => {
              state.articleId = articleId
            }}
          />

          <NextButton
            onClick={() => {
              state.hasLearnedNewTerm = true
            }}
          />
        </div>
      ) : (
        <TrueOrFalseQuestion onAnswer={game.answer} />
      )}
    </div>
  )
}
