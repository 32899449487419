import { cn } from '@/client/utils/cn'
import { ColorMap } from '@/common/boards/color-map'
import { minHeight, minWidth, roundToGrid } from '@/common/boards/constraints'
import { BoardNode, FontSize, NodeType } from '@/common/constants/boards'
import { Dimensions } from '@components/boards/utils/types'
import { ClassValue } from 'clsx'

export function getNodeClasses(
  {
    dragging = false,
    isSelected = false,
    node,
  }: {
    dragging: boolean
    isSelected: boolean
    node: Partial<BoardNode<string>>
  },
  ...className: ClassValue[]
) {
  return cn(
    'bg-white/30 text-black/80 dark:bg-white/30 dark:text-white',
    'hover:text-black/100 hover:dark:text-white/100',
    'absolute flex select-none items-center justify-center overflow-hidden',
    'rounded-md p-2 text-center text-xs font-medium',
    'group border-8 border-transparent bg-cover hover:shadow-lg',
    ColorMap[node.color],
    {
      'border-0 bg-transparent dark:bg-transparent':
        node.type === NodeType.Image &&
        (node.image || node._temporaryUrl) &&
        !node.color &&
        !isSelected,
      'text-xs': !node.fontSize || node.fontSize === FontSize.Small,
      'text-sm': node.fontSize === FontSize.Medium,
      'text-md': node.fontSize === FontSize.Large,
    },
    {
      'cursor-pointer': !dragging,
      'cursor-grabbing': dragging,
      'border-2 border-blue-500': isSelected,
      'border-2 border-transparent': !isSelected,
      'border-2 border-blue-400': dragging,
    },
    ...className,
  )
}

export function getNodeDimensions(
  node: Partial<BoardNode<string>>,
  text: string,
): Dimensions {
  const tempElement = document.createElement('div')
  tempElement.style.position = 'absolute'
  tempElement.style.visibility = 'hidden'
  tempElement.textContent = text
  tempElement.style.maxWidth = '368px'
  tempElement.className = getNodeClasses({
    dragging: false,
    isSelected: false,
    node,
  })

  const iconElement = document.createElement('div')
  iconElement.className = 'w-6 h-6'

  tempElement.appendChild(iconElement)

  document.body.appendChild(tempElement)

  const width = Math.max(roundToGrid(tempElement.offsetWidth), minWidth)
  const height = Math.max(roundToGrid(tempElement.offsetHeight), minHeight)

  document.body.removeChild(tempElement)

  return { width, height }
}
