import { cn } from '@/client/utils/cn'
import * as RadioGroup from '@radix-ui/react-radio-group'
import React from 'react'

export type RadioListItem = {
  value: string
  name: string
  description?: string
  disabled?: boolean
  className?: string
}

type RadioListProps = {
  items: RadioListItem[]
  value: string
  onChange: (value: string) => void
}
export function RadioList({ items, value, onChange }: RadioListProps) {
  return (
    <div>
      <RadioGroup.Root value={value} onValueChange={onChange}>
        <div className='-space-y-px rounded-md bg-white dark:bg-slate-700'>
          {items.map((item, index) => (
            <label
              key={item.value}
              className={cn(
                'relative flex border p-3 focus:outline-none',
                {
                  'border-indigo-200 bg-indigo-50 dark:border-indigo-400 dark:bg-slate-600':
                    value === item.value,
                  'border-gray-200 dark:border-slate-500': value !== item.value,
                  'cursor-not-allowed bg-gray-50 dark:bg-slate-600':
                    item.disabled,
                  'cursor-pointer': !item.disabled,
                  'rounded-tl-md rounded-tr-md': index === 0,
                  'rounded-bl-md rounded-br-md': index === items.length - 1,
                },
                item.className,
              )}
            >
              <RadioGroup.Item value={item.value} disabled={item.disabled} />

              <div
                className={cn(
                  value === item.value
                    ? 'border-transparent bg-indigo-400'
                    : 'border-gray-300 bg-white',
                  'mt-0.5 flex h-3 w-3 shrink-0 items-center justify-center rounded-full border',
                  {
                    'cursor-not-allowed': item.disabled,
                    'cursor-pointer': !item.disabled,
                  },
                )}
                aria-hidden='true'
              >
                <span className='h-1.5 w-1.5 rounded-full bg-white' />
              </div>

              <div className='ml-3 flex flex-col'>
                <span
                  className={cn(
                    'flex items-center gap-x-1.5 text-sm font-medium',
                    {
                      'text-indigo-900 dark:text-indigo-300':
                        value === item.value,
                      'text-gray-600 dark:text-gray-200':
                        value !== item.value && !item.disabled,
                      'text-gray-300 dark:text-gray-400': item.disabled,
                    },
                  )}
                >
                  {item.name}
                </span>
                <span
                  className={cn('block text-sm opacity-70', {
                    'text-indigo-700 dark:text-indigo-300':
                      value === item.value,
                    'text-gray-500 dark:text-gray-100':
                      value !== item.value && !item.disabled,
                    'text-gray-300 dark:text-gray-400': item.disabled,
                  })}
                >
                  {item.description}
                </span>
              </div>
            </label>
          ))}
        </div>
      </RadioGroup.Root>
    </div>
  )
}
