import { BaseColor } from '@/common/constants/color'

export const ColorMap = {
  [BaseColor.Red]:
    'bg-red-500/50 dark:bg-red-500/50 text-red-800 dark:text-red-200 fill-red-500 dark:fill-red-500 hover:text-red-900 dark:hover:text-red-100',
  [BaseColor.Orange]:
    'bg-orange-500/50 dark:bg-orange-500/50 text-orange-800 dark:text-orange-200 fill-orange-500 dark:fill-orange-500 hover:text-orange-900 dark:hover:text-orange-100',
  [BaseColor.Amber]:
    'bg-amber-500/50 dark:bg-amber-500/50 text-amber-800 dark:text-amber-200 fill-amber-500 dark:fill-amber-500 hover:text-amber-900 dark:hover:text-amber-100',
  [BaseColor.Yellow]:
    'bg-yellow-500/50 dark:bg-yellow-500/50 text-yellow-800 dark:text-yellow-200 fill-yellow-500 dark:fill-yellow-500 hover:text-yellow-900 dark:hover:text-yellow-100',
  [BaseColor.Lime]:
    'bg-lime-500/50 dark:bg-lime-500/50 text-lime-800 dark:text-lime-200 fill-lime-500 dark:fill-lime-500 hover:text-lime-900 dark:hover:text-lime-100',
  [BaseColor.Green]:
    'bg-green-500/50 dark:bg-green-500/50 text-green-800 dark:text-green-200 fill-green-500 dark:fill-green-500 hover:text-green-900 dark:hover:text-green-100',
  [BaseColor.Emerald]:
    'bg-emerald-500/50 dark:bg-emerald-500/50 text-emerald-800 dark:text-emerald-200 fill-emerald-500 dark:fill-emerald-500 hover:text-emerald-900 dark:hover:text-emerald-100',
  [BaseColor.Teal]:
    'bg-teal-500/50 dark:bg-teal-500/50 text-teal-800 dark:text-teal-200 fill-teal-500 dark:fill-teal-500 hover:text-teal-900 dark:hover:text-teal-100',
  [BaseColor.Cyan]:
    'bg-cyan-500/50 dark:bg-cyan-500/50 text-cyan-800 dark:text-cyan-200 fill-cyan-500 dark:fill-cyan-500 hover:text-cyan-900 dark:hover:text-cyan-100',
  [BaseColor.Sky]:
    'bg-sky-500/50 dark:bg-sky-500/50 text-sky-800 dark:text-sky-200 fill-sky-500 dark:fill-sky-500 hover:text-sky-900 dark:hover:text-sky-100',
  [BaseColor.Blue]:
    'bg-blue-500/50 dark:bg-blue-500/50 text-blue-800 dark:text-blue-200 fill-blue-500 dark:fill-blue-500 hover:text-blue-900 dark:hover:text-blue-100',
  [BaseColor.Indigo]:
    'bg-indigo-500/50 dark:bg-indigo-500/50 text-indigo-800 dark:text-indigo-200 fill-indigo-500 dark:fill-indigo-500 hover:text-indigo-900 dark:hover:text-indigo-100',
  [BaseColor.Violet]:
    'bg-violet-500/50 dark:bg-violet-500/50 text-violet-800 dark:text-violet-200 fill-violet-500 dark:fill-violet-500 hover:text-violet-900 dark:hover:text-violet-100',
  [BaseColor.Purple]:
    'bg-purple-500/50 dark:bg-purple-500/50 text-purple-800 dark:text-purple-200 fill-purple-500 dark:fill-purple-500 hover:text-purple-900 dark:hover:text-purple-100',
  [BaseColor.Fuchsia]:
    'bg-fuchsia-500/50 dark:bg-fuchsia-500/50 text-fuchsia-800 dark:text-fuchsia-200 fill-fuchsia-500 dark:fill-fuchsia-500 hover:text-fuchsia-900 dark:hover:text-fuchsia-100',
  [BaseColor.Pink]:
    'bg-pink-500/50 dark:bg-pink-500/50 text-pink-800 dark:text-pink-200 fill-pink-500 dark:fill-pink-500 hover:text-pink-900 dark:hover:text-pink-100',
  [BaseColor.Rose]:
    'bg-rose-500/50 dark:bg-rose-500/50 text-rose-800 dark:text-rose-200 fill-rose-500 dark:fill-rose-500 hover:text-rose-900 dark:hover:text-rose-100',
  [BaseColor.Neutral]:
    'bg-neutral-500/50 dark:bg-neutral-500/50 text-neutral-800 dark:text-neutral-200 fill-neutral-500 dark:fill-neutral-500 hover:text-neutral-900 dark:hover:text-neutral-100',
  [BaseColor.Gray]:
    'bg-gray-500/50 dark:bg-gray-500/50 text-gray-800 dark:text-gray-200 fill-gray-500 dark:fill-gray-500 hover:text-gray-900 dark:hover:text-gray-100',
  [BaseColor.Slate]:
    'bg-slate-500/50 dark:bg-slate-500/50 text-slate-800 dark:text-slate-200 fill-slate-500 dark:fill-slate-500 hover:text-slate-900 dark:hover:text-slate-100',
  [BaseColor.Stone]:
    'bg-stone-500/50 dark:bg-stone-500/50 text-stone-800 dark:text-stone-200 fill-stone-500 dark:fill-stone-500 hover:text-stone-900 dark:hover:text-stone-100',
  [BaseColor.Zinc]:
    'bg-zinc-500/50 dark:bg-zinc-500/50 text-zinc-800 dark:text-zinc-200 fill-zinc-500 dark:fill-zinc-500 hover:text-zinc-900 dark:hover:text-zinc-100',
}
