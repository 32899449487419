import { Trans } from '@lingui/macro'
import { DeckSectionAdder } from '@/client/components/deck-editor/deck-section-adder'
import React, { useEffect, useState } from 'react'
import { useOrderable } from '@/client/components/deck-editor/use-orderable'
import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { SectionRow } from '@/client/components/deck-editor/section-row'
import { SortableSectionRow } from '@/client/components/deck-editor/sortable-section-row'
import {
  ClientExerciseCollection,
  ClientSectionCollection,
} from '@/client/data'

export function DeckSectionNavigator({
  currentSection,
  sections,
  sectionId,
  setSectionId,
}) {
  const [buffer, setBuffer] = useState([])
  const [activeSection, setActiveSection] = useState(null)

  useEffect(() => {
    setBuffer(sections)
  }, [sections])

  const { getNextInOrder, getPreviousInOrder, moveAfter, moveBefore } =
    useOrderable(ClientSectionCollection)

  async function goToClosestSection(section) {
    const next = await getNextInOrder(section.order)

    if (next) {
      setSectionId(next._id)
    } else {
      const previous = await getPreviousInOrder(section.order)

      if (previous) {
        setSectionId(previous._id)
      } else {
        setSectionId(null)
      }
    }
  }

  async function handleDeleteSection(e, section) {
    e.stopPropagation()

    if (currentSection?._id === section._id) {
      await goToClosestSection(section)
    }

    if (section._new) {
      await ClientSectionCollection.remove({ _id: section._id })
    } else {
      await ClientSectionCollection.update(
        { _id: section._id },
        { $set: { removedAt: new Date() } },
      )
    }

    await ClientExerciseCollection.remove(
      { section: section._id, _new: true },
      { multi: true },
    )
    await ClientExerciseCollection.update(
      { section: section._id },
      { $set: { removedAt: new Date() } },
      { multi: true },
    )
  }

  async function handleDragStart(event) {
    const { active } = event

    const doc = await ClientSectionCollection.findOne({ _id: active.id })

    setActiveSection(doc)
  }

  return (
    <ul className='menu rounded-box m-0 overflow-hidden p-0'>
      <li className='m-0 box-border max-w-full p-0'>
        <h2 className='menu-title'>
          <Trans>Sections</Trans>
        </h2>
        <ul className='m-0 box-border max-w-full'>
          <DndContext
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={async event => {
              const { active, over } = event

              if (active.id === over.id) return

              const deltaY = event.delta.y

              if (deltaY > 0) {
                setBuffer(buffer =>
                  arrayMove(
                    buffer,
                    active.data.current.index,
                    over.data.current.index,
                  ),
                )

                moveAfter(active.id, over.id).catch(console.error)
              } else {
                setBuffer(buffer =>
                  arrayMove(
                    buffer,
                    active.data.current.index,
                    over.data.current.index,
                  ),
                )

                moveBefore(active.id, over.id).catch(console.error)
              }

              setActiveSection(null)
            }}
          >
            <SortableContext
              items={buffer.map(({ _id }) => _id)}
              strategy={verticalListSortingStrategy}
            >
              {buffer.map((section, index) => (
                <SortableSectionRow
                  key={section._id}
                  index={index}
                  section={section}
                  sectionId={sectionId}
                  setSectionId={setSectionId}
                  handleDeleteSection={handleDeleteSection}
                  disableDelete={sections.length === 1}
                />
              ))}
            </SortableContext>
            <DragOverlay>
              {activeSection ? <SectionRow section={activeSection} /> : null}
            </DragOverlay>
          </DndContext>
          <DeckSectionAdder />
        </ul>
      </li>
    </ul>
  )
}
