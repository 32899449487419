import { cn } from '@/client/utils/cn'
import { useTheme } from '@hooks/use-theme'
import { Button, CopyButton } from '@mantine/core'
import { IconClipboardCheck, IconCopy } from '@tabler/icons-react'
import React from 'react'
import ReactMarkdown from 'react-markdown'

import '@/client/assets/markdown-light.scss'
import '@/client/assets/markdown-dark.scss'

type Props = {
  children: string
  className?: string
}

export function Markdown({ children, className }: Props) {
  const theme = useTheme()

  const MarkdownComponents = {
    pre({ children }) {
      return <pre className='relative'>{children}</pre>
    },

    code({ node, className, children, ...props }) {
      return (
        <code className={className} {...props}>
          {children}

          <CopyButton value={children?.trim?.() ?? ''}>
            {({ copied, copy }) => (
              <Button
                color='gray'
                onClick={copy}
                className='absolute right-1 top-1'
                variant='transparent'
              >
                {copied ? (
                  <IconClipboardCheck size={16} />
                ) : (
                  <IconCopy size={16} />
                )}
              </Button>
            )}
          </CopyButton>
        </code>
      )
    },
  }

  return (
    <ReactMarkdown
      components={MarkdownComponents}
      className={cn(
        'markdown max-w-full',
        {
          'markdown-dark': theme.isDark,
          'markdown-light': theme.isLight,
        },
        className,
      )}
    >
      {children}
    </ReactMarkdown>
  )
}
