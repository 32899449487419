import wizard from '@/client/assets/wizard.png'
import { Button } from '@/client/components/ui/button'
import { MetaboardEvent } from '@/common/events'
import { KBD } from '@components/ui/kbd'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import anime from 'animejs'
import { ArrowLeft, LucidePlay } from 'lucide-react'
import React, { useLayoutEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

export function FinishedSession({ game, state }) {
  const { authenticated, client } = useMetaboardAuth()

  useHotkeys(['enter', 'space'], () => {
    game.restart()
  })

  useLayoutEffect(() => {
    const animation = anime({
      targets: '.floating',
      translateY: -10,
      easing: 'easeInOutSine',
      direction: 'alternate',
      duration: 1000,
      loop: true,
    })

    return () => {
      animation.pause()
      animation.reset()
      animation.remove('.floating')
    }
  }, [])

  return (
    <div className='text-center'>
      <img
        src={wizard}
        alt='Wizard'
        className='floating mx-auto h-[256px] w-[256px]'
      />
      <div className='mb-4'>
        <div className='space-y-2 rounded-md p-2 text-sm text-slate-800 dark:text-slate-200'>
          <p className='text-lg font-medium'>
            <Trans>Session Stats</Trans>
          </p>
          <table className='mx-auto w-full max-w-[260px]'>
            <tbody>
              <tr>
                <td className='text-left'>
                  <Trans>Perfect Answers:</Trans>
                </td>
                <td className='text-right'>{state.correctCount}</td>
              </tr>
              <tr>
                <td className='text-left'>
                  <Trans>Incorrect Answers:</Trans>
                </td>
                <td className='text-right'>{state.incorrectCount}</td>
              </tr>
              <tr>
                <td className='text-left'>
                  <Trans>XP Earned:</Trans>
                </td>
                <td className='text-right'>
                  {Number(state.xpEarned).toLocaleString()} XP
                </td>
              </tr>
              <tr>
                <td className='text-left'>
                  <Trans>Combo Bonus:</Trans>
                </td>
                <td className='text-right'>
                  {Number(state.bonusXpEarned).toLocaleString()} XP
                </td>
              </tr>
              <tr>
                <td className='text-left'>
                  <Trans>Total:</Trans>
                </td>
                <td className='text-right font-bold'>
                  {Number(
                    state.xpEarned + state.bonusXpEarned,
                  ).toLocaleString()}{' '}
                  XP
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='mb-4'>
        {authenticated ? (
          <Button
            icon={LucidePlay}
            onClick={() => {
              game.restart()
            }}
            size='lg'
            rounded={false}
          >
            <Trans>Next Session</Trans>
            <KBD>
              <Trans>Space</Trans>
            </KBD>
          </Button>
        ) : (
          <Button
            arrow='right'
            onClick={() => client.emit(MetaboardEvent.SIGN_IN)}
          >
            <Trans>Sign In</Trans>
          </Button>
        )}
      </div>

      {authenticated ? (
        <Button to='/' icon={ArrowLeft} variant='ghost'>
          <Trans>My Decks</Trans>
        </Button>
      ) : null}
    </div>
  )
}
