import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'

export type SimplePoint = { x: number; y: number }

export type SimpleDimensions = { width: number; height: number }

export function fromAbsX(x: number, bounds: VirtualBounds): number {
  return x - bounds.minX
}

export function fromAbsY(y: number, bounds: VirtualBounds): number {
  return y - bounds.minY
}

export function fromVirtualX(x: number, bounds: VirtualBounds): number {
  return x + bounds.minX
}

export function fromVirtualY(y: number, bounds: VirtualBounds): number {
  return y + bounds.minY
}
