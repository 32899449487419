import React, { useMemo } from 'react'
import { UrlHelper } from '@/common/url-helper'
import { Link } from 'react-router-dom'
import { PlaceholderIcon } from '@/client/components/placeholder-icon'
import { HorizontalLoader } from '@components/horizontal-loader'
import { useData } from '@helenejs/react'
import { Button } from '@/client/components/ui/button'
import { Trans } from '@lingui/macro'

export default function Articles() {
  const { data: articles, loading } = useData({
    method: 'article.listSortedByCreatedAt',
  })

  const { latest, featured } = useMemo(
    () => ({
      featured: articles?.slice(0, 3) ?? [],
      latest: articles?.slice(3) ?? [],
    }),
    [articles],
  )

  if (loading) return <HorizontalLoader />

  return (
    <div>
      <div className='mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4'>
        {featured.map(({ _id, title, slug, image, authorName, createdAt }) => {
          return (
            <div key={_id}>
              <div className='overflow-hidden rounded-lg bg-slate-300/50 shadow-xl dark:bg-slate-800/50'>
                <figure className='flex h-[224px] items-center justify-center overflow-hidden bg-gray-700 dark:bg-slate-600'>
                  {image ? (
                    <img
                      src={UrlHelper.getImageUrl(image, {
                        width: 640,
                        height: 480,
                        quality: 70,
                      })}
                      alt={title}
                      className='w-full'
                    />
                  ) : (
                    <PlaceholderIcon />
                  )}
                </figure>
                <div className='card-body p-4'>
                  <Link
                    className='card-title text-lg font-medium dark:text-slate-300'
                    to={`/article/${slug}`}
                  >
                    {title}
                  </Link>

                  <div className='card-actions items-center justify-start'>
                    <span>{createdAt.toLocaleDateString?.()}</span>

                    {authorName && (
                      <span className='ml-2 text-gray-500 dark:text-slate-400'>
                        by {authorName}
                      </span>
                    )}

                    <Button
                      to={`/article/${slug}`}
                      className='ml-auto uppercase'
                    >
                      <Trans>Read</Trans>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div className='grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-4'>
        {latest.map(({ _id, title, slug, image, createdAt }) => (
          <div key={_id}>
            <div className='flex overflow-hidden rounded rounded bg-slate-300/50 shadow dark:bg-slate-800/50'>
              <figure className='flex aspect-square h-24 shrink-0 items-center justify-center overflow-hidden bg-gray-700 dark:bg-slate-600'>
                {image ? (
                  <img
                    src={UrlHelper.getImageUrl(image, {
                      width: 192,
                      height: 192,
                      quality: 70,
                    })}
                    alt={title}
                    className='aspect-square'
                  />
                ) : (
                  <PlaceholderIcon />
                )}
              </figure>
              <div className='p-4'>
                <div className='mb-2'>
                  <Link
                    className='truncate font-medium dark:text-slate-300'
                    to={`/article/${slug}`}
                  >
                    {title}
                  </Link>
                </div>

                <div>
                  <span>{createdAt.toLocaleDateString?.()}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
