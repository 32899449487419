import { MyDeckLearnButton } from '@/client/components/decks/my-deck-learn-button'
import { ReviewCountdown } from '@/client/components/decks/review-countdown'
import { Dropdown } from '@/client/components/ui/dropdown'
import { cn } from '@/client/utils/cn'
import { MetaboardEvent } from '@/common/events'
import { EnrolledDeckImage } from '@components/decks/enrolled-deck-image'
import { ProgressBar } from '@components/ui/progress-bar'
import { Tag } from '@components/ui/tag'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useClient } from '@helenejs/react'
import { HeleneEvents } from '@helenejs/utils'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

export function EnrolledDeckCard({
  _id,
  title,
  image,
  slug,
  learnedCount,
  exerciseCount,
  review,
  reviewCount,
}) {
  const client = useClient()
  const { user, userId } = useMetaboardAuth()
  const history = useHistory()

  const progress = learnedCount / exerciseCount

  return (
    <div
      key={_id}
      className='flex flex-row items-center rounded-lg bg-white shadow dark:bg-slate-800/80'
    >
      {image ? (
        <div className='p-4'>
          <EnrolledDeckImage image={image} title={title} />
        </div>
      ) : null}
      <div
        className={cn('flex h-full min-w-0 flex-grow flex-col p-3', {
          'pl-0': !!image,
        })}
      >
        <div className='relative mb-3 min-h-[24px] min-w-0 grow-0 pr-[24px]'>
          <div className='grow-0 overflow-hidden truncate'>
            <Link
              to={`/deck/${slug}`}
              className='text-sm font-medium text-slate-800 hover:no-underline dark:text-slate-300'
            >
              {title}
            </Link>
          </div>

          <Dropdown
            className='absolute right-0 top-0 self-center'
            items={[
              {
                key: 'edit',
                onClick() {
                  history.push(`/decks/editor/${_id}`)
                },
                children: <Trans>Edit</Trans>,
                visible: !!user?.isAdmin,
              },
              {
                key: 'remove',
                async onClick() {
                  await client.call('deck.unenroll', {
                    deckId: _id,
                  })

                  client.emit(MetaboardEvent.UNENROLL_DECK, _id)
                },
                children: <Trans>Remove</Trans>,
              },
            ]}
            rightIcon={null}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </Dropdown>
        </div>

        {isNaN(progress) || (
          <div className='mb-3 flex items-center gap-2'>
            <ProgressBar className='flex-shrink' value={progress} />
            <div className='flex-grow whitespace-nowrap text-sm font-medium dark:text-slate-300'>
              {`${learnedCount} / ${exerciseCount}`}
            </div>
          </div>
        )}

        <div className='mt-auto flex items-center justify-end gap-2'>
          {reviewCount ? (
            <Tag color='amber'>
              {reviewCount} <Trans>for review</Trans>
            </Tag>
          ) : null}

          <ReviewCountdown
            review={review}
            reviewCount={reviewCount}
            onComplete={() =>
              client
                .channel(userId)
                .emit(HeleneEvents.METHOD_REFRESH, 'deck.listEnrolled')
            }
          />
          <MyDeckLearnButton
            {...{ deckId: _id, exerciseCount, reviewCount, learnedCount }}
          />
        </div>
      </div>
    </div>
  )
}
