import React, { Ref, useCallback } from 'react'

export function useMergedRefs<T>(...refs: Array<Ref<T>>): Ref<T> {
  return useCallback(
    (node: T | null) => {
      refs.forEach(ref => {
        if (typeof ref === 'function') {
          ref(node)
        } else if (ref != null) {
          ;(ref as React.MutableRefObject<T | null>).current = node
        }
      })
    },
    [refs],
  )
}
