import { useParams } from 'react-router-dom'
import { useMethod } from '@helenejs/react'
import { HorizontalLoader } from '@components/horizontal-loader'
import { DeckBuilder } from '@components/deck-editor/deck-builder'
import { useAsyncEffect } from 'ahooks'
import {
  ClientDeckCollection,
  ClientExerciseCollection,
  ClientSectionCollection,
} from '@/client/data'
import cloneDeep from 'lodash/cloneDeep'
import { Breadcrumbs } from '@/client/components/ui/breadcrumbs'
import { useState } from 'react'

// This makes it easy to revert changes
function populateOriginalProperty(data) {
  return data.map(doc => {
    return { ...doc, _original: cloneDeep(doc) }
  })
}

export function DeckEditor() {
  const params = useParams()

  const { result, loading, refresh } = useMethod({
    method: 'deck.dump',
    params: { deckId: params.deckId },
    required: ['deckId'],
    deps: [params.deckId],
  })

  const [persisting, setPersisting] = useState(true)

  // Initialize collections
  useAsyncEffect(async () => {
    if (!result) return

    setPersisting(true)

    const { deck, sections, exercises } = result

    await ClientDeckCollection.remove({}, { multi: true })
    await ClientSectionCollection.remove({}, { multi: true })
    await ClientExerciseCollection.remove({}, { multi: true })

    await ClientSectionCollection.insert(populateOriginalProperty(sections))
    await ClientExerciseCollection.insert(populateOriginalProperty(exercises))
    await ClientDeckCollection.insert(populateOriginalProperty([deck]))

    setPersisting(false)
  }, [result])

  if (loading || persisting) return <HorizontalLoader />

  return (
    <div className='mx-auto grid max-w-[1580px]'>
      <Breadcrumbs
        items={[{ label: 'Decks', to: '/decks' }, { label: 'Deck Editor' }]}
      />
      <DeckBuilder onSave={refresh} />
    </div>
  )
}
