import { MainLayout } from '@components/layouts/main-layout'
import React from 'react'
import { ProtectedRoute } from './protected-route'

export function AdminRoute(props) {
  return (
    <ProtectedRoute
      {...props}
      shouldAllow={({ user }) => user?.isAdmin}
      protectedLayout={MainLayout}
    />
  )
}
