import React from 'react'
import {
  ExerciseOrder,
  FontStyle,
  GameMode,
  NewCardBehavior,
} from '@/common/constants'
import { useField } from 'formik'
import { t } from '@lingui/macro'
import { FormGroup } from '@components/ui/forms/form-group'
import { RadioListField } from '@components/ui/forms/radio-list-field'

export function GameSettingsForm() {
  const [{ value: gameMode }] = useField('gameMode')

  const GameModes = [
    {
      value: GameMode.SESSION,
      name: t`Session`,
      description: t`Practice a specific number of exercises. Recommended for most people.`,
    },
    {
      value: GameMode.UNLIMITED,
      name: t`Unlimited`,
      description: t`Practice as many exercises as you can without stopping. Recommended for advanced users.`,
    },
  ]

  const SessionLengths = [
    {
      value: 5,
      name: t`5 exercises`,
    },
    {
      value: 10,
      name: t`10 exercises`,
    },
    {
      value: 25,
      name: t`25 exercises`,
    },
    {
      value: 50,
      name: t`50 exercises`,
    },
    {
      value: 100,
      name: t`100 exercises`,
    },
  ]

  const ExerciseOrders = [
    {
      value: ExerciseOrder.RANDOM,
      name: t`Random`,
      description: t`Exercises from anywhere in the deck can be shown at any time`,
    },
    {
      value: ExerciseOrder.SEQUENTIAL,
      name: t`Sequential`,
      description: t`Exercises are shown in the order they appear in the deck`,
    },
    {
      value: ExerciseOrder.COMPLEXITY,
      name: t`Complexity`,
      description: t`Exercises are shown in order of increasing complexity`,
    },
  ]

  const newCardBehavior = [
    {
      value: NewCardBehavior.ANSWER_FIRST,
      name: t`Answer First`,
      description: t`Show the answer before prompting the user to answer the card`,
    },
    {
      value: NewCardBehavior.QUESTION_FIRST,
      name: t`Question First`,
      description: t`Immediately prompt to answer the card without showing the answer`,
    },
  ]

  const fontStyle = [
    {
      value: FontStyle.SANS_SERIF,
      name: t`Sans Serif`,
    },
    {
      value: FontStyle.SERIF,
      name: t`Serif`,
      className: '!font-serif',
    },
    {
      value: FontStyle.MONOSPACE,
      name: t`Monospace`,
      className: '!font-mono',
    },
  ]

  return (
    <div>
      <FormGroup
        label={t`Font Style`}
        helperText={t`How do you want the font to look like?`}
      >
        <RadioListField name='fontStyle' items={fontStyle} />
      </FormGroup>

      <FormGroup label={t`Game Mode`} helperText={t`How is the game played?`}>
        <RadioListField name='gameMode' items={GameModes} />
      </FormGroup>

      {gameMode === GameMode.SESSION && (
        <FormGroup
          label={t`Session Length`}
          helperText={t`How many exercises are in a session?`}
        >
          <RadioListField name='sessionLength' items={SessionLengths} />
        </FormGroup>
      )}

      <FormGroup
        label={t`Exercise Ordering`}
        helperText={t`Which exercises are shown first?`}
      >
        <RadioListField name='exerciseOrder' items={ExerciseOrders} />
      </FormGroup>

      <FormGroup
        label={t`New Card Behavior`}
        helperText={t`What happens when a new card is shown?`}
      >
        <RadioListField name='newCardBehavior' items={newCardBehavior} />
      </FormGroup>
    </div>
  )
}
