import { ClientBoardEdgeCollection } from '@/client/data'
import { BoardEdge, BoardNode } from '@/common/constants/boards'
import { useAsyncMemo } from '@components/boards/hooks/use-async-memo'

export function useVisibleEdges(
  visibleNodes: BoardNode<string>[],
  edges: BoardEdge<string>[],
) {
  return useAsyncMemo(async () => {
    const nodeIds = visibleNodes.map(node => node._id)

    return ClientBoardEdgeCollection.find({
      $or: [{ source: { $in: nodeIds } }, { target: { $in: nodeIds } }],
    })
  }, [visibleNodes, edges])
}
