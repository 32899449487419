import { useMethod } from '@helenejs/react'

export function useDeck(deckId) {
  const { result: deck, loading } = useMethod({
    method: 'deck.get',
    params: { _id: deckId },
    deps: [deckId],
  })

  return { deck, loading }
}
