import { SmallAvatar } from '@/client/components/avatars/small-avatar'
import { Markdown } from '@/client/components/markdown'
import { LanguageCode } from '@/common/constants'
import { BotNameByLanguage } from '@/common/constants/bots'
import { ChatEvent } from '@/common/constants/chat'
import { VoiceId } from '@aws-sdk/client-polly'
import { PlayAudio } from '@components/chat/play-audio'
import { useClient, useLocalEvent } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import classnames from 'classnames'
import { format } from 'date-fns'
import defer from 'lodash/defer'
import { useState } from 'react'

export function Message({ _id, author, bot, message, sentAt, userId }) {
  const client = useClient()
  const [streamedMessage, setStreamedMessage] = useState(message ?? '')

  const { user } = useMetaboardAuth()

  useLocalEvent(
    {
      event: ChatEvent.MessageToken,
    },
    async ({ messageId, token }) => {
      if (messageId === _id) {
        defer(() => {
          setStreamedMessage(streamedMessage => streamedMessage + token)
        })
      }
    },
    [setStreamedMessage],
  )

  if (!streamedMessage) return null

  return (
    <div
      className={classnames('flex gap-2 px-2 py-2', {
        'flex-row-reverse': userId === author,
      })}
    >
      {bot ? (
        <img
          className='inline-block h-8 w-8 rounded-full object-cover'
          src='/profile-picture.png'
          alt={BotNameByLanguage[user.language ?? LanguageCode.EN]}
        />
      ) : (
        <SmallAvatar />
      )}
      <span
        className={classnames(
          'inline-block min-w-[100px] max-w-[75%] rounded-lg p-2 text-black lg:max-w-[48%]',
          {
            'bg-gray-200 dark:bg-slate-400': userId !== author,
            'bg-green-100': userId === author,
          },
        )}
      >
        <div className='text-[14px] font-medium'>
          <Markdown>{streamedMessage}</Markdown>
        </div>

        <div className='mt-1 flex items-center justify-end gap-2'>
          <span className='text-[12px] text-gray-500'>
            {format(sentAt, 'HH:mm')}
          </span>

          {bot ? (
            <PlayAudio
              languageCode={user?.language ?? LanguageCode.EN}
              text={message}
              shouldAutoPlay={false}
              onFinishedPlaying={async () => {
                await client.call('chat.markAsRead', {
                  messageId: _id,
                })
              }}
              voiceId={VoiceId.Matthew}
            />
          ) : null}
        </div>
      </span>
    </div>
  )
}
