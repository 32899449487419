import React from 'react'
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfWeek,
  format,
  getMonth,
  getYear,
  startOfWeek,
  subYears,
} from 'date-fns'
import { motion } from 'framer-motion'
import last from 'lodash/last'
import reverse from 'lodash/reverse'
import uniq from 'lodash/uniq'
import classnames from 'classnames'
import { useCreation } from 'ahooks'
import { useMethod } from '@helenejs/react'
import { Trans } from '@lingui/macro'
import { Spinner } from '@components/spinner'

const generateColor = (count, max) => {
  if (!count) {
    return 'bg-slate-300 dark:bg-slate-400'
  }
  const intensity = Math.ceil((count / max) * 6)

  return [
    'bg-indigo-300',
    'bg-indigo-400',
    'bg-indigo-500',
    'bg-indigo-600',
    'bg-indigo-700',
    'bg-indigo-800',
  ][intensity - 1]
}

const MonthLabel = ({ children, span }) => {
  return (
    <div
      className='relative pl-2 text-center text-[9px] font-medium text-gray-400 dark:text-slate-400'
      style={{
        gridColumn: `span ${span}`,
      }}
    >
      {span >= 2 ? children : null}
    </div>
  )
}

const getMonthKey = date => {
  const month = getMonth(date)
  const year = getYear(date)
  return `${year}-${month}`
}

export const YearlyHeatmap = ({ userId, className }) => {
  const { result: stats, loading } = useMethod({
    method: 'stats',
    params: { userId, timezoneOffset: new Date().getTimezoneOffset() },
    authenticated: true,
    defaultValue: [],
    deps: [userId],
  })

  const WeekDays = () => {
    const weekDays = [
      { name: 'Mon', top: 15 },
      { name: 'Wed', top: 45 },
      { name: 'Fri', top: 75 },
    ]
    return (
      <div style={{ position: 'relative' }}>
        {weekDays.map(day => (
          <div
            key={day.name}
            style={{
              position: 'absolute',
              top: `${day.top}px`,
            }}
            className='text-center text-[8px] font-medium text-gray-400 dark:text-slate-400'
          >
            {day.name}
          </div>
        ))}
      </div>
    )
  }

  const { data, total } = useCreation(() => {
    let total = 0

    const data = stats.reduce((acc, { date, correctCount }) => {
      total += correctCount
      acc[date] = correctCount
      return acc
    }, {})

    return { data, total }
  }, [stats])

  const today = new Date()
  const todayKey = format(today, 'yyyy-MM-dd')
  const lastYear = subYears(today, 1)
  const start = startOfWeek(lastYear)
  const end = today
  const weeks = eachWeekOfInterval({ start, end }).map(week => {
    return eachDayOfInterval({ start: week, end: endOfWeek(week) })
  })

  const lastDayEachWeek = weeks.map(week => last(week))
  const dayToMonthKey = uniq(lastDayEachWeek.map(day => getMonthKey(day)))

  const months = lastDayEachWeek.reduce((acc, lastDay) => {
    const key = getMonthKey(lastDay)
    acc[key] = acc[key] || { count: 0, label: format(lastDay, 'MMM') }
    acc[key].count++
    return acc
  }, {})

  let orderedMonths = dayToMonthKey.map(month => months[month])

  const weekCount = orderedMonths.reduce((acc, { count }) => acc + count, 0)

  const monthLabels = reverse(orderedMonths).map(({ label, count }, index) => {
    return (
      <MonthLabel key={index} span={count}>
        {label}
      </MonthLabel>
    )
  })

  const maxCount = Math.max(...Object.values(data))

  const columns = []

  for (const week of reverse(weeks)) {
    const column = []

    for (const day of week) {
      const dateKey = format(day, 'yyyy-MM-dd')
      const count = data[dateKey] || 0
      const color = generateColor(count, maxCount)

      column.push(
        <motion.div
          key={dateKey}
          className={classnames(
            'h-[11px] w-[11px] rounded-sm shadow-[inset_0_0_3px_rgba(255,255,255,0.1)]',
            color,
            {
              'ring-[1px] ring-indigo-500': dateKey === todayKey,
            },
          )}
        />,
      )
    }

    const key = format(week[0], 'yyyy-MM-dd')

    columns.push(
      <motion.div key={key} className='inline-flex flex-col gap-1'>
        {column}
      </motion.div>,
    )
  }

  if (loading) {
    return (
      <div className='flex h-[192px] w-[825px] items-center justify-center'>
        <Spinner />
      </div>
    )
  }

  return (
    <div className={className}>
      <div className='mb-2'>
        <Trans>
          {Number(total).toLocaleString()} correct answers in the last year
        </Trans>
      </div>
      <div className='rtl inline-block max-w-full overflow-x-auto rounded bg-white shadow dark:border-slate-600 dark:bg-slate-600'>
        <div className='inline-flex flex-col p-4'>
          <div
            className='inline-grid gap-1'
            style={{ gridTemplateColumns: `repeat(${weekCount}, 1fr)` }}
          >
            {monthLabels}
          </div>
          <motion.div className='ml-3 mt-2 inline-flex flex-row justify-center gap-1'>
            {columns}
            <WeekDays />
          </motion.div>
        </div>
      </div>
    </div>
  )
}
