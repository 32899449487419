import { EdgeHandle } from '@components/boards/edge-handle'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { Side } from '@components/boards/utils/types'
import React, { Fragment } from 'react'

type EdgeHandlesProps = {
  id: string
}

export function EdgeHandles({ id }: EdgeHandlesProps) {
  const { isWritable } = useBoardState()

  return isWritable ? (
    <Fragment>
      <EdgeHandle
        nodeId={id}
        className='absolute -bottom-[2px] left-[50%] translate-x-[-50%] transform'
        position={Side.Bottom}
      />
      <EdgeHandle
        nodeId={id}
        className='absolute -top-[2px] left-[50%] translate-x-[-50%] transform'
        position={Side.Top}
      />
      <EdgeHandle
        nodeId={id}
        className='absolute -left-[2px] top-[50%] translate-y-[-50%] transform'
        position={Side.Left}
      />
      <EdgeHandle
        nodeId={id}
        className='absolute -right-[2px] top-[50%] translate-y-[-50%] transform'
        position={Side.Right}
      />
    </Fragment>
  ) : null
}
