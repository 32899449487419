import { useCallback } from 'react'
import { useClient } from '@helenejs/react'
import { compact, map, pipe } from 'remeda'

export function useLoadMore({ state, deckId, maxSessionLength }) {
  const client = useClient()

  return useCallback(async () => {
    const currentIds = pipe(
      state.data.session,
      map(x => x?.exercise?._id),
      compact,
    )

    const { session } = await client.call('exercise.next', {
      deckId,
      currentIds,
    })

    state.data.session = [...state.data.session, ...session]

    if (state.data.session.length >= maxSessionLength * 3) {
      state.data.session.splice(
        maxSessionLength * state.virtualSessionNum,
        maxSessionLength,
        ...Array.from({ length: maxSessionLength }, () => null),
      )

      state.virtualSessionNum += 1
    }
  }, [state.data?.session])
}
