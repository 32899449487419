import { BoardEvent } from '@/common/constants/boards'
import { useClient } from '@helenejs/react'
import interact from 'interactjs'
import { useEffect } from 'react'

export function useInteractionForBoard({
  draggableElem,
  viewportPositionRef,
  zoomRef,
  setState,
  setDragging,
}) {
  const client = useClient()

  useEffect(() => {
    if (!draggableElem) return

    const position = viewportPositionRef.current
    let startZoom = zoomRef.current

    const interactable = interact(draggableElem)
      .draggable({
        cursorChecker: () => '',
        onstart(e) {
          if (e.shiftKey) return
          window._isDragging = true
          setDragging(true)

          position.x = viewportPositionRef.current.x
          position.y = viewportPositionRef.current.y
        },
        onend() {
          window._isDragging = false
          setDragging(false)
        },
        onmove(event) {
          if (!window._isDragging) return
          position.x += event.dx / zoomRef.current
          position.y += event.dy / zoomRef.current

          client.emit(BoardEvent.SetViewportPosition, position)
        },
        inertia: true,
      })
      .gesturable({
        onstart() {
          window._isDragging = true
          setDragging(true)
          startZoom = zoomRef.current
        },
        onend() {
          window._isDragging = false
          setDragging(false)
        },
        onmove(event) {
          if (!window._isDragging) return
          setState(draft => {
            const diminishedScale = (event.scale - 1) / 4 + 1

            const change =
              Math.floor((diminishedScale * startZoom) / 0.125) * 0.125

            if (change > 2) return
            if (change < 0.5) return

            draft.zoomMultiplier = change
          })
        },
      })

    function onContextMenu(e) {
      if (window._isDragging) {
        e.preventDefault()
      }
    }

    document.addEventListener('contextmenu', onContextMenu)

    return () => {
      interactable.unset()
      document.removeEventListener('contextmenu', onContextMenu)
    }
  }, [draggableElem])
}
