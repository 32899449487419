import React from 'react'
import { clsx } from 'clsx'
import { FontStyle } from '@/common/constants'

export function Answer({ answer, game }) {
  return (
    <div
      className={clsx('mb-6 mt-2 text-[18px] italic dark:text-slate-300', {
        '!font-serif': game.settings.fontStyle === FontStyle.SERIF,
        '!font-mono': game.settings.fontStyle === FontStyle.MONOSPACE,
      })}
      dangerouslySetInnerHTML={{ __html: answer }}
    />
  )
}
