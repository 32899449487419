import { cn } from '@/client/utils/cn'
import { MetaboardEvent } from '@/common/events'
import { ChatBody } from '@components/chat/chat-body'
import { faMessage, faWindowMinimize } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useReactiveLocalStorage } from '@hooks/use-reactive-local-storage'
import { Trans } from '@lingui/macro'
import React, { useLayoutEffect, useRef } from 'react'

export function Chat() {
  const chatRef = useRef(null)
  const { client, user, isPremiumActive, isChatOpen } = useMetaboardAuth()

  const chatState = useReactiveLocalStorage('chat:state', {
    activeChat: null,
    recipientName: '',
  })

  useLayoutEffect(() => {
    client.emit(MetaboardEvent.TOGGLE_SCROLL, !isChatOpen)
  }, [isChatOpen])

  if (!user) return null
  if (!isPremiumActive) return null

  return (
    <div
      ref={chatRef}
      className={cn(
        'block-section fixed bottom-0 right-0 z-[10001] hidden flex-col',
        'overflow-hidden bg-white shadow dark:bg-slate-700',
        'lg:z-[11]',
        {
          'flex h-full lg:h-[700px] lg:max-h-[calc(100vh-64px)]': isChatOpen,
          'right-0 w-full lg:w-[968px] lg:rounded-tl-lg lg:rounded-tr-lg':
            isChatOpen,
        },
      )}
    >
      <h1
        onClick={() => client.emit(MetaboardEvent.CHAT_TOGGLE)}
        className={cn(
          'm-0 flex cursor-pointer items-center justify-between bg-gray-300 p-4 text-sm font-medium',
          'text-gray-700 hover:bg-gray-400 dark:bg-slate-600 dark:text-slate-300 dark:hover:bg-slate-500',
        )}
      >
        <span className='flex items-center gap-2'>
          <FontAwesomeIcon icon={faMessage} className='h-3 w-3' />
          {chatState.recipientName ?? <Trans>Chat</Trans>}
        </span>

        <FontAwesomeIcon
          icon={faWindowMinimize}
          className='ml-2 inline-block h-3 w-3 cursor-pointer text-slate-600 dark:text-slate-400'
        />
      </h1>

      <ChatBody isOpen={isChatOpen} chatState={chatState} />
    </div>
  )
}
