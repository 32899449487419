import { YearlyHeatmap } from '@/client/components/users/yearly-heatmap'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMethod } from '@helenejs/react'
import { HorizontalLoader } from '@components/horizontal-loader'
import { XpUtils } from '@/common/xp-utils'
import { Tag } from '@/client/components/ui/tag'
import { Trans } from '@lingui/macro'

export function UserProfile() {
  const { userId } = useParams()

  const {
    result: { user },
    loading,
  } = useMethod({
    method: 'user.profile',
    params: { userId },
    required: ['userId'],
    defaultValue: {},
  })

  const experience = useMemo(
    () => (user ? XpUtils.calcLevelData(user.xpEarned) : null),
    [user],
  )

  if (loading) return <HorizontalLoader />

  if (!user) return null

  const { name, googlePicture, xpEarned } = user

  return (
    <div className='mx-auto flex w-fit max-w-full flex-col gap-6 lg:max-w-[1280px] lg:flex-row'>
      <div className='flex shrink-0 flex-col items-center'>
        <img
          src={
            // Get a high resolution image from Google, perhaps we should make this more generic.
            googlePicture?.replace('s96-c', 's512-c') ?? '/profile-picture.png'
          }
          referrerPolicy='no-referrer'
          className='mb-4 aspect-square w-[256px] max-w-full rounded-lg object-cover'
          alt={name}
        />
        <h1 className='mb-4 flex items-center text-xl font-bold'>{name}</h1>
        <div className='flex items-center gap-2'>
          <Tag className='px-3 py-1 text-[12px] opacity-90' color='indigo'>
            <Trans>Level {Number(experience.level).toLocaleString()}</Trans>
          </Tag>

          <Tag className='px-3 py-1 text-[12px] opacity-90' color='emerald'>
            {Number(xpEarned).toLocaleString()} XP
          </Tag>
        </div>
      </div>
      <div className='flex flex-col space-y-4'>
        <YearlyHeatmap userId={userId} className='w-fit max-w-full' />
      </div>
    </div>
  )
}
