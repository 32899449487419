import { AdminPages, load } from '@/client/admin'
import { useTheme } from '@/client/hooks/use-theme'
import AccountSettings from '@/client/pages/account-settings'
import { Board } from '@/client/pages/board'
import { Boards } from '@/client/pages/boards'
import { DeckEditor } from '@/client/pages/deck-editor'
import { MyLearning } from '@/client/pages/my-learning'
import { NotFound } from '@/client/pages/not-found'
import { OrgDetails } from '@/client/pages/org/org-details'
import { OrgList } from '@/client/pages/org/org-list'
import Practice from '@/client/pages/practice'
import Article from '@/client/pages/public/article'
import Articles from '@/client/pages/public/articles'
import Deck from '@/client/pages/public/deck'
import Decks from '@/client/pages/public/decks'
import { Home } from '@/client/pages/public/home'
import { ShareInvitation } from '@/client/pages/share-invitation'
import SignIn from '@/client/pages/sign-in'
import TaskHoldEm from '@/client/pages/task-hold-em'
import { UserProfile } from '@/client/pages/user-profile'
import { DefaultLegalArtifactKeys } from '@/common/constants/legal'
import ArtifactRenderer from '@components/legal-artifacts/artifact-renderer'
import { AdminRoute } from '@components/routing/admin-route'
import { ProtectedRoute } from '@components/routing/protected-route'
import { PublicRoute } from '@components/routing/public-route'
import { Spinner } from '@components/spinner'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import React from 'react'
import { Switch } from 'react-router-dom'

const OtherPages = {
  // Prevents Stripe data from being loaded on the client everywhere.
  Plans: load(() => import('@/client/pages/public/plans')),
}

export function Routes() {
  const AdminRoutes = AdminPages.filter(p => p.component)

  useTheme()

  const { loading } = useMetaboardAuth()

  // In essence, this will only ever be `loading` if the user document is not present and is being fetched.
  if (loading) {
    return (
      <div className='fixed inset-0 flex items-center justify-center bg-white dark:bg-slate-700'>
        <div className='flex items-center gap-2'>
          <span>
            <Trans>Loading...</Trans>
          </span>
          <Spinner />
        </div>
      </div>
    )
  }

  return (
    <Switch>
      <PublicRoute path='/' component={Home} exact />
      <PublicRoute path='/my-learning' component={MyLearning} exact />
      <PublicRoute
        path='/privacy-policy'
        component={() => (
          <ArtifactRenderer
            artifactKey={DefaultLegalArtifactKeys.PrivacyPolicy}
          />
        )}
      />
      <PublicRoute
        path='/terms-of-use'
        component={() => (
          <ArtifactRenderer artifactKey={DefaultLegalArtifactKeys.TermsOfUse} />
        )}
      />
      <PublicRoute path='/sign-in/:plan' component={SignIn} />
      <PublicRoute path='/sign-in' component={SignIn} />
      <PublicRoute path='/blog' component={Articles} exact />
      <PublicRoute path='/article/:slug' component={Article} />
      <PublicRoute path='/profile/:userId' component={UserProfile} />
      <PublicRoute path='/deck/:deckSlug' component={Deck} />
      <PublicRoute path='/decks' component={Decks} exact />
      <PublicRoute path='/taskholdem' component={TaskHoldEm} exact />
      <PublicRoute path='/taskholdem/:sessionId' component={TaskHoldEm} />

      <ProtectedRoute path='/decks/me' component={Decks} exact />
      <ProtectedRoute path='/decks/editor/:deckId' component={DeckEditor} />
      <PublicRoute
        path='/practice/:deckId'
        component={Practice}
        layout={null}
      />
      <ProtectedRoute path='/boards' component={Boards} exact />
      <PublicRoute path='/b/:boardId' component={Board} exact />
      <PublicRoute path='/b/:boardId/n/:nodeId' component={Board} exact />
      <PublicRoute path='/s/:uuid' component={ShareInvitation} exact />

      <ProtectedRoute path='/org/list' component={OrgList} exact />
      <ProtectedRoute path='/org/:orgId' component={OrgDetails} exact />

      <ProtectedRoute path='/settings/account' component={AccountSettings} />
      <ProtectedRoute path='/plan' component={OtherPages.Plans} />

      {AdminRoutes.map(({ path, component }) => (
        <AdminRoute key={path} path={path} component={component} />
      ))}

      <PublicRoute path='*' component={NotFound} />
    </Switch>
  )
}
