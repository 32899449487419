import { InviteForm } from '@/client/components/org/invite-form'
import { RoleType } from '@/common/constants/role'
import DeleteConfirmation from '@components/delete-confirmation'
import { TimedLoader } from '@components/timed-loader'
import { useClient, useMethod } from '@helenejs/react'
import { useLocaleMemo } from '@hooks/use-locale-memo'
import { t, Trans } from '@lingui/macro'
import { Button, Menu } from '@mantine/core'
import { IconAlertTriangleFilled, IconBuilding } from '@tabler/icons-react'
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from 'mantine-react-table'
import React, { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

export function OrgDetails() {
  const client = useClient()
  const { orgId } = useParams<{ orgId: string }>()
  const history = useHistory()

  const details = useMethod({
    method: 'org.details',
    params: {
      orgId,
    },
    deps: [orgId],
  })

  const roleLabels = useLocaleMemo(() => ({
    [RoleType.Owner]: t`Owner`,
    [RoleType.Admin]: t`Admin`,
    [RoleType.Member]: t`Member`,
  }))

  const columns = useMemo<MRT_ColumnDef<Record<string, any>>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t`Name`,
      },
      {
        accessorKey: 'email',
        header: t`Email`,
      },
      {
        accessorKey: 'role.type',
        header: t`Role`,
        Cell: ({ cell }) => {
          return roleLabels[cell.getValue() as RoleType]
        },
      },
    ],
    [details.result, roleLabels],
  )

  const table = useMantineReactTable({
    columns,
    data: details.result?.users ?? [],
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    initialState: {
      density: 'xs',
      sorting: [
        {
          id: 'name',
          desc: true,
        },
      ],
    },
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => {
      return (
        <>
          <Menu.Item
            onClick={async () => {
              await client.call('org.eject', {
                orgId,
                userId: row.original._id,
              })
              details.refresh()
            }}
          >
            <Trans>Remove</Trans>
          </Menu.Item>
        </>
      )
    },
  })

  const [isDeletingOrg, setDeletingOrg] = useState(false)

  if (details.loading) return <TimedLoader />
  if (!details.result) return null

  const { org } = details.result

  return (
    <div className='flex flex-col gap-4'>
      <h1 className='flex items-center gap-2 text-xl font-medium'>
        <IconBuilding size={24} /> {org.name}
      </h1>

      <InviteForm orgId={orgId} refresh={details.refresh} />

      <MantineReactTable table={table} />

      {/* @todo Hide for non-owner members */}
      <div>
        <Button
          onClick={() => setDeletingOrg(true)}
          variant='subtle'
          size='xs'
          color='red'
        >
          <IconAlertTriangleFilled size={16} className='mr-1.5' />
          <Trans>Delete Organization</Trans>
        </Button>
      </div>

      <DeleteConfirmation
        title={t`Delete Organization`}
        message={t`This will delete the organization and all its data. Are you sure?`}
        isOpen={!!isDeletingOrg}
        onConfirm={async () => {
          await client.call('org.delete', { orgId: org._id })
          history.push('/org/list')
        }}
        onCancel={() => setDeletingOrg(false)}
        onClose={() => setDeletingOrg(false)}
      />
    </div>
  )
}
