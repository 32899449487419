import { EnrolledDecks } from '@/client/components/my-learning/enrolled-decks'
import React, { useEffect } from 'react'

export function MyLearning() {
  useEffect(() => {
    if (!('Notification' in window)) return

    /**
     * @todo add analytics
     */

    Notification.requestPermission().catch(error => {
      console.error('notification permission request failed:', error)
    })
  }, [])

  return (
    <div className='-m-4'>
      <EnrolledDecks />
    </div>
  )
}
