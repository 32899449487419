import { ClientBoardCollection } from '@/client/data'
import { ContextMenu } from '@components/context-menu'
import { useClient, useFindOne } from '@helenejs/react'
import { t } from '@lingui/macro'
import { LucideText } from 'lucide-react'
import React, { useState } from 'react'
import DeleteConfirmation from '../delete-confirmation'

type BoardContextMenuProps = {
  target: any
  boardId: string
}

export function OuterBoardContextMenu({
  target,
  boardId,
}: BoardContextMenuProps) {
  const client = useClient()
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)

  const board = useFindOne(ClientBoardCollection, { _id: boardId })

  if (!board) return null

  return (
    <>
      <ContextMenu
        className='w-44'
        target={target}
        items={[
          {
            label: t`Delete Board`,
            icon: <LucideText className='mr-1.5 h-4 w-4' />,
            onClick: () => setIsDeleteConfirmationOpen(true),
          },
        ]}
      />

      <DeleteConfirmation
        title={t`Delete Board`}
        message={t`Are you sure you want to delete the board "${board?.name}"? This action cannot be undone.`}
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onCancel={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={async () => {
          await client.m.boards.deleteBoard({ boardId: board.id })
        }}
      />
    </>
  )
}
