import { SimpleNode } from '@components/boards/simple-node'
import { TimedLoader } from '@components/timed-loader'
import { useClient } from '@helenejs/react'
import { t, Trans } from '@lingui/macro'
import { Button, Input } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconSearch } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'

export function NodeSearch() {
  const client = useClient()
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const [nodes, setNodes] = useState([])

  const submit = useCallback(async () => {
    if (!search) return
    try {
      setLoading(true)
      const result = await client.call('boards.searchNodesText', {
        query: search,
      })
      setNodes(result.hits)
    } catch (e) {
      console.error(e)
      notifications.show({
        title: t`Error`,
        message: t`Failed to search nodes. Please try again later. If the problem persists, contact support.`,
        color: 'red',
      })
      return
    } finally {
      setLoading(false)
    }
  }, [search])

  return (
    <div>
      <div
        className='mb-4 flex items-center gap-2'
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            submit()
          }
        }}
      >
        <Input
          leftSection={<IconSearch size={16} />}
          onChange={e => {
            setSearch(e.currentTarget.value)
          }}
          placeholder={t`Search nodes...`}
          className='flex-grow'
        />
        <Button
          onClick={() => submit()}
          variant='subtle'
          rightSection={<IconSearch size={16} />}
        >
          <Trans>Search</Trans>
        </Button>
      </div>

      {loading ? (
        <TimedLoader label={t`Searching...`} />
      ) : (
        <div className='flex flex-wrap justify-center gap-2'>
          {nodes.map(node => (
            <SimpleNode key={node._id} node={node} />
          ))}
        </div>
      )}
    </div>
  )
}
