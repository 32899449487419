import { Point } from '@/client/utils/point'
import { User } from '@/common/constants/users'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { NavButton } from '@components/nav-button'
import { Tag } from '@components/ui/tag'
import { useWindowResize } from '@hooks/use-window-resize'
import { LucideToggleLeft, LucideToggleRight } from 'lucide-react'
import React from 'react'

type DeveloperInfoProps = {
  user: User
  developerMode: boolean
  setDeveloperMode: (mode: boolean) => void
  viewportPosition: Point
  virtualBounds: VirtualBounds
  zoomMultiplier: number
}

export function DeveloperInfo({
  user,
  developerMode,
  setDeveloperMode,
  viewportPosition,
  virtualBounds,
  zoomMultiplier,
}: DeveloperInfoProps) {
  const win = useWindowResize()

  return (
    <>
      {user?.isAdmin ? (
        <NavButton
          className='fixed right-4 top-40 z-[20000] flex cursor-pointer select-none items-center gap-1.5'
          onDoubleClick={e => e.stopPropagation()}
          onClick={e => {
            e.stopPropagation()
            setDeveloperMode(!developerMode)
          }}
          color={developerMode ? 'green' : 'gray'}
        >
          Developer Mode
          {developerMode ? (
            <LucideToggleRight className='h-4 w-4' />
          ) : (
            <LucideToggleLeft className='h-4 w-4' />
          )}
        </NavButton>
      ) : null}

      {developerMode ? (
        <Tag
          className='fixed right-4 top-[11rem] z-[30000] select-none gap-1.5'
          color='green'
        >
          <div>
            VP: {viewportPosition.x.toFixed(2)} {viewportPosition.y.toFixed(2)}
          </div>
          <div>
            VD: {win.width} {win.height}
          </div>
          <div>
            BD: {virtualBounds.width} {virtualBounds.height}
          </div>
          <div>ZM: {zoomMultiplier}</div>
        </Tag>
      ) : null}
    </>
  )
}
