import { useGoogleAuth } from '@/client/hooks/use-google-auth'
import { MenuButton } from '@components/navigation/menu-button'
import { useClient } from '@helenejs/react'
import { Trans } from '@lingui/macro'
import { LucideLogOut } from 'lucide-react'
import React from 'react'
import { useHistory } from 'react-router-dom'

export function LogoutButton() {
  const client = useClient()
  const google = useGoogleAuth()
  const history = useHistory()

  return (
    <MenuButton
      className='text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300'
      onClick={async () => {
        if (google.isGoogleAuth) {
          await google.logout()
        }

        await client.logout()

        history.push('/')
      }}
      icon={LucideLogOut}
    >
      <Trans>Sign Out</Trans>
    </MenuButton>
  )
}
