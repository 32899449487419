import { FunkyButton } from '@components/ui/funky-button'
import React from 'react'
import { useClient } from '@helenejs/react'
import { useParams } from 'react-router-dom'

const POKER_POINTS = [
  1,
  2,
  3,
  5,
  8,
  13,
  21,
  34,
  55,
  89,
  144,
  '?',
  '∞',
  '☕️',
  '🍺',
  '🤷',
]

export function Voting() {
  const { sessionId } = useParams<{ sessionId: string }>()
  const client = useClient()
  const [myVote, setMyVote] = React.useState<number | string | null>(null)

  return (
    <div>
      <div className='mx-auto flex w-2/3 flex-wrap justify-center gap-2'>
        {POKER_POINTS.map(p => (
          <FunkyButton
            key={p}
            variant={myVote === p ? 'info' : 'fb'}
            onClick={() => {
              setMyVote(p)
              client
                .call('taskholdem.vote', { id: sessionId, points: p })
                .catch(console.error)
            }}
          >
            {p}
          </FunkyButton>
        ))}
      </div>
    </div>
  )
}
