import SignIn from '@/client/pages/sign-in'
import { MetaboardEvent } from '@/common/events'
import { useLocalEvent } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import { Modal } from '@mantine/core'
import React, { useState } from 'react'

export function SignInDialog() {
  const [isOpen, setOpen] = useState(false)
  const { authenticated } = useMetaboardAuth()

  useLocalEvent(
    {
      event: MetaboardEvent.SIGN_IN,
    },
    () => {
      setOpen(true)
    },
    [setOpen],
  )

  if (authenticated) return null

  return (
    <Modal
      onClose={() => {
        setOpen(false)
      }}
      opened={isOpen}
      title={<Trans>Sign In</Trans>}
      closeOnClickOutside={false}
      size='xl'
    >
      <div className='py-8'>
        <SignIn />
      </div>
    </Modal>
  )
}
