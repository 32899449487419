import { Link, useLocation } from 'react-router-dom'
import React from 'react'
import isNumber from 'lodash/isNumber'
import { cn } from '../../utils/cn'

export function LinkTabs({ tabs }) {
  const { pathname } = useLocation()

  return (
    <div className='tabs'>
      {tabs.map(({ name, href, count }) => (
        <Link
          key={href}
          to={href}
          className={cn(
            'tab tab-bordered text-gray-500 hover:no-underline dark:text-slate-300',
            {
              'tab-active': pathname === href,
            },
          )}
        >
          {name} {isNumber(count) ? `(${count})` : null}
        </Link>
      ))}
    </div>
  )
}
