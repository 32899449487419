import { cn } from '@/client/utils/cn'
import { isMutableRefObject } from '@/client/utils/components'
import { isTouchDevice } from '@/client/utils/environment'
import { MetaboardEvent } from '@/common/events'
import { useClickOutside } from '@components/boards/hooks/use-click-outside'
import { useCursorPosition } from '@components/boards/hooks/use-cursor-position'
import { BodyPortal } from '@components/body-portal'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Badge, Paper } from '@mantine/core'
import { useMergedRef, useResizeObserver } from '@mantine/hooks'
import { useLongPress } from 'ahooks'
import React, { MutableRefObject, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

type ContextMenuItem = {
  icon?: React.ReactNode
  label: string
  shortcut?: string
  onClick?: () => void
  className?: string
  premium?: boolean
  admin?: boolean
} & React.HTMLAttributes<HTMLButtonElement>

type ContextMenuProps = {
  target: React.RefObject<any> | any
  children?: React.ReactNode
  items: ContextMenuItem[]
  onPositionChange?: (position: { x: number; y: number }) => void
  strict?: boolean
  className?: string
}

export function ContextMenu({
  target,
  children,
  items,
  onPositionChange,
  strict,
  className,
}: ContextMenuProps) {
  const client = useClient()
  const cursorPositionRef = useCursorPosition()

  const [visible, setVisible] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const element = isMutableRefObject(target)
    ? (target as MutableRefObject<any>)?.current
    : target

  useLongPress(
    () => {
      if (window._isDragging) return
      client.emit(MetaboardEvent.STOP_DRAGGING)
      setPosition(cursorPositionRef.current)
      setVisible(true)
    },
    element,
    {
      delay: 1000,
    },
  )

  useEffect(() => {
    const handleContextMenu = e => {
      if (isTouchDevice()) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      if (window._isDragging) return

      if (strict) {
        if (e.target !== element) return
      }

      if (!element?.contains(e.target)) return
      if (e.shiftKey) return

      e.preventDefault()
      e.stopPropagation()

      const newPosition = cursorPositionRef.current

      if (newPosition.x === 0 && newPosition.y === 0) return

      setVisible(true)
      setPosition(newPosition)
    }

    element?.addEventListener('contextmenu', handleContextMenu)
    return () => {
      element?.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [element])

  useEffect(() => {
    onPositionChange?.(position)
  }, [position])

  const [menu, setMenu] = useState(null)

  useClickOutside(menu, () => setVisible(false))

  const [ref, rect] = useResizeObserver()

  const refs = useMergedRef(ref, setMenu)

  const adjustedPosition = useMemo(() => {
    const { innerWidth, innerHeight } = window

    const menuWidth = rect.width
    const menuHeight = rect.height

    let adjustedX = position.x
    let adjustedY = position.y

    if (position.x + menuWidth > innerWidth) {
      adjustedX = innerWidth - menuWidth - 16
    }

    if (position.y + menuHeight > innerHeight) {
      adjustedY = innerHeight - menuHeight - 16
    }

    return {
      x: adjustedX,
      y: adjustedY,
    }
  }, [rect, position])

  return (
    <>
      {visible ? (
        <BodyPortal>
          <Paper
            ref={refs}
            style={{
              top: adjustedPosition.y,
              left: adjustedPosition.x,
              zIndex: 999999,
            }}
            onClick={e => {
              e.stopPropagation()
              setVisible(false)
            }}
            className={cn(
              'fixed flex w-[160px] touch-none select-none flex-col items-center justify-center overflow-hidden border border-neutral-300 border-opacity-25',
              className,
            )}
          >
            {items.filter(Boolean).map((item, index) => (
              <ContextMenuButton
                key={index}
                className={item.className}
                onClick={item.onClick}
                premium={item.premium}
                admin={item.admin}
              >
                {item.icon}
                {item.label}
              </ContextMenuButton>
            ))}
            {children}
          </Paper>
        </BodyPortal>
      ) : null}
    </>
  )
}

type ContextMenuButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  premium?: boolean
  admin?: boolean
}

export function ContextMenuButton({
  children,
  className,
  premium,
  admin,
  onClick,
  ...rest
}: ContextMenuButtonProps) {
  const history = useHistory()
  const { isPremiumActive, isAdmin } = useMetaboardAuth()

  if (admin && !isAdmin) return null

  return (
    <button
      {...rest}
      className={cn(
        'flex w-full items-center justify-start gap-1.5 p-2 text-left text-sm hover:bg-slate-200 dark:hover:bg-slate-600',
        'text-xs font-semibold text-neutral-900 dark:text-neutral-100',
        'disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      onClick={e => {
        if (premium && !isPremiumActive) {
          history.push('/plan')
          return
        }

        onClick?.(e)
      }}
    >
      {children}
      {premium && !isPremiumActive ? (
        <Badge color='yellow' size='xs'>
          Premium
        </Badge>
      ) : null}
    </button>
  )
}

export function ContextMenuSeparator() {
  return <hr className='h-[1px] w-full bg-neutral-300 opacity-25' />
}
