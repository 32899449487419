import { ObjectIdOrString } from '@/common/constants/database'
import { StandardDocument } from '@/common/constants/documents'
import { LanguageCode } from '@/common/constants/index'

export type LegalArtifact<T = ObjectIdOrString> = StandardDocument<T> & {
  key: string
  content: string
  author: T
  revision: number
  language: LanguageCode
}

export enum DefaultLegalArtifactKeys {
  TermsOfUse = 'terms-of-use',
  PrivacyPolicy = 'privacy-policy',
}
