import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { useDebounceEffect } from 'ahooks'

export function EditableHeader({ data, field, collection, className }) {
  const docValue = data?.[field]

  const [value, setValue] = useState(docValue)

  useDebounceEffect(
    () => {
      collection.update(
        { _id: data._id },
        {
          $set: {
            [field]: value,
          },
        },
      )
    },
    [value],
    {
      wait: 250,
    },
  )

  useEffect(() => {
    setValue(docValue)
  }, [docValue])

  return (
    <input
      type='text'
      value={value}
      className={classnames(
        'w-full border-0 bg-transparent p-0 text-2xl font-bold outline-none focus:ring-0',
        className,
      )}
      onChange={e => setValue(e.target.value)}
    />
  )
}
