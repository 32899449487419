import { MetaboardEvent } from '@/common/events'
import { NavButton } from '@components/nav-button'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import { LucideLogIn } from 'lucide-react'
import React from 'react'

export function AuthenticationButton() {
  const client = useClient()
  const { authenticated } = useMetaboardAuth()

  if (authenticated) return null

  return (
    <NavButton
      onClick={() => client.emit(MetaboardEvent.SIGN_IN)}
      icon={LucideLogIn}
    >
      <Trans>Sign In</Trans>
    </NavButton>
  )
}
