import { cn } from '@/client/utils/cn'
import { FunkyButton } from '@components/ui/funky-button'
import React from 'react'

type NameFormProps = {
  username: string
  setUsername: (username: string) => void
  submit: (params?: { observe?: true }) => void
  session: object
}

export function NameForm({
  username,
  setUsername,
  submit,
  session,
}: NameFormProps) {
  return (
    <div className='flex gap-2'>
      <input
        type='text'
        placeholder='Enter your name'
        value={username}
        onChange={e => setUsername(e.target.value)}
        className={cn(
          'block w-full rounded-lg border border-slate-300 bg-slate-50 p-2.5',
          'text-sm text-slate-900 focus:border-blue-500 focus:ring-blue-500 dark:border-slate-600',
          'dark:bg-slate-700 dark:text-white dark:placeholder-slate-400 dark:focus:border-blue-500 dark:focus:ring-blue-500',
          'disabled:cursor-not-allowed disabled:opacity-50',
        )}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            submit()
          }
        }}
      />

      <FunkyButton variant='primary' onClick={() => submit()}>
        {session ? 'Join' : 'Start'}
      </FunkyButton>
      <FunkyButton variant='fb' onClick={() => submit({ observe: true })}>
        Observe
      </FunkyButton>
    </div>
  )
}
