import { useMethod } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { MultiSelect } from '@mantine/core'
import { IconBuilding } from '@tabler/icons-react'
import { useField } from 'formik'
import React, { useMemo } from 'react'

type UserMultiSelectProps = {
  value: string[]
  onChange: (value: string[]) => void
  className?: string
  orgId: string
  disabled?: boolean
}

export function UserMultiSelect({
  value,
  onChange,
  className,
  orgId,
  disabled,
}: UserMultiSelectProps) {
  const { user } = useMetaboardAuth()

  const method = useMethod({
    method: 'org.roles.list',
    params: {
      orgId,
    },
    defaultValue: [],
    deps: [orgId],
  })

  const data = useMemo(() => {
    if (!user) return []

    return method.result.map((role: any) => ({
      label: `${role.user.name} (${role.user.email})`,
      value: role._id,
    }))
  }, [user, method.result])

  return (
    <MultiSelect
      data={data}
      value={value || []}
      leftSection={<IconBuilding size={16} />}
      onChange={value => {
        onChange(value?.length ? [...value] : null)
      }}
      className={className}
      size='xs'
      searchable
      disabled={disabled}
    />
  )
}

export function UserMultiSelectField(props: {
  name: string
  orgId: string
  disabled?: boolean
}) {
  const [field, , helper] = useField(props.name)
  return (
    <UserMultiSelect
      orgId={props.orgId}
      value={field.value}
      onChange={helper.setValue}
      disabled={props.disabled}
    />
  )
}
