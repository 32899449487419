import { ClientBoardNodeCollection } from '@/client/data'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { fromAbsX, fromAbsY } from '@components/boards/utils/virtualization'
import { useWindowResize } from '@hooks/use-window-resize'
import defer from 'lodash/defer'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

type AutoCenterNodeProps = {
  isViewportInitialized: boolean
  virtualBounds: VirtualBounds
  updatePosition: (x: number, y: number) => void
  loading: boolean
}

export function useAutoCenterNode({
  isViewportInitialized,
  virtualBounds,
  updatePosition,
  loading,
}: AutoCenterNodeProps) {
  const win = useWindowResize()

  const { nodeId } = useParams<{ nodeId: string }>()

  useEffect(() => {
    if (!nodeId) return
    if (loading) return

    defer(async () => {
      if (!virtualBounds.initialized) return
      if (!isViewportInitialized) return

      const node = await ClientBoardNodeCollection.findOne({ _id: nodeId })

      if (!node) return

      const pos = {
        x:
          (fromAbsX(node.x, virtualBounds) - (win.width - node.width) / 2) * -1,
        y:
          (fromAbsY(node.y, virtualBounds) - (win.height - node.height) / 2) *
          -1,
      }

      updatePosition(pos.x, pos.y)
    })
  }, [nodeId, virtualBounds.initialized, loading, isViewportInitialized])
}
