import { BoardNode } from '@/common/constants/boards'
import { usePdfUrl } from '@components/boards/hooks/use-pdf-url'
import { TimedLoader } from '@components/timed-loader'
import React from 'react'

type NodeTextEditorProps = {
  node: BoardNode<string>
}

export function NodePdfViewer({ node }: NodeTextEditorProps) {
  const pdfUrl = usePdfUrl(node)

  if (!pdfUrl) return <TimedLoader />

  return <iframe src={pdfUrl} width='100%' height='100%'></iframe>
}
