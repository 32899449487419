import { cn } from '@/client/utils/cn'
import { useEffect, useRef } from 'react'
import { range } from 'remeda'
import defer from 'lodash/defer'

export const TokenLength = 6

export function OTPSquare({
  index,
  value,
  onChange,
  onPaste,
  disabled,
  length,
  firstRef,
  lastRef,
}) {
  return (
    <input
      className={cn(
        'flex h-16 w-12 flex-col items-center justify-center rounded-xl border',
        'border-gray-200 bg-white text-center text-lg text-gray-800 outline-none',
        'ring-blue-700 focus:bg-gray-50 focus:ring-1 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:opacity-50',
      )}
      type='text'
      autoComplete='one-time-code'
      inputMode='numeric'
      value={value}
      pattern='\d*'
      onKeyDown={e => {
        if (e.key === 'Backspace') {
          onChange('')
          defer(() => {
            e.target.previousElementSibling?.focus()
          })
        }

        if (e.key === 'ArrowLeft') {
          defer(() => {
            e.target.previousElementSibling?.focus()
          })
        }

        if (e.key === 'ArrowRight') {
          defer(() => {
            e.target.nextElementSibling?.focus()
          })
        }
      }}
      onChange={e => {
        if (!/\d+/.test(e.target.value)) {
          e.preventDefault()
          e.target.value = ''
          return
        }

        if (e.target.value.length === 1) {
          onChange(e.target.value)
          defer(() => {
            e.target.nextElementSibling?.focus()
          })
          return
        }

        if (e.target.value.length === 0) {
          onChange('')
          defer(() => {
            e.target.previousElementSibling?.focus()
          })
          return
        }

        if (e.target.value.length > 1) {
          e.target.value = e.target.value[e.target.value.length - 1]
          onChange(e.target.value)
          defer(() => {
            e.target.nextElementSibling?.focus()
          })
        }
      }}
      disabled={disabled || (index > 0 && length < index)}
      onPaste={onPaste}
      autoFocus={index === 0}
      ref={index === 0 ? firstRef : index === TokenLength - 1 ? lastRef : null}
    />
  )
}

const tokens = range(0, TokenLength)

export function OTPInput({ value, onChange, disabled, onComplete }) {
  const firstRef = useRef(null)
  const lastRef = useRef(null)

  useEffect(() => {
    defer(() => {
      firstRef.current?.focus()
    })
  }, [])

  return (
    <div className='mx-auto flex w-full max-w-xs flex-row items-center justify-between gap-2'>
      {tokens.map(index => (
        <OTPSquare
          key={index}
          index={index}
          value={value?.split('')[index] ?? ''}
          onChange={token => {
            const newValue = value?.split('') ?? []

            if (token) {
              newValue[index] = token
            } else {
              newValue.splice(index, 1)
            }

            onChange(newValue.join(''))

            if (newValue.length === TokenLength) {
              onComplete?.()
            }
          }}
          onPaste={e => {
            e.preventDefault()
            const pasted = e.clipboardData.getData('text')
            if (pasted.length === TokenLength) {
              onChange(pasted)

              defer(() => {
                lastRef.current?.focus()
                onComplete?.()
              })
            }
          }}
          disabled={disabled}
          length={value?.length ?? 0}
          firstRef={firstRef}
          lastRef={lastRef}
        />
      ))}
    </div>
  )
}
