import { InstallModal } from '@/client/components/add-to-home'
import { ScrollToTop } from '@/client/components/scroll-to-top'
import { isDevelopment } from '@/client/utils/constants'
import { initI18n } from '@/client/utils/i18n'
import { ClientOptions, TransportMode } from '@helenejs/client'
import { ClientProvider } from '@helenejs/react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter } from 'react-router-dom'
import { SingletonHooksContainer } from 'react-singleton-hook'
import { AuthProvider } from './providers/google-auth-provider'
import { Routes } from './routes'

initI18n()

const clientOptions: ClientOptions = {
  mode: TransportMode.WebSocket,
  host: window.location.hostname,
  port: isDevelopment ? 8002 : undefined,
  errorHandler: console.error,
  secure: window.location.protocol === 'https:',
  allowedContextKeys: ['token', 'language', 'exp'],
  idlenessTimeout: 600000, // 10 minutes,
}

declare global {
  interface Window {
    _isDragging: boolean
    _isDraggingEdge: boolean
  }
}

export function App() {
  // @ts-ignore
  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID

  if (!clientId) {
    throw new Error('Missing GOOGLE_CLIENT_ID environment variable')
  }

  return (
    <ClientProvider clientOptions={clientOptions}>
      <I18nProvider i18n={i18n}>
        <AuthProvider clientId={clientId}>
          <BrowserRouter>
            <ScrollToTop />
            <Helmet>
              <title>Metaboard</title>
            </Helmet>
            <InstallModal />
            <SingletonHooksContainer />
            <Routes />
          </BrowserRouter>
        </AuthProvider>
      </I18nProvider>
    </ClientProvider>
  )
}
