import React from 'react'
import { cn } from '@/client/utils/cn'
import { KeyboardIcon } from '@radix-ui/react-icons'

const hasNoKeyboard =
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0

export function KBD({ children }) {
  if (hasNoKeyboard) return null

  return (
    <kbd
      className={cn(
        'flex items-center gap-1.5 rounded font-mono backdrop-blur-lg',
        'border border-slate-200/30 bg-slate-100/30 px-1.5 py-1 text-[0.625rem]',
        'font-medium text-slate-500 dark:border-slate-500/10',
        'leading-none dark:bg-slate-600/10 dark:text-slate-300/70',
      )}
    >
      <KeyboardIcon className='relative h-4 w-4' />
      {children}
    </kbd>
  )
}
