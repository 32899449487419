import { FieldOutline } from '@components/ui/forms/field-outline'
import { Select } from '@components/ui/input/select'
import { useField } from 'formik'
import React from 'react'

type SelectFieldProps = {
  outlineProps?: React.ComponentProps<typeof FieldOutline>
  items: { label: string; value: string }[]
  name: string
} & Omit<React.ComponentProps<typeof Select>, 'value' | 'onChange'>

export function SelectField({
  outlineProps,
  items,
  name,
  ...props
}: SelectFieldProps) {
  const [field, meta, helper] = useField(name)

  return (
    <FieldOutline meta={meta} {...outlineProps}>
      <Select
        items={items ?? []}
        value={field.value}
        onChange={helper.setValue}
        {...props}
      />
    </FieldOutline>
  )
}
