import { TOKEN_HEADER_KEY } from '@helenejs/utils'
import { Client } from '@helenejs/client'

export function fetchAudioBufferWithAuth(client: Client, path: string) {
  return new Promise((resolve, reject) => {
    return fetch(client.href(path), {
      headers: {
        [TOKEN_HEADER_KEY]: client.context.token,
      },
    })
      .then(response => response.arrayBuffer())
      .then(resolve)
      .catch(reject)
  })
}
