import { ColName } from '@/server/data/col-name'
import { Collection } from '@helenejs/data'
import deepDiff from 'deep-diff'

export async function patchDocument(
  collection: Collection,
  _id: string,
  doc: Record<string, any>,
  updatedFields?: Record<string, any>,
  removedFields?: string[],
) {
  const existing = await collection.findOne({ _id })

  if (!existing) {
    await collection.insert(doc)
    return
  }

  if (collection.name === ColName.BoardNodes) {
    /**
     * Prevent updating the node if the node is being renamed.
     */

    const diff = deepDiff(existing, doc)

    if (
      diff.some(
        d =>
          d.kind === 'D' &&
          (d.path.includes('_renaming') || d.path.includes('_recording')),
      )
    ) {
      return
    }
  }

  await collection.update(
    { _id },
    {
      $set: updatedFields,
      ...(removedFields?.length
        ? {
            $unset: removedFields.reduce((acc, key) => {
              acc[key] = ''
              return acc
            }, {}),
          }
        : {}),
    },
  )
}
