import { useCallback, useState } from 'react'

type AudioRecorderOptions = {
  onBlobReady?: (blob: Blob) => void
  onUrlReady?: (url: string) => void
}

export const useAudioRecorder = ({
  onBlobReady,
  onUrlReady,
}: AudioRecorderOptions) => {
  const [isRecording, setIsRecording] = useState(false)
  const [audioUrl, setAudioUrl] = useState('')
  const [mediaRecorder, setMediaRecorder] = useState(null)
  const [audioBlob, setAudioBlob] = useState(null)

  const startRecording = useCallback(async () => {
    try {
      // Request permission to access the microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })

      // Create a MediaRecorder instance and start recording
      const recorder = new MediaRecorder(stream)
      const audioChunks = []

      recorder.ondataavailable = event => {
        audioChunks.push(event.data)
      }

      recorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' })
        setAudioBlob(audioBlob)
        onBlobReady?.(audioBlob)
        const audioURL = URL.createObjectURL(audioBlob)
        setAudioUrl(audioURL)
        onUrlReady?.(audioURL)
        // Clean up after stopping
        stream.getTracks().forEach(track => track.stop())
      }

      recorder.start()
      setMediaRecorder(recorder)
      setIsRecording(true)
    } catch (error) {
      console.error('Error accessing the microphone:', error)
    }
  }, [])

  const stopRecording = useCallback(() => {
    if (mediaRecorder) {
      mediaRecorder.stop()
      setIsRecording(false)
      setMediaRecorder(null)
    }
  }, [mediaRecorder])

  return { isRecording, startRecording, stopRecording, audioUrl, audioBlob }
}
