import { cn } from '@/client/utils/cn'
import { MetaboardEvent } from '@/common/events'
import { NavButton } from '@components/nav-button'
import { useClient } from '@helenejs/react'
import React from 'react'

type MenuButtonProps = React.ComponentProps<typeof NavButton> & {
  keep?: boolean
}

export function MenuButton({
  children,
  className,
  onClick,
  keep,
  ...rest
}: MenuButtonProps) {
  const client = useClient()

  return (
    <NavButton
      className={cn('w-full', className)}
      onClick={e => {
        onClick?.(e)
        if (!keep) client.emit(MetaboardEvent.TOGGLE_MENU)
      }}
      {...rest}
    >
      {children}
    </NavButton>
  )
}
