import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/client/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/client/components/ui/popover'
import { cn } from '@/client/utils/cn'
import { t, Trans } from '@lingui/macro'
import { Check, ChevronsUpDown } from 'lucide-react'
import React, { useState } from 'react'

interface ComboboxProps {
  items: {
    label: string
    value: string
  }[]
  value?: string
  onChange?: (value: string) => void
  optional?: boolean
  fluid?: boolean
  className?: string
  align?: 'end' | 'center' | 'start'
}

export function Combobox({
  items,
  value,
  onChange,
  optional,
  fluid = false,
  className,
  align,
}: ComboboxProps) {
  const [open, setOpen] = useState(false)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          role='combobox'
          aria-expanded={open}
          className={cn('flex items-center gap-1.5 truncate text-sm', {
            'w-[200px]': !fluid,
          })}
        >
          {value ? (
            items.find(item => item.value === value)?.label
          ) : (
            <Trans>Select item...</Trans>
          )}
          <ChevronsUpDown className='h-4 w-4 shrink-0 opacity-50' />
        </button>
      </PopoverTrigger>
      <PopoverContent
        className={cn('z-[100001] w-[200px] p-0', className)}
        align={align}
      >
        <Command>
          <CommandInput placeholder={t`Search item...`} />
          <CommandEmpty>
            <Trans>No item found</Trans>
          </CommandEmpty>
          <CommandGroup className='max-h-60 overflow-auto'>
            {items.map(item => (
              <CommandItem
                key={item.value}
                onSelect={() => {
                  onChange(item.value === value && optional ? null : item.value)
                  setOpen(false)
                }}
                className='truncate'
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    value === item.value ? 'opacity-100' : 'opacity-0',
                  )}
                />
                {item.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
