import {
  ClientBoardEdgeCollection,
  ClientBoardNodeCollection,
} from '@/client/data'
import { patchDocument } from '@/client/reactivity/patch-document'
import { BoardEvent } from '@/common/constants/boards'
import { useRemoteEvent } from '@helenejs/react'

export function useBoardChangeEvents(boardId: string) {
  if (!boardId) {
    throw new Error('boardId is required')
  }

  useRemoteEvent(
    {
      event: BoardEvent.NodeChanged,
      channel: boardId,
    },
    async ({ coll, _id, deleted, doc, updatedFields, removedFields }) => {
      console.log('NodeChanged', coll, _id, deleted, doc)

      if (deleted) {
        await ClientBoardNodeCollection.remove({ _id })
        return
      }

      await patchDocument(
        ClientBoardNodeCollection,
        _id,
        doc,
        updatedFields,
        removedFields,
      )
    },
    [boardId],
  )

  useRemoteEvent(
    {
      event: BoardEvent.EdgeChanged,
      channel: boardId,
    },
    async ({ coll, _id, deleted, doc, updatedFields, removedFields }) => {
      console.log('EdgeChanged', coll, _id, deleted, doc)

      if (deleted) {
        await ClientBoardEdgeCollection.remove({ _id })
        return
      }

      await patchDocument(
        ClientBoardEdgeCollection,
        _id,
        doc,
        updatedFields,
        removedFields,
      )
    },
    [boardId],
  )
}
