import { t, Trans } from '@lingui/macro'
import { Button, Tooltip } from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import { IconCheck, IconCopy } from '@tabler/icons-react'
import React, { useState } from 'react'

type ClipboardButtonProps = {
  content?: string
  text?: React.ReactNode
  fetch?: () => Promise<string>
}

export function ClipboardButton({
  content,
  text = <Trans>Copy</Trans>,
  fetch,
}: ClipboardButtonProps) {
  const [loading, setLoading] = useState(false)
  const clipboard = useClipboard()
  return (
    <Tooltip
      label={t`Link copied!`}
      offset={5}
      position='bottom'
      radius='xl'
      transitionProps={{ duration: 100, transition: 'slide-down' }}
      opened={clipboard.copied}
      zIndex={999999}
    >
      <Button
        variant='light'
        rightSection={
          clipboard.copied ? (
            <IconCheck size={16} stroke={1.5} />
          ) : (
            <IconCopy size={16} stroke={1.5} />
          )
        }
        radius='xl'
        size='xs'
        onClick={async () => {
          if (fetch) {
            setLoading(true)
            const result = await fetch()
            clipboard.copy(result)
            setLoading(false)
            return
          }
          clipboard.copy(content)
        }}
        loading={loading}
      >
        {text}
      </Button>
    </Tooltip>
  )
}
