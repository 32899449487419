import { FieldOutline } from '@components/ui/forms/field-outline'
import { useField } from 'formik'
import React from 'react'
import { RichText } from '../rich-text/rich-text-editor'

type RichTextFieldProps = {
  name: string
  disabled?: boolean
  raw?: boolean
} & Omit<React.ComponentProps<typeof RichText>, 'content' | 'onChange'>

export function RichTextField(props: RichTextFieldProps) {
  const { name, disabled, raw, ...rest } = props

  const [field, meta, helper] = useField(name)

  const editor = (
    <RichText
      content={field.value || ''}
      onChange={val => (!disabled ? helper.setValue(val) : null)}
      {...rest}
    />
  )

  if (raw) {
    return editor
  }

  return <FieldOutline meta={meta}>{editor}</FieldOutline>
}
