import { Point } from '@/client/utils/point'
import { BoardEvent } from '@/common/constants/boards'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { useCounterShifting } from '@components/boards/hooks/use-counter-shifting'
import { useCursorPosition } from '@components/boards/hooks/use-cursor-position'
import { useDebouncedEffect } from '@components/boards/hooks/use-debounced-effect'
import { useInteractionForBoard } from '@components/boards/hooks/use-interaction-for-board'
import { useViewportPersistence } from '@components/boards/hooks/use-viewport-persistence'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { useVisibleRectAbs } from '@components/boards/hooks/use-visible-rect-abs'
import { enforceBounds } from '@components/boards/utils/enforce-bounds'
import { SimplePoint } from '@components/boards/utils/virtualization'
import { useHeleneEvent } from '@hooks/use-helene-event'
import { useWindowResize } from '@hooks/use-window-resize'
import { useCreation, usePrevious, useThrottleFn } from 'ahooks'
import { useCallback, useEffect, useRef, useState } from 'react'

export function useViewport(
  boardId: string,
  bounds: VirtualBounds,
  zoom: number,
  containerElem: HTMLElement,
  draggableElem: SVGElement,
) {
  const { setDragging, isDragging, setState } = useBoardState()

  const [isViewportInitialized, setViewportInitialized] = useState(false)

  useEffect(() => {
    setViewportInitialized(false)
  }, [boardId])

  const win = useWindowResize()

  const { width, height } = bounds

  const mousePositionRef = useCursorPosition()

  const viewportPositionRef = useRef({ x: 0, y: 0 })

  const [viewportPosition, setViewportPosition] = useState<Point>(null)

  const throttledSetViewportPosition = useThrottleFn(setViewportPosition, {
    wait: 1000 / 60,
    leading: true,
    trailing: true,
  })

  const zoomRef = useRef(zoom)

  useEffect(() => {
    zoomRef.current = zoom
  }, [zoom])

  const updatePosition = useCallback(
    (x: number, y: number, ignoreBounds = false, throttle = true) => {
      const pos = {
        x,
        y,
      }

      if (!ignoreBounds) enforceBounds(pos, win, width, height, zoomRef.current)

      if (containerElem) {
        containerElem.style.transform = `scale(${zoomRef.current}) translate3d(${pos.x}px, ${pos.y}px, 0)`
      }

      viewportPositionRef.current = pos

      if (throttle) {
        throttledSetViewportPosition.run(new Point(pos.x, pos.y))
      } else {
        setViewportPosition(new Point(pos.x, pos.y))
      }
    },
    [win, width, height, containerElem],
  )

  const previousZoom = usePrevious(zoom)

  useEffect(() => {
    if (!previousZoom) return

    const zoomDiff = zoom - previousZoom

    if (zoomDiff === 0) return
    if (!viewportPositionRef.current) return

    const adjustmentX =
      win.width * zoomDiff * (mousePositionRef.current.x / win.width)
    const adjustmentY =
      win.height * zoomDiff * (mousePositionRef.current.y / win.height)

    updatePosition(
      viewportPositionRef.current.x - adjustmentX,
      viewportPositionRef.current.y - adjustmentY,
    )
  }, [zoom])

  const { initialPosition, persistPosition } = useViewportPersistence(boardId)

  /**
   * User interaction sets the viewport position through here
   */
  useHeleneEvent(
    BoardEvent.SetViewportPosition,
    ({ x, y }: SimplePoint) => {
      updatePosition(x, y)
      persistPosition({ x, y })
    },
    [updatePosition, persistPosition],
  )

  useDebouncedEffect(
    () => {
      if (!bounds.initialized) return
      if (isViewportInitialized) return

      console.log(
        `Initializing viewport position for board ${boardId}`,
        width,
        height,
        { ...initialPosition },
      )

      if (initialPosition) {
        updatePosition(initialPosition.x, initialPosition.y, true)
      } else {
        updatePosition(
          (width / 2 - win.width / 2) * -1,
          (height / 2 - win.height / 2) * -1,
          true,
        )
      }

      /**
       * This should only run when the virtual bounds are initialized
       */

      setViewportInitialized(true)
    },
    250,
    [
      boardId,
      bounds.initialized,
      initialPosition,
      width,
      height,
      isViewportInitialized,
    ],
  )

  useInteractionForBoard({
    viewportPositionRef,
    draggableElem,
    zoomRef,
    setDragging,
    setState,
  })

  const visibleRect = useVisibleRectAbs(
    viewportPosition,
    bounds,
    win,
    zoom,
    512,
  )

  useCounterShifting({
    bounds,
    updatePosition,
    viewportPositionRef,
  })

  return useCreation(
    () => ({
      isDragging,
      viewportPosition: viewportPosition || new Point(0, 0),
      viewportPositionRef,
      updatePosition,
      visibleRect,
      isViewportInitialized,
    }),
    [
      isDragging,
      viewportPosition,
      updatePosition,
      visibleRect,
      isViewportInitialized,
    ],
  )
}
