import { MetaboardEvent } from '@/common/events'
import { useHeleneEvent } from '@hooks/use-helene-event'
import { flow } from 'lodash'
import { useEffect, useRef } from 'react'

type DragEvent = MouseEvent | Touch

type MouseDraggingProps = {
  onMove: (e: DragEvent) => void
  onStart?: (e: DragEvent) => void
  onEnd?: (e: DragEvent) => void
  shouldAllow?: (e: DragEvent) => boolean
  target: HTMLElement | Document | Window
  deps?: any[]
}

export function isTouchEvent(e: DragEvent): e is Touch {
  return 'radiusX' in e
}

function addListener(
  type: string,
  listener: (e: MouseEvent | TouchEvent) => void,
  target: HTMLElement | Document | Window,
) {
  target.addEventListener(type, listener as EventListener)
  return () => target.removeEventListener(type, listener as EventListener)
}

export function useMouseDragging({
  onMove,
  onStart,
  onEnd,
  shouldAllow,
  target,
  deps = [],
}: MouseDraggingProps) {
  const draggingRef = useRef(false)

  useHeleneEvent(MetaboardEvent.STOP_DRAGGING, () => {
    draggingRef.current = false
  })

  useEffect(() => {
    if (!target) return

    return flow(
      /**
       * Touch Events
       */
      addListener(
        'touchstart',
        (e: TouchEvent) => {
          if (shouldAllow && !shouldAllow(e.touches[0])) return

          draggingRef.current = true
          onStart?.(e.touches[0])
          onMove(e.touches[0])
        },
        target,
      ),
      addListener(
        'touchend',
        (e: TouchEvent) => {
          draggingRef.current = false
          onEnd?.(e.touches[0])
        },
        target,
      ),
      addListener(
        'touchmove',
        (e: TouchEvent) => {
          if (!draggingRef.current) return
          onMove(e.touches[0])
        },
        target,
      ),

      /**
       * Mouse Events
       */
      addListener(
        'mousedown',
        (e: MouseEvent) => {
          if (shouldAllow && !shouldAllow(e)) return

          draggingRef.current = true
          onStart?.(e)
          onMove(e)
        },
        target,
      ),
      addListener(
        'mouseup',
        (e: MouseEvent) => {
          draggingRef.current = false
          onEnd?.(e)
        },
        target,
      ),
      addListener(
        'mousemove',
        (e: MouseEvent) => {
          if (!draggingRef.current) return
          onMove(e)
        },
        target,
      ),
    )
  }, deps)
}
