import { MenuButton } from '@components/navigation/menu-button'
import { MoonIcon } from '@heroicons/react/24/outline'
import { SunIcon } from '@heroicons/react/24/solid'
import { useTheme } from '@hooks/use-theme'
import { Trans } from '@lingui/macro'
import React from 'react'

export function ThemeButton() {
  const theme = useTheme()
  return (
    <MenuButton
      onClick={theme.toggle}
      icon={theme.isLight ? SunIcon : MoonIcon}
    >
      {theme.isDark ? (
        <Trans>Theme (Dark)</Trans>
      ) : (
        <Trans>Theme (Light)</Trans>
      )}
    </MenuButton>
  )
}
