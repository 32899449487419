import Logo from '@/client/assets/metaboard-logo.svg?react'
import { cn } from '@/client/utils/cn'
import { AuthenticationButton } from '@components/authentication-button'
import { BoardBrowserDrawer } from '@components/boards/board-browser-drawer'
import { BoardDrawer } from '@components/boards/board-drawer'
import { CreateBoardButton } from '@components/boards/create-board-button'
import { Feedback } from '@components/navigation/feedback'
import { MobileMenu } from '@components/navigation/mobile-menu'
import { UserMenuButton } from '@components/ui/navigation/user-menu-button'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import React from 'react'
import { Link } from 'react-router-dom'
import { Search } from '@components/boards/command-palette'

function Navbar({ children }: { children: React.ReactNode }) {
  return (
    <div
      className={cn(
        'fixed left-4 right-4 top-4 flex h-[48px] items-center gap-2 rounded-lg px-4',
        'transition-all duration-300 ease-in-out',
        'bg-white/50 dark:bg-slate-600/50',
        'hover:bg-white/75 dark:hover:bg-slate-600/75',
        'z-[10000] select-none',
      )}
      onDoubleClick={e => e.stopPropagation()}
    >
      {children}
    </div>
  )
}

export function BoardNavbar() {
  const { incomplete } = useMetaboardAuth()

  if (incomplete)
    return (
      <Navbar>
        <Link to={`/${location.hash}`}>
          <Logo className='h-[32px] w-[128px] fill-slate-700 dark:fill-slate-300' />
        </Link>
      </Navbar>
    )

  return (
    <Navbar>
      <Link to={`/${location.hash}`}>
        <Logo className='h-[32px] w-[128px] fill-slate-700 dark:fill-slate-300' />
      </Link>

      <div className='flex items-center gap-1'>
        <BoardBrowserDrawer />
      </div>

      <div className='ml-auto flex items-center gap-1'>
        <Search />

        <CreateBoardButton />

        <BoardDrawer />

        <Feedback />

        <UserMenuButton />

        <AuthenticationButton />
      </div>

      <MobileMenu />
    </Navbar>
  )
}
