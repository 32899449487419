import { createPortal } from 'react-dom'
import React from 'react'

type SvgPortalProps = {
  children: React.ReactNode
}

export function SvgPortal({ children }: SvgPortalProps) {
  const element = document.getElementById('svg-root')

  if (!element) {
    return null
  }

  return createPortal(children, element)
}
