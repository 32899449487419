import { useBoardId } from '@components/boards/hooks/use-board-id'
import DeleteConfirmation from '@components/delete-confirmation'
import { ClipboardButton } from '@components/mantine-ui/clipboard-button'
import { useClient } from '@helenejs/react'
import { t, Trans } from '@lingui/macro'
import { Button } from '@mantine/core'
import { LucideTrash2 } from 'lucide-react'
import React, { useState } from 'react'

export function ShareLink({ permission }) {
  const boardId = useBoardId()
  const client = useClient()

  const [clearing, setClearing] = useState(false)

  return (
    <div className='flex items-center'>
      <ClipboardButton
        text={<Trans>Copy Link</Trans>}
        fetch={async () => {
          return await client.call('boards.createShareLink', {
            boardId,
            permission,
          })
        }}
      />

      <Button
        size='xs'
        radius='xl'
        variant='subtle'
        color='red'
        onClick={() => setClearing(true)}
        rightSection={<LucideTrash2 size={16} />}
        ml='auto'
      >
        <Trans>Security: Reset Links</Trans>
      </Button>

      <DeleteConfirmation
        title={t`Reset Links`}
        message={t`This will delete all previous share links while preserving all accesses already granted. Are you sure?`}
        isOpen={!!clearing}
        onConfirm={async () => {
          await client.call('boards.clearShareLinks', { boardId })

          setClearing(false)
        }}
        onCancel={() => setClearing(false)}
        onClose={() => setClearing(false)}
      />
    </div>
  )
}
