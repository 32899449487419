import { minHeight, minWidth, roundToGrid } from '@/common/boards/constraints'
import { Dimensions } from '@components/boards/utils/types'
import isEmpty from 'lodash/isEmpty'
import { BoardNode } from '../constants/boards'

export type BoardNodeBounds = Pick<BoardNode, 'x' | 'y' | 'xw' | 'yh'>

export const getBoundsFromNodes = (
  nodes: BoardNodeBounds[],
  win?: Dimensions,
) => {
  const offsetX = roundToGrid(win?.width ?? 256)
  const offsetY = roundToGrid(win?.height ?? 256)

  /**
   * If there are no nodes, calculate the bounds based on the minimum width and height
   */
  if (isEmpty(nodes)) {
    nodes = [
      {
        x: 0,
        y: 0,
        xw: minWidth,
        yh: minHeight,
      },
    ]
  }

  let minX = roundToGrid(Math.min(...nodes.map(node => node.x)) - offsetX)
  let minY = roundToGrid(Math.min(...nodes.map(node => node.y)) - offsetY)
  let maxX = roundToGrid(Math.max(...nodes.map(node => node.xw)) + offsetX)
  let maxY = roundToGrid(Math.max(...nodes.map(node => node.yh)) + offsetY)

  let diffX = 0,
    diffY = 0

  /**
   * On thumbnails, we don't care about the window size
   */
  if (win) {
    const width = maxX - minX
    const height = maxY - minY

    if (width < win.width) {
      diffX = win.width - width
      minX -= roundToGrid(diffX / 2)
      maxX += roundToGrid(diffX / 2)
    }

    if (height < win.height) {
      diffY = win.height - height
      minY -= roundToGrid(diffY / 2)
      maxY += roundToGrid(diffY / 2)
    }

    if (isNaN(minX)) minX = -offsetX
    if (isNaN(minY)) minY = -offsetY
    if (isNaN(maxX)) maxX = win.width + offsetX
    if (isNaN(maxY)) maxY = win.height + offsetY
  }

  const width = maxX - minX
  const height = maxY - minY

  return {
    width,
    height,
    minX,
    minY,
    maxX,
    maxY,
    offsetX,
    offsetY,
    diffX,
    diffY,
    initialized: true,
  }
}
