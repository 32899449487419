export const grid = 16
export const minHeight = 64
export const minWidth = 112
export const maxWidth = 4096
export const maxHeight = 4096

export const minPositionY = 5 * grid
export const minPositionX = 0

export function roundToGrid(value: number) {
  return Math.round(value / grid) * grid
}

export function ensureLimits(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value))
}

export function limit2grid(value: number, min: number, max: number) {
  return roundToGrid(ensureLimits(value, min, max))
}
