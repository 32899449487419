import {
  ClientBoardCollection,
  ClientBoardEdgeCollection,
  ClientBoardNodeCollection,
} from '@/client/data'
import { callWithRetry } from '@/client/utils/call-with-retry'
import { minHeight, minWidth, roundToGrid } from '@/common/boards/constraints'
import {
  Board,
  BoardNode,
  EdgeDirection,
  NodeType,
} from '@/common/constants/boards'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { StateManager } from '@components/boards/utils/state-manager'
import { useClient } from '@helenejs/react'
import { t } from '@lingui/macro'
import { useCreation } from 'ahooks'
import { ObjectId } from 'bson'
import deepDiff from 'deep-diff'
import isNumber from 'lodash/isNumber'
import omit from 'lodash/omit'
import { useHotkeys } from 'react-hotkeys-hook'
import { singletonHook } from 'react-singleton-hook'

const initialValues = {
  updateBoard: async () => null,
  addNode: async () => null,
  updateNode: async () => null,
  deleteNode: async () => null,
  addEdge: async () => null,
  updateEdgeDirection: async () => null,
  duplicateNode: async () => null,
}

export const useBoardOperations = singletonHook(initialValues, () => {
  const client = useClient()

  const { boardId, zoomMultiplier } = useBoardState()

  const stateManager = useCreation(() => new StateManager(client), [boardId])

  useHotkeys(['ctrl+z', 'meta+z'], e => {
    e.preventDefault()
    console.log('undo', ...stateManager.undoStack)
    stateManager.undo()
  })

  useHotkeys(['ctrl+shift+z', 'meta+shift+z'], e => {
    e.preventDefault()
    console.log('redo', ...stateManager.redoStack)
    stateManager.redo()
  })

  return useCreation(() => {
    if (!boardId) return initialValues

    /**
     * Board
     */

    async function updateBoard(values: Partial<Board<string>>) {
      ClientBoardCollection.update({ _id: boardId }, { $set: values }).catch(
        console.error,
      )
      callWithRetry(client, 'boards.update', {
        boardId: boardId,
        data: values,
      }).catch(console.error)
    }

    /**
     * Nodes
     */

    async function addNode(
      x: number,
      y: number,
      overrides: Partial<BoardNode<string>> = {},
    ) {
      // @todo prevent adding nodes too far away from the viewport (safeguard)

      const _id = new ObjectId().toHexString()

      const node: BoardNode<string> = {
        _id,
        x: roundToGrid(x),
        y: roundToGrid(y),
        width: minWidth,
        height: minHeight,
        name: t`New Node`,
        type: NodeType.Text,
        author: client.context.userId,
        _clientId: client.uuid,
        ...overrides,
      }

      callWithRetry(client, 'boards.addNode', {
        _id,
        boardId,
        node,
      }).catch(console.error)

      await ClientBoardNodeCollection.insert({
        ...node,
        xw: node.x + node.width,
        yh: node.y + node.height,
        board: boardId,
        _renaming: node.type === NodeType.Text,
      })

      stateManager.insert(
        ClientBoardNodeCollection,
        await ClientBoardNodeCollection.findOne({
          _id: node._id,
        }),
        {
          method: 'boards.deleteNode',
          params: {
            boardId,
            nodeId: node._id,
          },
        },
        {
          method: 'boards.restoreNode',
          params: {
            boardId,
            nodeId: node._id,
          },
        },
      )

      return node._id as string
    }

    async function updateNode(nodeId: string, data: Partial<BoardNode>) {
      const oldData = await ClientBoardNodeCollection.findOne(
        {
          _id: nodeId,
        },
        { _renaming: 0, _recording: 0 },
      )

      if (!oldData) {
        console.error('Node not found', nodeId)
        return
      }

      const diff = deepDiff(oldData, data).filter(diff => diff.kind !== 'D')

      if (!diff.length) return

      if (
        oldData.x !== data.x ||
        oldData.y !== data.y ||
        oldData.width !== data.width ||
        oldData.height !== data.height
      ) {
        const dim = {
          x: data.x ?? oldData.x,
          y: data.y ?? oldData.y,
          width: data.width ?? oldData.width,
          height: data.height ?? oldData.height,
        }

        // If we don't recompute these in the client, virtual bounds will be off.
        if (isNumber(dim.x) || isNumber(dim.width)) {
          data.xw = dim.x + dim.width
        }

        if (isNumber(dim.y) || isNumber(dim.height)) {
          data.yh = dim.y + dim.height
        }
      }

      data._clientId = client.uuid

      await ClientBoardNodeCollection.update(
        { _id: nodeId },
        {
          $set: data,
          $unset: {
            _renaming: '',
          },
        },
      )

      const newData = await ClientBoardNodeCollection.findOne(
        {
          _id: nodeId,
        },
        { _renaming: 0, _recording: 0 },
      )

      stateManager.update(
        ClientBoardNodeCollection,
        oldData,
        newData,
        {
          method: 'boards.updateNode',
          params: {
            boardId,
            nodeId,
            data: omit(oldData, ['_id', '_renaming', '_recording']),
          },
        },
        {
          method: 'boards.updateNode',
          params: {
            boardId,
            nodeId,
            data,
          },
        },
      )

      await callWithRetry(client, 'boards.updateNode', {
        boardId,
        nodeId,
        data,
      })
    }

    async function deleteNode(id: string) {
      const oldData = await ClientBoardNodeCollection.findOne({ _id: id })

      ClientBoardNodeCollection.remove({ _id: id }).catch(console.error)
      ClientBoardEdgeCollection.remove({ source: id }).catch(console.error)
      ClientBoardEdgeCollection.remove({ target: id }).catch(console.error)

      callWithRetry(client, 'boards.deleteNode', {
        boardId,
        nodeId: id,
      }).catch(console.error)

      stateManager.delete(
        ClientBoardNodeCollection,
        oldData,
        {
          method: 'boards.restoreNode',
          params: {
            boardId,
            nodeId: id,
          },
        },
        {
          method: 'boards.deleteNode',
          params: {
            boardId,
            nodeId: id,
          },
        },
      )
    }

    /**
     * Edges
     */

    async function addEdge(source: string, target: string) {
      if (
        await ClientBoardEdgeCollection.findOne({
          board: boardId,
          source,
          target,
        })
      )
        return

      const _id = new ObjectId().toHexString()

      ClientBoardEdgeCollection.insert({
        _id,
        board: boardId,
        source,
        target,
      }).catch(console.error)

      callWithRetry(client, 'boards.addEdge', {
        _id,
        boardId,
        source,
        target,
      }).catch(console.error)
    }

    function updateEdgeDirection(id: string, direction: EdgeDirection) {
      ClientBoardEdgeCollection.update(
        { _id: id },
        {
          $set: {
            direction,
          },
        },
      ).catch(console.error)

      callWithRetry(client, 'boards.updateEdgeMetadata', {
        boardId,
        edgeId: id,
        direction,
      }).catch(console.error)
    }

    async function duplicateNode(nodeId: string) {
      const nodeToDuplicate = await ClientBoardNodeCollection.findOne({
        _id: nodeId,
      })

      if (!nodeToDuplicate) {
        console.error('Node not found', nodeId)
        return
      }

      const newNodeId = new ObjectId().toHexString()
      const newNode = {
        ...nodeToDuplicate,
        x: nodeToDuplicate.x + 16,
        y: nodeToDuplicate.y + 16,
        _id: newNodeId,
        _clientId: client.uuid,
      }

      await ClientBoardNodeCollection.insert(newNode)

      callWithRetry(client, 'boards.addNode', {
        _id: newNodeId,
        boardId,
        node: newNode,
      }).catch(console.error)

      stateManager.insert(
        ClientBoardNodeCollection,
        await ClientBoardNodeCollection.findOne({
          _id: newNode._id,
        }),
        {
          method: 'boards.deleteNode',
          params: {
            boardId,
            nodeId: newNode._id,
          },
        },
        {
          method: 'boards.restoreNode',
          params: {
            boardId,
            nodeId: newNode._id,
          },
        },
      )

      return newNode._id as string
    }

    return {
      updateBoard,
      addNode,
      updateNode,
      deleteNode,
      addEdge,
      updateEdgeDirection,
      duplicateNode,
    }
  }, [boardId, zoomMultiplier, client])
})

export type BoardOperations = ReturnType<typeof useBoardOperations>
