import React from 'react'
import { useClient } from '@helenejs/react'
import { useGoogleLogin } from '@react-oauth/google'
import { Button } from '@/client/components/ui/button'
import { getBrowserLanguage } from '@/client/utils/i18n'
import { GoogleLogoSmall } from '@/client/components/icons/google-logo-small'
import { Trans } from '@lingui/macro'

export function GoogleAuthButton() {
  const client = useClient()

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      /**
       * Beware! Once the user finishes logging in on Google's screen, the `visibilitychange` event will fire.
       */

      const { token, exp } = await client.call('google.auth', {
        code,
        language: getBrowserLanguage(),
      })

      await client.setContextAndReInit({
        isGoogleAuth: true,
        token,
        language: getBrowserLanguage(),
        exp,
      })
    },
    flow: 'auth-code',
    select_account: true,
  })

  return (
    <Button
      onClick={e => {
        e.preventDefault()
        googleLogin()
      }}
      className='flex h-[36px] flex-row items-center'
    >
      <GoogleLogoSmall />
      <Trans>Sign in with Google</Trans>
    </Button>
  )
}
