import { useEffect, useRef } from 'react'

export function useCursorPosition() {
  const mousePositionRef = useRef({ x: 0, y: 0 })

  useEffect(() => {
    const updateMousePosition = e => {
      mousePositionRef.current = {
        x: e.clientX ?? e?.touches?.[0].clientX,
        y: e.clientY ?? e?.touches?.[0].clientY,
      }
    }

    window.addEventListener('mousemove', updateMousePosition)
    window.addEventListener('touchstart', updateMousePosition)
    window.addEventListener('touchmove', updateMousePosition)

    return () => {
      window.removeEventListener('mousemove', updateMousePosition)
      window.removeEventListener('touchstart', updateMousePosition)
      window.removeEventListener('touchmove', updateMousePosition)
    }
  }, [])

  return mousePositionRef
}
