import { t } from '@lingui/macro'
import React from 'react'
import { BaseButton } from '@/client/components/practice/buttons/base-button'
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline'

export function ThemeButton({ toggle, isDark }) {
  return (
    <BaseButton
      onClick={toggle}
      rightIcon={isDark ? MoonIcon : SunIcon}
      text={t`Theme`}
    />
  )
}
