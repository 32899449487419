import beepCorrect from '@/client/assets/sounds/correct.mp3'
import beepIncorrect from '@/client/assets/sounds/incorrect.mp3'
import { Bonus, BonusBreakpoints } from '@/common/constants/bonus'
import { MetaboardEvent } from '@/common/events'
import { XpUtils } from '@/common/xp-utils'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useCreation } from 'ahooks'
import delay from 'lodash/delay'
import isNumber from 'lodash/isNumber'
import { useCallback, useEffect, useState } from 'react'
import useSound from 'use-sound'

const XP_BASE = 40

export function useAnswer({
  exercise,
  history,
  next,
  state,
  continueDelay = 250,
}) {
  const client = useClient()
  const { authenticated } = useMetaboardAuth()

  const [playCorrect] = useSound(beepCorrect)
  const [playIncorrect] = useSound(beepIncorrect)

  const startedAt = useCreation(() => new Date(), [exercise])

  // We need to ignore the first correct answer after incorrect ones
  const [hasAnsweredIncorrectly, setAnsweredIncorrectly] = useState(false)

  useEffect(() => {
    setAnsweredIncorrectly(false)
  }, [exercise?._id])

  const computeCombo = () => {
    if (!authenticated) return

    const xpBonus = Bonus[state.consecutiveHits]

    if (!isNumber(xpBonus)) return

    state.bonusXpEarned += xpBonus

    client.emit(MetaboardEvent.COMBO, {
      combo: state.consecutiveHits,
    })

    client
      .call('exercise.comboBonus', {
        combo: state.consecutiveHits,
        deckId: exercise.deck,
      })
      .catch(console.error)

    if (state.consecutiveHits === Math.max(...BonusBreakpoints)) {
      state.consecutiveHits = 0
    }
  }

  return useCallback(
    async ({ answer, setLoading, setCorrect, setSelected } = {}) => {
      const now = new Date()

      const isCorrect = answer && exercise.answer === answer

      if (isCorrect) {
        playCorrect()

        const score = (history?.score ?? 0) + 1

        const xpEarned = XpUtils.calcResponseXp(score, XP_BASE)
        state.xpEarned += xpEarned

        if (hasAnsweredIncorrectly) {
          setAnsweredIncorrectly(false)
        } else {
          state.consecutiveHits += 1
          state.correctCount += 1
        }

        computeCombo()
      } else {
        playIncorrect()

        state.consecutiveHits = 0
        state.incorrectCount += 1
        setAnsweredIncorrectly(true)
      }

      setLoading?.(false)
      setCorrect?.(isCorrect)

      if (authenticated) {
        client
          .call('exercise.answer', {
            exerciseId: exercise._id,
            answer,
            startedAt,
            finishedAt: now,
          })
          .catch(console.error)
      }

      delay(() => {
        if (!isCorrect) {
          setSelected?.(null)
          state.hasLearnedNewTerm = false
          state.currentItem = {
            ...state.currentItem,
            history: {
              ...state.currentItem.history,
              score: 0,
              isWrong: true,
            },
            isNew: false,
            isReview: true,
          }
        } else {
          next()
        }
      }, continueDelay)
    },
    [exercise, history, next, startedAt],
  )
}
