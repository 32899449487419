import { cn } from '@/client/utils/cn'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { ChevronRightIcon } from '@radix-ui/react-icons'
import React, { forwardRef } from 'react'

export namespace Dropdown {
  const DISABLED_STYLE =
    'data-[disabled]:pointer-events-none data-[disabled]:text-slate-500 dark:data-[disabled]:text-slate-500'

  const HIGHLIGHT_STYLE =
    'data-[highlighted]:bg-slate-600 data-[highlighted]:text-white group-data-[highlighted]:bg-slate-600 group-data-[highlighted]:text-white'

  const TEXT_STYLE = 'text-slate-600 dark:text-slate-400'

  export const Content = forwardRef<
    React.ElementRef<typeof DropdownMenu.Content>,
    React.ComponentPropsWithoutRef<typeof DropdownMenu.Content>
  >(({ children, className, ...props }, ref) => (
    <DropdownMenu.Content
      className={cn(
        'z-[1000000]',
        'min-w-[220px] rounded-md bg-white p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] dark:bg-slate-700',
        'will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
        className,
      )}
      ref={ref}
      {...props}
    >
      {children}
      <Arrow />
    </DropdownMenu.Content>
  ))

  export function SubTrigger({
    children,
    className,
    ...props
  }: React.ComponentProps<typeof DropdownMenu.SubTrigger>) {
    return (
      <DropdownMenu.SubTrigger
        className={cn(
          'group relative flex h-[25px] select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none outline-none',
          'data-[state=open]:bg-slate-600',
          TEXT_STYLE,
          HIGHLIGHT_STYLE,
          DISABLED_STYLE,
          className,
        )}
        {...props}
      >
        {children}
        <RightIcon>
          <ChevronRightIcon className={TEXT_STYLE} />
        </RightIcon>
      </DropdownMenu.SubTrigger>
    )
  }

  export function RightIcon({ children }: { children: React.ReactNode }) {
    return (
      <div
        className={cn(
          'ml-auto pl-[20px] group-data-[disabled]:text-mauve8',
          TEXT_STYLE,
          HIGHLIGHT_STYLE,
        )}
      >
        {children}
      </div>
    )
  }

  export function SubContent({
    children,
    className,
    ...props
  }: React.ComponentProps<typeof DropdownMenu.SubContent>) {
    return (
      <DropdownMenu.SubContent
        className={cn(
          'min-w-[220px] rounded-md bg-white p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] dark:bg-slate-700',
          ' will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
          className,
        )}
        {...props}
      >
        {children}
      </DropdownMenu.SubContent>
    )
  }

  export function Item({
    children,
    className,
    ...props
  }: React.ComponentProps<typeof DropdownMenu.Item>) {
    return (
      <DropdownMenu.Item
        className={cn(
          'group relative flex h-[25px] select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none outline-none',
          TEXT_STYLE,
          HIGHLIGHT_STYLE,
          DISABLED_STYLE,
          className,
        )}
        {...props}
      >
        {children}
      </DropdownMenu.Item>
    )
  }

  export function Shortcut({ children }: { children: React.ReactNode }) {
    return (
      <div className='ml-auto pl-[20px] text-mauve11 group-data-[disabled]:text-mauve8 group-data-[highlighted]:text-white dark:text-slate-400 dark:group-data-[disabled]:text-mauve9'>
        {children}
      </div>
    )
  }

  export function Separator() {
    return (
      <DropdownMenu.Separator className='m-[5px] h-[1px] bg-slate-200 dark:bg-slate-600' />
    )
  }

  export function Label({ children }: { children: React.ReactNode }) {
    return (
      <DropdownMenu.Label className='pl-[25px] text-xs leading-[25px] text-mauve11 dark:text-slate-500'>
        {children}
      </DropdownMenu.Label>
    )
  }

  export function ItemIndicator({
    children,
    className,
    ...props
  }: React.ComponentProps<typeof DropdownMenu.ItemIndicator>) {
    return (
      <DropdownMenu.ItemIndicator
        className={cn(
          'absolute left-0 inline-flex w-[25px] items-center justify-center',
          className,
        )}
        {...props}
      >
        {children}
      </DropdownMenu.ItemIndicator>
    )
  }

  /**
   * Arrow
   */

  export function Arrow() {
    return <DropdownMenu.Arrow className='fill-white dark:fill-slate-700' />
  }

  /**
   * Radio
   */

  export function RadioItem({
    children,
    className,
    ...props
  }: React.ComponentProps<typeof DropdownMenu.RadioItem>) {
    return (
      <DropdownMenu.RadioItem
        className={cn(
          'relative flex h-[25px] select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none outline-none',
          TEXT_STYLE,
          HIGHLIGHT_STYLE,
          DISABLED_STYLE,
          className,
        )}
        {...props}
      >
        {children}
      </DropdownMenu.RadioItem>
    )
  }

  /**
   * Checkbox
   */

  export function CheckboxItem({
    children,
    className,
    ...props
  }: React.ComponentProps<typeof DropdownMenu.CheckboxItem>) {
    return (
      <DropdownMenu.CheckboxItem
        className={cn(
          'relative flex h-[25px] select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none outline-none',
          TEXT_STYLE,
          HIGHLIGHT_STYLE,
          DISABLED_STYLE,
          className,
        )}
        {...props}
      >
        {children}
      </DropdownMenu.CheckboxItem>
    )
  }
}
