import { BaseDocument, Collection, CollectionEvent } from '@helenejs/data'
import { IDBStorage } from '@helenejs/data/lib/browser'
import {
  useClient,
  useFind,
  useLocalEvent,
  useObject,
  useRemoteEvent,
} from '@helenejs/react'
import { ClientEvents, HeleneEvents } from '@helenejs/utils'
import { useThrottleFn } from 'ahooks'
import useCreation from 'ahooks/lib/useCreation'
import deepDiff from 'deep-diff'
import chunk from 'lodash/chunk'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import { useEffect, useState } from 'react'

const storage = new IDBStorage()

type Props = {
  method: string
  channel?: string
  params?: any

  /**
   * Scope the data to a specific filter.
   */
  filter?: Record<string, any>
  sort?: Record<string, 1 | -1>
  projection?: Record<string, 0 | 1>
  selectiveSync?: boolean
  authenticated?: boolean
  collectionName?: string
  collection?: Collection
  single?: boolean
  required?: any[]
}

export function useData({
  method,
  channel,
  params,
  filter = {},
  sort,
  projection,
  selectiveSync = false,
  authenticated = false,
  collectionName = null,
  collection = null,
  single = false,
  required = [],
}: Props) {
  const name = useCreation(
    () => collection?.name ?? collectionName ?? `collection:${method}`,
    [method, collectionName],
  )

  const innerCollection = useCreation(
    () =>
      collection ??
      new Collection({
        name,
        storage,
        timestamps: true,
        autoload: true,
      }),
    [name, collection],
  )

  const [loading, setLoading] = useState(false)

  const client = useClient()

  const result: any = useCreation(() => ({}), [])

  const data = useFind(innerCollection, filter, sort, projection)

  const refresh = useThrottleFn(
    async () => {
      if (loading) return
      if (!innerCollection) return

      if (!innerCollection.ready) {
        await innerCollection.waitFor(CollectionEvent.READY)
      }

      if (authenticated && !client.authenticated) {
        await innerCollection.deleteMany(filter)
        return
      }

      if (required.some(item => item === undefined || item === null)) {
        return
      }

      setLoading(true)

      const count = await innerCollection.count(filter)

      try {
        let response: BaseDocument | BaseDocument[]

        if (count && selectiveSync) {
          const { updatedAt: lastUpdatedAt } = await innerCollection.findOne(
            filter,
            { updatedAt: 1 },
            { updatedAt: -1 },
          )

          const documentIds = (
            await innerCollection.find(filter).projection({ _id: 1 })
          ).map(({ _id }) => _id)

          const { data, documentIdsToRemove } = await client.call(method, {
            ...params,
            documentIds,
            lastUpdatedAt,
          })

          response = data

          if (!isEmpty(documentIdsToRemove)) {
            await innerCollection.deleteMany({
              $and: [
                filter,
                {
                  _id: { $in: documentIdsToRemove },
                },
              ],
            })
          }
        } else {
          const result = await client.call(method, {
            ...params,
          })

          response = result?.data ?? result
        }

        if (response) {
          if (!Array.isArray(response)) {
            response = [response]
          }

          const retrievedIds = response.map((datum: BaseDocument) => datum._id)

          const promiseChunks = chunk(
            response.map(async (datum: BaseDocument) => {
              const existing = await innerCollection.findOne({ _id: datum._id })

              if (existing) {
                const removedFields = Object.keys(existing).filter(
                  key => !(key in datum),
                )

                const diff = deepDiff(existing, datum)

                if (!diff) return

                await innerCollection.updateOne(
                  { ...filter, _id: datum._id },
                  {
                    $set: datum,
                    $unset: removedFields.reduce((acc, key) => {
                      acc[key] = ''
                      return acc
                    }, {}),
                  },
                )
              } else {
                try {
                  await innerCollection.insert(datum)
                } catch (error) {
                  console.error(error)
                  console.log(datum)
                }
              }
            }),
            64,
          )

          for (const chunk of promiseChunks) {
            await Promise.all(chunk)
          }

          if (!selectiveSync) {
            const toRemove = await innerCollection.find({
              $and: [
                filter,
                {
                  _id: { $nin: retrievedIds },
                },
              ],
            })

            if (!isEmpty(toRemove)) {
              await innerCollection.deleteMany({
                $and: [
                  filter,
                  {
                    _id: { $in: toRemove.map(({ _id }) => _id) },
                  },
                ],
              })
            }
          }
        }
      } catch (error) {
        console.error(error)
        await innerCollection.deleteMany(filter)
        console.error({
          method,
          ...error,
        })
      } finally {
        setLoading(false)
      }
    },
    { wait: 1000, leading: true },
  )

  useEffect(() => {
    set(window, `collections.${innerCollection.name}`, innerCollection)
    refresh.run()
  }, [innerCollection, useObject(filter), useObject(params)])

  useLocalEvent(
    {
      event: ClientEvents.INITIALIZED,
    },
    refresh.run,
  )

  useRemoteEvent(
    {
      event: HeleneEvents.METHOD_REFRESH,
      channel,
    },
    (refreshMethod: string) => {
      if (refreshMethod === method) refresh.run()
    },
    [refresh.run],
  )

  result.collection = innerCollection
  result.data = single ? data[0] : data
  result.loading = loading
  result.client = client
  result.refresh = refresh.run

  return result
}
