import React from 'react'
import { RadioList } from '@components/ui/radio-list'

export function EditableRadioList({ data, collection, field, items }) {
  return (
    <div>
      <RadioList
        value={data?.[field]}
        onChange={value => {
          collection.update(
            { _id: data._id },
            {
              $set: {
                [field]: value,
              },
            },
          )
        }}
        items={items}
      />
    </div>
  )
}
