import { number, object, string } from 'yup'
import {
  ExerciseOrder,
  FontStyle,
  GameMode,
  NewCardBehavior,
} from '@/common/constants'

export const gameSettingsSchema = object({
  exerciseOrder: string().oneOf(Object.values(ExerciseOrder)).required(),
  gameMode: string().oneOf(Object.values(GameMode)).required(),
  sessionLength: number().min(1).max(100).required(),
  newCardBehavior: string().oneOf(Object.values(NewCardBehavior)).required(),
  fontStyle: string().oneOf(Object.values(FontStyle)).required(),
})
