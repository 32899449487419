import {
  ServiceWorkerEvent,
  ServiceWorkerMessage,
  ServiceWorkerState,
} from '@/client/utils/sw-utils'
import { urlBase64ToUint8Array } from '@/common/array'
import { Client } from '@helenejs/client'

export async function persistSubscription(
  registration: ServiceWorkerRegistration,
  client: Client,
) {
  if (!('PushManager' in window)) return

  let subscription = await registration.pushManager.getSubscription()

  if (!subscription) {
    const publicKey = await client.call('push.publicKey')

    const convertedVapidKey = urlBase64ToUint8Array(publicKey)

    subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey,
    })
  }

  await client.call('push.subscribe', {
    subscription: subscription.toJSON(),
  })
}

export async function onRegistration(registration: ServiceWorkerRegistration) {
  registration.addEventListener(ServiceWorkerEvent.UPDATE_FOUND, () => {
    const installing = registration.installing

    if (!installing) return

    installing.addEventListener(ServiceWorkerEvent.STATE_CHANGE, () => {
      if (
        installing.state === ServiceWorkerState.INSTALLED &&
        navigator.serviceWorker.controller
      ) {
        installing.postMessage({
          type: ServiceWorkerMessage.SKIP_WAITING,
        })
      }

      if (installing.state === ServiceWorkerState.ACTIVATED) {
        // @todo Notify user that the update is ready
      }
    })
  })
}

// @todo Re-persist registration if user changes.
export async function registerServiceWorker(client: Client) {
  if (!('serviceWorker' in navigator)) return

  try {
    const registration = await navigator.serviceWorker.register('/sw.js')

    await onRegistration(registration)
    await persistSubscription(registration, client)
  } catch (error) {
    console.error('service worker registration failed:', error)
  }
}
