import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { Button } from '@/client/components/ui/button'
import { cn } from '@/client/utils/cn'
import { Icon, RenderIcon } from '@/client/utils/parse-icon'
import { createPortal } from 'react-dom'

type DropdownItemProps = {
  key?: string
  to?: string
  onClick?: () => void
  icon?: Icon
  children?: React.ReactNode
  visible?: boolean
}

export function DropdownItem({
  to,
  onClick,
  children,
  icon,
  visible = true,
}: Omit<DropdownItemProps, 'key'>) {
  const Component = to ? Link : ('button' as any)

  if (!visible) {
    return null
  }

  return (
    <Menu.Item>
      {({ active }) => (
        <Component
          type={!to ? 'button' : undefined}
          className={classnames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'group flex w-full items-center gap-3 px-4 py-2 text-sm',
          )}
          onClick={onClick}
        >
          {icon ? (
            <RenderIcon
              icon={icon}
              className='h-5 w-5 text-gray-400 group-hover:text-gray-500'
              aria-hidden='true'
            />
          ) : null}
          {children}
        </Component>
      )}
    </Menu.Item>
  )
}

export type DropdownProps = {
  className?: string
  items: DropdownItemProps[]
  children?: React.ReactNode
  rightIcon?: React.ReactNode
}

export function Dropdown({
  items,
  className,
  children,
  rightIcon = (
    <ChevronDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
  ),
}: DropdownProps) {
  const [menuElem, setMenuElem] = React.useState<HTMLDivElement | null>(null)

  if (isEmpty(items) || items.every(({ visible }) => visible === false)) {
    return null
  }
  const onRef = (el: HTMLDivElement) => {
    if (el !== null) {
      setMenuElem(el)
    }
  }

  /**
   * We are using a portal to the body element so that elements that control
   * overflow don't hide part of the dropdown.
   */
  const rect = menuElem?.getBoundingClientRect()

  return (
    <Menu
      as='div'
      className={cn('inline-block text-left', className)}
      ref={onRef}
    >
      <div className='relative'>
        <Menu.Button as={Button} variant='ghost'>
          {children}
          {rightIcon}
        </Menu.Button>

        {createPortal(
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              className={cn(
                'absolute z-[100100] mt-2 w-56 origin-top-right',
                'divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1',
                'ring-black ring-opacity-5 focus:outline-none',
              )}
              style={{
                top: rect?.bottom,
                left: rect?.left,
              }}
            >
              <div className='py-1'>
                {items.map(({ key, ...item }) => (
                  <DropdownItem key={key} {...item} />
                ))}
              </div>
            </Menu.Items>
          </Transition>,
          document.body,
        )}
      </div>
    </Menu>
  )
}
