import React from 'react'

export function DeckDuration({ exerciseCount, className }) {
  if (!exerciseCount) return null

  const hours = Math.floor(exerciseCount / 60)
  const minutes = exerciseCount % 60

  if (!hours && !minutes) return null

  return (
    <span className={className}>
      {!hours && minutes ? `${minutes}m` : `${hours}h`}
    </span>
  )
}
