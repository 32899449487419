import { StandardForm } from '@components/forms/standard-form'
import { UserForm } from '@components/forms/user-form'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { SchemaHook } from '@hooks/use-schema'
import { Trans } from '@lingui/macro'
import React from 'react'
import { boolean, object } from 'yup'

export default function AccountSettings() {
  const client = useClient()
  const { user } = useMetaboardAuth()

  const nameSchema = SchemaHook.useName()

  if (!user) return null

  return (
    <div>
      <h2>
        <Trans>Account Settings</Trans>
      </h2>

      <StandardForm
        initialValues={user}
        schema={object({
          name: nameSchema,
          notificationSettings: object({
            reminders: object({
              enabled: boolean(),
            }),
          }),
        })}
        onSave={async values => {
          await client.call('user.update', values)
        }}
      >
        <UserForm />
      </StandardForm>
    </div>
  )
}
