import { RadioGroup } from '@headlessui/react'
import { cn } from '@/client/utils/cn'
import { CheckIcon } from '@heroicons/react/20/solid'

export function Radio({ items, value, onChange }) {
  return (
    <div className='w-full'>
      <div className='w-full max-w-md'>
        <RadioGroup value={value} onChange={onChange}>
          <RadioGroup.Label className='sr-only'>Server size</RadioGroup.Label>
          <div className='space-y-2'>
            {items.map(plan => (
              <RadioGroup.Option
                key={plan.label}
                value={plan.value}
                className={({ checked }) =>
                  cn(
                    checked
                      ? 'bg-slate-500 dark:bg-slate-200'
                      : 'bg-slate-200 dark:bg-slate-500',
                    'relative flex cursor-pointer rounded-lg px-4 py-3 shadow focus:outline-none',
                  )
                }
              >
                {({ checked }) => (
                  <>
                    <div className='flex w-full items-center justify-between'>
                      <div className='flex items-center'>
                        <div className='text-sm'>
                          <RadioGroup.Label
                            as='p'
                            className={cn(
                              'm-0 font-medium text-slate-900',
                              checked
                                ? 'text-slate-200 dark:text-slate-700'
                                : 'text-slate-700 dark:text-slate-200',
                            )}
                          >
                            {plan.label}
                          </RadioGroup.Label>
                          {plan.description ? (
                            <RadioGroup.Description
                              as='span'
                              className={cn(
                                'inline',
                                checked ? 'text-sky-100' : 'text-gray-500',
                              )}
                            >
                              <span>{plan.description}</span>
                            </RadioGroup.Description>
                          ) : null}
                        </div>
                      </div>
                      {checked && (
                        <div className='shrink-0 text-slate-200 dark:text-slate-700'>
                          <CheckIcon className='h-4 w-4' />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  )
}
