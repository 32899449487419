import { useFind } from '@helenejs/react'
import React, { useEffect, useState } from 'react'
import { DeckExerciseAdder } from '@/client/components/deck-editor/deck-exercise-adder'
import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { SortableExerciseRow } from '@/client/components/deck-editor/sortable-exercise-row'
import { ExerciseRow } from '@/client/components/deck-editor/exercise-row'
import { EditableHeader } from '@/client/components/deck-editor/editable-header'
import { useOrderable } from '@/client/components/deck-editor/use-orderable'
import {
  ClientExerciseCollection,
  ClientSectionCollection,
} from '@/client/data'

export function DeckSection({ section }) {
  const exercises = useFind(
    ClientExerciseCollection,
    { section: section?._id, removedAt: { $exists: false } },
    { order: 1 },
  )

  const [buffer, setBuffer] = useState([])

  useEffect(() => {
    setBuffer(exercises)
  }, [exercises])

  const [activeExercise, setActiveExercise] = useState(null)

  async function handleDragStart(event) {
    const { active } = event

    const doc = await ClientExerciseCollection.findOne({ _id: active.id })

    setActiveExercise(doc)
  }

  const { moveAfter, moveBefore } = useOrderable(ClientExerciseCollection)

  if (!section) return null

  return (
    <div key={section._id} className='card'>
      <div className='card-body m-0 flex flex-col gap-4 p-0'>
        <EditableHeader
          className='card-title'
          collection={ClientSectionCollection}
          data={section}
          field='title'
        />
        {buffer.length ? <DeckExerciseAdder section={section} top /> : null}
        <DndContext
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={async event => {
            const { active, over } = event

            if (active.id === over.id) return

            const deltaY = event.delta.y

            if (deltaY > 0) {
              setBuffer(exercises =>
                arrayMove(
                  exercises,
                  active.data.current.index,
                  over.data.current.index,
                ),
              )

              moveAfter(active.id, over.id).catch(console.error)
            } else {
              setBuffer(exercises =>
                arrayMove(
                  exercises,
                  active.data.current.index,
                  over.data.current.index,
                ),
              )

              moveBefore(active.id, over.id).catch(console.error)
            }

            setActiveExercise(null)
          }}
        >
          <div className='flex flex-col gap-4'>
            <SortableContext
              items={buffer.map(({ _id }) => _id)}
              strategy={verticalListSortingStrategy}
            >
              {buffer.map((exercise, index) => (
                <SortableExerciseRow
                  key={exercise._id}
                  exercise={exercise}
                  index={index}
                />
              ))}
            </SortableContext>
            <DragOverlay>
              {activeExercise ? (
                <ExerciseRow exercise={activeExercise} />
              ) : null}
            </DragOverlay>
          </div>
        </DndContext>
        <DeckExerciseAdder section={section} />
      </div>
    </div>
  )
}
