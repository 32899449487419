import React, { useMemo } from 'react'
import { Markdown } from '../../markdown'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import { Trans } from '@lingui/macro'
import { Info } from 'lucide-react'

export function Explanation({ exercise }) {
  const { explanation, examples } = exercise

  const $examples = useMemo(() => {
    const components = []

    if (!examples || isEmpty(examples)) return null

    for (const [index, example] of examples.entries()) {
      if (isString(example)) {
        components.push(
          <Markdown key={index} className=''>
            {`${index + 1}. ${example}`}
          </Markdown>,
        )
      }

      if (isObject(example)) {
        components.push(
          <div key={index}>
            <Markdown className='mb-2'>{`${index + 1}. ${
              example.example
            }`}</Markdown>
            <Markdown className=''>{`> *${example.meaning}*`}</Markdown>
          </div>,
        )
      }
    }

    return components
  }, [examples])

  if (!explanation) return null

  return (
    <div>
      <div className='mb-6 rounded bg-blue-600/20 p-4 text-[14px] dark:text-slate-300'>
        <h2 className='mb-4 flex items-center gap-1.5 text-[14px] font-medium'>
          <Info className='h-4 w-4' /> <Trans>Explanation</Trans>
        </h2>
        <Markdown>{explanation}</Markdown>
      </div>

      {$examples ? (
        <div className='mb-6 rounded bg-emerald-500/10 p-4 text-[14px] dark:text-slate-300'>
          <h2 className='mb-4 text-[14px] font-medium'>
            <Trans>Examples</Trans>
          </h2>
          <div className='flex flex-col gap-4'>{$examples}</div>
        </div>
      ) : null}
    </div>
  )
}
