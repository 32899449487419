import {
  SimpleDimensions,
  SimplePoint,
} from '@components/boards/utils/virtualization'

export function enforceBounds(
  pos: SimplePoint,
  win: SimpleDimensions,
  width: number,
  height: number,
  zoom: number,
) {
  if (pos.x > 0) {
    pos.x = 0
  }

  if (pos.y > 0) {
    pos.y = 0
  }

  if (pos.x <= win.width / zoom - width) {
    pos.x = win.width / zoom - width
  }

  if (pos.y <= win.height / zoom - height) {
    pos.y = win.height / zoom - height
  }
}
