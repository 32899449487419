export const BillingPeriod = {
  Monthly: 'monthly',
  Yearly: 'yearly',
}

export const BillingPeriods = Object.values(BillingPeriod)

export const StripePrice = {
  Monthly: {
    Production: 'price_1NguZlKiGLXn6wY4utZaNDLW',
    Test: 'price_1Nguh8KiGLXn6wY4niWEqztk',
  },
  Yearly: {
    Production: 'price_1Ngud9KiGLXn6wY4TS9YoDkT',
    Test: 'price_1NguhdKiGLXn6wY4JfgLExiy',
  },
}
