import { AutoPlayButton } from '@/client/components/practice/buttons/auto-play-button'
import { ThemeButton } from '@/client/components/practice/buttons/theme-button'
import { Button } from '@/client/components/ui/button'
import { useTheme } from '@/client/hooks/use-theme'
import { cn } from '@/client/utils/cn'
import { MetaboardEvent } from '@/common/events'
import { HorizontalLoader } from '@components/horizontal-loader'
import { SettingsButton } from '@components/practice/buttons/settings-button'
import { ComboNotification } from '@components/practice/combo-notification'
import { PracticeExercise } from '@components/practice/practice-exercise'
import { ProgressXp } from '@components/progress-xp'
import { SignInDialog } from '@components/sign-in-dialog'
import { useDeck } from '@hooks/use-deck'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { usePracticeState } from '@hooks/use-practice-state'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useParams } from 'react-router-dom'

export default function Practice() {
  const { deckId } = useParams()
  const { authenticated, client } = useMetaboardAuth()
  const { isAutoPlayEnabled, toggleAutoPlay } = usePracticeState()

  const { isDark, toggle } = useTheme()
  const { deck, loading } = useDeck(deckId)

  if (loading) return <HorizontalLoader />

  return (
    <div
      className={cn(
        'relative flex min-h-[100vh] flex-col items-center bg-cover bg-center',
      )}
    >
      <ComboNotification />

      <div className='text-md z-[3] p-4 text-center font-medium'>
        {deck?.title}
      </div>
      <div className='z-[4] flex w-full justify-between px-4'>
        {authenticated ? (
          <Button
            className='self-start justify-self-start'
            arrow='left'
            variant='ghost'
            onClick={() => window.history.back()}
          >
            <Trans>Back</Trans>
          </Button>
        ) : (
          <Button
            className='self-start justify-self-start'
            arrow='right'
            variant='ghost'
            onClick={() => client.emit(MetaboardEvent.SIGN_IN)}
          >
            <Trans>Sign In</Trans>
          </Button>
        )}

        <div className='flex flex-row items-end justify-end gap-2 self-start justify-self-end'>
          <AutoPlayButton
            isAutoPlayEnabled={isAutoPlayEnabled}
            toggleAutoPlay={toggleAutoPlay}
          />

          <ThemeButton isDark={isDark} toggle={toggle} />

          {authenticated ? <SettingsButton deckId={deckId} /> : null}
        </div>
      </div>

      <div className='z-[5] w-full max-w-xl grow p-8'>
        <PracticeExercise deck={deck} />
      </div>

      <div className='z-[6] h-[60px] w-full shrink-0' />
      {/* To push the progress bar to the bottom */}

      <ProgressXp className='z-[7]' />

      <SignInDialog />
    </div>
  )
}
