import { useWheel } from '@components/boards/hooks/use-wheel'
import { useCreation, useLocalStorageState } from 'ahooks'
import { useState } from 'react'
import { singletonHook } from 'react-singleton-hook'
import { useImmer } from 'use-immer'

const initialValues = {
  containerElem: null,
  draggableElem: null,
  selectedIds: [],
  isReadOnly: true,
  zoomMultiplier: 1,
  boardId: null,
  isDragging: false,
}

type BoardState = {
  boardId: string | null
  developerMode: boolean
  setDeveloperMode: (value: boolean) => void
  containerElem: HTMLDivElement | null
  draggableElem: SVGElement | null
  selectedIds: string[]
  isReadOnly: boolean
  isWritable: boolean
  zoomMultiplier: number
  setState: (fn: (draft: typeof initialValues) => void) => void
  setContainerElem: (elem: HTMLDivElement | null) => void
  setDraggableElem: (elem: SVGElement | null) => void
  isDragging: boolean
  setDragging: (value: boolean) => void
}

export const useBoardState = singletonHook<BoardState>(
  {
    ...initialValues,
    developerMode: false,
    setDeveloperMode: () => {},
    isWritable: false,
    setState: () => {},
    setContainerElem: () => {},
    setDraggableElem: () => {},
    setDragging: () => {},
  },
  () => {
    const [developerMode, setDeveloperMode] = useLocalStorageState<boolean>(
      'board:developer:mode',
      {
        defaultValue: false,
      },
    )

    const [isDragging, setDragging] = useState(false)

    const [containerElem, setContainerElem] = useState<HTMLDivElement | null>(
      null,
    )
    const [draggableElem, setDraggableElem] = useState<SVGElement | null>(null)
    const [state, setState] = useImmer<typeof initialValues>(initialValues)

    useWheel(
      () => {
        setState(draft => {
          if (draft.zoomMultiplier >= 1.5) return
          draft.zoomMultiplier += 0.125
        })
      },
      () => {
        setState(draft => {
          if (draft.zoomMultiplier <= 0.5) return
          draft.zoomMultiplier -= 0.125
        })
      },
    )

    return useCreation(
      () => ({
        ...state,
        developerMode,
        setDeveloperMode,
        containerElem,
        setContainerElem,
        draggableElem,
        setDraggableElem,
        setState,
        isWritable: !state.isReadOnly,

        isDragging,
        setDragging,
      }),
      [
        state,
        setState,
        containerElem,
        setContainerElem,
        draggableElem,
        setDraggableElem,
        developerMode,
        setDeveloperMode,
        isDragging,
        setDragging,
      ],
    )
  },
)
