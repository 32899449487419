import qs from 'qs'

let host = typeof window === 'object' ? location.origin : process.env.HOST

if (host?.includes('localhost')) {
  host = 'http://localhost:8002'
}

const getServerEndpoint = (path: string) => host.concat(`/${path}`)

const getImageUrl = (imageId: string, options?: any) => {
  if (!imageId) return null
  if (!options) return getServerEndpoint(`image/${imageId}`)
  return getServerEndpoint(`image/${imageId}?${qs.stringify(options)}`)
}

const getVideoUrl = (uuid: string) => {
  return getServerEndpoint(`video/${uuid}`)
}

const getUrlWithOptions = (url: string, options: Record<string, any>) => {
  return `${url}?${qs.stringify(options)}`
}

export const UrlHelper = {
  getServerEndpoint,
  getImageUrl,
  getVideoUrl,
  getUrlWithOptions,
}
