import { ClientBoardNodeCollection } from '@/client/data'
import { cn } from '@/client/utils/cn'
import { Point } from '@/client/utils/point'
import { ColorMap } from '@/common/boards/color-map'
import { BoardEvent, NodeType } from '@/common/constants/boards'
import { useBoardId } from '@components/boards/hooks/use-board-id'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { useMouseDragging } from '@components/boards/hooks/use-mouse-dragging'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { useClient, useFind } from '@helenejs/react'
import { useWindowResize } from '@hooks/use-window-resize'
import { useThrottleFn } from 'ahooks'
import { LucideMapPin } from 'lucide-react'
import React, { useMemo } from 'react'

type BoardMinimapProps = {
  bounds: VirtualBounds
  viewport: Point
}

const MINIMAP_WIDTH = 384
const MINIMAP_HEIGHT = 192

function getFactor(bounds: VirtualBounds) {
  let factor = bounds.width / MINIMAP_WIDTH

  if (bounds.height / factor > MINIMAP_HEIGHT) {
    factor = bounds.height / MINIMAP_HEIGHT
  }

  return factor
}

export function BoardMinimap({ bounds, viewport }: BoardMinimapProps) {
  const client = useClient()

  const boardId = useBoardId()

  const [containerElem, setContainerElem] =
    React.useState<HTMLDivElement | null>(null)

  const { zoomMultiplier } = useBoardState()

  const nodes = useFind(ClientBoardNodeCollection, { board: boardId })

  const factor = useMemo(() => getFactor(bounds), [bounds])

  const width = bounds.width / factor
  const height = bounds.height / factor

  const viewportX = (viewport.x / factor) * -1
  const viewportY = (viewport.y / factor) * -1

  const win = useWindowResize()

  const $defs = useMemo(
    () => (
      <defs>
        {nodes
          .filter(node => node.type === NodeType.Image)
          .map(node => (
            <pattern
              key={node._id}
              id={`stripes-${node._id}`}
              patternUnits='userSpaceOnUse'
              width='2'
              height='2'
              patternTransform='rotate(45)'
            >
              <rect
                x='0'
                y='0'
                width='1'
                height='2'
                fill={node.dominantColorInImage ?? '#ccc'}
              />
              <rect
                x='0'
                y='0'
                width='1'
                height='2'
                fill={node.dominantColorInImage ?? '#fff'}
              />
            </pattern>
          ))}
      </defs>
    ),
    [nodes],
  )

  const $nodes = useMemo(
    () =>
      nodes.map(node => {
        const x = (node.x - bounds.minX) / factor
        const y = (node.y - bounds.minY) / factor

        return (
          <rect
            key={node._id}
            x={x}
            y={y}
            rx={0.5}
            ry={0.5}
            width={node.width / factor}
            height={node.height / factor}
            className={cn(
              'fill-slate-400 dark:fill-slate-400',
              ColorMap[node.color],
            )}
            style={{
              fill:
                node.type === NodeType.Image
                  ? `url(#stripes-${node._id})`
                  : undefined,
            }}
          />
        )
      }),
    [nodes, bounds, factor],
  )

  const setViewportPosition = useThrottleFn(
    (e: MouseEvent | Touch) => {
      let x =
        (e.clientX - containerElem.offsetLeft) * -1 * factor +
        win.width / 2 / zoomMultiplier
      const y =
        (e.clientY - containerElem.offsetTop) * -1 * factor +
        win.height / 2 / zoomMultiplier

      // Account for when the minimap is centered for small screens.
      if (!win.lg) {
        x += (width / 2) * factor
      }

      client.emit(BoardEvent.SetViewportPosition, { x, y })
    },
    {
      wait: 1000 / 60,
    },
  )

  useMouseDragging({
    onMove(e) {
      setViewportPosition.run(e)
    },
    target: containerElem,
    deps: [containerElem],
  })

  return (
    <div
      ref={setContainerElem}
      className={cn(
        'absolute bottom-4 right-1/2 translate-x-1/2 lg:right-4 lg:translate-x-0',
        'z-[20002] select-none rounded bg-white/50 transition-opacity duration-200 hover:opacity-100 dark:bg-slate-600/50 lg:opacity-50',
      )}
      style={{
        width,
        height,
      }}
      data-minimap
    >
      <LucideMapPin className='absolute left-1.5 top-1.5 h-4 w-4' />
      <svg className='absolute inset-0' width={width} height={height}>
        {$defs}

        {$nodes}

        <rect
          x={viewportX}
          y={viewportY}
          width={win.width / factor / zoomMultiplier}
          height={win.height / factor / zoomMultiplier}
          className='stroke fill-slate-500/10 stroke-slate-600 dark:fill-white/10 dark:stroke-white'
        />
      </svg>
      <span className='absolute bottom-1.5 right-1.5 text-xs font-medium'>
        Zoom: {zoomMultiplier}x
      </span>
    </div>
  )
}
