import { BoardNode } from '@/common/constants/boards'
import { MetaboardEvent } from '@/common/events'
import { NodeContentRenderer } from '@components/boards/node-content'
import { getNodeClasses } from '@components/boards/utils/nodes'
import { unescapeHtml } from '@components/boards/utils/unescape-html'
import { useClient } from '@helenejs/react'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

type DraggableNodeProps = {
  node: BoardNode<string>
  className?: string
}

export function SimpleNode({ node, className }: DraggableNodeProps) {
  const nodeRef = useRef(null)
  const client = useClient()

  const label = unescapeHtml(node.name)

  return (
    <Link
      ref={nodeRef}
      style={{
        width: `${node.width}px`,
        height: `${node.height}px`,
      }}
      className={getNodeClasses(
        {
          dragging: false,
          isSelected: false,
          node,
        },
        className,
        'relative',
      )}
      to={`/b/${node.board}/n/${node._id}`}
      onClick={() => {
        client.emit(MetaboardEvent.CLOSE_BOARD_BROWSER)
      }}
    >
      <NodeContentRenderer node={node} label={label} />
    </Link>
  )
}
