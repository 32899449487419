import { FormControl } from '@/client/components/ui/form-control'
import { FunkyButton } from '@/client/components/ui/funky-button'
import { isDevelopment } from '@/client/utils/constants'
import { Languages } from '@/common/constants'
import { DeckGenerationMode } from '@/common/constants/decks'
import { LimitType } from '@/common/constants/limits'
import { MetaboardEvent } from '@/common/events'
import { Card } from '@components/ui/card'
import { Select } from '@components/ui/input/select'
import { RadioList } from '@components/ui/radio-list'
import { useClient, useLocalEvent } from '@helenejs/react'
import { useLimits } from '@hooks/use-limits'
import { useLocaleMemo } from '@hooks/use-locale-memo'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import { Progress, Textarea } from '@mantine/core'
import React, { useMemo, useState } from 'react'

export function Limits() {
  const { user } = useMetaboardAuth()

  const limits = useLimits()

  const limitLabelMap = useMemo(() => Object.values(limits), [limits])

  const labels = {
    [LimitType.Flashcards]: t`Flashcards`,
    [LimitType.ChatbotMessage]: t`Chatbot Messages`,
    [LimitType.CoverGeneration]: t`Cover Generation`,
  }

  return (
    <div className='prose text-sm'>
      <div className='mb-2 flex items-center gap-1.5 font-bold'>
        {user.isPremiumActive ? (
          <Trans>Premium Monthly Limits</Trans>
        ) : (
          <Trans>Free Monthly Limits</Trans>
        )}
      </div>

      <div className='space-y-2 text-sm'>
        <div>
          {limitLabelMap.map(({ type, usage, limit }) => (
            <div key={type}>
              <b>{labels[type]}</b>: {usage ?? 0} of {limit}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export function DeckCreatorPrompt({
  state,
  setGenerating,
  onSave,
  generating,
}) {
  const client = useClient()

  const { user } = useMetaboardAuth()

  const mode = DeckGenerationMode.ADVANCED

  const [topic, setTopic] = useState('')
  const [length, setLength] = useState(10)
  const [progress, setProgress] = useState(0)
  const [cards, setCards] = useState([])
  const [result, setResult] = useState(null)
  const [saving, setSaving] = useState(false)

  const { questionLanguage, answerLanguage } = state

  useLocalEvent(
    {
      event: MetaboardEvent.DECK_GENERATION_PROGRESS,
    },
    ({ progress, card }) => {
      setCards(cards => [...cards, card])
      setProgress(progress)
    },
    [],
  )

  const lengths = useLocaleMemo(
    () =>
      [
        isDevelopment
          ? {
              value: '10',
              name: t`Extra Small`,
              description: t`10 items (development only)`,
            }
          : null,
        {
          value: '25',
          name: t`Small`,
          description: t`25 items`,
        },
        {
          value: '50',
          name: t`Medium`,
          description: t`50 items`,
        },
        {
          value: '100',
          name: t`Large`,
          description: t`100 items`,
        },
        user.isAdmin
          ? {
              value: '200',
              name: (
                <>
                  <Trans>Huge</Trans> (admin only)
                </>
              ),
              description: t`200 items`,
            }
          : null,
      ].filter(Boolean),
    [user],
  ) as { value: string; name: string; description: string }[]

  return (
    <div className='grid gap-4'>
      <Textarea
        placeholder={t`Topic...`}
        onChange={e => setTopic(e.target.value)}
        defaultValue={topic}
        rows={3}
      />

      <div className='grid gap-4 md:grid-cols-2'>
        <FormControl label={<Trans>Deck Length</Trans>}>
          <RadioList
            value={String(length)}
            onChange={value => setLength(Number(value))}
            items={lengths}
          />
        </FormControl>
        <div>
          <FormControl label={<Trans>Question Language</Trans>}>
            <Select
              items={Languages}
              value={questionLanguage}
              onChange={l => {
                state.questionLanguage = l
              }}
            />
          </FormControl>
          <FormControl label={<Trans>Answer Language</Trans>}>
            <Select
              items={Languages}
              value={answerLanguage}
              onChange={l => {
                state.answerLanguage = l
              }}
            />
          </FormControl>
        </div>
      </div>

      <div className='grid gap-4'>
        {cards.length > 0 ? (
          <div className='space-y-4'>
            <h2>Flashcards</h2>
            {cards.map(({ id, q, a }) => {
              return (
                <Card
                  key={id}
                  className='relative grid gap-4 text-sm md:grid-cols-[1fr_1fr]'
                  transition
                >
                  <span className='absolute left-0 top-0 inline-flex items-center justify-center rounded-br rounded-tl-lg bg-slate-600 px-1.5 py-0.5 text-sm text-slate-400'>
                    {id}
                  </span>
                  <div className='font-bold'>{q}</div>
                  <div>{a}</div>
                </Card>
              )
            })}
          </div>
        ) : null}
      </div>

      {generating && cards.length ? (
        <Progress value={progress * 100} color='blue' />
      ) : null}

      <div className='mt-4 flex justify-end gap-2'>
        <FunkyButton
          variant={result ? 'secondary' : 'primary'}
          onClick={async () => {
            setGenerating(true)
            setProgress(0)
            setCards([])
            setResult(null)
            try {
              const result = await client.call(
                'ai.deck.generate',
                { topic, length, mode, questionLanguage, answerLanguage },
                { timeout: 300000 },
              )
              setResult(result)
            } catch (e) {
              console.error(e)
            } finally {
              setGenerating(false)
            }
          }}
          loading={generating}
          disabled={!topic}
        >
          {result ? <Trans>Regenerate</Trans> : <Trans>Generate</Trans>}
        </FunkyButton>

        {result ? (
          <FunkyButton
            variant='primary'
            onClick={() => {
              setSaving(true)
              return onSave({ ...result })
            }}
            loading={saving}
          >
            <Trans>Save</Trans>
          </FunkyButton>
        ) : null}
      </div>
    </div>
  )
}
