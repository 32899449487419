import React from 'react'
import { Trans } from '@lingui/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FunkyButton } from '@/client/components/ui/funky-button'
import { AudioUtils } from '@/client/utils/audio-utils'

export function MyDeckLearnButton({
  deckId,
  exerciseCount,
  reviewCount,
  learnedCount,
  className,
}) {
  return (
    <FunkyButton
      variant={reviewCount ? 'warning' : 'primary'}
      to={`/practice/${deckId}`}
      disabled={
        !exerciseCount || // No exercises in deck
        (!reviewCount && learnedCount === exerciseCount) // No reviews and all exercises learned
      }
      className={className}
      onClick={() => {
        AudioUtils.unlock()
      }}
    >
      <FontAwesomeIcon icon={faPlay} /> <Trans>Practice</Trans>
    </FunkyButton>
  )
}
