import { cn } from '@/client/utils/cn'
import { MetaboardEvent } from '@/common/events'
import { Recommendation } from '@components/landing-page/recommendation'
import { ParticleBackground } from '@components/particles/particle-background'
import { useClient } from '@helenejs/react'
import { useLocaleMemo } from '@hooks/use-locale-memo'
import { useReactive } from '@hooks/use-reactive-local-storage'
import { t, Trans } from '@lingui/macro'
import { Button } from '@mantine/core'
import { LucideLogIn } from 'lucide-react'
import React from 'react'
import { createPortal } from 'react-dom'
import ReactPlayer from 'react-player'

export default function Landing() {
  const client = useClient()

  useReactive({
    isPortalLoading: false,
  })

  const topics = useLocaleMemo(() => [
    {
      name: t`Learn`,
      description: t`You can use Metaboard to assist you in learning new concepts and ideas`,
    },
    {
      name: t`Plan`,
      description: t`You can use Metaboard to plan and assist on execution of complex projects`,
    },
    {
      name: t`Collaborate`,
      description: t`You can use Metaboard to collaborate with others in real-time`,
    },
  ]) as { name: string; description: string; className?: string }[]

  return (
    <div className='mx-auto max-w-5xl'>
      {createPortal(
        <ParticleBackground />,
        document.getElementById('portal'),
        'particles',
      )}

      <div className='mb-8 flex items-center justify-center'>
        <ReactPlayer url='https://www.youtube.com/watch?v=mkNFPxQyoOM&rel=0' />
      </div>

      {/* @todo Show video tutorial when clicking each of the items */}

      <div className='mb-8 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-2'>
        {topics.map(({ name, description, className }) => (
          <div
            key={name}
            className={cn(
              'transform rounded-lg p-3 text-center text-sm',
              'flex flex-col justify-center gap-2',
              className,
            )}
          >
            <h1 className='mb-0 text-lg font-bold'>{name}</h1>
            <p className='text-md'>{description}</p>
          </div>
        ))}
      </div>

      <h2 className='mb-4 text-center font-medium'>
        <Trans>What people are saying about Metaboard</Trans>
      </h2>

      <div className='space-y-4'>
        <Recommendation
          name='Leonardo Venturini (Founder of Metaboard)'
          url='https://www.linkedin.com/in/leonardoventurini/'
          image='/avatars/leonardo.png'
          text='Metaboard can extend your mind in ways you never thought possible! You will not only relieve your stress levels by not holding everything in your own head, but you will also be able to handle a lot more information and build bigger projects than you were able before.'
        />
        <Recommendation
          name='Paula Nedopetalski (Consultora Industrial)'
          url='https://www.linkedin.com/in/paula-nedopetalski-2ab9b41a3/'
          image='/avatars/paula.png'
          text='Sabe quando você tem um monte de coisas na cabeça e não consegue organizar tudo? Já usei muitas planilhas e aplicativos, mas nada tão prático e fácil quanto o Metaboard. Uso para diversas áreas diferentes: finanças, estudos, planejamento de cursos e até para fazer os fluxogramas dos meus clientes. Estou menos estressada porque coloco tudo lá, parei de esquecer as coisas e tenho sido muito mais produtiva. É definitivamente meu segundo cérebro.'
        />
        <Recommendation
          name='Nathalia Nedopetalski (Empresária)'
          url='https://www.instagram.com/nathalianedo/'
          image='/avatars/nathalia.png'
          text='Eu tenho a mente extremamente acelerada, e amo papel e caneta! Só que toda vez que eu escrevia tudo, eu perdia o papel hahaha! O metaboard tem me auxiliado demais pois jogo tudo que preciso ali, escrevo da minha maneira e depois eu organizo! Como trabalho com várias áreas, crio diversos quadros e me divirto ligando ele! Inclusive, é muito fácil, interativo e didático!'
        />
        <Recommendation
          name='Isaque Chaves (Software Engineer)'
          url='https://www.linkedin.com/in/isaque-chaves/'
          image='/avatars/isaque.png'
          text='Metaboard has been the main tool for my studies and work. It helps me offload my mind while keeping the information accessible.'
        />
      </div>

      <div className='mb-8 mt-16 flex items-center justify-center'>
        <Button
          onClick={() => client.emit(MetaboardEvent.SIGN_IN)}
          leftSection={<LucideLogIn size={16} />}
          variant='gradient'
          gradient={{
            from: 'teal',
            to: 'cyan',
          }}
        >
          <Trans>Start for Free</Trans>
        </Button>
      </div>
    </div>
  )
}
