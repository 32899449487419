import { cn } from '@/client/utils/cn'
import { FieldOutline } from '@components/ui/forms/field-outline'
import { useTheme } from '@hooks/use-theme'
import { IconMoodSmile } from '@tabler/icons-react'
import EmojiPicker, { Theme } from 'emoji-picker-react'
import { useField } from 'formik'
import React, { forwardRef, useState } from 'react'

type InputFieldProps = {
  name: string
  className?: string
  errorClassName?: string
  emoji?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

export function InputField({
  name,
  className,
  errorClassName,
  emoji = false,
  ...props
}: InputFieldProps) {
  const theme = useTheme()
  const [field, meta, helper] = useField(name)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const handleEmojiClick = (emojiData: any) => {
    helper.setValue(field.value + emojiData.emoji)
  }

  return (
    <FieldOutline meta={meta} errorClassName={errorClassName}>
      <div className='relative'>
        <input
          className={cn(
            'block w-full rounded-lg border border-slate-300 bg-slate-50 p-2.5',
            'text-sm text-slate-900 focus:border-blue-500 focus:ring-blue-500 dark:border-slate-600',
            'dark:bg-slate-700 dark:text-white dark:placeholder-slate-400 dark:focus:border-blue-500 dark:focus:ring-blue-500',
            'disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          value={field.value ?? ''}
          onInput={e => {
            helper.setValue((e.target as HTMLInputElement).value)
          }}
          {...props}
        />
        {emoji && (
          <>
            <button
              type='button'
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className='absolute right-2 top-1/2 -translate-y-1/2 transform'
            >
              <IconMoodSmile className='h-6 w-6 text-slate-500 dark:text-slate-400' />
            </button>
            {showEmojiPicker && (
              <div className='absolute right-0 z-10'>
                <EmojiPicker
                  onEmojiClick={handleEmojiClick}
                  previewConfig={{
                    showPreview: false,
                  }}
                  theme={theme.isLight ? Theme.LIGHT : Theme.DARK}
                />
              </div>
            )}
          </>
        )}
      </div>
    </FieldOutline>
  )
}

export const RawInputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ name, className, errorClassName, ...props }, ref) => {
    const [field, , helper] = useField(name)
    return (
      <input
        className={cn(
          'rounded-lg border border-slate-300 bg-slate-50 p-2.5',
          'text-sm text-slate-900 focus:border-blue-500 focus:ring-blue-500 dark:border-slate-600',
          'dark:bg-slate-700 dark:text-white dark:placeholder-slate-400 dark:focus:border-blue-500 dark:focus:ring-blue-500',
          'disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        value={field.value ?? ''}
        onInput={e => {
          // @ts-ignore
          helper.setValue(e.target.value)
        }}
        ref={ref}
        {...props}
      />
    )
  },
)
