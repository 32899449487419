import React from 'react'
import { PlayAudio } from '../play-audio'
import { LanguageTag } from './language-tag'
import { Markdown } from '../../markdown'
import { Trans } from '@lingui/macro'
import { Tag } from '@components/ui/tag'
import { clsx } from 'clsx'
import { FontStyle } from '@/common/constants'

function getExerciseTag({ history, isReview }) {
  if (isReview && history?.isWrong) {
    return (
      <Tag color='red'>
        <Trans>Learn Again</Trans>
      </Tag>
    )
  }

  if (isReview) {
    return (
      <Tag color='indigo'>
        <Trans>Review</Trans>
      </Tag>
    )
  }

  return (
    <Tag color='green'>
      <Trans>New Term</Trans>
    </Tag>
  )
}

/**
 * The exercise header is shown on top of the exercise when the user is practicing.
 */
export function ExerciseHeader({
  question,
  questionLanguage,
  history,
  isReview,
  state,
  isDifferentLanguage,
  game,
}) {
  return (
    <>
      <div className='mb-4 flex gap-1.5'>
        <Tag color={history?.score > 0 ? 'orange' : 'zinc'}>
          <Trans>Level</Trans> {history?.score ?? 0}
        </Tag>
        {getExerciseTag({ history, isReview })}
      </div>

      <div className='mb-4 flex items-center gap-2'>
        {!state.hasLearnedNewTerm && isDifferentLanguage ? (
          <LanguageTag languageCode={questionLanguage} />
        ) : null}
        <PlayAudio text={question} languageCode={questionLanguage} />
      </div>

      <div className='relative mb-4 mt-2 flex flex-wrap overflow-hidden pr-10 text-[18px] dark:text-slate-300'>
        <Markdown
          className={clsx({
            '!font-serif': game.settings.fontStyle === FontStyle.SERIF,
            '!font-mono': game.settings.fontStyle === FontStyle.MONOSPACE,
          })}
        >
          {question}
        </Markdown>
      </div>
    </>
  )
}
