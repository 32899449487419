import './colorful-spinner.scss'
import { cn } from '@/client/utils/cn'

export function ColorfulSpinner({ className }) {
  return (
    <svg
      className={cn('colorful-spinner', className)}
      width='65px'
      height='65px'
      viewBox='0 0 66 66'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        className='colorful-path'
        fill='none'
        strokeWidth='6'
        strokeLinecap='round'
        cx='33'
        cy='33'
        r='30'
      ></circle>
    </svg>
  )
}
