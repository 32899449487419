import { useReactive } from '@hooks/use-reactive-local-storage'
import { useLocalStorageState } from 'ahooks'
import noop from 'lodash/noop'
import { useCallback } from 'react'
import { singletonHook } from 'react-singleton-hook'

export const usePracticeState = singletonHook(
  {
    state: {},
    isAutoPlayEnabled: false,
    toggleAutoPlay: noop,
    toggleAnimated: noop,
  },
  () => {
    const state = useReactive({
      exerciseId: null,
    })

    const [isAutoPlayEnabled, setAutoPlayEnabled] = useLocalStorageState(
      'isPracticeAutoPlay',
      {
        defaultValue: true,
      },
    )

    return {
      state,
      isAutoPlayEnabled,
      toggleAutoPlay: useCallback(
        () => setAutoPlayEnabled(!isAutoPlayEnabled),
        [isAutoPlayEnabled],
      ),
    }
  },
  { unmountIfNoConsumers: true },
)
