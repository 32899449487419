import { cn } from '@/client/utils/cn'
import { BoardEvent } from '@/common/constants/boards'
import { Side } from '@components/boards/utils/types'
import { useClient } from '@helenejs/react'
import { PlusIcon } from '@heroicons/react/20/solid'
import React from 'react'

type Props = {
  nodeId: string
  position: Side
  className?: string
}

export function EdgeHandle({ nodeId, className, position }: Props) {
  const client = useClient()

  function start(e) {
    client.emit(BoardEvent.EdgeStart, {
      nodeId,
      position,
      clientX: e.touches?.[0]?.pageX ?? e.pageX,
      clientY: e.touches?.[0]?.pageY ?? e.pageY,
    })
  }

  return (
    <div
      className={cn('group h-5 w-5 cursor-crosshair lg:h-3 lg:w-3', className)}
      onMouseDown={e => {
        if (e.button !== 0) return
        e.stopPropagation()
        start(e)
      }}
      onTouchStart={e => {
        e.stopPropagation()
        start(e)
      }}
      onClick={e => e.stopPropagation()}
    >
      <PlusIcon className='h-5 w-5 text-gray-300 opacity-0 transition-opacity group-hover:opacity-100 touch:opacity-35 dark:text-gray-400 lg:h-3 lg:w-3' />
    </div>
  )
}
