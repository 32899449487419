import Decimal from 'decimal.js'

const MULTIPLIER = 100
const EXPONENT = 1.5
const XP_BASE = 40

/**
 * @param {number} score
 * @returns {number}
 */

function calcResponseXp(score) {
  return new Decimal(score)
    .pow(3)
    .times(XP_BASE / 2)
    .plus((score * XP_BASE) / 2)
    .toNumber()
}

/**
 * @param {number} xp
 * @returns {number}
 */
function calcLevel(xp) {
  return new Decimal(xp)
    .plus(1)
    .pow(1 / EXPONENT)
    .div(MULTIPLIER)
    .floor()
    .toNumber()
}

/**
 * @param {number} level
 * @returns {number}
 */
function calcXpForLevel(level) {
  return new Decimal(level).mul(MULTIPLIER).pow(EXPONENT).toNumber()
}

/**
 * @param {number} xp
 * @returns {{ level: number, progress: number, percentage: number, xpForNextLevel: number, currentLevelStartXp: number, nextLevelStartXp: number }}
 */
function calcLevelData(xp) {
  const level = calcLevel(xp)
  const currentLevelStartXp = calcXpForLevel(level)
  const nextLevelStartXp = calcXpForLevel(level + 1)
  const progress = xp - currentLevelStartXp

  return {
    level,
    progress,
    percentage: progress / (nextLevelStartXp - currentLevelStartXp),
    xpForNextLevel: nextLevelStartXp - currentLevelStartXp,
    currentLevelStartXp,
    nextLevelStartXp,
  }
}

export const XpUtils = {
  calcLevel,
  calcXpForLevel,
  calcLevelData,
  calcResponseXp,
}
