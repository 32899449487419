import { useEffect, useState } from 'react'

export function useAsyncMemo(fn: () => Promise<any>, deps = []) {
  const [value, setValue] = useState(null)

  useEffect(() => {
    fn().then(setValue).catch(console.error)
  }, deps)

  return value
}
