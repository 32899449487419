import { SapienzaBot } from '@/common/constants/bots'
import { ChatInput } from '@components/chat/chat-input'
import { ChatPicker } from '@components/chat/chat-picker'
import { Message } from '@components/chat/message'
import { TypingAnimation } from '@components/chat/typing-animation'
import { useTypingEvent } from '@components/chat/use-typing-event'
import { Spinner } from '@components/spinner'
import { useClient, useRemoteEvent } from '@helenejs/react'
import { UsersIcon } from '@heroicons/react/24/solid'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import { useLocalStorageState, useReactive } from 'ahooks'
import classnames from 'classnames'
import defer from 'lodash/defer'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { sortBy, uniqBy } from 'remeda'

export function ChatBody({ isOpen, chatState }) {
  const client = useClient()

  const messagesRef = useRef(null)

  const { userId, authenticated, user } = useMetaboardAuth()
  const [activeRecipient, setActiveRecipient] = useLocalStorageState(
    'chat:active_recipient',
    { defaultValue: null },
  )
  const [messages, setMessages] = useState([])

  const state = useReactive({
    isLoading: false,
    botsTyping: [],
    usersTyping: [],
    isSomeoneTyping: false,
    isChatPickerOpen: false,
  })

  const addMessage = useCallback(
    message => {
      setMessages(
        sortBy(
          uniqBy([message, ...messages], x => x._id),
          [x => x.sentAt, 'desc'],
        ),
      )
    },
    [messages],
  )

  const refreshChat = useCallback(async () => {
    if (authenticated && activeRecipient) {
      state.isLoading = true
      const chat = await client.call('chat.getChatForRecipient', {
        recipient: activeRecipient,
      })
      if (chat) {
        chatState.activeChat = chat
        setMessages(chat.messages)
      } else {
        chatState.activeChat = null
        setMessages([])
      }
      state.isLoading = false
    }
  }, [activeRecipient, authenticated])

  useEffect(() => {
    if (!authenticated) return
    if (!isOpen) return
    refreshChat().catch(console.error)
  }, [activeRecipient, refreshChat, authenticated, isOpen])

  useRemoteEvent(
    {
      event: 'chat:message',
      active: !!chatState.activeChat,
      channel: chatState.activeChat
        ? `chat:${chatState.activeChat._id}`
        : undefined,
    },
    message => {
      addMessage(message)

      setTimeout(() => {
        messagesRef.current?.scrollTo({
          top: messagesRef.current.scrollHeight,
          behavior: 'smooth',
        })
      }, 1)
    },
    [chatState.activeChat, addMessage],
  )

  useTypingEvent({ state, activeChat: chatState.activeChat })

  if (!isOpen) return null

  return (
    <div className='relative grid min-h-0 grow grid-cols-4'>
      <div className='relative col-span-4 flex min-h-0 flex-col lg:col-span-3'>
        <UsersIcon
          className='absolute right-2 top-2 h-8 w-8 cursor-pointer text-slate-400 hover:text-slate-500 lg:hidden'
          onClick={() =>
            defer(() => (state.isChatPickerOpen = !state.isChatPickerOpen))
          }
        />
        <div
          ref={messagesRef}
          className='flex grow flex-col-reverse overflow-y-auto'
        >
          {!state.isLoading &&
          isEmpty(messages) &&
          activeRecipient === SapienzaBot ? (
            <div className='mx-auto max-w-[400px] px-4 pb-8 text-center text-gray-500'>
              <Trans>
                Send a message to start a conversation. You can ask anything.
              </Trans>
            </div>
          ) : null}
          {state.isLoading ? (
            <Spinner />
          ) : (
            <>
              <TypingAnimation
                isSomeoneTyping={state.isSomeoneTyping}
                className='pt-4'
              />
              {messages.map(({ _id, ...message }, index) => (
                <Message
                  key={_id}
                  _id={_id}
                  index={index}
                  userId={userId}
                  {...message}
                />
              ))}
            </>
          )}
        </div>
        {activeRecipient ? (
          <ChatInput
            chat={chatState.activeChat}
            onSend={async ({ message }) => {
              const [chat, chatMessage] = await client.call('chat.send', {
                message,
                recipient: activeRecipient,
              })

              if (chat && chatMessage) {
                if (!chatState.activeChat) {
                  chatState.activeChat = chat
                }
                addMessage(chatMessage)
              }
            }}
            onClearChat={async () => {
              await client.call('chat.delete', {
                chatId: chatState.activeChat._id,
              })
              refreshChat().catch(console.error)
            }}
          />
        ) : null}
      </div>
      <ChatPicker
        className={classnames(
          'absolute right-0 col-span-4 h-full w-2/3 transition-all lg:static lg:col-span-1 lg:block lg:w-auto ',
          {
            hidden: !state.isChatPickerOpen,
          },
        )}
        activeRecipient={activeRecipient}
        setActiveRecipient={(recipient, name) => {
          setActiveRecipient(recipient)

          chatState.recipientName = name
        }}
        state={state}
      />
    </div>
  )
}
