import React from 'react'
import { cn } from '@/client/utils/cn'

type Props = {
  label: React.ReactNode | string
  children: React.ReactNode
  className?: string
  inline?: boolean
  labelClassName?: string
}

export function FormGroup({
  label,
  children,
  className,
  inline,
  labelClassName,
}: Props) {
  return (
    <div
      className={cn(
        'mb-4 [&_>_div]:mb-0',
        {
          'flex items-center gap-2': inline,
        },
        className,
      )}
    >
      <label
        className={cn(
          'block text-sm font-medium text-slate-800 dark:text-slate-300',
          { 'mb-2': !inline },
          labelClassName,
        )}
      >
        {label}
      </label>
      {children}
    </div>
  )
}
