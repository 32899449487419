import React from 'react'
import Countdown from 'react-countdown'

export function ReviewCountdown({ review, reviewCount, onComplete }) {
  if (!review) return null
  if (reviewCount) return null

  return (
    <div className='flex flex-col items-center gap-0.5'>
      <span className='text-[11px] font-medium uppercase dark:text-slate-300'>
        Next review in
      </span>
      <Countdown
        date={review.expiredAt}
        onComplete={onComplete}
        renderer={({ days, hours, minutes, seconds, completed }) => {
          if (completed) {
            return null
          }

          return (
            <div className='badge badge-ghost grid cursor-default select-none auto-cols-max grid-flow-col gap-2 bg-sky-700 text-center font-mono font-medium text-slate-300 dark:bg-sky-500/20'>
              {!!days && (
                <div className='flex items-center text-sm'>
                  <span className='countdown'>
                    <span style={{ '--value': days }}></span>
                  </span>
                  d
                </div>
              )}
              <div className='flex items-center text-sm'>
                <span className='countdown'>
                  <span style={{ '--value': hours }}></span>
                </span>
                h
              </div>
              {!hours ? (
                <div className='flex items-center text-sm'>
                  <span className='countdown'>
                    <span style={{ '--value': minutes }}></span>
                  </span>
                  m
                </div>
              ) : null}
              {!hours ? (
                <div className='flex items-center text-sm'>
                  <span className='countdown'>
                    <span style={{ '--value': seconds }}></span>
                  </span>
                  s
                </div>
              ) : null}
            </div>
          )
        }}
      />
    </div>
  )
}
