import { ClientBoardCollection } from '@/client/data'
import { BoardBackground, BoardPrivacy } from '@/common/constants/boards'
import { BoardOrganizationSwitcher } from '@components/boards/board-organization-switcher'
import { DeleteButton } from '@components/boards/delete-button'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { InputField } from '@components/fields/input-field'
import { StandardForm } from '@components/forms/standard-form'
import { NavButton } from '@components/nav-button'
import { FormGroup } from '@components/ui/forms/form-group'
import { RadioListField } from '@components/ui/forms/radio-list-field'
import { useClient, useFindOne } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import { Drawer } from '@mantine/core'
import { SettingsIcon } from 'lucide-react'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { array, object, string } from 'yup'

export function BoardDrawer() {
  const [isBoardDrawerOpen, setBoardDrawerOpen] = useState(false)

  const client = useClient()
  const { boardId } = useParams<{ boardId: string }>()

  const { userId } = useMetaboardAuth()
  const { isWritable } = useBoardState()

  const board = useFindOne(ClientBoardCollection, { _id: boardId })

  const operations = useBoardOperations()

  if (board?.author !== userId) {
    return null
  }

  if (!board) return null

  return (
    <>
      {isWritable ? (
        <NavButton
          onClick={() => setBoardDrawerOpen(true)}
          icon={SettingsIcon}
          collapsible
        >
          <Trans>Settings</Trans>
        </NavButton>
      ) : null}
      <Drawer
        opened={isBoardDrawerOpen}
        onClose={() => setBoardDrawerOpen(false)}
        title={t`Board Settings`}
        position='right'
        size='lg'
        zIndex={30000}
      >
        <StandardForm
          initialValues={{
            name: board?.name,
            privacy: board?.privacy || BoardPrivacy.Private,
            org: board?.org,
            background: board?.background || BoardBackground.Grid,
            teams: board?.teams,
          }}
          onSave={values => {
            operations.updateBoard(values)
          }}
          schema={object({
            name: string()
              .min(1, t`Name is required`)
              .max(100, t`Name is too long`)
              .required(t`Name is required`),
            privacy: string()
              .oneOf(Object.values(BoardPrivacy))
              .required(t`Privacy is required`),
            background: string().oneOf(Object.values(BoardBackground)),
            org: string().length(24).nullable(),
            teams: array().of(string().length(24)).nullable(),
          })}
        >
          <FormGroup label={t`Name`}>
            <InputField name='name' emoji />
          </FormGroup>

          <FormGroup label={t`Background`}>
            <RadioListField
              name='background'
              items={[
                {
                  value: BoardBackground.Grid,
                  name: t`Grid`,
                  description: t`Light or dark background with crossing lines`,
                },
                {
                  value: BoardBackground.Dotted,
                  name: t`Dotted`,
                  description: t`Light or dark background with dots`,
                },
                {
                  value: BoardBackground.Plain,
                  name: t`Plain`,
                  description: t`Light or dark background with no lines or dots`,
                },
              ]}
            />
          </FormGroup>

          <FormGroup label={t`Privacy`}>
            <RadioListField
              name='privacy'
              items={[
                {
                  value: BoardPrivacy.Private,
                  name: t`Private`,
                  description: t`Only you can view this board`,
                },
                {
                  value: BoardPrivacy.Public,
                  name: t`Public`,
                  description: t`Anyone with the link can view this board`,
                },
              ]}
            />
          </FormGroup>

          <BoardOrganizationSwitcher board={board} />

          <FormGroup label={t`Delete`}>
            <DeleteButton boardId={boardId} />
          </FormGroup>
        </StandardForm>
      </Drawer>
    </>
  )
}
