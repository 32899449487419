import React from 'react'
import { GameMode } from '@/common/constants'
import { UnlimitedMode } from '@/client/components/practice/modes/unlimited-mode'
import { SessionMode } from '@/client/components/practice/modes/session-mode'
import { Trans } from '@lingui/macro'
import { useCreation } from 'ahooks'
import { Alert } from '@components/ui/alert'

export function ModeRenderer({ game, exercise, ...rest }) {
  // We cache the exercise so that when the exercise changes the transition occurs smoothly.
  const exerciseCache = useCreation(() => exercise, [])

  switch (game.gameMode) {
    case GameMode.SESSION:
      return <SessionMode game={game} exercise={exerciseCache} {...rest} />
    case GameMode.UNLIMITED:
      return <UnlimitedMode game={game} exercise={exerciseCache} {...rest} />
    default:
      return (
        <Alert variant='danger'>
          <Trans>Unknown Game Mode</Trans>
        </Alert>
      )
  }
}
