import React, { useState } from 'react'
import { Markdown } from '@/client/components/markdown'
import { useMethod } from '@helenejs/react'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import { Tag } from '@/client/components/ui/tag'
import { Button } from '@/client/components/ui/button'
import { HorizontalLoader } from '@components/horizontal-loader'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { Trans } from '@lingui/macro'

export function DeckSection({ index, section }) {
  const [isOpen, setOpen] = useState(false)
  const { _id, title, exerciseCount, description } = section

  const { result, refresh, loading } = useMethod({
    method: 'exercise.public.listBySectionId',
    params: { sectionId: _id },
    required: ['sectionId'],
    defaultValue: [],
    lazy: true,
  })

  return (
    <div key={_id} className='mb-4 block overflow-x-auto rounded-lg p-2'>
      <div className='block min-w-0'>
        <h5 className='m-0 flex min-w-0 items-center gap-2 font-medium'>
          {index + 1}. {title}{' '}
          <Tag className='ml-1' color='zinc'>
            {exerciseCount} items
          </Tag>
          <Button
            onClick={() => {
              setOpen(!isOpen)
              if (exerciseCount && isEmpty(result)) refresh()
            }}
            variant='ghost'
          >
            {isOpen ? (
              <ChevronDownIcon className='h-5 w-5' />
            ) : (
              <ChevronRightIcon className='h-5 w-5' />
            )}
          </Button>
        </h5>
        {description && (
          <div className='mt-4'>
            <Markdown>{description}</Markdown>
          </div>
        )}

        <div className={classnames({ hidden: !isOpen })}>
          {loading ? (
            <HorizontalLoader />
          ) : (
            <div className='mt-4 flex flex-wrap items-center gap-2'>
              {isEmpty(result) ? (
                <div className='mx-auto font-medium'>
                  <Trans>Empty</Trans>
                </div>
              ) : (
                result.map(({ _id, question }, index) => (
                  <Tag key={_id} color='zinc'>
                    <div className='flex items-center gap-2'>
                      <span>{index + 1}.</span>
                      <Markdown>{question}</Markdown>
                    </div>
                  </Tag>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
