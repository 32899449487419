import { HorizontalLoader } from '@components/horizontal-loader'
import { Trans } from '@lingui/macro'
import loadable from '@loadable/component'
import React from 'react'

export const fallback = <HorizontalLoader />

export const load = path => loadable(path, { fallback })

export const Section = {
  Resources: 'Resources',
  Audio: 'Audio',
  Examples: 'Examples',
  System: 'System',
}

export const SectionTitle = {
  [Section.Audio]: <Trans>Audio</Trans>,
  [Section.Examples]: <Trans>Examples</Trans>,
  [Section.Resources]: <Trans>Resources</Trans>,
  [Section.System]: <Trans>System</Trans>,
}

export const AdminPages = [
  // Resources
  {
    path: '/manage/articles',
    title: <Trans>Articles</Trans>,
    section: Section.Resources,
    component: load(() => import('@/client/pages/manage/articles')),
  },
  {
    path: '/manage/users',
    title: <Trans>Users</Trans>,
    section: Section.Resources,
    component: load(() => import('@/client/pages/manage/users')),
  },
  {
    path: '/manage/videos',
    title: <Trans>Videos</Trans>,
    section: Section.Resources,
    component: load(() => import('@/client/pages/manage/videos')),
  },
  {
    path: '/manage/legal-artifacts',
    title: <Trans>Legal Artifacts</Trans>,
    section: Section.Resources,
    component: load(() => import('@/client/pages/manage/legal-artifacts')),
  },

  // System

  {
    path: '/manage/analytics',
    title: <Trans>Analytics</Trans>,
    section: Section.System,
    component: load(() => import('@/client/pages/manage/analytics')),
  },
  {
    path: '/manage/qrcodes',
    title: 'QR Codes',
    component: load(() => import('@/client/pages/manage/qrcodes')),
    section: Section.System,
  },
  {
    path: '/manage/development-tokens',
    title: <Trans>Development Tokens</Trans>,
    component: load(() => import('@/client/pages/manage/development-tokens')),
    section: Section.System,
  },

  // Entity-Specific Pages
  {
    path: '/manage/article/:articleId',
    component: load(() => import('@/client/pages/manage/article')),
  },
]
