import React from 'react'
import { Trans } from '@lingui/macro'
import { Button } from '@/client/components/ui/button'
import { useHotkeys } from 'react-hotkeys-hook'
import { KBD } from '@components/ui/kbd'

export function NextButton({ onClick }) {
  useHotkeys(['enter', 'space'], onClick)

  return (
    <Button
      onClick={onClick}
      size='lg'
      className='gap-1.5'
      rounded={false}
      fill
    >
      <Trans>Answer Now</Trans>
      <KBD>
        <Trans>Space</Trans>
      </KBD>
    </Button>
  )
}
