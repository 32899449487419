import React, { useEffect, useState } from 'react'
import { useClient } from '@helenejs/react'
import { useHistory, useParams } from 'react-router-dom'
import { useLocalStorageState } from 'ahooks'
import { NameForm } from '@components/task-hold-em/name-form'
import { Session } from '@components/task-hold-em/session'
import { TimedLoader } from '@components/timed-loader'
import { Helmet } from 'react-helmet'

export default function TaskHoldEm() {
  const { sessionId } = useParams()
  const client = useClient()
  const [username, setUsername] = useLocalStorageState('taskholdem:username', {
    defaultValue: '',
  })
  const [session, setSession] = useState(null)
  const history = useHistory()
  const [joinedAt, setJoinedAt] = useState(0)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const isInSession =
    !!session?.users?.[client.uuid] || !!session?.observers?.[client.uuid]

  useEffect(() => {
    if (sessionId) {
      client
        .call('taskholdem.getSession', { id: sessionId })
        .then(session => {
          if (!session) {
            history.push('/taskholdem')
            setError('Session not found')
            return
          }
          setSession(session)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [sessionId])

  useEffect(() => {
    const channel = client.channel(sessionId)

    channel.subscribe('taskholdem:session_update').catch(console.error)

    function handler({ session }) {
      setSession(session)
    }

    channel.on('taskholdem:session_update', handler)

    return () => {
      channel.off('taskholdem:session_update', handler)
      channel.unsubscribe('taskholdem:session_update').catch(console.error)
    }
  }, [sessionId, joinedAt])

  async function submit({ observe = false } = {}) {
    try {
      if (session) {
        const updatedSession = await client.call('taskholdem.joinSession', {
          id: sessionId,
          username,
          observe,
        })

        if (updatedSession.error) {
          setError(updatedSession.error)
          return
        }

        setSession(updatedSession)

        setJoinedAt(Date.now())
        setError(null)
        return
      }

      const newSession = await client.call('taskholdem.createSession', {
        username,
        observe,
      })

      setSession(newSession)
      setError(null)

      history.push(`/taskholdem/${newSession.id}`)
    } catch (error) {
      setError(error.message)
    }
  }

  if (loading) {
    return <TimedLoader />
  }

  return (
    <div className='mx-auto mt-24 max-w-xl'>
      <Helmet>
        <title>Task Hold 'em</title>
      </Helmet>

      <h1 className='mb-4 text-center'>Task Hold 'em</h1>

      {!isInSession ? (
        <NameForm
          username={username}
          setUsername={setUsername}
          submit={submit}
          session={session}
        />
      ) : null}

      {error ? (
        <p className='mt-4 animate-pulse text-center text-sm uppercase text-red-500'>
          {error}
        </p>
      ) : null}

      {session !== true ? (
        <Session session={session} isInSession={isInSession} />
      ) : null}

      <div className='mt-16 text-center text-xs opacity-50'>
        Made with{' '}
        <a
          href='https://github.com/leonardoventurini/helene'
          target='_blank'
          rel='noreferrer'
        >
          Helene
        </a>
      </div>
    </div>
  )
}

TaskHoldEm.layoutProps = {
  navigation: false,
  chat: false,
}
