import { t } from '@lingui/macro'
import React from 'react'
import { BaseButton } from '@/client/components/practice/buttons/base-button'
import { SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/react/24/outline'

export function AutoPlayButton({ toggleAutoPlay, isAutoPlayEnabled }) {
  return (
    <BaseButton
      onClick={toggleAutoPlay}
      rightIcon={isAutoPlayEnabled ? SpeakerWaveIcon : SpeakerXMarkIcon}
      text={t`Audio`}
    />
  )
}
