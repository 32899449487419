import { fetchFileWithAuth } from '@/client/utils/fetch-file-with-auth'
import { BoardNode, NodeType } from '@/common/constants/boards'
import { useClient } from '@helenejs/react'
import { useEffect, useState } from 'react'

export function usePdfUrl(node: BoardNode<string>) {
  const client = useClient()
  const [pdfUrl, setPdfUrl] = useState(null)

  useEffect(() => {
    if (node.type === NodeType.PDF) {
      if (!node.pdf) {
        return setPdfUrl(null)
      }

      fetchFileWithAuth(client, `board/${node.board}/file/${node.pdf}`).then(
        setPdfUrl,
      )
    } else {
      setPdfUrl(null)
    }
  }, [node.type, node.pdf])

  return pdfUrl
}
