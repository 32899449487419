import { faDiscord, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faX } from '@fortawesome/free-solid-svg-icons/faX'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import React from 'react'
import { Link } from 'react-router-dom'

export function Footer() {
  return (
    <footer>
      <div className='mx-auto mb-4 grid max-w-7xl grid-flow-row-dense gap-4 text-slate-700 dark:text-slate-300 lg:grid-cols-3'>
        <div className='flex items-center justify-center gap-2 lg:justify-start'>
          <img src='/favicon.svg' width={32} height={32} alt='Sapienza' />
          <div className='text-sm font-semibold text-gray-600 dark:text-gray-400'>
            Copyright © {new Date().getFullYear()}
          </div>
        </div>

        <div className='flex flex-col items-center justify-center gap-4 md:flex-row'>
          <Link
            to='/terms-of-use'
            className='text-sm font-semibold text-slate-800 opacity-80 transition-all hover:no-underline hover:opacity-100 dark:text-slate-400'
          >
            <Trans>Terms of Use</Trans>
          </Link>
          <Link
            to='/privacy-policy'
            className='text-sm font-semibold text-slate-800 opacity-80 transition-all hover:no-underline hover:opacity-100 dark:text-slate-400'
          >
            <Trans>Privacy Policy</Trans>
          </Link>
        </div>

        <div className='flex items-center justify-center gap-4 lg:justify-end'>
          <a
            href='https://discord.gg/YyH6Z7tm5J'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon
              icon={faDiscord}
              className='h-5 w-5 text-black dark:text-white'
            />
          </a>
          <a href='https://x.com/metaboardcc' target='_blank' rel='noreferrer'>
            <FontAwesomeIcon
              icon={faX}
              className='h-5 w-5 text-black dark:text-white'
            />
          </a>
          <a
            href='https://www.linkedin.com/company/metaboard'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              className='h-5 w-5 text-black dark:text-white'
            />
          </a>
        </div>
      </div>
      <div className='mb-4 text-center text-xs font-medium text-slate-500'>
        <div>TECHSTER TECH LTDA | CNPJ 42.866.654/0001-42</div>
      </div>
    </footer>
  )
}
