import { isMutableRefObject } from '@/client/utils/components'
import { MutableRefObject, useEffect } from 'react'

export function useClickOutside(
  ref: MutableRefObject<HTMLElement> | HTMLElement,
  handler: () => void,
  deps: any[] = [],
) {
  const element = isMutableRefObject(ref) ? ref.current : ref

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (!element || element.contains(event.target as Node)) {
        return
      }
      handler()
    }

    window.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('touchstart', handleClickOutside)
    window.addEventListener('contextmenu', handleClickOutside)

    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('touchstart', handleClickOutside)
      window.removeEventListener('contextmenu', handleClickOutside)
    }
  }, [element, ...deps])
}
