import { useMergedRefs } from '@hooks/use-merged-refs'
import { t } from '@lingui/macro'
import { TextInput, TextInputProps } from '@mantine/core'
import { useField } from 'formik'
import React, { forwardRef } from 'react'

export const URLInputField = forwardRef<HTMLInputElement, TextInputProps>(
  (props, inputRef) => {
    if (!props.name) throw new Error('missing name prop')
    const [field, meta, helper] = useField(props.name)

    const ref = useMergedRefs(inputRef) as any

    return (
      <TextInput
        {...props}
        type='url'
        value={field.value ?? ''}
        onInput={e => {
          // @ts-ignore
          helper.setValue(e.target.value)
        }}
        ref={ref}
        error={meta.error}
        placeholder={t`Link URL`}
      />
    )
  },
)
