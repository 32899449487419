import { FunkyButton } from '@/client/components/ui/funky-button'
import { MetaboardEvent } from '@/common/events'
import { Modal } from '@components/ui/modal'
import { useLocalEvent } from '@helenejs/react'
import React, { useState } from 'react'

export function GlobalAlert() {
  const [visible, setVisible] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  useLocalEvent(
    {
      event: MetaboardEvent.ALERT,
    },
    message => {
      setAlertMessage(message)
      setVisible(true)
    },
    [],
  )

  const close = () => {
    setVisible(false)
    setTimeout(() => {
      setAlertMessage('')
    }, 1000)
  }

  return (
    <Modal isOpen={visible} onClose={close} className='prose text-center'>
      <p>{alertMessage}</p>
      <FunkyButton variant='warning' onClick={close}>
        OK
      </FunkyButton>
    </Modal>
  )
}
