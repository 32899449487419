import { DeckSection } from '@/client/components/deck-editor/deck-section'
import { DeckSectionNavigator } from '@/client/components/deck-editor/deck-section-navigator'
import { EditableCell } from '@/client/components/deck-editor/editable-cell'
import { EditableHeader } from '@/client/components/deck-editor/editable-header'
import { EditableRadioList } from '@/client/components/deck-editor/editable-radio-list'
import { ExerciseDrawer } from '@/client/components/deck-editor/exercise-drawer'
import { FunkyButton } from '@/client/components/ui/funky-button'
import { PremiumTag } from '@/client/components/ui/premium-tag'
import {
  ClientDeckCollection,
  ClientExerciseCollection,
  ClientSectionCollection,
} from '@/client/data'
import { useClient, useCount, useFind, useFindOne } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import { useAsyncEffect } from 'ahooks'
import first from 'lodash/first'
import React, { useState } from 'react'

export async function persistDeck({ client, onSave }) {
  const deck = await ClientDeckCollection.findOne({})

  delete deck._original

  const sections = await ClientSectionCollection.find({
    $or: [
      { _new: true },
      { _modified: true },
      { removedAt: { $exists: true } },
    ],
  }).map(doc => {
    delete doc._original
    return doc
  })

  const exercises = await ClientExerciseCollection.find({
    $or: [
      { _new: true },
      { _modified: true },
      { removedAt: { $exists: true } },
    ],
  }).map(doc => {
    delete doc._original
    return doc
  })

  try {
    const deckId = await client.call('deck.persist', {
      deck,
      sections,
      exercises,
    })

    onSave?.({
      ...deck,
      _id: deckId,
    })
  } catch (error) {
    console.error(error)
  }
}

export function DeckBuilder({ onSave }) {
  const { isPremiumActive } = useMetaboardAuth()

  const [isPersisting, setPersisting] = useState(false)
  const [activeSectionId, setActiveSectionId] = useState(null)

  const currentSection = useFindOne(
    ClientSectionCollection,
    {
      _id: activeSectionId ? activeSectionId : { $exists: true },
    },
    { order: 1 },
  )

  const sections = useFind(
    ClientSectionCollection,
    { removedAt: { $exists: false } },
    { order: 1 },
  )

  useAsyncEffect(async () => {
    const section = await ClientSectionCollection.findOne({
      _id: activeSectionId,
    })

    if (!activeSectionId || !section) {
      setActiveSectionId(first(sections)?._id)
    }
  }, [sections, activeSectionId])

  const deck = useFindOne(ClientDeckCollection, {})

  const deckChangedOrNew = useCount(ClientDeckCollection, {
    $or: [{ _new: true }, { _modified: true }],
  })

  const sectionChangedRemovedOrNewCount = useCount(ClientSectionCollection, {
    $or: [
      { _new: true },
      { _modified: true },
      { removedAt: { $exists: true } },
    ],
  })

  const exerciseChangedRemovedOrNewCount = useCount(ClientExerciseCollection, {
    $or: [
      { _new: true },
      { _modified: true },
      { removedAt: { $exists: true } },
    ],
  })

  const client = useClient()

  const canSubmit =
    deckChangedOrNew > 0 ||
    sectionChangedRemovedOrNewCount > 0 ||
    exerciseChangedRemovedOrNewCount > 0

  const DeckVisibility = [
    {
      name: t`Public`,
      description: t`The community can see this deck`,
      value: false,
    },
    {
      name: (
        <>
          <Trans>Private</Trans>
          <PremiumTag />
        </>
      ),
      description: t`Only you can see this deck`,
      value: true,
      disabled: !isPremiumActive,
    },
  ]

  if (!deck) {
    return null
  }

  return (
    <div className='prose grid w-full max-w-full gap-8'>
      <div className='grid gap-8'>
        <EditableHeader
          className='text-2xl font-bold'
          field='title'
          collection={ClientDeckCollection}
          data={deck}
        />

        <label>
          <Trans>Description</Trans>
          <EditableCell
            collection={ClientDeckCollection}
            data={deck}
            field='description'
            placeholder={t`No Description`}
            rows={5}
            markdown
          />
        </label>
        <label>
          <Trans>Visibility</Trans>
          <EditableRadioList
            collection={ClientDeckCollection}
            data={deck}
            field='private'
            items={DeckVisibility}
          />
        </label>
      </div>
      <div className='grid gap-4 lg:grid-cols-[300px_1fr]'>
        <DeckSectionNavigator
          sections={sections}
          currentSection={currentSection}
          sectionId={activeSectionId}
          setSectionId={setActiveSectionId}
        />
        <DeckSection section={currentSection} />
        <ExerciseDrawer
          onSave={async data => {
            await ClientExerciseCollection.update(
              { _id: data._id },
              { $set: data },
            )
          }}
          sections={sections}
        />
      </div>
      <div className='flex items-center justify-end'>
        <FunkyButton
          onClick={async () => {
            setPersisting(true)

            await persistDeck({ client, onSave })

            setPersisting(false)
          }}
          disabled={!canSubmit}
          loading={isPersisting}
          variant='primary'
        >
          {deck._original ? <Trans>Save</Trans> : <Trans>Create</Trans>}
        </FunkyButton>
      </div>
    </div>
  )
}
