import { useRecModeProps } from '@/client/hooks/use-rec-mode-props'
import { Permission } from '@/common/constants/permissions'
import { useBoardId } from '@components/boards/hooks/use-board-id'
import DeleteConfirmation from '@components/delete-confirmation'
import { useClient } from '@helenejs/react'
import { t, Trans } from '@lingui/macro'
import {
  Avatar,
  Button,
  Group,
  SegmentedControl,
  Table,
  Text,
} from '@mantine/core'
import isEmpty from 'lodash/isEmpty'
import { LucideEye, LucidePencil } from 'lucide-react'
import React from 'react'

export function ShareList({ data, refresh }) {
  const client = useClient()
  const boardId = useBoardId()

  const [removingAccess, setRemovingAccess] = React.useState(false)
  const activeItemRef = React.useRef(null)

  const recModeProps = useRecModeProps()

  if (isEmpty(data)) {
    return (
      <Text fz='xs' className='text-center text-gray-500'>
        <Trans>
          No one but you has access to this board. Share it with someone!
        </Trans>
      </Text>
    )
  }

  const rows = data.map(item => (
    <Table.Tr key={item.name}>
      <Table.Td>
        <Group gap='sm'>
          <Avatar size={40} src={item.googlePicture} radius={40} />
          <div>
            <Text fz='sm' fw={500} {...recModeProps}>
              {item.name}
            </Text>
            <Text fz='xs' c='dimmed' {...recModeProps}>
              {item.email}
            </Text>
          </div>
        </Group>
      </Table.Td>

      <Table.Td className='flex items-center gap-2'>
        <SegmentedControl
          size='xs'
          color='indigo'
          value={item.permission}
          onChange={async value => {
            await client.call('boards.changeAccessPermission', {
              boardId,
              userId: item.user,
              permission: value,
            })

            refresh()
          }}
          data={[
            {
              label: (
                <span className='flex items-center gap-1.5'>
                  <LucideEye size={16} /> <Trans>See</Trans>
                </span>
              ),
              value: Permission.Read,
            },
            {
              label: (
                <span className='flex items-center gap-1.5'>
                  <LucidePencil size={16} /> <Trans>Edit</Trans>
                </span>
              ),
              value: Permission.ReadWrite,
            },
          ]}
        />

        <Button
          onClick={async () => {
            setRemovingAccess(true)
            activeItemRef.current = item
          }}
          size='xs'
          variant='subtle'
          color='red'
        >
          <Trans>Remove</Trans>
        </Button>
      </Table.Td>
    </Table.Tr>
  ))

  return (
    <>
      <DeleteConfirmation
        title={t`Remove Access`}
        message={t`Are you sure you want to remove access for ${activeItemRef.current?.name}?`}
        isOpen={!!removingAccess}
        onConfirm={async () => {
          await client.call('boards.removeAccess', {
            boardId,
            userId: activeItemRef.current?.user,
          })

          refresh()

          setRemovingAccess(false)
        }}
        onCancel={() => setRemovingAccess(false)}
        onClose={() => setRemovingAccess(false)}
      />
      <Table verticalSpacing='sm'>
        <Table.Thead>
          <Table.Tr>
            <Table.Th className='text-black/80 dark:text-white/80'>
              <Trans>User</Trans>
            </Table.Th>
            <Table.Th>
              <Trans>Permission</Trans>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </>
  )
}
