import { Button } from '@/client/components/ui/button'
import { getBrowserLanguage } from '@/client/utils/i18n'
import { InputField } from '@components/fields/input-field'
import { FormGroup } from '@components/ui/forms/form-group'
import { OTPField } from '@components/ui/forms/otp-field'
import { useClient } from '@helenejs/react'
import { ClientEvents } from '@helenejs/utils'
import { t, Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Form, Formik, useFormikContext } from 'formik'
import defer from 'lodash/defer'
import React, { useState } from 'react'
import { object, string } from 'yup'

/**
 * @todo Show error message when OTP is invalid
 */
export function OTPForm({ email, onBack }) {
  const formik = useFormikContext()

  return (
    <Form className='mx-auto flex w-full max-w-md flex-col pt-12' noValidate>
      <FormGroup
        label={t`Authentication Code`}
        labelClassName='text-center text-xl'
        className='space-y-4'
      >
        <div className='text-center text-sm'>
          <Trans>
            A 6-digit authentication code has been sent to your email address{' '}
            <b>{email}</b>. Please enter the authentication code below:
          </Trans>
        </div>

        <OTPField
          name='otp'
          onComplete={() => {
            formik.submitForm().catch(console.error)
          }}
          disabled={formik.isSubmitting}
          errorClassName='block text-center bg-transparent border-none text-red-500 text-base'
        />
      </FormGroup>

      <div className='mb-4 text-center text-sm italic'>
        <Trans>In case you don't find it, check your spam folder</Trans>
      </div>

      <div className='text-center'>
        <Button
          onClick={e => {
            e.preventDefault()
            onBack?.()
          }}
          variant='ghost'
        >
          <Trans>Back</Trans>
        </Button>
      </div>
    </Form>
  )
}

export function EmailForm({ error, loading, onBack }) {
  return (
    <Form className='mx-auto flex w-full max-w-md flex-col' autoComplete='on'>
      <div className='mb-8 mt-8 text-center text-xl'>
        <Trans>Sign in with Email</Trans>
      </div>

      <InputField
        type='email'
        name='email'
        placeholder={t`Email`}
        className='input-bordered input-ghost w-full'
        autoComplete='email'
      />

      {error ? (
        <div className='alert alert-error mb-4 justify-center bg-red-500/30 text-sm text-red-500'>
          <div>{error}</div>
        </div>
      ) : null}

      <Button
        type='submit'
        className={classNames('mb-8 h-[48px] w-full rounded-lg')}
        loading={loading}
      >
        <Trans>Sign In</Trans>
      </Button>

      <Button
        onClick={e => {
          e.preventDefault()
          onBack?.()
        }}
        variant='ghost'
      >
        <Trans>Back</Trans>
      </Button>
    </Form>
  )
}

export function EmailSignInForm({ state, onBack }) {
  const client = useClient()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hasSentOTP, setHasSentOTP] = useState(false)

  const [email, setEmail] = useState('')

  const LoginSchema = object().shape({
    email: string()
      .email(t`It needs to be a valid email`)
      .required(t`Please enter your email`),
  })

  if (hasSentOTP) {
    return (
      <Formik
        initialValues={{ otp: '' }}
        onSubmit={async ({ otp }, helpers) => {
          try {
            state.isLoading = true
            await client.login({ email, otp })
            await client.waitFor(ClientEvents.INITIALIZED, 30000)
            state.isLoading = false
          } catch (error) {
            state.isLoading = false
            console.error(error)
            helpers.setFieldValue('otp', '')
            defer(() => {
              helpers.setFieldError('otp', t`Invalid Code`)
            })
          }
        }}
      >
        <OTPForm
          email={email}
          onBack={() => {
            setHasSentOTP(false)
          }}
        />
      </Formik>
    )
  }

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={async ({ email }, { setSubmitting }) => {
        setLoading(true)

        try {
          await client.call('user.otp', {
            email,
            browserLanguage: getBrowserLanguage(),
          })

          setEmail(email)
          setHasSentOTP(true)
        } catch (error) {
          console.log({ error })
          setError(error.message)
        } finally {
          setLoading(false)
          setSubmitting(false)
        }
      }}
      validationSchema={LoginSchema}
    >
      <EmailForm error={error} loading={loading} onBack={onBack} />
    </Formik>
  )
}
