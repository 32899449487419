import { cn } from '@/client/utils/cn'
import React from 'react'

export function TextArea({ className, ...props }) {
  return (
    <textarea
      className={cn(
        'block w-full rounded-lg border border-slate-300 bg-slate-50 p-2.5 text-sm text-slate-900 focus:border-blue-500 focus:ring-0 focus:ring-blue-500 dark:border-slate-600 dark:bg-slate-700 dark:text-white dark:placeholder-slate-400 dark:focus:border-blue-500 dark:focus:ring-blue-500',
        className,
      )}
      {...props}
    />
  )
}
