import React from 'react'

export function FormControl({ children, label }) {
  return (
    <div className='form-control'>
      <label className='label'>
        <span className='label-text'>{label}</span>
      </label>
      {children}
    </div>
  )
}
