import { cn } from '@/client/utils/cn'
import { MetaboardEvent } from '@/common/events'
import { useLocalEvent } from '@helenejs/react'
import { useLocaleMemo } from '@hooks/use-locale-memo'
import { useReactive } from '@hooks/use-reactive-local-storage'
import { t } from '@lingui/macro'
import { AnimatePresence, motion } from 'framer-motion'

export function ComboNotification() {
  const state = useReactive({
    show: false,
    combo: null,
  })

  const ComboData = useLocaleMemo(() => ({
    5: {
      name: t`Good`,
      className: 'text-green-600 dark:text-green-400',
    },
    10: {
      name: t`Great`,
      className: 'text-blue-600 dark:text-blue-400',
    },
    15: {
      name: t`Excellent`,
      className: 'text-yellow-600 dark:text-yellow-400',
    },
    20: {
      name: t`Incredible`,
      className: 'text-orange-600 dark:text-orange-400',
    },
    25: {
      name: t`Perfect`,
      className: 'text-red-600 dark:text-red-400',
    },
  }))

  useLocalEvent(
    {
      event: MetaboardEvent.COMBO,
    },
    ({ combo }) => {
      state.combo = combo
      state.show = true

      setTimeout(() => {
        state.show = false
      }, 2000)
    },
  )

  const data = ComboData[state.combo]

  return (
    <div
      className={cn(
        'absolute left-0 right-0 top-0 flex justify-center pt-24',
        data?.className,
      )}
    >
      <AnimatePresence>
        {state.show ? (
          <motion.div
            key='combo'
            initial={{ opacity: 0, top: -50 }}
            animate={{ opacity: 1, top: 0, transition: { delay: 0.75 } }}
            exit={{ opacity: 0, top: 50 }}
            className={cn(
              'relative flex flex-col items-center justify-center gap-4 rounded-lg p-3',
              'z-[100]',
            )}
            onAnimationComplete={definition => {
              if (definition.opacity === 0) {
                state.combo = null
              }
            }}
          >
            <div className='flex gap-2 text-center text-2xl font-medium'>
              {data.name}! {state.combo} in a row
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  )
}
