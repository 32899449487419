import { useLoadMore } from '@/client/components/practice/use-load-more'
import { DefaultGameSettings, GameMode } from '@/common/constants'
import { useAnswer } from '@components/practice/use-answer'
import { useObject } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useCreation, useDeepCompareEffect } from 'ahooks'
import merge from 'lodash/merge'
import { useCallback, useState } from 'react'

export async function checkFinished({ client, deckId }) {
  if (!client.authenticated) return true

  return await client.call('exercise.has_more', { deckId })
}

export function useGame({ deckId, state, exercise, history }) {
  const { user, client } = useMetaboardAuth()

  const settings = useCreation(() => {
    return merge({}, DefaultGameSettings, user?.gameSettings ?? {})
  }, [user])

  const { gameMode } = settings

  const isSessionMode = gameMode === GameMode.SESSION
  const isUnlimitedMode = gameMode === GameMode.UNLIMITED

  const [sessionProgress, setSessionProgress] = useState(0)
  const [finished, setFinished] = useState(false)

  const next = useCallback(() => {
    setSessionProgress(sessionProgress + 1)

    const remainder = (sessionProgress % maxSessionLength) + 1
    const isMultipleOfSessionLength = remainder === maxSessionLength
    const sessionHalf = Math.round(maxSessionLength / 2)

    if (isMultipleOfSessionLength && !isUnlimitedMode) {
      state.isExLoading = true

      checkFinished({ client, deckId })
        .then(hasMore => {
          if (!hasMore) state.data.session = []
        })
        .finally(() => {
          setFinished(true)
          state.isExLoading = false
        })
      return
    }

    if (remainder === sessionHalf && isUnlimitedMode) {
      loadMore().catch(console.error)
    }

    if (state.currentIndex === state.data?.session?.length - 1) {
      setFinished(true)
      state.data.session = []
      return
    }

    state.currentIndex++
    state.currentItem = state.data.session[state.currentIndex]
  }, [sessionProgress, state, state.data])

  const { maxSessionLength } = state.data ?? {}

  const loadMore = useLoadMore({
    state,
    deckId,
    maxSessionLength,
  })

  const reload = useCallback(async () => {
    console.log('Reloading...')
    state.isExLoading = true
    state.currentIndex = 0
    state.virtualSessionNum = 0
    state.currentItem = null
    state.data = await client.call('exercise.next', { deckId })
    state.isExLoading = false
  }, [state.data])

  const answer = useAnswer({ exercise, history, next, state })

  const restart = useCallback(() => {
    setSessionProgress(0)
    setFinished(false)
    state.correctCount = 0
    state.incorrectCount = 0
    state.hasLearnedNewTerm = false
    state.xpEarned = 0
    state.bonusXpEarned = 0
    state.consecutiveHits = 0
    reload().catch(console.error)
  }, [reload])

  useDeepCompareEffect(() => {
    restart()
  }, [settings])

  return useObject({
    gameMode,
    settings,
    sessionLength: maxSessionLength,
    isSessionMode,
    isUnlimitedMode,
    sessionProgress,
    next,
    finished,
    answer,
    restart,
    reload,
    loadMore,
  })
}
