import { ClientBoardCollection, ClientTeamCollection } from '@/client/data'
import { patchDocument } from '@/client/reactivity/patch-document'
import { useHeleneSubscribe } from '@/client/reactivity/use-helene-subscribe'
import { BoardEvent } from '@/common/constants/boards'
import { Team } from '@/common/constants/team'
import { useFind } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import useCreation from 'ahooks/lib/useCreation'
import { useCallback } from 'react'

const lastEvents = new Set<string>()

const ensureStackSize = (stack: Set<string>, maxSize: number) => {
  if (stack.size > maxSize) {
    const [first] = stack
    stack.delete(first)
  }
}

export function useGlobalChangeEvents() {
  const { userId, currentWorkspace } = useMetaboardAuth()

  const teams = useFind(ClientTeamCollection, {
    org: currentWorkspace,
  }) as Team<string>[] | null

  const teamIds = useCreation(
    () => teams?.map(team => `team:${team._id}`),
    [teams],
  )

  const handleChanged = useCallback(
    async ({
      eventId,
      coll,
      _id,
      deleted,
      doc,
      updatedFields,
      removedFields,
    }) => {
      if (lastEvents.has(eventId)) return

      lastEvents.add(eventId)

      console.log('BoardChanged', coll, _id, deleted, doc)

      if (deleted) {
        await ClientBoardCollection.remove({ _id })
        return
      }

      await patchDocument(
        ClientBoardCollection,
        _id,
        doc,
        updatedFields,
        removedFields,
      )

      ensureStackSize(lastEvents, 1000)
    },
    [userId, teams],
  )

  useHeleneSubscribe(
    {
      event: BoardEvent.BoardChanged,
      channel: `user:${userId}`,
      active: !!userId,
    },
    handleChanged,
    [userId],
  )

  useHeleneSubscribe(
    {
      event: BoardEvent.BoardChanged,
      channel: teamIds,
      active: !!teamIds?.length,
    },
    handleChanged,
    [teamIds],
  )
}
