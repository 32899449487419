import { ExerciseStatus, ExerciseType, LanguageCode } from '@/common/constants'
import { MetaboardEvent } from '@/common/events'
import { Drawer } from '@components/ui/drawer'
import { useClient, useLocalEvent, useMethod } from '@helenejs/react'
import { useReactive } from '@hooks/use-reactive-local-storage'
import { t } from '@lingui/macro'
import pick from 'lodash/pick'
import React, { useEffect, useMemo, useState } from 'react'
import { LS } from '../../utils/ls'
import { ExerciseForm } from '../forms/exercise-form'

export function ExerciseDialog({ onSave, onClose }) {
  const [isOpen, setIsOpen] = useState(false)
  const [exerciseId, setExerciseId] = useState(null)
  const [deckId, setDeckId] = useState(null)
  const [sectionId, setSectionId] = useState(null)

  const state = useReactive({
    data: null,
  })

  const client = useClient()

  const { result, loading, refresh } = useMethod({
    method: 'exercise.get',
    params: { _id: exerciseId },
    deps: [exerciseId],
    required: ['_id'],
  })

  const { result: sections } = useMethod({
    method: 'section.listByCourseId',
    params: { deckId },
    parse(params) {
      if (!params.deckId) return null
    },
    deps: [deckId],
  })

  useEffect(() => {
    if (result) {
      setDeckId(result.deck)
    } else {
      setDeckId(null)
    }
  }, [result])

  useLocalEvent({ event: 'exercise.open' }, exerciseId => {
    setIsOpen(true)
    setExerciseId(exerciseId)
  })

  useLocalEvent({ event: 'exercise.create' }, ({ sectionId, deckId }) => {
    setIsOpen(true)
    setDeckId(deckId)
    setSectionId(sectionId)
  })

  useLocalEvent({ event: 'exercise.close' }, () => {
    setExerciseId(null)
    setIsOpen(false)
    setSectionId(null)
    setDeckId(null)
  })

  useLocalEvent({ event: 'exercise:update' }, refresh, [refresh])

  const initialValues = useMemo(
    () =>
      result ?? {
        section: sectionId,
        type: ExerciseType.MULTIPLE_CHOICE,
        questionLanguage: LanguageCode.EN,
        answerLanguage: LanguageCode.EN,
        status: ExerciseStatus.PUBLISHED,
        ...LS.get('exerciseForm'),
      },
    [result, sectionId],
  )

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        state.data = null
        client.emit('exercise.close')

        onClose?.()
      }}
      title={t`Card Editor`}
      loading={loading}
      onConfirm={
        state.data
          ? async () => {
              const { data } = state

              if (!data) return

              if (exerciseId) {
                await client.call('exercise.update', {
                  ...data,
                  _id: exerciseId,
                })
              } else {
                LS.set(
                  'exerciseForm',
                  pick(data, ['type', 'questionLanguage', 'answerLanguage']),
                )

                await client.call('exercise.create', {
                  deck: deckId,
                  ...data,
                })
              }

              client.emit(MetaboardEvent.LIVE_NOTIFICATION, {
                icon: 'success',
                message: exerciseId ? t`Exercise Saved` : t`Exercise Created`,
                timeout: 1000,
                variation: 'success',
              })

              onSave?.()

              client.emit('exercise:update', { exerciseId })
            }
          : undefined
      }
    >
      <ExerciseForm
        sections={sections}
        initialValues={initialValues}
        onSave={async data => {
          state.data = data
        }}
      />
    </Drawer>
  )
}
