import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripLines, faTrash } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useCount } from '@helenejs/react'
import { ClientExerciseCollection } from '@/client/data'

export function SectionRow({
  section,
  sectionId,
  setSectionId,
  handleDeleteSection,
  listeners,
  attributes,
  setNodeRef,
  style,
  isDragging,
  disableDelete,
}) {
  const exerciseCount = useCount(ClientExerciseCollection, {
    section: section._id,
    removedAt: { $exists: false },
  })

  return (
    <li
      ref={setNodeRef}
      style={style}
      className={classnames('group flex w-full min-w-0 flex-row gap-1', {
        'opacity-0': isDragging,
      })}
    >
      <div
        className={classnames(
          'flex w-full min-w-0',
          section._id === sectionId
            ? 'bg-indigo-100 font-bold dark:bg-cyan-100/20'
            : '',
        )}
        onClick={e => {
          e.stopPropagation()
          return setSectionId(section._id)
        }}
      >
        <div className='flex-shrink-1 flex-grow-0 truncate'>
          {section.title} ({exerciseCount})
        </div>
        <button
          className={classnames(
            'btn btn-square btn-ghost btn-sm ml-auto opacity-0 group-hover:opacity-100',
            { 'cursor-not-allowed': disableDelete },
          )}
          onClick={e => handleDeleteSection(e, section)}
          disabled={disableDelete}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
        <FontAwesomeIcon
          icon={faGripLines}
          className='cursor-grab text-gray-400 opacity-0 group-hover:opacity-100'
          {...listeners}
          {...attributes}
        />
      </div>
    </li>
  )
}
