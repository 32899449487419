import isPlainObject from 'lodash/isPlainObject'
import { marked } from 'marked'

export function ucfirst(s: string) {
  if (s.length <= 1) {
    return s.toUpperCase()
  }
  return s[0].toUpperCase() + s.slice(1).toLowerCase()
}

export function capitalizeWords(s: string) {
  return s
    .split(' ')
    .map(element => {
      return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase()
    })
    .join(' ')
}

export function snakeCaseToTitleCase(s: string) {
  return capitalizeWords(s.split('_').join(' '))
}

/**
 * https://gist.github.com/codeguy/6684588
 */
export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(' ')

  return value
    .normalize('NFD') // split an accented letter in the base letter and the accent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator
}

export function cleanText(s: string) {
  return s.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ')
}

export function smartLowerCase(s: string) {
  return cleanText(s).toLowerCase()
}

export function format(str: string, ...args: any[]) {
  str = String(str)

  if (args.length) {
    args = isPlainObject(args[0]) ? args[0] : args

    for (const key in args) {
      str = str.replace(
        new RegExp('(?<!\\{)\\{' + key + '\\}(?!\\})', 'gi'),
        args[key],
      )
    }

    str = str.replaceAll('{{', '{').replaceAll('}}', '}')
  }

  return str
}

export function unwrapQuotes(s: string) {
  return s.replace(/^['"](.*)['"]$/g, '$1')
}

export function renderMarkdown(s: string) {
  return marked.parse(s)
}

export async function transform(input: string, processors = []) {
  let output = input

  for (const processor of processors) {
    output = await processor(output)
  }

  return output
}

export function isYoutubeUrl(url: string) {
  if (url.includes('youtube.com/watch') || url.includes('youtube.com/shorts')) {
    return true
  }

  return false
}
