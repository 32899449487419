import { MAX_LENGTH } from '@components/chat/chat-input'
import { Trans } from '@lingui/macro'
import { Button } from 'flowbite-react'
import React from 'react'

export function ChatInputControl({
  message,
  onInputSend,
  setConfirmingClear,
  chat,
}) {
  return (
    <div className='absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2'>
      <div className='flex items-center space-x-5'>
        <div className='text-sm text-gray-500'>
          {message.length}/{MAX_LENGTH}
        </div>
      </div>
      <div className='flex flex-shrink-0 gap-2'>
        {chat?.bot ? (
          <Button
            color='red'
            onClick={e => {
              e.preventDefault()
              setConfirmingClear(true)
            }}
          >
            <Trans>Clear Chat</Trans>
          </Button>
        ) : null}
        <Button
          color='indigo'
          onMouseDown={e => {
            e.preventDefault()
            onInputSend?.()
          }}
          disabled={!message}
        >
          <Trans>Send</Trans>
        </Button>
      </div>
    </div>
  )
}
