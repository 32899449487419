import { cn } from '@/client/utils/cn'
import { useEffect, useRef } from 'react'
import defer from 'lodash/defer'

export function Card({ className, children, transition = false }) {
  const ref = useRef()

  useEffect(() => {
    defer(() => {
      if (ref.current && transition) {
        ref.current.classList.remove('opacity-0')
        ref.current.classList.add('opacity-100')
        ref.current.classList.remove('h-0')
        ref.current.classList.remove('p-0')
        ref.current.classList.add('p-6')
      }
    })
  }, [])

  return (
    <div
      ref={ref}
      className={cn(
        'rounded-lg bg-white p-6 shadow dark:bg-slate-800',
        transition
          ? 'border-box h-0 overflow-hidden p-0 opacity-0 transition-all duration-500'
          : '',
        className,
      )}
    >
      {children}
    </div>
  )
}
