import { SimplePoint } from '@components/boards/utils/virtualization'
import { useReactiveLocalStorage } from '@hooks/use-reactive-local-storage'
import { useCreation } from 'ahooks'

export function useViewportPersistence(boardId: string) {
  const viewportPositionStorage = useReactiveLocalStorage(
    'board:viewport:position',
    {},
  )

  return useCreation(
    () => ({
      initialPosition: viewportPositionStorage[boardId] ?? null,
      persistPosition: (pos: SimplePoint) => {
        viewportPositionStorage[boardId] = {
          x: pos.x,
          y: pos.y,
        }
      },
    }),
    [boardId, viewportPositionStorage],
  )
}
