import { Markdown } from '@/client/components/markdown'
import { FieldOutline } from '@components/ui/forms/field-outline'
import { useClient } from '@helenejs/react'
import { t } from '@lingui/macro'

import 'react-mde/lib/styles/scss/react-mde-all.scss'

import '@/client/assets/react-mde-light.scss'
import axios from 'axios'
import classnames from 'classnames'
import { useField } from 'formik'
import React, { useState } from 'react'
import S from 'react-mde'

// @ts-ignore
export const ReactMde = S.default ? S.default : S

/**
 * @warning Beware of https://github.com/vitejs/vite/issues/2139 with `react-mde`.
 */
export function MarkdownEditorField(props) {
  const { className, minHeight, name, disabled, ...rest } = props

  const [field, meta, helper] = useField(name)

  const client = useClient()
  const [selectedTab, setSelectedTab] = useState('write')

  const commands = [
    ['header', 'bold', 'italic', 'strikethrough'],
    ['link', 'quote', 'code', 'image'],
    ['unordered-list', 'ordered-list'],
  ]

  if (!client) return null

  const imageUrl = client.href('/image')

  return (
    <FieldOutline meta={meta}>
      <div className={className}>
        <ReactMde
          l18n={{
            write: t`Write`,
            preview: t`Preview`,
            pasteDropSelect: t`Paste or drop a file here, or click to select a file`,
            uploadingImage: t`Uploading image`,
          }}
          classes={{
            reactMde:
              'border border-gray-300 dark:border-slate-500 !rounded-md overflow-hidden',
            toolbar:
              'flex !bg-transparent h-[48px] dark:border-slate-500 !text-sm',
            textArea: classnames(
              'bg-white dark:bg-slate-800 text-black dark:text-slate-300',
              {
                'opacity-50 cursor-not-allowed pointer-events-none': disabled,
              },
            ),
          }}
          toolbarCommands={commands}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          value={field.value}
          onChange={val => (!disabled ? helper.setValue(val) : null)}
          generateMarkdownPreview={markdown =>
            Promise.resolve(<Markdown>{markdown}</Markdown>)
          }
          minEditorHeight={minHeight ?? 192}
          minPreviewHeight={(minHeight ?? 192) + 23}
          paste={{
            async *saveImage(data, file) {
              const formData = new FormData()
              formData.append('image', file)

              const {
                data: { success, error, _id },
              } = await axios.post(imageUrl, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              })

              if (error) console.error(error)

              if (success) yield client.href(`/image/${_id}`)

              return success
            },
          }}
          {...rest}
        />
      </div>
    </FieldOutline>
  )
}
