import { NavButton } from '@components/nav-button'
import { Trans } from '@lingui/macro'
import { Modal } from '@mantine/core'
import { LucideHelpCircle } from 'lucide-react'
import React, { useState } from 'react'

export function BoardHelp() {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <NavButton icon={LucideHelpCircle} onClick={() => setOpen(true)}>
        <Trans>Help</Trans>
      </NavButton>
      <Modal
        onClose={() => setOpen(false)}
        opened={isOpen}
        title={<Trans>Help</Trans>}
        size='xl'
      >
        <div className='prose'>
          <pre>Click/Touch + Dragging: Drag Viewport</pre>
          <pre>Right Click On Board: Create Node (Node Type Picker)</pre>
          <pre>
            Right Click On Node: Open Context Menu (Change Color, Delete,
            Rename)
          </pre>
          <pre>Double Click: Create Text Node</pre>
        </div>
      </Modal>
    </>
  )
}
