import { cn } from '@/client/utils/cn'
import { MetaboardEvent } from '@/common/events'
import { generateUUID } from '@/common/uuid'
import { Transition } from '@headlessui/react'
import { useLocalEvent } from '@helenejs/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import { Trans } from '@lingui/macro'
import { useUpdate } from 'ahooks'
import classnames from 'classnames'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import React, { useEffect, useMemo, useState } from 'react'

/**
 * Needs to persist between remounts.
 */
let notifications = []

const Icons = {
  success: (
    <CheckCircleIcon
      className='h-6 w-6 text-green-400 dark:text-green-600'
      aria-hidden='true'
    />
  ),

  error: (
    <XCircleIcon
      className='h-6 w-6 text-red-400 dark:text-red-600'
      aria-hidden='true'
    />
  ),

  warning: (
    <ExclamationCircleIcon
      className='h-6 w-6 text-amber-400 dark:text-amber-600'
      aria-hidden='true'
    />
  ),

  info: (
    <InformationCircleIcon
      className='h-6 w-6 text-blue-400 dark:text-blue-600'
      aria-hidden='true'
    />
  ),
}

const Backgrounds = {
  success: 'bg-green-300/40 dark:bg-green-600/40',
  error: 'bg-red-300/40 dark:bg-red-600/40',
  warning: 'bg-amber-300/40 dark:bg-amber-600/40',
  info: 'bg-blue-300/40 dark:bg-blue-600/40',
  default: 'bg-white dark:bg-slate-600/40',
}

type Props = {
  title?: string
  message: string
  icon?: string | JSX.Element
  closeable?: boolean
  action?: string | JSX.Element
  onClick?: () => void
  onClose?: () => void
  timeout?: number
  variation?: keyof typeof Backgrounds
}

export function NotificationCard({
  title,
  message,
  icon,
  closeable = true,
  action,
  onClick,
  onClose,
  timeout,
  variation = 'default',
}: Props) {
  const [show, setShow] = useState(true)

  const $icon = useMemo(() => {
    if (icon) {
      if (isString(icon)) {
        return Icons[icon]
      } else {
        return icon
      }
    }

    return null
  }, [icon])

  useEffect(() => {
    if (isNumber(timeout) && timeout > 0) {
      setTimeout(() => {
        setShow(false)
      }, timeout)
    }
  }, [])

  return (
    <Transition
      show={show}
      as='div'
      appear
      enter='transition-opacity duration-75'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-150'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
      className={cn(
        'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5',
        'backdrop-blur-md',
        Backgrounds[variation],
      )}
      afterLeave={() => {
        onClose?.()
      }}
    >
      <div className='p-4'>
        <div className='flex items-center gap-2'>
          <div className='flex-shrink-0'>{$icon}</div>
          <div className='flex w-0 flex-1 flex-col gap-3'>
            {title ? (
              <p className='m-0 text-sm font-medium text-gray-900 dark:text-gray-300'>
                {title}
              </p>
            ) : null}
            {message ? (
              <p className='m-0 text-sm text-gray-700 dark:text-gray-300'>
                {message}
              </p>
            ) : null}
            {action ? (
              <button
                onClick={onClick}
                className='inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm'
              >
                {action}
              </button>
            ) : null}
          </div>
          {closeable ? (
            <div className='flex flex-shrink-0'>
              <button
                type='button'
                className={cn(
                  'inline-flex rounded-md bg-transparent text-gray-700/50 hover:text-gray-700/100 focus:outline-none',
                  'dark:text-gray-300/50 dark:hover:text-gray-300/100',
                )}
                onClick={() => {
                  setShow(false)
                }}
              >
                <span className='sr-only'>
                  <Trans>Close</Trans>
                </span>
                <XMarkIcon className='h-5 w-5' aria-hidden='true' />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </Transition>
  )
}

export function LiveNotifications() {
  const update = useUpdate()

  useLocalEvent({ event: MetaboardEvent.LIVE_NOTIFICATION }, notification => {
    notifications = [
      ...notifications,
      {
        id: generateUUID(),
        ...notification,
      },
    ]
    update()
  })

  const remove = id => {
    notifications = notifications.filter(n => n.id !== id)
    update()
  }

  return (
    <div
      aria-live='assertive'
      className={classnames(
        'pointer-events-none fixed inset-0 z-[10003] flex items-end px-4 py-6 sm:items-start sm:p-6',
        {
          hidden: notifications.length === 0,
        },
      )}
    >
      <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
        {notifications.map(({ id, ...props }) => (
          <NotificationCard key={id} onClose={() => remove(id)} {...props} />
        ))}
      </div>
    </div>
  )
}
