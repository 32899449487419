import { MetaboardEvent } from '@/common/events'
import { useClient, useLocalEvent } from '@helenejs/react'
import { googleLogout, GoogleOAuthProvider } from '@react-oauth/google'
import { useCreation } from 'ahooks'
import React, { createContext } from 'react'

export const AuthProviderContext = createContext({})

export function InnerAuthProvider({ children }) {
  const client = useClient()

  useLocalEvent({ event: MetaboardEvent.REFRESH_GOOGLE_TOKEN }, credentials => {
    if (!credentials) {
      client.clearContext()
      return
    }

    const { token, exp } = credentials

    // We shouldn't trigger context change events or re-initialization
    client.context.token = token
    client.context.exp = exp
  })

  const value = useCreation(
    () => ({
      isAuthenticated: client.authenticated,
      isGoogleAuth: client.context.isGoogleAuth,
      logout: googleLogout,
    }),
    [client.authenticated, client.context.isGoogleAuth],
  )

  return (
    <AuthProviderContext.Provider value={value}>
      {children}
    </AuthProviderContext.Provider>
  )
}

export const AuthProvider = ({ children, clientId }) => {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <InnerAuthProvider>{children}</InnerAuthProvider>
    </GoogleOAuthProvider>
  )
}
