import {
  addDeck,
  addExercise,
  addSection,
  ClientDeckCollection,
  ClientExerciseCollection,
  ClientSectionCollection,
} from '@/client/data'
import { DeckCreatorPrompt } from '@components/deck-creator/deck-creator-prompt'
import { persistDeck } from '@components/deck-editor/deck-builder'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useReactiveLocalStorage } from '@hooks/use-reactive-local-storage'
import React, { useState } from 'react'

export function DeckCreator({ onSave }) {
  const [generating, setGenerating] = useState(false)

  const { user, client } = useMetaboardAuth()

  const state = useReactiveLocalStorage('deck:creator', {
    questionLanguage: user.language,
    answerLanguage: user.language,
  })

  return (
    <DeckCreatorPrompt
      setGenerating={setGenerating}
      onSave={async candidate => {
        const { title, description, flashcards, outline, article, cover } =
          candidate

        await ClientDeckCollection.remove({}, { multi: true })
        await ClientSectionCollection.remove({}, { multi: true })
        await ClientExerciseCollection.remove({}, { multi: true })

        await addDeck({
          title,
          description,
        })

        const section = await addSection()

        for (const { q, a, e } of flashcards) {
          await addExercise({
            questionLanguage: state.questionLanguage,
            answerLanguage: state.answerLanguage,
            question: q,
            answer: a,
            explanation: e,
            section,
          })
        }

        await persistDeck({
          client,
          onSave,
        })
      }}
      generating={generating}
      state={state}
    />
  )
}
