import { cn } from '@/client/utils/cn'
import { MetaboardEvent } from '@/common/events'
import { NavButton } from '@components/nav-button'
import { UserIcon } from '@heroicons/react/24/solid'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useRecModeProps } from '@hooks/use-rec-mode-props'
import { LucideMenu } from 'lucide-react'
import React from 'react'

export function UserMenuButton() {
  const { client, user, incomplete } = useMetaboardAuth()

  const recModeProps = useRecModeProps()

  if (!user) return null
  if (incomplete) return null

  return (
    <NavButton
      className='shrink-0 items-center text-xs'
      onClick={() => client.emit(MetaboardEvent.TOGGLE_MENU)}
    >
      {user.googlePicture ? (
        <img
          src={user.googlePicture}
          referrerPolicy='no-referrer'
          className={cn('inline h-5 w-5 rounded-full object-cover')}
          alt={user.name}
        />
      ) : (
        <UserIcon className='mr-2 inline h-4 w-4' />
      )}
      <span className={cn('hidden truncate lg:inline', recModeProps.className)}>
        {user.name}
      </span>

      <LucideMenu className='h-5 w-5' />
    </NavButton>
  )
}
