import React from 'react'
import { ExerciseRenderer } from '@/client/components/practice/exercise/exercise-renderer'
import { FinishedSession } from '@/client/components/practice/finished-session'

export function SessionMode({ type, params, game }) {
  if (game.finished) {
    return <FinishedSession game={game} {...params} />
  }

  return (
    <>
      <ExerciseRenderer type={type} params={params} />
    </>
  )
}
