import { useCreation } from 'ahooks'
import { i18n } from '@lingui/core'
import { string } from 'yup'
import { t } from '@lingui/macro'

export function useSchema(getSchema) {
  return useCreation(getSchema, [i18n.locale])
}

export const SchemaHook = {}

SchemaHook.useName = () =>
  useSchema(() =>
    string()
      .required(t`Please enter your full name`)
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        t`Name can only contain Latin letters`,
      )
      .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, t`Please enter your full name`)
      .max(32, t`Name must be less than 32 characters`),
  )
