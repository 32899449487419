import React from 'react'
import { RenderIcon } from '@/client/utils/parse-icon'
import { Link } from 'react-router-dom'

interface BreadcrumbsProps {
  items: {
    label: string
    to?: string
    icon?: React.ReactNode
  }[]
}

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  return (
    <div className='breadcrumbs text-sm'>
      <ul className='m-0'>
        {items.map((breadcrumb, index) => {
          const Component = breadcrumb.to ? Link : 'span'

          return (
            <li key={index}>
              <Component
                to={breadcrumb.to}
                className='flex items-center space-x-1'
              >
                <RenderIcon icon={breadcrumb.icon} />
                {breadcrumb.label}
              </Component>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
