import { ClientBoardTabCollection } from '@/client/models/board-tabs'
import { BoardViewport } from '@components/boards/board-viewport'
import { useFind, useFindOne } from '@helenejs/react'
import { LucideX } from 'lucide-react'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ClientBoardCollection } from '../data'
import { cn } from '../utils/cn'

function BoardTab({ tab, active, onClose }) {
  const history = useHistory()
  const board = useFindOne(ClientBoardCollection, { _id: tab.boardId })

  if (!board) return null

  return (
    <button
      className={cn(
        'group flex h-8 items-center gap-1 pl-4 pr-1 text-xs',
        active
          ? 'bg-white/75 font-medium dark:bg-slate-600/75'
          : 'bg-transparent',
      )}
      onClick={() => {
        history.push(`/b/${board._id}`)
      }}
    >
      {board.name}
      <span
        className='flex h-8 items-center justify-center opacity-0 group-hover:opacity-100 touch:opacity-100'
        onClick={async e => {
          e.stopPropagation()
          await ClientBoardTabCollection.remove({ _id: tab._id })
          onClose()
        }}
      >
        <LucideX className='h-4 w-4' />
      </span>
    </button>
  )
}

function BoardTabs() {
  const history = useHistory()
  const tabs = useFind(ClientBoardTabCollection, {}, { createdAt: -1 })

  const { boardId } = useParams<{ boardId: string }>()

  return (
    <div
      className={cn(
        'fixed left-4 right-4 top-20 z-[10000] flex h-8 items-center gap-2 rounded-lg bg-slate-500',
        'transition-all duration-300 ease-in-out',
        'bg-white/50 dark:bg-slate-600/50',
        'hover:bg-white/75 dark:hover:bg-slate-600/75',
        'overflow-hidden touch:hidden',
      )}
    >
      {tabs.map((tab, index) => (
        <BoardTab
          key={tab._id}
          tab={tab}
          active={tab.boardId === boardId}
          onClose={() => {
            if (tab.boardId !== boardId) return
            // if there is a previous tab, go to it
            if (index > 0) {
              history.push(`/b/${tabs[index - 1].boardId}`)
            } else if (index === 0 && tabs.length > 1) {
              // if there is a next tab, go to it
              history.push(`/b/${tabs[1].boardId}`)
            } else if (tabs.length === 1) {
              // if there is no next tab, go to the home page
              history.push('/')
            }
          }}
        />
      ))}
    </div>
  )
}

export function Board() {
  const { boardId } = useParams<{ boardId: string }>()

  return (
    <>
      <BoardTabs />
      <BoardViewport boardId={boardId} />
    </>
  )
}

Board.layoutProps = {
  noSpacing: true,
}
