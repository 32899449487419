import { TypingStatus } from '@/common/constants/chat'
import { useRemoteEvent } from '@helenejs/react'

export function useTypingEvent({ state, activeChat }) {
  return useRemoteEvent(
    {
      event: 'chat:typing',
      active: !!activeChat,
      channel: activeChat ? `chat:${activeChat._id}` : undefined,
    },
    ({ status, userId, botId }) => {
      if (status === TypingStatus.STARTED) {
        if (botId) {
          state.botsTyping.push(botId)
        } else {
          state.usersTyping.push(userId)
        }
      }

      if (status === TypingStatus.STOPPED) {
        if (botId) {
          state.botsTyping = state.botsTyping.filter(x => x !== botId)
        } else {
          state.usersTyping = state.usersTyping.filter(x => x !== userId)
        }
      }

      state.isSomeoneTyping =
        state.botsTyping.length || state.usersTyping.length
    },
    [activeChat, state.botsTyping, state.usersTyping],
  )
}
